import React, { Component } from 'react'
import styles from './NiceBox.module.css';


export class NiceBox extends Component {

    
    render() {

       
    

        return (
                <div className={styles.container}>
                    {this.props.children}
                </div>
            
        )
    }
}

export default NiceBox
