import { ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  Table,
  TableCaption,
  TabList,
  TagLabel,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveSankey } from "@nivo/sankey";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { FcBearish } from "react-icons/fc";
import { connect } from "react-redux";
import { a } from "react-spring";
import {
  ChevronDown,
  ChevronUp,
  TableHeader,
  CustomLink,
  TableFrame,
  CustomTab,
  CustomTabList,
  CustomTabPanels,
  CustomTabsContainer,
  DataPanel,
  ExchangeIcon,
  CustomTag,
  InsetTab,
  InsetTabList,
  InsetTabPanels,
  InsetTabsContainer,
  LineChart,
  PinIcon,
  SuitcaseIcon,
  StockPriceWidget,
  CustomTr,
  CustomTh,
  CustomTBody,
  CustomTd,
  NoDataWidget,
} from "../../../design_library";
import colors from "../../../helpers/colors";
import DataContainer from "../../Analysis/DataContainer";
import { Spinner } from "../../components";
import TradeList from "../../dashboard/DynamicDashboard/TradeList";
import NewsPanel from "../../dashboard/panels/NewsPanel";
import apiCalls from "../../helpers/apiCalls";
import API from "../../helpers/apiCalls";
import { ChartFrame } from "../../helpers/ChartFrame";
import { APIEndpoint } from "../../helpers/dataStructures";
import { Panel } from "../../helpers/Panel";
import { TabsAlt } from "../../helpers/Tabs";
import ScanOptionChains from "../../Lookup/ScanOptionsChains";
import FundamentalTrackingUpgrade from "../../payments/FundamentalTrackingUpgrade";
import UpgradePage from "../../payments/UpgradePage";
import TradeDetailModal from "../TradeDetailModal";
import ChartData from "./ChartData";
import FinancialStatRow from "./FinancialStatRow";
import "./FundamentalData.css";
import TradeListComp from "../../dashboard/DynamicDashboard/TradeList";
import { PoolContainer } from "./PoolContainer";

var abbreviate = require("number-abbreviate");
var abbreviateX = new abbreviate(["K", "M", "B", "T"]);
var currencyFormatter = require("currency-formatter");

// getFundamentalData
class FundamentalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      valid: false,
      fundamentalData: {},
      logoURL: "",
      stockPrice: "",
      trades: [],
    };
  }

  componentDidMount() {
    this.getTrades();

    console.log("Fundamental tab opened!");

    this.setState({ loading: true });

    if (this.props.standalone === true) {
      if (this.props.stockTicker.length > 0) {
        API.getFundamentalData(
          this.props.settings.APIEndpoint,
          this.props.stockTicker
        ).then((fundamentals) => {
          if (fundamentals.data === undefined) {
            this.setState({ loading: false, valid: false });
            this.props.onError("Could not find that fundamental chart");
          } else {
            this.setState({ fundamentalData: fundamentals.data });
            try {
              this.setState({
                logoURL:
                  "https://eodhistoricaldata.com/" +
                  fundamentals.data.General.LogoURL,
              });
            } catch (e) {
              console.log(e);
            }

            API.getStockPrice(
              this.props.settings.APIEndpoint,
              this.props.stockTicker
            ).then((stockPrice) => {
              try {
                if (stockPrice.data.data) {
                  this.setState({
                    loading: false,
                    valid: true,
                    stockPrice: stockPrice.data.data,
                  });
                } else {
                  this.setState({
                    loading: false,
                    valid: true,
                    stockPrice: stockPrice.data,
                  });
                }
              } catch (e) {
                console.log(e);
                this.setState({
                  loading: false,
                  valid: false,
                });
              }
            });
          }
        });
      } else {
        this.setState({ loading: false, valid: false });
      }
    } else {
      // if (this.state.tradeData.fundamentalData === undefined) {
      //     this.setState({ loading: false, valid: false });
      // }
      // else {

      API.getASpecificTrade(
        this.props.settings.APIEndpoint,
        this.props.tradeData._id
      ).then((data) => {
        this.setState({ tradeData: data.trade });

        API.getFundamentalData(
          this.props.settings.APIEndpoint,
          data.trade.stockTicker
        ).then((fundamentals) => {
          if (fundamentals.data === undefined) {
            this.setState({ loading: false, valid: false });
          } else {
            this.setState({
              loading: false,
              valid: true,
              fundamentalData: fundamentals.data,
              logoURL:
                "https://eodhistoricaldata.com/" +
                fundamentals.data.General.LogoURL,
            });
          }
        });
      });
    }
  }

  getTrades = () => {
    apiCalls
      .getTrades(APIEndpoint, { stockTicker: this.props.stockTicker })
      .then((data) => {
        if (data.success === true) {
          this.setState({ trades: data.trades });
        }
      });
  };

  render() {
    if (this.state.loading === true && this.props.standalone === true) {
      return (
        <Box
          maxW="2xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
          mt="10"
        >
          <Spinner thickness="4px" speed="0.4s" size="xl" />
          <Heading
            as="h3"
            size="lg"
            fontWeight="extrabold"
            letterSpacing="tight"
            mt="4"
          >
            We're searching for your stock...
          </Heading>
        </Box>
      );
    }
    if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.valid === false) {
      return (
        <Box
          maxW="2xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
        >
          <Icon as={FcBearish} w={126} h={126} pb="6" />
          <Heading
            as="h2"
            size="3xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            Fundamental data is not available
          </Heading>
          <Text mt="4" fontSize="lg">
            For some underlyings fundamental data is not available. This is
            usually the case for some ETNs, or funds.
          </Text>
        </Box>
      );
    } else if (this.props.standalone === true) {
      let fd = this.state.fundamentalData ? this.state.fundamentalData : null;

      // if(Object.keys(fd).length < 15) {
      //     return <></>
      // }

      let lastUpdated = 0;
      let dayChange = 0;
      let differenceFromTargetPrice = 0;
      if (fd.AnalystRatings) {
        lastUpdated = moment(fd.lastUpdated)
          .utc()
          .format("dddd Do MMMM YYYY hh:mm:ss z");
        dayChange = (
          (this.state.stockPrice.change / this.state.stockPrice.close) *
          100
        ).toFixed(2);
        differenceFromTargetPrice = Math.abs(
          this.state.stockPrice.close - fd.AnalystRatings.TargetPrice
        );
        if (fd.AnalystRatings.TargetPrice < this.state.stockPrice.close) {
          differenceFromTargetPrice = -differenceFromTargetPrice;
        }
      }
      // ({fd.stockTicker})

      let sankeyData = {
        nodes: [
          {
            id: "Total",
            nodeColor: "hsl(161, 70%, 50%)",
          },
          {
            id: "Insiders",
            nodeColor: "hsl(161, 70%, 50%)",
          },
          {
            id: "Institutions",
            nodeColor: "hsl(161, 70%, 50%)",
          },
          {
            id: "Others",
            nodeColor: "hsl(161, 70%, 50%)",
          },
        ],
        links: [
          {
            source: "Total",
            target: "Others",
            value: fd.SharesStats
              ? 100 -
                fd.SharesStats.PercentInstitutions -
                fd.SharesStats.PercentInsiders
              : 0,
          },
          {
            source: "Total",
            target: "Institutions",
            value: fd.SharesStats ? fd.SharesStats.PercentInstitutions : 0,
          },
          {
            source: "Total",
            target: "Insiders",
            value: fd.SharesStats ? fd.SharesStats.PercentInsiders : 0,
          },
        ],
      };

      //Create new nodes
      let total = 0;
      let holdings = [];

      try {
        Object.keys(fd.Holders ? fd.Holders.Funds : {}).forEach((fund) => {
          let data = fd.Holders.Funds[fund];
          sankeyData.nodes.push({
            id: data.name,
            nodeColor: "hsl(161, 70%, 50%)",
          });
          sankeyData.links.push({
            source: "Institutions",
            target: data.name,
            value: data.totalShares,
          });
          holdings.push({
            target: data.name,
            value: data.totalShares,
            type: "Fund",
          });
          total += data.totalShares;
        });
        Object.keys(fd.Holders ? fd.Holders.Institutions : {}).forEach(
          (Institutions) => {
            let data = fd.Holders.Institutions[Institutions];
            sankeyData.nodes.push({
              id: data.name,
              nodeColor: "hsl(161, 70%, 50%)",
            });
            sankeyData.links.push({
              source: "Institutions",
              target: data.name,
              value: data.totalShares,
            });
            holdings.push({
              target: data.name,
              value: data.totalShares,
              type: "Institutions",
            });
            total += data.totalShares;
          }
        );
      } catch (e) {
        console.log(e);
      }
      sankeyData.nodes.push({
        id: "Multiple smaller holdings",
        nodeColor: "hsl(161, 70%, 50%)",
      });
      sankeyData.links.push({
        source: "Institutions",
        target: "Multiple smaller holdings",
        value: fd.SharesStats ? fd.SharesStats.PercentInstitutions - total : 0,
      });

      let institutionalHoldings = fd.SharesStats
        ? fd.SharesStats.PercentInstitutions - total
        : 0;
      let insidersHoldings = fd.SharesStats
        ? fd.SharesStats.PercentInsiders
        : 0;
      let othersHoldings = fd.SharesStats
        ? 100 -
          fd.SharesStats.PercentInstitutions -
          fd.SharesStats.PercentInsiders
        : 0;
      // holdings // MAP
      //   Holders

      holdings.sort((a, b) => {
        return b.value - a.value;
      });

      let totalAnalyst = fd.AnalystRatings
        ? fd.AnalystRatings.StrongBuy +
          fd.AnalystRatings.Buy +
          fd.AnalystRatings.Hold +
          fd.AnalystRatings.Sell +
          fd.AnalystRatings.StrongSell
        : 0;
      let buyRatings = fd.AnalystRatings
        ? fd.AnalystRatings.StrongBuy + fd.AnalystRatings.Buy
        : 0;

      let earningsData = [
        {
          id: "Actual",
          color: "hsla(99, 100%, 29%, 1)",
          data: [],
        },
        {
          id: "Estimate",
          color: "hsla(99, 100%, 51%, 1)",
          data: [],
        },
      ];

      let history = fd.Earnings ? fd.Earnings.History : {};

      let estimateData = [];
      let actalData = [];
      let estimate_lowest = 9999999999;

      function getQuarter(date) {
        let month = moment(date, "YYYY-MM-DD").format("M");
        let year = moment(date, "YYYY-MM-DD").format("YY");
        let quarter = Math.ceil(month / 3);
        return "Q" + quarter + " FY" + year;
      }

      Object.keys(history)
        .reverse()
        .filter((annual) => {
          if (
            moment(fd.Earnings.History[annual].reportDate).isAfter(
              moment().subtract(5, "year")
            ) &&
            moment(fd.Earnings.History[annual].reportDate).isBefore(moment())
          ) {
            if (fd.Earnings.History[annual].epsActual !== null) {
              actalData.push({
                x: getQuarter(
                  moment(fd.Earnings.History[annual].reportDate, "YYYY-MM-DD")
                ),
                y: parseFloat(fd.Earnings.History[annual].epsActual),
              });

              if (
                parseFloat(fd.Earnings.History[annual].epsActual) <
                estimate_lowest
              ) {
                estimate_lowest = parseFloat(
                  fd.Earnings.History[annual].epsActual
                );
              }
            }

            if (fd.Earnings.History[annual].epsEstimate !== null) {
              estimateData.push({
                x: getQuarter(
                  moment(fd.Earnings.History[annual].reportDate, "YYYY-MM-DD")
                ),
                y: parseFloat(fd.Earnings.History[annual].epsEstimate),
              });

              if (
                parseFloat(fd.Earnings.History[annual].epsEstimate) <
                estimate_lowest
              ) {
                estimate_lowest = parseFloat(
                  fd.Earnings.History[annual].epsEstimate
                );
              }
            }
          }
        });

      estimateData.sort(a, (b) => {
        if (a.x.isAfter(b.x)) {
          return 1;
        } else {
          return -1;
        }
      });

      actalData.sort(a, (b) => {
        if (a.x.isAfter(b.x)) {
          return 1;
        } else {
          return -1;
        }
      });

      earningsData[0].data = actalData;
      earningsData[1].data = estimateData;

      let Balance_Sheet_Lowest = 9999999999;

      let BalanceSheet = [];
      let FinancialsPossibleValues = [
        "totalAssets",
        "intangibleAssets",
        "earningAssets",
        "otherCurrentAssets",
        "totalLiab",
        "totalStockholderEquity",
        "deferredLongTermLiab",
        "otherCurrentLiab",
        "commonStock",
        "retainedEarnings",
        "otherLiab",
        "goodWill",
        "otherAssets",
        "cash",
        "totalCurrentLiabilities",
        "netDebt",
        "shortTermDebt",
        "shortLongTermDebt",
        "shortLongTermDebtTotal",
        "otherStockholderEquity",
        "propertyPlantEquipment",
        "totalCurrentAssets",
        "longTermInvestments",
        "netTangibleAssets",
        "shortTermInvestments",
        "netReceivables",
        "longTermDebt",
        "inventory",
        "accountsPayable",
        "totalPermanentEquity",
        "noncontrollingInterestInConsolidatedEntity",
        "temporaryEquityRedeemableNoncontrollingInterests",
        "accumulatedOtherComprehensiveIncome",
        "additionalPaidInCapital",
        "commonStockTotalEquity",
        "preferredStockTotalEquity",
        "retainedEarningsTotalEquity",
        "treasuryStock",
        "accumulatedAmortization",
        "nonCurrrentAssetsOther",
        "deferredLongTermAssetCharges",
        "nonCurrentAssetsTotal",
        "capitalLeaseObligations",
        "longTermDebtTotal",
        "nonCurrentLiabilitiesOther",
        "nonCurrentLiabilitiesTotal",
        "negativeGoodwill",
        "warrants",
        "preferredStockRedeemable",
        "capitalSurpluse",
        "liabilitiesAndStockholdersEquity",
        "cashAndShortTermInvestments",
        "propertyPlantAndEquipmentGross",
        "propertyPlantAndEquipmentNet",
        "accumulatedDepreciation",
        "netWorkingCapital",
        "netInvestedCapital",
        "commonStockSharesOutstanding",
      ];
      FinancialsPossibleValues.map((field) => {
        try {
          let BalanceSheetQuarterly = fd.Financials
            ? fd.Financials.Balance_Sheet.quarterly
            : {};

          let data = {
            id:
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2")[0].toUpperCase() +
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2").slice(1),
            color: "hsla(204, 100%, 43%)",
            data: [],
          };
          Object.keys(BalanceSheetQuarterly)
            .reverse()
            .filter((annual) => {
              if (
                BalanceSheetQuarterly[annual] &&
                BalanceSheetQuarterly[annual][field] &&
                moment(BalanceSheetQuarterly[annual].date).isAfter(
                  moment().subtract(3, "year")
                ) &&
                moment(BalanceSheetQuarterly[annual].date).isBefore(moment())
              ) {
                if (
                  BalanceSheetQuarterly[annual].accountsPayable !== null &&
                  BalanceSheetQuarterly[annual][field] !== null
                ) {
                  data.data.push({
                    x: getQuarter(
                      moment(BalanceSheetQuarterly[annual].date, "YYYY-MM-DD")
                    ),
                    y: parseFloat(BalanceSheetQuarterly[annual][field]),
                  });

                  if (
                    parseFloat(BalanceSheetQuarterly[annual][field]) <
                    Balance_Sheet_Lowest
                  ) {
                    Balance_Sheet_Lowest = parseFloat(
                      BalanceSheetQuarterly[annual][field]
                    );
                  }
                }
              }
            });
          BalanceSheet.push(data);
        } catch (e) {
          return;
        }
      });

      let CashFlow = [];
      let Cash_Flow_Lowest = 9999999999;
      let CashFlowFields = [
        "investments",
        "changeToLiabilities",
        "totalCashflowsFromInvestingActivities",
        "netBorrowings",
        "totalCashFromFinancingActivities",
        "changeToOperatingActivities",
        "netIncome",
        "changeInCash",
        "beginPeriodCashFlow",
        "endPeriodCashFlow",
        "totalCashFromOperatingActivities",
        "depreciation",
        "otherCashflowsFromInvestingActivities",
        "dividendsPaid",
        "changeToInventory",
        "changeToAccountReceivables",
        "salePurchaseOfStock",
        "otherCashflowsFromFinancingActivities",
        "changeToNetincome",
        "capitalExpenditures",
        "changeReceivables",
        "cashFlowsOtherOperating",
        "exchangeRateChanges",
        "cashAndCashEquivalentsChanges",
        "changeInWorkingCapital",
        "otherNonCashItems",
        "freeCashFlow",
      ];
      CashFlowFields.map((field) => {
        try {
          let CashFlowQuarterly = fd.Financials
            ? fd.Financials.Cash_Flow.quarterly
            : {};

          let data = {
            id:
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2")[0].toUpperCase() +
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2").slice(1),
            color: "hsla(129, 100%, 36%)",
            data: [],
          };
          Object.keys(CashFlowQuarterly)
            .reverse()
            .filter((annual) => {
              if (
                CashFlowQuarterly[annual] &&
                CashFlowQuarterly[annual][field] &&
                moment(CashFlowQuarterly[annual].date).isAfter(
                  moment().subtract(3, "year")
                ) &&
                moment(CashFlowQuarterly[annual].date).isBefore(moment())
              ) {
                if (
                  CashFlowQuarterly[annual].CashFlowQuarterly !== null &&
                  CashFlowQuarterly[annual][field] !== null
                ) {
                  data.data.push({
                    x: getQuarter(
                      moment(CashFlowQuarterly[annual].date, "YYYY-MM-DD")
                    ),
                    y: parseFloat(CashFlowQuarterly[annual][field]),
                  });

                  if (
                    parseFloat(CashFlowQuarterly[annual][field]) <
                    Cash_Flow_Lowest
                  ) {
                    Cash_Flow_Lowest = parseFloat(
                      CashFlowQuarterly[annual][field]
                    );
                  }
                }
              }
            });
          CashFlow.push(data);
        } catch (e) {
          return;
        }
      });

      let IncomeStatement = [];
      let Income_Statement_Lowest = 9999999999;
      let IncomeStatementFields = [
        "researchDevelopment",
        "effectOfAccountingCharges",
        "incomeBeforeTax",
        "minorityInterest",
        "netIncome",
        "sellingGeneralAdministrative",
        "sellingAndMarketingExpenses",
        "grossProfit",
        "reconciledDepreciation",
        "ebit",
        "ebitda",
        "depreciationAndAmortization",
        "nonOperatingIncomeNetOther",
        "operatingIncome",
        "otherOperatingExpenses",
        "interestExpense",
        "taxProvision",
        "interestIncome",
        "netInterestIncome",
        "extraordinaryItems",
        "nonRecurring",
        "otherItems",
        "incomeTaxExpense",
        "totalRevenue",
        "totalOperatingExpenses",
        "costOfRevenue",
        "totalOtherIncomeExpenseNet",
        "discontinuedOperations",
        "netIncomeFromContinuingOps",
        "netIncomeApplicableToCommonShares",
        "preferredStockAndOtherAdjustments",
      ];
      IncomeStatementFields.map((field) => {
        try {
          let IncomeStatementQuarterly = fd.Financials
            ? fd.Financials.Income_Statement.quarterly
            : {};

          let data = {
            id:
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2")[0].toUpperCase() +
              field.replace(/([a-z0-9])([A-Z])/g, "$1 $2").slice(1),
            color: "hsla(295, 100%, 43%)",
            data: [],
          };
          Object.keys(IncomeStatementQuarterly)
            .reverse()
            .filter((annual) => {
              if (
                IncomeStatementQuarterly[annual] &&
                IncomeStatementQuarterly[annual][field] &&
                moment(IncomeStatementQuarterly[annual].date).isAfter(
                  moment().subtract(3, "year")
                ) &&
                moment(IncomeStatementQuarterly[annual].date).isBefore(moment())
              ) {
                if (
                  IncomeStatementQuarterly[annual].IncomeStatementQuarterly !==
                    null &&
                  IncomeStatementQuarterly[annual][field] !== null
                ) {
                  data.data.push({
                    x: getQuarter(
                      moment(
                        IncomeStatementQuarterly[annual].date,
                        "YYYY-MM-DD"
                      )
                    ),
                    y: parseFloat(IncomeStatementQuarterly[annual][field]),
                  });
                  if (
                    parseFloat(IncomeStatementQuarterly[annual][field]) <
                    Income_Statement_Lowest
                  ) {
                    Income_Statement_Lowest = parseFloat(
                      IncomeStatementQuarterly[annual][field]
                    );
                  }
                }
              }
            });
          IncomeStatement.push(data);
        } catch (e) {
          return;
        }
      });

      return (
        <>
          <Box
            background={"var(--dark-gray)"}
            borderRadius="12px"
            padding="20px"
            marginTop="20px"
            marginBottom="20px"
          >
            <StockPriceWidget
              logoUrl={this.state.logoURL}
              code={fd.General ? fd.General.Code : ""}
              name={fd.General ? fd.General.Name : ""}
              price={this.state.stockPrice.close}
              dayChange={dayChange}
            />

            {/* <SimpleGrid columns={2} display="flex">

                            <Box marginRight="20px" marginBottom="0px">

                                <HStack>
                                    <Image
                                        boxSize="56px"
                                        objectFit="cover"
                                        src={this.state.logoURL}
                                        alt="Logo"
                                        borderRadius={'8px'}
                                        border={'1px solid var(--bg)'}
                                        background={'var(--white)'}
                                        padding="4px"
                                    />

                                    <VStack ml="8px" alignItems="flex-start" height={"56px"}>

                                        <Text textAlign={'left'} fontSize={'12px'} fontWeight="400" lineHeight={'140%'} color="var(--light-gray)">{fd.General ? fd.General.Code : ''}</Text>
                                        <Text color={'var(--white)'} textAlign={'left'} fontSize={'20px'} fontWeight="700" lineHeight={'140%'} >{fd.General ? fd.General.Name : ''}</Text>
                                    </VStack>

                                </HStack>
                            </Box>

                            <Box borderLeft={'solid 1px var(--gray)'} marginRight="20px" marginBottom="0px" maxW={'200px'}>
                                <VStack ml="8px" alignItems="flex-start">
                                    <Text textAlign={'left'} fontSize={'12px'} fontWeight="400" lineHeight={'140%'} color="var(--light-gray)">{'STOCK PRICE'}</Text>

                                    <HStack>

                                        <Text color={'var(--white)'} textAlign={'left'} fontSize={'20px'} fontWeight="700" lineHeight={'140%'} >{currencyFormatter.format(this.state.stockPrice.close, { code: 'USD' })}</Text>


                                        {dayChange > 0 &&
                                            <Box
                                                background={'var(--green)'}
                                                borderRadius="4px"
                                                margin="8px"
                                                padding="6px 6px 6px 6px"
                                                display="flex"
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <ChevronUp fill="var(--white)" stroke="var(--white)" />
                                                <Text color={'var(--white)'} fontSize={'12px'} fontWeight="500" lineHeight={'140%'}>{dayChange}%</Text>
                                            </Box>
                                        }

                                        {dayChange < 0 &&
                                            <Box
                                                background={'var(--red)'}
                                                borderRadius="4px"
                                                margin="8px"
                                                padding="6px 6px 6px 6px"
                                                display="flex"
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <ChevronDown fill="var(--white)" stroke="var(--white)" />
                                                <Text color={'var(--white)'} fontSize={'12px'} fontWeight="500" lineHeight={'140%'}>{dayChange}%</Text>
                                            </Box>
                                        }


                                    </HStack>
                                </VStack>
                            </Box>


                        </SimpleGrid>> */}

            {this.props.stockTicker.length > 0 && (
              <Box overflow="hidden" borderRadius={"8px"}>
                <ChartFrame>
                  <ChartData
                    multi={true}
                    fundamentalData={fd}
                    tradeData={this.state.trades}
                    standalone={true}
                    stockTicker={this.props.stockTicker}
                    onError={this.props.onError}
                  />
                </ChartFrame>
              </Box>
            )}
          </Box>

          <InsetTabsContainer>
            <InsetTabList tall={true}>
              <InsetTab>Highlights</InsetTab>
              <InsetTab>Option Chain</InsetTab>
              <InsetTab>News</InsetTab>
              <InsetTab>Your trades</InsetTab>
            </InsetTabList>

            <InsetTabPanels>
              <TabPanel
                margin="0"
                padding="0"
                paddingTop="10px"
                minHeight="600px"
              >
                <CustomTabsContainer
                  background={"var(--dark-gray)"}
                  borderRadius="12px"
                  padding="20px"
                >
                  <CustomTabList>
                    <CustomTab>About</CustomTab>
                    <CustomTab>Dividends</CustomTab>
                    <CustomTab>Valuation</CustomTab>
                    <CustomTab>Earnings</CustomTab>
                    {fd.InsiderTransactions &&
                      Object.keys(fd.InsiderTransactions).length > 0 && (
                        <CustomTab>Insider Transactions</CustomTab>
                      )}
                    <CustomTab>Ownership</CustomTab>
                    <CustomTab>Balance Sheet</CustomTab>
                    <CustomTab>Cash Flow</CustomTab>
                    <CustomTab>Income Statement</CustomTab>
                  </CustomTabList>

                  <CustomTabPanels>
                    <TabPanel>
                      {/* About */}

                      {fd.General && (
                        <>
                          <PanelTitleSection title="About" />
                          <Text
                            fontWeight={400}
                            fontSize={"14px"}
                            color="var(--semi-white)"
                          >
                            {fd.General.Description}
                          </Text>
                          <SimpleGrid
                            columns={4}
                            mt="26px"
                            mb="46px"
                            minChildWidth={"200px"}
                          >
                            <HStack height="90px">
                              <Box
                                h="44px"
                                width="44px"
                                bg="red"
                                borderRadius={"40px"}
                                display="flex"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <Avatar />
                              </Box>
                              <VStack alignItems={"flex-start"} pl="16px">
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight={400}
                                  color="var(--light-gray)"
                                >
                                  CEO
                                </Text>
                                <Text
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="var(--white)"
                                >
                                  {fd.General.Officers
                                    ? fd.General.Officers[0].Name
                                    : ""}
                                </Text>
                              </VStack>
                            </HStack>

                            <HStack height="90px">
                              <Box
                                h="44px"
                                width="44px"
                                bg="var(--bg)"
                                borderRadius={"6px"}
                                display="flex"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <SuitcaseIcon
                                  color="var(--light-gray)"
                                  fill="var(--light-gray)"
                                  w="20px"
                                  h="20px"
                                />
                              </Box>
                              <VStack alignItems={"flex-start"} pl="16px">
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight={400}
                                  color="var(--light-gray)"
                                >
                                  Employees
                                </Text>
                                <Text
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="var(--white)"
                                >
                                  {fd.General
                                    ? fd.General.FullTimeEmployees
                                    : ""}
                                </Text>
                              </VStack>
                            </HStack>
                            <HStack height="90px">
                              <Box
                                h="44px"
                                width="44px"
                                bg="var(--bg)"
                                borderRadius={"6px"}
                                display="flex"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <PinIcon
                                  color="var(--light-gray)"
                                  fill="var(--light-gray)"
                                  w="20px"
                                  h="20px"
                                />
                              </Box>
                              <VStack alignItems={"flex-start"} pl="16px">
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight={400}
                                  color="var(--light-gray)"
                                >
                                  Headquarters
                                </Text>
                                <Text
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="var(--white)"
                                >
                                  {fd.General.AddressData
                                    ? fd.General.AddressData.City
                                    : ""}{" "}
                                  {fd.General.AddressData
                                    ? fd.General.AddressData.State
                                    : ""}
                                </Text>
                              </VStack>
                            </HStack>
                            <HStack height="90px">
                              <Box
                                h="44px"
                                width="44px"
                                bg="var(--bg)"
                                borderRadius={"6px"}
                                display="flex"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <ExchangeIcon
                                  color="var(--light-gray)"
                                  fill="var(--light-gray)"
                                  w="20px"
                                  h="20px"
                                />
                              </Box>
                              <VStack alignItems={"flex-start"} pl="16px">
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight={400}
                                  color="var(--light-gray)"
                                >
                                  Exchange
                                </Text>
                                <Text
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="var(--white)"
                                >
                                  {fd.General ? fd.General.Exchange : ""}
                                </Text>
                              </VStack>
                            </HStack>
                          </SimpleGrid>
                        </>
                      )}
                      <PanelTitleSection title="Key statistics" />

                      {fd.Highlights && (
                        <SimpleGrid
                          columns={4}
                          mb="46px"
                          mt="26px"
                          minChildWidth={"200px"}
                        >
                          <DataPanel
                            label={"Market Cap"}
                            data={abbreviateX.abbreviate(
                              fd.Highlights.MarketCapitalization,
                              2
                            )}
                          />
                          <DataPanel
                            label={"Price-Earnings"}
                            data={fd.Highlights.PERatio}
                          />
                          <DataPanel
                            label={"Dividend yield"}
                            data={
                              (fd.Highlights.DividendYield * 100).toFixed(2) +
                              "%"
                            }
                          />
                          <DataPanel
                            label={"Previous close"}
                            data={"$" + this.state.stockPrice.previousClose}
                          />
                          <DataPanel
                            label={"High today"}
                            data={"$" + this.state.stockPrice.high}
                          />
                          <DataPanel
                            label={"Low today"}
                            data={"$" + this.state.stockPrice.low}
                          />
                          <DataPanel
                            label={"Open price"}
                            data={"$" + this.state.stockPrice.open}
                          />
                          <DataPanel
                            label={"Volume"}
                            data={
                              "$" +
                              abbreviateX.abbreviate(
                                this.state.stockPrice.volume
                              )
                            }
                          />
                          <DataPanel
                            label={"52 Week"}
                            data={fd.Technicals["52WeekHigh"]}
                          />
                          <DataPanel
                            label={"52 Week"}
                            data={fd.Technicals["52WeekLow"]}
                          />
                        </SimpleGrid>
                      )}

                      {fd.AnalystRatings && (
                        <>
                          <PanelTitleSection title="Analyst ratings" />

                          <HStack
                            flex={"flex"}
                            flexWrap={"wrap"}
                            spacing={"10px"}
                          >
                            <Box flex="1">
                              <PoolContainer
                                value={(buyRatings / totalAnalyst) * 100}
                                suffix="%"
                                max={totalAnalyst}
                                label={"RATINGS"}
                              />
                            </Box>
                            <Box flex="6">
                              <Stack width="100%" minChildWidth={"200px"}>
                                <HStack width="100%">
                                  <Text
                                    width="90px"
                                    textAlign="right"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    Strong buy:
                                  </Text>
                                  <Progress
                                    height="16px"
                                    width="100%"
                                    className="strongBuyValue"
                                    value={
                                      (fd.AnalystRatings.StrongBuy /
                                        totalAnalyst) *
                                      100
                                    }
                                  />
                                  <Text
                                    fontSize="xs"
                                    textAlign="center"
                                    ml="2"
                                    width="25px"
                                  >
                                    {fd.AnalystRatings.StrongBuy}
                                  </Text>
                                </HStack>
                                <Spacer height="32px" />
                                <HStack width="100%">
                                  <Text
                                    width="90px"
                                    textAlign="right"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    Buy:
                                  </Text>
                                  <Progress
                                    height="16px"
                                    width="100%"
                                    className="buyValue"
                                    value={
                                      (fd.AnalystRatings.Buy / totalAnalyst) *
                                      100
                                    }
                                  />
                                  <Text
                                    fontSize="xs"
                                    textAlign="center"
                                    ml="2"
                                    width="25px"
                                  >
                                    {fd.AnalystRatings.Buy}
                                  </Text>
                                </HStack>
                                <Spacer height="32px" />
                                <HStack width="100%">
                                  <Text
                                    width="90px"
                                    textAlign="right"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    Hold:
                                  </Text>
                                  <Progress
                                    height="16px"
                                    width="100%"
                                    className="holdValue"
                                    value={
                                      (fd.AnalystRatings.Hold / totalAnalyst) *
                                      100
                                    }
                                  />
                                  <Text
                                    fontSize="xs"
                                    textAlign="center"
                                    ml="2"
                                    width="25px"
                                  >
                                    {fd.AnalystRatings.Hold}
                                  </Text>
                                </HStack>
                                <Spacer height="32px" />
                                <HStack width="100%">
                                  <Text
                                    width="90px"
                                    textAlign="right"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    Sell:
                                  </Text>
                                  <Progress
                                    height="16px"
                                    width="100%"
                                    className="sellValue"
                                    value={
                                      (fd.AnalystRatings.Sell / totalAnalyst) *
                                      100
                                    }
                                  />
                                  <Text
                                    fontSize="xs"
                                    textAlign="center"
                                    ml="2"
                                    width="25px"
                                  >
                                    {fd.AnalystRatings.Sell}
                                  </Text>
                                </HStack>
                                <Spacer height="32px" />
                                <HStack width="100%">
                                  <Text
                                    width="90px"
                                    textAlign="right"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    Strong sell:
                                  </Text>
                                  <Progress
                                    height="16px"
                                    width="100%"
                                    className="strongSellValue"
                                    value={
                                      (fd.AnalystRatings.StrongSell /
                                        totalAnalyst) *
                                      100
                                    }
                                  />
                                  <Text
                                    fontSize="xs"
                                    textAlign="center"
                                    ml="2"
                                    width="25px"
                                  >
                                    {fd.AnalystRatings.StrongSell}
                                  </Text>
                                </HStack>
                              </Stack>
                            </Box>
                          </HStack>
                        </>
                      )}
                    </TabPanel>

                    <TabPanel>
                      <PanelTitleSection title="Dividend" />
                      {fd.SplitsDividends &&
                      fd.SplitsDividends.DividendDate !== "0000-00-00" ? (
                        <SimpleGrid
                          columns={4}
                          mb="46px"
                          mt="26px"
                          minChildWidth={"200px"}
                        >
                          <DataPanel
                            data={moment(
                              fd.SplitsDividends.DividendDate
                            ).format("Do MMM YYYY")}
                            label={"Dividend Date"}
                          />
                          <DataPanel
                            data={moment(
                              fd.SplitsDividends.ExDividendDate
                            ).format("Do MMM YYYY")}
                            label={"Ex Dividend Date"}
                          />
                          <DataPanel
                            data={fd.SplitsDividends.ForwardAnnualDividendRate}
                            label={"Forward Annual Dividend Rate"}
                          />
                          <DataPanel
                            data={fd.SplitsDividends.ForwardAnnualDividendYield}
                            label={"Forward Annual Dividend Yield"}
                          />
                          <DataPanel
                            data={moment(
                              fd.SplitsDividends.LastSplitDate
                            ).format("Do MMM YYYY")}
                            label={"Last Split Date"}
                          />
                          <DataPanel
                            data={fd.SplitsDividends.LastSplitFactor}
                            label={"Last Split Factor"}
                          />
                          <DataPanel
                            data={fd.SplitsDividends.PayoutRatio}
                            label={"Payout Ratio"}
                          />
                          <DataPanel
                            label={"Dividend yield"}
                            data={
                              (fd.Highlights.DividendYield * 100).toFixed(2) +
                              "%"
                            }
                          />
                        </SimpleGrid>
                      ) : (
                        <Text>Dividend data not available.</Text>
                      )}
                      {/* Here */}
                    </TabPanel>

                    <TabPanel>
                      <PanelTitleSection title="Valuation" />

                      {fd.Valuation && (
                        <SimpleGrid
                          columns={4}
                          mb="46px"
                          mt="26px"
                          minChildWidth={"200px"}
                        >
                          <DataPanel
                            data={fd.Valuation.EnterpriseValueEbitda}
                            label={"Enterprise Value EBITDA"}
                          />
                          <DataPanel
                            data={fd.Valuation.EnterpriseValueRevenue}
                            label={"Enterprise Value Revenue"}
                          />
                          <DataPanel
                            data={fd.Valuation.ForwardPE}
                            label={"Forward PE"}
                          />
                          <DataPanel
                            data={fd.Valuation.PriceBookMRQ}
                            label={"PriceBook MRQ"}
                          />
                          <DataPanel
                            data={fd.Valuation.PriceSalesTTM}
                            label={"Price Sales"}
                          />
                          <DataPanel
                            data={fd.Valuation.TrailingPE}
                            label={"Trailing PE"}
                          />
                        </SimpleGrid>
                      )}
                    </TabPanel>

                    <TabPanel>
                      {/* Earnings */}

                      <Heading
                        as="h2"
                        size="md"
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        mt="12"
                      >
                        Earnings
                      </Heading>
                      <Divider mt="2" mb="4" />

                      <Box height="400px" width="full">
                        <LineChart
                          data={earningsData}
                          margin={{ top: 30, right: 40, bottom: 100, left: 70 }}
                          tooltipFormat={(value) => {
                            return currencyFormatter.format(value, {
                              code: "USD",
                            });
                          }}
                          gradient={true}
                          lowestValueForBaseLine={estimate_lowest}
                        />
                      </Box>
                    </TabPanel>

                    {fd.InsiderTransactions &&
                      Object.keys(fd.InsiderTransactions).length > 0 && (
                        <TabPanel>
                          {/*  Insider Transactions  */}

                          {fd.InsiderTransactions && (
                            <>
                              <Heading
                                as="h2"
                                size="md"
                                fontWeight="extrabold"
                                letterSpacing="tight"
                                mt="12"
                              >
                                Insider Transactions
                              </Heading>
                              <Divider mt="26px" mb="26px" />

                              <TableFrame>
                                <TableHeader>
                                  <CustomTr>
                                    <CustomTh>Date</CustomTh>
                                    <CustomTh>Name</CustomTh>
                                    <CustomTh>Value</CustomTh>
                                    <CustomTh>Action</CustomTh>
                                    <CustomTh>Link</CustomTh>
                                  </CustomTr>
                                </TableHeader>
                                <Tbody>
                                  {Object.keys(fd.InsiderTransactions).map(
                                    (item, index) => {
                                      let ITran = fd.InsiderTransactions[item];
                                      return (
                                        <CustomTr>
                                          <Td>
                                            <Text>
                                              {moment(ITran.date).format(
                                                "Do MMMM YYYY"
                                              )}
                                            </Text>
                                          </Td>
                                          <Td>
                                            <Text>{ITran.ownerName}</Text>
                                          </Td>
                                          <Td>
                                            <Text>
                                              {currencyFormatter.format(
                                                ITran.transactionAmount *
                                                  ITran.transactionPrice,
                                                { code: "USD" }
                                              )}
                                            </Text>
                                          </Td>
                                          <Td>
                                            {ITran.transactionAcquiredDisposed ===
                                            "D" ? (
                                              <CustomTag backgroundColor="rgba(234, 88, 72, 0.4) !important">
                                                Disposal
                                              </CustomTag>
                                            ) : (
                                              <CustomTag backgroundColor="rgba(38, 195, 99, 0.4) !important">
                                                Acquired
                                              </CustomTag>
                                            )}
                                          </Td>
                                          <Td>
                                            <CustomLink
                                              href={"" + ITran.secLink}
                                              isExternal
                                              color="var(--accent) !important"
                                              textDecorationLine="underline"
                                            >
                                              View SEC document
                                            </CustomLink>
                                          </Td>
                                        </CustomTr>
                                      );
                                    }
                                  )}
                                </Tbody>
                              </TableFrame>
                            </>
                          )}
                        </TabPanel>
                      )}

                    <TabPanel>
                      {/* Ownership */}

                      <Heading
                        as="h2"
                        size="md"
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        mt="12"
                      >
                        Ownership
                      </Heading>
                      <Divider mt="2" mb="4" />

                      <Box>
                        <Stack width="100%">
                          <Flex columns={3}>
                            <Text
                              flex={2}
                              textAlign="left"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight={"140%"}
                            >
                              Institutions
                            </Text>
                            <Progress
                              flex={3}
                              height="16px"
                              className="institutionProgress"
                              value={institutionalHoldings}
                            />
                            <Text
                              flex={1}
                              fontSize="xs"
                              textAlign="left"
                              ml="2"
                            >
                              {institutionalHoldings.toFixed(2)}%
                            </Text>
                          </Flex>
                          <Spacer height="32px" />
                          <Flex columns={3}>
                            <Text
                              flex={2}
                              textAlign="left"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight={"140%"}
                            >
                              Insiders
                            </Text>
                            <Progress
                              flex={3}
                              height="16px"
                              className="insiderProgress"
                              value={insidersHoldings}
                            />
                            <Text
                              flex={1}
                              fontSize="xs"
                              textAlign="left"
                              ml="2"
                            >
                              {insidersHoldings.toFixed(2)}%
                            </Text>
                          </Flex>
                          <Spacer height="32px" />
                          <Flex columns={3}>
                            <Text
                              flex={2}
                              textAlign="left"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight={"140%"}
                            >
                              Others
                            </Text>
                            <Progress
                              flex={3}
                              height="16px"
                              className="otherProgress"
                              value={othersHoldings}
                            />
                            <Text
                              flex={1}
                              fontSize="xs"
                              textAlign="left"
                              ml="2"
                            >
                              {othersHoldings.toFixed(2)}%
                            </Text>
                          </Flex>
                        </Stack>

                        <Heading
                          as="h2"
                          size="md"
                          fontWeight="extrabold"
                          letterSpacing="tight"
                          mt="12"
                        >
                          Breakdown
                        </Heading>
                        <Divider mt="2" mb="4" />

                        <Stack width="100%">
                          {holdings.map((value, index) => {
                            if (value.type === "Fund") {
                              return (
                                <Flex columns={3} key={index}>
                                  <Text
                                    flex={2}
                                    textAlign="left"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    {value.target}
                                  </Text>
                                  <Progress
                                    flex={3}
                                    height="16px"
                                    className="fundProgress"
                                    value={value.value}
                                  />
                                  <Text
                                    flex={1}
                                    fontSize="xs"
                                    textAlign="left"
                                    ml="2"
                                  >
                                    {value.value.toFixed(2)}%
                                  </Text>
                                </Flex>
                              );
                            } else {
                              return (
                                <Flex columns={3} key={index}>
                                  <Text
                                    flex={2}
                                    textAlign="left"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight={"140%"}
                                  >
                                    {value.target}
                                  </Text>
                                  <Progress
                                    flex={3}
                                    height="16px"
                                    className="institutionProgress"
                                    value={value.value}
                                  />
                                  <Text
                                    flex={1}
                                    fontSize="xs"
                                    textAlign="left"
                                    ml="2"
                                  >
                                    {value.value.toFixed(2)}%
                                  </Text>
                                </Flex>
                              );
                            }
                          })}
                        </Stack>

                        {/* <ResponsiveSankey
                                                    theme={colors.platform.charts[this.props.colorMode]}
                                                    data={sankeyData}
                                                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                                    align="justify"
                                                    sort="descending"
                                                    colors={{ scheme: 'category10' }}
                                                    nodeOpacity={1}
                                                    nodeHoverOthersOpacity={0.35}
                                                    nodeThickness={18}
                                                    nodeSpacing={10}
                                                    nodeBorderWidth={0}
                                                    nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
                                                    nodeBorderRadius={3}
                                                    linkOpacity={0.5}
                                                    linkHoverOthersOpacity={0.1}
                                                    linkContract={3}
                                                    enableLinkGradient={true}
                                                    // labelPosition="outside"
                                                    labelOrientation="horizontal"
                                                    labelPadding={16}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
                                                    label={d => `${d.id} (${d.value.toFixed(2)}%)`}
                                                /> */}
                      </Box>
                    </TabPanel>

                    <TabPanel>
                      {/*  Balance Sheet  */}

                      <Heading
                        as="h2"
                        size="md"
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        mt="12"
                      >
                        Financials - Balance Sheet
                      </Heading>
                      <Divider mt="2" mb="4" />

                      {Object.keys(BalanceSheet).map((value, i) => {
                        if (BalanceSheet[value].data.length === 0) {
                          return null;
                        }

                        return (
                          <Box height="400px" width="100%" key={i}>
                            <LineChart
                              data={[BalanceSheet[value]]}
                              margin={{
                                top: 10,
                                right: 40,
                                bottom: 120,
                                left: 40,
                              }}
                              tooltipFormat={(value) => {
                                return value;
                              }}
                              gradient={true}
                              lowestValueForBaseLine={Balance_Sheet_Lowest}
                            />
                          </Box>
                        );
                      })}
                    </TabPanel>

                    <TabPanel>
                      {/* Cash Flow  */}

                      <Heading
                        as="h2"
                        size="md"
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        mt="20"
                      >
                        Financials - Cash Flow
                      </Heading>
                      <Divider mt="2" mb="4" />

                      {Object.keys(CashFlow).map((value, i) => {
                        if (CashFlow[value].data.length === 0) {
                          return null;
                        }

                        return (
                          <Box height="400px" width="100%" key={i}>
                            <LineChart
                              data={[CashFlow[value]]}
                              margin={{
                                top: 10,
                                right: 40,
                                bottom: 120,
                                left: 40,
                              }}
                              tooltipFormat={(value) => {
                                return value;
                              }}
                              gradient={true}
                              lowestValueForBaseLine={BalanceSheet.lowestValue}
                            />
                          </Box>
                        );
                      })}
                    </TabPanel>

                    <TabPanel>
                      {/* Income Statement */}
                      <Heading
                        as="h2"
                        size="md"
                        fontWeight="extrabold"
                        letterSpacing="tight"
                        mt="20"
                      >
                        Financials - Income Statement
                      </Heading>
                      <Divider mt="2" mb="4" />

                      {Object.keys(IncomeStatement).map((value, i) => {
                        if (IncomeStatement[value].data.length === 0) {
                          return null;
                        }

                        return (
                          <Box height="400px" width="100%" key={i}>
                            <LineChart
                              data={[IncomeStatement[value]]}
                              margin={{
                                top: 10,
                                right: 40,
                                bottom: 120,
                                left: 40,
                              }}
                              tooltipFormat={(value) => {
                                return value;
                              }}
                              gradient={true}
                              lowestValueForBaseLine={Income_Statement_Lowest}
                            />
                          </Box>
                        );
                      })}
                    </TabPanel>
                  </CustomTabPanels>
                </CustomTabsContainer>
              </TabPanel>

              <TabPanel
                background={"var(--dark-gray)"}
                borderRadius="12px"
                padding="20px"
                marginTop="20px"
              >
                <ChartFrame>
                  <ScanOptionChains stockTicker={this.props.stockTicker} />
                </ChartFrame>
              </TabPanel>

              <TabPanel
                isLazy
                background={"var(--dark-gray)"}
                borderRadius="12px"
                padding="20px"
                marginTop="20px"
              >
                <NewsPanel
                  noHeader
                  trades={[
                    {
                      stockTicker: this.props.stockTicker,
                      positionStatus: "OPEN",
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                isLazy
                background={"var(--dark-gray)"}
                borderRadius="12px"
                padding="20px"
                marginTop="20px"
              >
                <ViewYourTrades
                  isLoading={this.state.loading}
                  trades={this.state.trades}
                  stockTicker={this.props.stockTicker}
                />
              </TabPanel>
            </InsetTabPanels>
          </InsetTabsContainer>
        </>
      );
    } else {
      if (!this.state.tradeData.fundamentalData) {
        return (
          <Box>
            <NoDataWidget label="No fundamental data is available for this stock ticker." />{" "}
          </Box>
        );
      }

      let fd = this.state.fundamentalData ? this.state.fundamentalData : null;
      let atTradeHl = this.state.tradeData.fundamentalData.Highlights
        ? this.state.tradeData.fundamentalData.Highlights
        : null;
      let atTradeSs = this.state.tradeData.fundamentalData.SharesStats
        ? this.state.tradeData.fundamentalData.SharesStats
        : null;

      if (!fd.Highlights) {
        return (
          <Box>
            <NoDataWidget label="No fundamental data is available for this stock ticker." />{" "}
          </Box>
        );
      }

      return (
        <>
          <HStack spacing={4} pb={5}>
            {this.state.logoURL && this.state.logoURL.length > 30 && (
              <Image
                boxSize="50px"
                objectFit="cover"
                src={this.state.logoURL}
                alt="Logo"
              />
            )}
            <Text fontSize="lg" fontWeight={600}>
              {fd.General.Name}
            </Text>
            <Text fontSize="lg">{fd.General.Industry}</Text>
            <Text fontSize="lg">{fd.General.HomeCategory}</Text>
          </HStack>

          <Text fontSize="md">{fd.General.Description}</Text>
          {/* <FundamentalTrackingUpgrade /> */}
          <Divider pt={10} />

          <TableFrame variant={"striped"} size="sm">
            <TableCaption>Highlights</TableCaption>
            <TableHeader>
              <CustomTr>
                <CustomTh>Label</CustomTh>
                <CustomTh>Value</CustomTh>
                <CustomTh>Change since opening trade</CustomTh>
              </CustomTr>
            </TableHeader>
            <CustomTBody>
              {fd.Highlights && (
                <>
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.MarketCapitalizationMln}
                    numberThen={atTradeHl.MarketCapitalizationMln}
                    label={"Market Cap (MLN)"}
                    type="NUMERICAL_CURRENCY"
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.BookValue}
                    numberThen={atTradeHl.BookValue}
                    label="Book Value"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.DilutedEpsTTM}
                    numberThen={atTradeHl.DilutedEpsTTM}
                    label="Diluted EPS"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.DividendShare}
                    numberThen={atTradeHl.DividendShare}
                    label="Dividend Share"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.DividendYield}
                    numberThen={atTradeHl.DividendYield}
                    label="Dividend Yield"
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EBITDA}
                    numberThen={atTradeHl.EBITDA}
                    label="EBITDA"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EPSEstimateCurrentQuarter}
                    numberThen={atTradeHl.EPSEstimateCurrentQuarter}
                    label="EPS Est. Current Qtr"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EPSEstimateCurrentYear}
                    numberThen={atTradeHl.EPSEstimateCurrentYear}
                    label="EPS Est. Current Year"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EPSEstimateNextQuarter}
                    numberThen={atTradeHl.EPSEstimateNextQuarter}
                    label="EPS Est. Next Quarter"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EPSEstimateNextYear}
                    numberThen={atTradeHl.EPSEstimateNextYear}
                    label="EPS Est. Next Year"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.EarningsShare}
                    numberThen={atTradeHl.EarningsShare}
                    label="Earnings Share"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.GrossProfitTTM}
                    numberThen={atTradeHl.GrossProfitTTM}
                    label="Gross Profit"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.MostRecentQuarter}
                    numberThen={atTradeHl.MostRecentQuarter}
                    label="Most Recent Quarter"
                    type={"DATE"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.OperatingMarginTTM}
                    numberThen={atTradeHl.OperatingMarginTTM}
                    label="Operating Margin"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.PEGRatio}
                    numberThen={atTradeHl.PEGRatio}
                    label="PEG"
                    type={"NUMBER_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.PERatio}
                    numberThen={atTradeHl.PERatio}
                    label="PE"
                    type={"NUMBER_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.ProfitMargin}
                    numberThen={atTradeHl.ProfitMargin}
                    label="Profit Margin"
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.QuarterlyEarningsGrowthYOY}
                    numberThen={atTradeHl.QuarterlyEarningsGrowthYOY}
                    label="Qtr Earnings Growth YOY"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.QuarterlyRevenueGrowthYOY}
                    numberThen={atTradeHl.QuarterlyRevenueGrowthYOY}
                    label="Qtr Revenue Growth YOY"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.ReturnOnAssetsTTM}
                    numberThen={atTradeHl.ReturnOnAssetsTTM}
                    label="Return On Assets"
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.ReturnOnEquityTTM}
                    numberThen={atTradeHl.ReturnOnEquityTTM}
                    label="Return On Equity"
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.RevenuePerShareTTM}
                    numberThen={atTradeHl.RevenuePerShareTTM}
                    label="Revenue Per Share"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.RevenueTTM}
                    numberThen={atTradeHl.RevenueTTM}
                    label="Revenue"
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Highlights.WallStreetTargetPrice}
                    numberThen={atTradeHl.WallStreetTargetPrice}
                    label="WallStreet Target Price"
                    type={"NUMERICAL_CURRENCY"}
                  />
                </>
              )}
            </CustomTBody>
          </TableFrame>
          <Divider pt={10} />

          <TableFrame variant="striped" size="sm">
            <TableCaption>Share Stats</TableCaption>
            <TableHeader>
              <CustomTr>
                <CustomTh>Label</CustomTh>
                <CustomTh>Value</CustomTh>
                {this.props.settings.profile.subscriptionType === "PREMIUM" && (
                  <CustomTh>Change since opening trade</CustomTh>
                )}
              </CustomTr>
            </TableHeader>
            <Tbody>
              {fd.SharesStats && (
                <>
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.PercentInsiders}
                    numberThen={atTradeSs.PercentInsiders}
                    label={"Percent Insiders"}
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.PercentInstitutions}
                    numberThen={atTradeSs.PercentInstitutions}
                    label={"Percent Institutions"}
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.SharesFloat}
                    numberThen={atTradeSs.SharesFloat}
                    label={"Shares Float"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.SharesOutstanding}
                    numberThen={atTradeSs.SharesOutstanding}
                    label={"SharesOutstanding"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.SharesShort}
                    numberThen={atTradeSs.SharesShort}
                    label={"Shares Short"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.SharesShortPriorMonth}
                    numberThen={atTradeSs.SharesShortPriorMonth}
                    label={"Shares Short Prior Month"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.ShortPercentFloat}
                    numberThen={atTradeSs.ShortPercentFloat}
                    label={"Short Percent Float"}
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.ShortPercentOutstanding}
                    numberThen={atTradeSs.ShortPercentOutstanding}
                    label={"Short Percent Outstanding"}
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SharesStats.ShortRatio}
                    numberThen={atTradeSs.ShortRatio}
                    label={"Short Ratio"}
                    type={"NUMERICAL_ONLY"}
                  />
                </>
              )}
            </Tbody>
          </TableFrame>

          <Divider pt={10} />

          <TableFrame variant="striped" size="sm">
            <TableCaption>Dividends</TableCaption>
            <TableHeader>
              <CustomTr>
                <CustomTh>Label</CustomTh>
                <CustomTh>Value</CustomTh>
                <CustomTh>Change since opening trade</CustomTh>
              </CustomTr>
            </TableHeader>
            <Tbody>
              {fd.SplitsDividends && (
                <>
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.DividendDate}
                    label={"Dividend Date"}
                    type={"DATE"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.ExDividendDate}
                    label={"Ex Dividend Date"}
                    type={"DATE"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.ForwardAnnualDividendRate}
                    label={"Forward Annual Dividend Rate"}
                    type={"PERCENTAGE"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.ForwardAnnualDividendYield}
                    label={"Forward Annual Dividend Yield"}
                    type={"PERCENTAGE_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.LastSplitDate}
                    label={"Last Split Date"}
                    type={"DATE"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.LastSplitFactor}
                    label={"Last Split Factor"}
                    type={"TEXT"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.SplitsDividends.PayoutRatio}
                    label={"Payout Ratio"}
                    type={"TEXT"}
                  />
                </>
              )}
            </Tbody>
          </TableFrame>
          <Divider pt={10} />

          <TableFrame variant="striped" size="sm">
            <TableCaption>Valuation</TableCaption>
            <TableHeader>
              <CustomTr>
                <CustomTh>Label</CustomTh>
                <CustomTh>Value</CustomTh>
                {this.props.settings.profile.subscriptionType === "PREMIUM" && (
                  <CustomTh>Change since opening trade</CustomTh>
                )}
              </CustomTr>
            </TableHeader>
            <Tbody>
              {fd.Valuation && (
                <>
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.EnterpriseValue}
                    label={"Enterprise Value"}
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.EnterpriseValueEbitda}
                    label={"Enterprise Value EBITDA"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.EnterpriseValueRevenue}
                    label={"Enterprise Value Revenue"}
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.ForwardPE}
                    label={"Forward PE"}
                    type={"NUMERICAL_ONLY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.PriceBookMRQ}
                    label={"PriceBook MRQ"}
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.PriceSalesTTM}
                    label={"Price Sales"}
                    type={"NUMERICAL_CURRENCY"}
                  />
                  <FinancialStatRow
                    subscriptionType={
                      this.props.settings.profile.subscriptionType
                    }
                    numberNow={fd.Valuation.TrailingPE}
                    label={"Trailing PE"}
                    type={"TEXT"}
                  />
                </>
              )}
            </Tbody>
          </TableFrame>
        </>
      );
    }
  }
}

const PanelTitleSection = (props) => {
  return (
    <>
      <Heading size="16px" fontWeight={500} mt="16px" mb="16px">
        {props.title}
      </Heading>
      <Divider mb="20px" />
    </>
  );
};

const ViewYourTrades = (props) => {
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [isOpen, setIsOpen] = useState(false);
  const [tradeData, setTradeData] = useState({});

  const setTradeDataFunc = (trade) => {
    setTradeData(trade);
    setIsOpen(true);
  };

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Box>
      <TradeListComp
        tradeList={props.trades}
        notModal={true}
        setTradeData={setTradeDataFunc}
      />

      <TradeDetailModal
        modalStatus={isOpen}
        setModal={setIsOpen}
        APIEndpoint={APIEndpoint}
        toggle={setIsOpen}
        loading={isLoading}
        tradeData={tradeData}
        optionData={tradeData.optionData}
        // history={this.props.history}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(FundamentalData);
