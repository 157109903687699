

export const subscription = (subscriptionDetails) => ({
  type: 'SUBSCRIPTION',
  subscriptionDetails
});

export const profile = (profile) => ({
  type: 'PROFILE',
  profile
});

export const websites = (websites) => ({
  type: 'WEBSITES',
  websites
});

export const setLoggedIn = () => ({
  type: 'SET_LOGGED_IN',
})
export const changingAccount = (changingAccount) => ({
  type: 'CHANGING_ACCOUNT',
  changingAccount
})

export const APIStatus = (status) => ({
  type: 'API_STATUS',
  status
})
export const setUserData = (userData) => ({
  type: 'SET_USER_DATA',
  userData
})
export const setTrades = (trades) => ({
  type: 'SET_TRADES',
  trades
})
export const setEmphemeralTardes = (trades) => ({
  type: 'SET_EMPHEMERAL_TRADES',
  trades
})

export const clearLoggedInData = () => ({
  type: 'CLEAR_LOGGEDIN_DATA',
})

export const clearSubscriptionData = () => ({
  type: 'CLEAR_SUBSCRIPTION_DATA',
})
