import * as React from 'react';
import './index.css';
import { widget } from '../../../../charting_library';
import apiCalls from '../../../helpers/apiCalls';
import { APIEndpoint } from '../../../helpers/dataStructures';
import moment from 'moment';
import { NoDataWidget } from '../../../../design_library';
import { Box } from '@chakra-ui/react';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}


let configurationData = {
	supported_resolutions: ['1D', '1',],
	supports_marks: true,
	supports_timescale_marks: true,

};

export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: 'AAPL',
		interval: 'D',
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: APIEndpoint + "/tradingview",
		chartsStorageApiVersion: 'v1',
		fullscreen: false,
		timeframe: '3M',
		autosize: true,
		studiesOverrides: {},

	};

	tvWidget = null;

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = {
			dataRequested: false,
			initialResolution: '???',
			chartNotFound: false,
		}
	}



	componentDidMount() {

		const widgetOptions = {
			symbol: this.props.stockTicker,
			datafeed: {
				onReady: (callback) => {
					setTimeout(() => callback(configurationData));
				},
				resolveSymbol: async (
					symbolName,
					onSymbolResolvedCallback,
					onResolveErrorCallback
				) => {

					if (symbolName.includes('/')) {
						this.setState({ chartNotFound: true })

					}
					else {

						apiCalls.searchForStock(APIEndpoint, symbolName).then((data) => {
							if (data.success === false) {
								this.setState({ chartNotFound: true })
							}
							else {
								const symbolInfo = {
									ticker: data.data.Code,
									name: data.data.Code,
									// exchange: data.data.Exchange,
									minmov: 1,
									pricescale: 100,
									has_intraday: true,
									has_no_volume: true,
									has_weekly_and_monthly: false,
									supported_resolutions: configurationData.supported_resolutions,
									volume_precision: 2,
								};

								onSymbolResolvedCallback(symbolInfo);
							}


						})
					}


				},
				getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
					const { from, to, firstDataRequest } = periodParams;
					const daily = ['1D'].includes(resolution)


					if (resolution === '1D' && this.state.initialResolution !== '1D') {
						console.log("Requesting daily data...");


						apiCalls.getChartData(APIEndpoint, symbolInfo.ticker, periodParams).then((data) => {

							let newData = [];
							data.data.data.filter((bar, index) => {
								newData.push({
									time: moment(bar.date).valueOf(),
									low: bar.low,
									high: bar.high,
									open: bar.open,
									close: bar.close,
								})
							});


							onHistoryCallback(newData, { noData: false });
							this.setState({ initialResolution: resolution, dataRequested: true });
						});
					}

					if (resolution === '1' && this.state.initialResolution !== '1') {
						console.log("Requesting intraday data...");

						apiCalls.getIntradayChartData(APIEndpoint, symbolInfo.ticker, periodParams).then((data) => {
							this.setState({ initialResolution: resolution, dataRequested: true });

							let newData = [];
							data.data.data.filter((bar, index) => {
								newData.push({
									time: bar.timestamp * 1000,
									low: bar.low,
									high: bar.high,
									open: bar.open,
									close: bar.close,
								})
							});

							onHistoryCallback(newData, { noData: false });
						});
					}
				},
				getMarks: async (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
					onDataCallback(this.props.annotations)
				},
				getTimescaleMarks: async (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
					onDataCallback(this.props.timescaleMarks)
				},
				unsubscribeBars: async (subscriberId) => {
					return true;
				},
			},
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: 'optiontracker.io',
			user_id: this.props.user._id,
			autosize: this.props.autosize,
			theme: this.props.colorMode === 'dark' ? 'Dark' : 'Light',
			timezone: "America/New_York",
			data_status: "endofday",
			chartsStorageUrl: APIEndpoint + "/tradingview/v1/",
			chartsStorageApiVersion: '',
			load_last_chart: true,
		};


		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {


			this.props.annotations.filter((value) => {

				if (value.tradeData !== undefined) {
					if (value.tradeData.positionStatus === 'OPEN') {
						if (value.tradeData.tradeType === 'STOCK') {
							tvWidget.chart().createOrderLine()
								.setText(`${value.tradeData.tradeType} ${value.tradeData.spotPriceAtTradeTime}`)
								.setLineLength(3)
								.setLineStyle(2)
								.setQuantity(value.tradeData.quantity)
								.setPrice(value.tradeData.spotPriceAtTradeTime);

						}
						else if (value.tradeData.tradeType === 'CALL' || value.tradeData.tradeType === 'PUT') {
							tvWidget.chart().createOrderLine()
								.setText(`${value.tradeData.tradeType} $${value.tradeData.strikePrice.toFixed(2)} ${moment(value.tradeData.expiryDate).format('MM/DD/YY')}`)
								.setLineLength(3)
								.setLineStyle(1)
								.setQuantity(value.tradeData.contracts)
								.setPrice(value.tradeData.strikePrice);



						}
					}



					// tvWidget.activeChart().createMultipointShape(
					// 	[
					// 		{
					// 			time: time,
					// 			price: tradeData.stockPriceAtClose - (tradeData.stockPriceAtClose * 0.01)
					// 		},
					// 		{
					// 			time: time,
					// 			price: tradeData.stockPriceAtClose
					// 		}
					// 	],
					// 	{
					// 		shape: 'note',
					// 		overrides: {
					// 			fontSize: 12,
					// 			backgroundColor: tradeData.tradeType === 'STOCK' ? 'blue' : tradeData.tradeType === 'CALL' ? 'green' : 'yellow',
					// 			fixedSize: false,
					// 			markerColor: tradeData.tradeType === 'STOCK' ? 'blue' : tradeData.tradeType === 'CALL' ? 'green' : 'yellow',
					// 			textColor: 'white',
					// 		},
					// 		text: tradeData.tradeType === 'STOCK' ? `You sold ${tradeData.quantity} of ${tradeData.stockTicker} at ${tradeData.spotPriceAtTradeTime}` : `${tradeData.action === 'SELL_TO_CLOSE' ? 'STC' : 'BTC'} ${tradeData.contracts}x ${tradeData.stockTicker} ${moment(tradeData.expiryDate).format('MM/DD/YYYY')} ${tradeData.strikePrice.toFixed(2)} at ${tradeData.premium} ${tradeData.tradeType === 'CALL' ? 'C' : 'P'}. P&L: $${(tradeData.realisedProfitAndLoss * 100).toFixed(2)}`,
					// 		lock: true,
					// 		disableSave: true,
					// 	});








					// }

					// }


				}


			})




		});

		// console.log(tvWidget.chart())

	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {

		if (this.state.chartNotFound === true) {
			return (
				<Box>
					<NoDataWidget text="Chart not available" label="Chart data was not available" />
				</Box>
			)
		}
		return (
			<div
				ref={this.ref}
				className={'TVChartContainer'}
			/>
		);
	}
}
