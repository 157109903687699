import React, { Component } from 'react'
import { Spinner, Center, Box, Container } from "@chakra-ui/react"

export default class SpinnerClass extends Component {


    render() {
        return (
            <Center>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                    {...this.props}
                />
            </Center>
        )
    }
}
