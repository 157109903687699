import { Box, Button, FormLabel, Heading, HStack, Input, Spinner, Text } from '@chakra-ui/react';
import React, { Component } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { PrimaryButton, TextInput } from '../../design_library';
import colors from '../../helpers/colors';
import { profile } from '../../redux/actions';
import API from '../helpers/apiCalls';

class VerifyPhoneNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phoneNumberSubmitted: false,
            verificationCodeSubmitted: false,
            phoneNumber: '',
            verificationCode: '',
            errorSubmittingVerifyCode: '',
            errorSubmittingPhoneNumber: ''
        }
    }

    submitVerify = (phoneNumber) => {

        this.setState({ loading: true, phoneNumberSubmitted: true, errorSubmittingPhoneNumber: '' });
        API.phoneVerify(this.props.settings.APIEndpoint, phoneNumber).then((data) => {
            if (data.success === true) {
                this.setState({ loading: false, phoneNumberSubmitted: true });
            }
            else {

                setTimeout(() => {
                    this.setState({ loading: false, phoneNumberSubmitted: false, errorSubmittingPhoneNumber: 'Please check your phone number is correct.' });
                }, 2500);


            }

        })
    }



    checkVerification = (code) => {
        this.setState({ loading: true, verificationCodeSubmitted: true, errorSubmittingVerifyCode: '' });
        API.phoneCheck(this.props.settings.APIEndpoint, code).then((data) => {
            if (data.success === true) {
                this.props.dispatch(profile(data.user));
            }
            else {
                this.setState({ loading: false, verificationCodeSubmitted: false, errorSubmittingVerifyCode: 'That verification code was not correct. Please try again.' });
            }
        })

    }

    render() {
        return (
            <Box>
                {this.state.phoneNumberSubmitted === false && <Text mb="4">Please verify your phone number to use the notifications service. You must supply a number capable of receiving text messages.</Text>}
                {this.state.phoneNumberSubmitted === true && <Text mb="4">Check your phone for a verification code, enter this below to register your phone number.</Text>}

                {this.state.loading === true ?
                    <Spinner />
                    :
                    <>
                        {this.state.phoneNumberSubmitted === false ?
                            <>
                                <HStack>
                                    <PhoneInput
                                        className="chakra-input"
                                        style={{ backgroundColor: 'var(--dark-gray)', border: 'solid 1px var(--dark-gray)', borderRadius: '4px', padding: '8px', color: 'var(--white)' }}
                                        defaultCountry="US"
                                        placeholder="Enter phone number"
                                        value={this.state.phoneNumber}
                                        onChange={(e) => this.setState({ phoneNumber: e })}
                                        error={this.state.errorSubmittingPhoneNumber ? (isValidPhoneNumber(this.state.phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                    <PrimaryButton onClick={() => this.submitVerify(this.state.phoneNumber)}>Submit</PrimaryButton>

                                </HStack>
                                <FormLabel mt="4" color="red">{this.state.errorSubmittingPhoneNumber}</FormLabel>
                            </>
                            :
                            <>
                                <HStack maxWidth="300px">
                                    <TextInput placeholder="Enter verification code" onChange={(e) => this.setState({ verificationCode: e.target.value })} />
                                    <PrimaryButton onClick={() => this.checkVerification(this.state.verificationCode)}>Submit</PrimaryButton>
                                </HStack>
                                <FormLabel mt="4" color="red">{this.state.errorSubmittingVerifyCode}</FormLabel>

                            </>
                        }
                    </>
                }

            </Box>

        )
    }
}



const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(VerifyPhoneNumber);
