import React, { Component } from 'react'
import { connect } from 'react-redux';
import { CustomToolTip, SecondaryButton } from '../../design_library';

class CalledAway extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validPosition: false,
            amountToCallAway: 0
        }
    }


    componentDidMount() {

        let trade = this.props.tradeData;
        let trades = this.props.trades;

        let accountId = this.props.settings.profile.accounts.filter((value, index) => { if (value.selected === true) { return value; } })[0].id;

        let stock = 0;

        let openTrades = trades.filter((value, index) => {
            if (value.positionStatus === 'OPEN' && value.stockTicker === trade.stockTicker && accountId === value.account) {
                if (value.tradeType === 'STOCK') {
                    stock += value.quantity;
                }
                return value;

            }

        });


        let potentialAmountToCallAway = trade.contracts * 100;
        if (potentialAmountToCallAway < stock) {
            potentialAmountToCallAway = 0;
        }


        // HEERERERE

        let valid = false;
        if (potentialAmountToCallAway > 0) {
            valid = true;
        }

        this.setState({ amountToCallAway: potentialAmountToCallAway, validPosition: valid })
        // Close options (whole position)
        // Close appropraite stock
    }





    render() {

        // Not yet in use
        return null;

        if (this.state.validPosition === false) {
            return null;

        }
        else {
            return (
                <div>
                    {this.props.tradeData.tradeType !== 'STOCK' &&
                        <CustomToolTip label="Closes the option at 100% gain and closes 100 shares per contract">
                            <SecondaryButton
                                onClick={() => {
                                    // this.props.onSubmit(true);
                                    console.log("CLICK!")
                                }}
                                colorScheme="teal" variant="outlne"
                            >
                                Quick: Called
                            </SecondaryButton>
                        </CustomToolTip>
                    }
                </div>
            )
        }

    }
}




const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(CalledAway);
