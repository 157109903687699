import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export class CustomLink extends Component {
    render() {
        return (
            <Link to={this.props.to} >{this.props.children}</Link>
        )
    }
}

export default CustomLink
