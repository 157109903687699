import { Table, Thead, useColorMode } from '@chakra-ui/react';
import React from 'react';
import colors from '../../helpers/colors';
import styles from './TableHeader.module.css';

export const TableHeader = (props) => {
    const { colorMode } = useColorMode();
    return (
        <Thead
            className={styles.tableHeaderX}
            // style={{}}
            {...props}
        >
            {props.children}
        </Thead >
    )
}