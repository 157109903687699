import React, { Component } from "react";
import API from "../../helpers/apiCalls";
import {
    Text,
    Spinner,
} from "@chakra-ui/react";
import {
    Stat,
    StatArrow,
    StatGroup,
} from "@chakra-ui/react"
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
} from "@chakra-ui/react"
import moment from 'moment';
import { TriangleDownIcon } from "@chakra-ui/icons";
import { CustomTd, CustomTr } from "../../../design_library";
var currencyFormatter = require('currency-formatter');
class FinancialStatRow extends React.Component {

    constructor() {
        super();
    }

    render() {


        let increaseOrDecrease = 'decrease';
        if (this.props.numberNow >= this.props.numberThen) {
            increaseOrDecrease = 'increase';
        }

        let percentageDifference = 100 * Math.abs((this.props.numberNow - this.props.numberThen) / ((this.props.numberNow + this.props.numberThen) / 2));
        let numericalDifference = Math.abs((this.props.numberNow - this.props.numberThen));

        if (this.props.numberNow === null) {
            return <></>
        }
        if (this.props.type === 'NUMERICAL') {
            return (
                <CustomTr>
                    <CustomTd><Text>{this.props.label}</Text></CustomTd>
                    <CustomTd><Text >{this.props.numberNow}</Text></CustomTd>
                    {!isNaN(numericalDifference) && numericalDifference !== 0 &&
                        <>
                            {this.props.subscriptionType === 'PREMIUM' ? <CustomTd> <Text>
                                <StatArrow type={increaseOrDecrease} />
                                ${numericalDifference.toFixed(0)}
                            </Text></CustomTd> :
                                <CustomTd></CustomTd>}
                        </>
                    }
                </CustomTr>
            )
        }
        else if (this.props.type === 'NUMERICAL_CURRENCY') {
            return (
                <CustomTr>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text >{currencyFormatter.format((this.props.numberNow), { code: 'USD' })}</Text></CustomTd>

                    <>
                        {this.props.subscriptionType === 'PREMIUM' ?
                            <CustomTd>{!isNaN(numericalDifference) && numericalDifference !== 0 &&
                                <Text>
                                    <StatArrow type={increaseOrDecrease} />
                                    {currencyFormatter.format((numericalDifference), { code: 'USD' })}
                                </Text>
                            }</CustomTd> :
                            <CustomTd></CustomTd>
                        }
                    </>
                </CustomTr>
            )
        }
        else if (this.props.type === 'NUMBER_ONLY' || this.props.type === 'NUMERICAL_ONLY') {
            return (
                <CustomTr>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text >{this.props.numberNow}</Text></CustomTd>
                    <>
                        {this.props.subscriptionType === 'PREMIUM' ?
                            <CustomTd>{!isNaN(numericalDifference) && numericalDifference !== 0 &&
                                <Text>
                                    <StatArrow type={increaseOrDecrease} />
                                    {numericalDifference}
                                </Text>
                            }</CustomTd> :
                            <CustomTd></CustomTd>
                        }
                    </>
                </CustomTr>
            )
        }
        else if (this.props.type === 'PERCENTAGE_ONLY') {
            return (
                <CustomTr>
                    <CustomTd><Text>{this.props.label}</Text></CustomTd>
                    <CustomTd><Text >{(this.props.numberNow).toFixed(2)}%</Text></CustomTd>
                    <>
                        {this.props.subscriptionType === 'PREMIUM' ? <CustomTd>{!isNaN(numericalDifference) && numericalDifference !== 0 &&
                            <Text>
                                <StatArrow type={increaseOrDecrease} />
                                {(numericalDifference).toFixed(2)}%
                            </Text>
                        }</CustomTd> :
                            <CustomTd></CustomTd>
                        }
                    </>
                </CustomTr>
            )
        }
        else if (this.props.type === 'DATE') {

            return (
                <CustomTr maxWidth={"180px"}>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text >{this.props.numberNow === '0000-00-00' ? 'Not available from market' : moment(this.props.numberNow).format('Do MMM YYYY')}</Text></CustomTd>
                    <CustomTd></CustomTd>
                </CustomTr>
            )
        }
        else if (this.props.type === 'PERCENTAGE') {
            return (
                <CustomTr>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text>{this.props.numberNow}</Text></CustomTd>
                    <>
                        {this.props.subscriptionType === 'PREMIUM' ?
                            <CustomTd>{!isNaN(percentageDifference) && percentageDifference !== 0 &&
                                <Text>
                                    <StatArrow type={increaseOrDecrease} />
                                    {percentageDifference.toFixed(0)}%
                                </Text>
                            }</CustomTd>
                            :
                            <CustomTd></CustomTd>
                        }
                    </>
                </CustomTr>
            )
        }
        else if (this.props.type === 'TEXT') {
            return (
                <CustomTr>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text>{this.props.numberNow}</Text></CustomTd>
                    <CustomTd></CustomTd>
                </CustomTr>
            )
        }
        else if (this.props.type === 'BASIC') {
            if (this.props.label === null || this.props.value === null || this.props.label === undefined || this.props.value === undefined) {
                return null;
            }
            else {
                return (
                    <CustomTr>
                        <CustomTd><Text >{this.props.label}</Text></CustomTd>
                        <CustomTd><Text>{this.props.value}</Text></CustomTd>
                        <CustomTd></CustomTd>
                    </CustomTr>
                )
            }

        }
        else if (this.props.type === 'OTM') {

            let value = Math.abs(this.props.value);
            let otm = (1 - value) * 100;

            return (
                <CustomTr>
                    <CustomTd><Text >{this.props.label}</Text></CustomTd>
                    <CustomTd><Text>{otm.toFixed(0)}%</Text></CustomTd>
                    <CustomTd></CustomTd>
                </CustomTr>
            )
        }
        else {
            return null;
        }


    }
}



export default FinancialStatRow;
