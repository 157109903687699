import { DeleteIcon } from '@chakra-ui/icons';
import {
  Alert, AlertDialog,
  AlertDialogBody, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Center, FormControl,
  FormLabel, HStack, Flex, IconButton, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useRadio,
  useRadioGroup,
  Select
} from "@chakra-ui/react";
import moment from 'moment';
import React, { Component, useState } from "react";
// import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { IoBuildOutline } from "react-icons/io5";
import { connect } from 'react-redux';
import { CustomDatePicker, CustomIconButton, CustomModal, CustomTab, CustomTabList, CustomTabPanels, CustomTabsContainer, CustomToolTip, DropDown, EditIcon, NegativeButton, PrimaryButton, RadioCard, SecondaryButton } from '../../../design_library';
import API from "../../helpers/apiCalls";
import { customStrategies } from '../../helpers/dataStructures';
import { DropDownSelectorsCustom } from '../../helpers/DropDownSelectorsCustom';
import { TabsAlt } from '../../helpers/Tabs';
import { fixTimezoneOffset } from '../../helpers/time';
import styles from "../Trades.module.css";
import ContextHighlightLink from './ContextMenu/ContextHighlightLink';
import TagView from './TagView';

class EditTrade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.editTradeModalStatus || false,
    }
  }

  toggleModal = (tog) => {
    this.setState({ isOpen: tog })
  }

  render() {

    if (this.props.newTable === true && this.props.data) {

      if (this.props.editTradeModalStatus === true) {

        return (
          <ModalComponent APIEndpoint={this.props.settings.APIEndpoint} isOpen={this.props.editTradeModalStatus} toggleModal={this.props.toggleModal} data={this.props.data} getTrades={this.props.getTrades} />
        )
      }
      else {
        return null;
      }
    }

    else if (this.props.data && this.props.data.canExpand !== true) {
      return (
        <div>

          {this.props.noIcon === true &&
            <ContextHighlightLink id="rcedit" text='Edit trade' onClick={() => this.setState({ isOpen: true })} />
          }

          {this.props.noIcon !== true &&

            <CustomToolTip label={'Edit trade'} >
              <CustomIconButton
                id="editbutton"
                onClick={() => this.setState({ isOpen: true })}
                icon={<EditIcon />}
                overrideIconColor={true}
              />
            </CustomToolTip>
          }

          <ModalComponent APIEndpoint={this.props.settings.APIEndpoint} isOpen={this.state.isOpen} toggleModal={this.toggleModal} data={this.props.data.original} getTrades={this.props.getTrades} />
        </div>
      )
    }
    else {
      return '';
    }
  }
}

function ModalComponent(props) {

  const [loading, setLoading] = useState(false);


  const saveText = () => {
    setLoading(true);

    let payload = props.data;
    // payload.notes = notes;
    payload.skipRecalculation = true;


    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        if (res.message) {
          alert(res.message);
        }
        else {
          alert(res.msg);
        }
        setLoading(false);
      }
      else {
        setLoading(false);
        props.toggleModal(false);

      }
    })


  }



  return (
    <>
      <TradeDetailModal
        modalStatus={props.isOpen}
        setModal={(value) => props.toggleModal(value)}
        APIEndpoint={props.APIEndpoint}
        toggle={(value) => props.toggleModal(value)}
        loading={loading}
        tradeData={props.data}
        getTrades={props.getTrades}
      // dataOffBy50Cent={dataOffBy50Cent}
      // history={props.history}
      // setStockTicker={props.setStockTicker}
      />
    </>
  )
}



function TradeDetailModal(props) {

  //Props pass in trade data + get new trade data
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, defaultValues },
  } = useForm();
  const [positionStatus, setPositionStatus] = useState(props.tradeData.positionStatus);
  const [isDividendFlag, setIsDividendFlag] = useState(props.tradeData.isDividendFlag);

  const [strategy, setStrategy] = useState({ label: props.tradeData.strategy || 'No strategy', value: props.tradeData.strategy || 'No strategy' });
  const [strategies, setStrategies] = useState([...customStrategies]);

  const [showEditTradeScreen, setShowEditTradeScreen] = useState(null);
  const [showCloseTradeScreen, setShowCloseTradeScreen] = useState(false);
  const [loadingDetail, setLoading] = useState(false);
  const [assignedBool, setAssignedBool] = useState(false);



  //Trade type radio group
  const tradeTypeOptions = ['Stock', 'Call', 'Put']
  const tradeTypeFunctions = useRadioGroup({
    name: 'tradeType',
    defaultValue: props.tradeData.tradeType === 'STOCK' ? 'Stock' : props.tradeData.tradeType === 'CALL' ? 'Call' : 'Put',
    onChange: (e) => {
      if (e === 'Stock') {
        setValue(props.tradeData._id + ".tradeType", 'STOCK')
      }
      else if (e === 'Call') {
        setValue(props.tradeData._id + ".tradeType", 'CALL')
      }
      else if (e === 'Put') {
        setValue(props.tradeData._id + ".tradeType", 'PUT')
      }
    }
  });
  const tradeTypeGroup = tradeTypeFunctions.getRootProps()


  //Action type radio group
  const actionsOptions = ['Buy to open', 'Sell to open']
  const actionFunctions = useRadioGroup({
    name: 'action',
    defaultValue: props.tradeData.action === 'BUY_TO_OPEN' ? 'Buy to open' : 'Sell to open',
    onChange: (e) => {
      if (e === 'Buy to open') {
        setValue(props.tradeData._id + ".action", 'BUY_TO_OPEN')
      }
      else if (e === 'Sell to open') {
        setValue(props.tradeData._id + ".action", 'SELL_TO_OPEN')
      }
    }
  });
  const actionGroup = actionFunctions.getRootProps()

  //Stock type radio group
  const stockOptions = ['Buy', 'Short']
  const stockFunctions = useRadioGroup({
    name: 'stockActions',
    defaultValue: props.tradeData.action === 'BUY' ? 'Buy' : 'Short',
    onChange: (e) => {
      if (e === 'Buy') {
        setValue(props.tradeData._id + ".action", 'BUY')
      }
      else if (e === 'Short') {
        setValue(props.tradeData._id + ".action", 'SHORT')
      }

    }
  });
  const stockGroup = stockFunctions.getRootProps()



  const onSubmit = (expired) => {


    setLoading(true);




    let payload = props.tradeData;
    payload.positionStatus = 'CLOSED';
    payload.assignedBool = assignedBool;
    payload.dateClosed = getValues('dateClosed') === undefined ? moment().format('MM/DD/YYYY') : getValues('dateClosed');
    payload.stockClosePrice = getValues('stockClosePrice');
    payload.spotPriceAtAssignment = assignedBool === true ? props.tradeData.strikePrice : undefined;
    payload.buyBackPricePerContract = assignedBool === true ? 0 : getValues('buyBackPricePerContract');
    payload.isDividendFlag = isDividendFlag;

    if (expired === true) {
      payload.dateClosed = moment(props.tradeData.expiryDate).format('MM/DD/YYYY');
      payload.buyBackPricePerContract = 0;
    }
    payload.skipRecalculation = true;


    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        if (res.message) {
          alert(res.message);
        }
        else {
          alert(res.msg);
        }
        setLoading(false);

      }
      else {
        props.getTrades(true);
        setLoading(false);
        props.toggle();
      }
    })


  };

  const handleCreateItem = (item) => {
    setStrategies((curr) => [...curr, item]);
    setStrategy((curr) => item);

  };
  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setStrategy({ label: 'No strategy', value: 'No strategy' });
      }
      else {
        if (selectedItems.length > 1) {
          setStrategy(selectedItems[1]);
        }
        else {
          setStrategy({ label: "No strategy", value: "No strategy" });
        }
      }
    }
  };
  const customRender = (selected) => {
    return (
      <Flex flexDir="row" alignItems="center">
        <Text>{selected.label}</Text>
      </Flex>
    )
  }


  const customCreateItemRender = (value) => {
    return (
      <Text backgroundColor={'var(--dark-gray)'} padding="0" margin="0">
        <Box as='span'>Add strategy tag: </Box>{' '}
        <Box as='span' bg='var(--white)' color="var(--black)" fontWeight='bold'>
          "{value}"
        </Box>
      </Text>
    )
  }

  const updateTrade = (id) => {



    setLoading(true);




    let payload = props.tradeData;
    payload = { ...props.tradeData, ...getValues()[id], strategy: strategy.value };

    payload.skipRecalculation = true;


    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        alert(res);
      }
      else {
        props.getTrades(true)

        setTimeout(() => {

          setLoading(false);
          props.toggle();
        }, 5)
      }
    })
  }


  if (props.loading) {
    return (
      <>
        <CustomModal size="6xl" isOpen={props.modalStatus} onClose={props.toggle}

          body={
            <Center height="200px">
              <Spinner />
            </Center>
          } />
      </>
    )
  }

  let optionExpired = false;
  if (!props.tradeData) {
    return null;
  }
  else if (props.tradeData.tradeType === 'PUT' || props.tradeData.tradeType === 'CALL') {
    if (!props.optionData) {
      var a = moment();
      var b = moment(props.tradeData.expiryDate);
      if (-a.diff(b, 'days') < 0) {
        optionExpired = true;

      }
      else {
      }
    }
    else {

    }
  }



  let closedAnnualisedReturn = 0;
  let openForDays = 0;
  if (props.tradeData.tradeType !== 'STOCK' && props.tradeData.positionStatus === 'CLOSED') {
    var a = moment(props.tradeData.dateOpened);
    var b = moment(props.tradeData.dateClosed);
    let totalRisk = (props.tradeData.contracts * props.tradeData.strikePrice) * 100;
    let percentageProfit = (props.tradeData.realisedProfitAndLoss / totalRisk) * 100;
    closedAnnualisedReturn = (percentageProfit / (-a.diff(b, 'days'))) * 365;
    openForDays = (-a.diff(b, 'days'));
  }


  let array = Object.keys(props.tradeData);



  return (
    <Box>
      <CustomModal size="6xl" isOpen={props.modalStatus} onClose={props.toggle}
        header={
          <Box>
            <ModalCloseButton />

          </Box>
        }
        body={

          <Box>
            {props.loading ?
              <Box>
                <Center>
                  <Spinner />
                </Center>
              </Box>
              :
              <>

                {loadingDetail ?
                  <Box width={'100%'} height={'200px'} display={'flex'} flexDir={'column'} justifyContent={'center'} alignItems='center'>
                    <Spinner />
                    <p>Loading...</p>
                  </Box>
                  :
                  <CustomTabsContainer>
                    <CustomTabList>

                      <CustomTab>
                        <EditIcon w={4} h={4} color="black.500" mr="2" id="editbutton" />
                        Edit trade</CustomTab>


                      <CustomTab >
                        <DeleteIcon id="deletetrademodalbutton" w={4} h={4} color="black.500" mr="2" />Delete trade</CustomTab>
                    </CustomTabList>


                    <CustomTabPanels>


                      {!props.loading &&
                        <TabPanel>

                          <>
                            {props.tradeData.tradeType === 'CASH' ? <Text>Cash transactions cannot be edited.</Text>
                              :
                              <>
                                {[...Object.keys(props.tradeData), 'strategy'].filter((v, i, a) => a.indexOf(v) === i).map((value, index) => {
                                  let arr = array;


                                  let forDeletion = [
                                    'account',
                                    // '_id',
                                    'createdAt',
                                    'updatedAt',
                                    'stockTicker',
                                    // 'tradeType',
                                    // 'action',
                                    'notes',
                                    // 'positionStatus',
                                    // 'dateOpened',
                                    'tags',
                                    'plaidImported',
                                    'shared',
                                    'initialDaysToExpiry',
                                    'protection',
                                    'initialPremiumPerDay',
                                    'initialInTheMoney',
                                    'totalRisk',
                                    'potentialReturnPercentage',
                                    'AnnualisedReturn',
                                    "timeOptionDataLastUpdated",
                                    "lastOptionPriceFromUpdate",
                                    'userId',
                                    'optionData',
                                    '__v',
                                    'fundamentalData',
                                    'assignedBool', // WORKING ON THESE
                                    // 'dateClosed',
                                    'activeNotificationsIds',
                                    'notifications',
                                    'liveStockPrice',
                                    'importedTrade',
                                    'importedTradeId',
                                    'shortDateOpened',
                                    'shortDateClosed',
                                    'timeOpened',
                                    'views',
                                    'strategy',
                                    'previousFundamentalData',
                                    'pastFundamentals',
                                    'tradeMarkedExpired', // WORKING ON THESE
                                    'tradeType',
                                    'importedTradeBatch',
                                    'logoUrl',
                                    'initialTimeValue',
                                    'initialVega',
                                    'initialIntrinsicValue',
                                    'initialIV',
                                    'initialTheta',
                                    'initialDelta',
                                    'initialGamma',
                                    'initialTheoretical',
                                    'initialRho',
                                    'initialLastPrice',
                                    'initialIntrinsicPrice',
                                    'dataAvailable',
                                    'skipRecalculation'];
                                  arr = arr.filter(item => !forDeletion.includes(item))
                                  arr.push('strategy');

                                  if (arr.includes(value)) {

                                    let type = '';
                                    if (typeof props.tradeData[value] === 'number') {
                                      type = 'number';
                                    }
                                    else {
                                      type = 'text'
                                    }

                                    if (props.tradeData[value] !== null) {


                                      if (value === '_id') {
                                        return <Text mt="2" color="var(--gray)" size="xs">TradeID: {props.tradeData._id}</Text>
                                      }
                                      // if (value === 'updatedAt') {
                                      //   return <Text mt="2" color="var(--gray)" size="xs">Data last updated at: {moment(props.tradeData.updatedAt).format('Do MMMM YYYY hh:mm:ss z')}</Text>
                                      // }
                                      // if (value === 'createdAt') {
                                      //   return <Text mt="2" color="var(--gray)" size="xs">Trade created at: {moment(props.tradeData.createdAt).format('Do MMMM YYYY hh:mm:ss z')}</Text>
                                      // }
                                      else if (value === 'dateOpened' || value === 'dateClosed' || value === 'expiryDate') {


                                        return (
                                          <FormControl mt="4" isRequired>
                                            <Stack>
                                              <FormLabel >{value.split().map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join('').match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(' ')}</FormLabel>
                                              <Controller
                                                control={control}
                                                name={"ReactDatepicker-" + value}

                                                render={(field) => (
                                                  <CustomDatePicker

                                                    className={styles['react-datepicker']}
                                                    placeholderText="Select date"
                                                    onChange={(e) => {

                                                      field.field.onChange(e);
                                                      setValue(props.tradeData._id + "." + value, fixTimezoneOffset(e))
                                                      props.tradeData[value] = fixTimezoneOffset(e);
                                                    }}
                                                    value={moment(props.tradeData[value]).format('MM/DD/YYYY')}

                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </FormControl>
                                        )

                                      }
                                      else if (value === 'tradeType') {
                                        return (
                                          <HStack width="100%" key={'tradeType-hstack'} mt="2" {...tradeTypeGroup} id="tradeType">
                                            {tradeTypeOptions.map((value) => {
                                              const radio = tradeTypeFunctions.getRadioProps({ value })
                                              return (
                                                <RadioCard key={'radio-tradetype-' + value} {...radio}>
                                                  {value}
                                                </RadioCard>
                                              )
                                            })}
                                          </HStack>
                                        )
                                      }
                                      // else if (value === 'assignedBool') {

                                      //   if (props.tradeData['tradeType'] !== 'STOCK' && props.tradeData['positionStatus'] === 'CLOSED') {
                                      //     return (
                                      //       <FormControl mt="4" isRequired>
                                      //         <Stack>
                                      //           <FormLabel >Trade Assigned</FormLabel>

                                      //           <HStack width="100%" mt="2" id="assignedBoolDropdown">
                                      //             <Select
                                      //               placeholder={props.tradeData[value]}

                                      //               onChange={(e) => {
                                      //                 setValue(props.tradeData._id + "." + value, e.target.value);
                                      //                 props.tradeData[value] = e.target.value;
                                      //               }}
                                      //             >

                                      //               <option value={true}>Yes</option>
                                      //               <option value={false}>No</option>
                                      //             </Select>
                                      //           </HStack>
                                      //         </Stack>
                                      //       </FormControl>
                                      //     )
                                      //   }
                                      //   else {
                                      //     return null;
                                      //   }

                                      // }
                                      // else if (value === 'tradeMarkedExpired') {

                                      //   if (props.tradeData['tradeType'] !== 'STOCK' && props.tradeData['positionStatus'] === 'CLOSED') {
                                      //     return (
                                      //       <FormControl mt="4" isRequired>
                                      //         <Stack>
                                      //           <FormLabel >Trade Expired</FormLabel>
                                      //           <HStack width="100%" mt="2" id="tradeMarkedExpired">
                                      //             <Select

                                      //               id="tradeMarkedExpired"
                                      //               placeholder={props.tradeData[value]}
                                      //               onChange={(e) => {
                                      //                 setValue(props.tradeData._id + "." + value, e.target.value);
                                      //                 props.tradeData[value] = e.target.value;
                                      //               }}
                                      //             >
                                      //               <option value={true}>Yes</option>
                                      //               <option value={false}>No</option>
                                      //             </Select>
                                      //           </HStack>
                                      //         </Stack>
                                      //       </FormControl>
                                      //     )
                                      //   }
                                      //   else {
                                      //     return null;
                                      //   }

                                      // }
                                      // else if (value === 'tags') {
                                      //   console.log(props.tradeData)
                                      //   return (
                                      //     <FormControl isRequired mt="4" key={index}>
                                      //       <FormLabel>Tags</FormLabel>
                                      //       <InputGroup>
                                      //         <TagView
                                      //           // {...register(props.tradeData._id + "." + value)}
                                      //           // onChange={(e) => {
                                      //           //   setValue(props.tradeData._id + "." + value, e.target.value)
                                      //           // }}

                                      //           allTags={[]}
                                      //           trade={props.tradeData}
                                      //         />
                                      //       </InputGroup>
                                      //     </FormControl>
                                      //   )
                                      // }

                                      else if (value === 'action') {
                                        if (props.tradeData['tradeType'] !== 'STOCK') {
                                          return (
                                            <HStack width="100%" mt="2" {...actionGroup} id="actionTab">
                                              {actionsOptions.map((value) => {
                                                const radio = actionFunctions.getRadioProps({ value })
                                                return (
                                                  <RadioCard key={'radio-action-' + value} {...radio}>
                                                    {value}
                                                  </RadioCard>
                                                )
                                              })}
                                            </HStack>
                                          )
                                        }
                                        else {
                                          return (
                                            <HStack width="100%" mt="2" {...stockGroup} id="actionTab">
                                              {stockOptions.map((value) => {
                                                const radio = stockFunctions.getRadioProps({ value })
                                                return (
                                                  <RadioCard key={`radio-stock-options-${value}`} {...radio}>
                                                    {value}
                                                  </RadioCard>
                                                )
                                              })}
                                            </HStack>
                                          )
                                        }
                                      }
                                      else if (value === 'isDividendFlag' && props.tradeData.tradeType === 'STOCK') {
                                        return (
                                          <FormControl mt="4" isRequired>
                                            <Stack>
                                              <FormLabel >Dividend</FormLabel>

                                              <Status
                                                mt="2"
                                                selected={isDividendFlag === false ? "Is not dividend" : 'Is dividend'}
                                                options={["Is dividend", "Is not dividend"]}
                                                onChange={(e) => {


                                                  if (e.target.value === 'Is dividend') {
                                                    setIsDividendFlag(true);
                                                    props.tradeData[value] = true;
                                                    setValue(props.tradeData._id + "." + value, true);


                                                  }
                                                  else if (e.target.value === 'Is not dividend') {
                                                    setIsDividendFlag(false);
                                                    props.tradeData[value] = false;
                                                    setValue(props.tradeData._id + "." + value, false);


                                                  }

                                                }} />
                                            </Stack>
                                          </FormControl>)
                                      }
                                      else if (value === 'positionStatus') {
                                        return (<Status
                                          mt="2"
                                          selected={positionStatus}
                                          options={["OPEN", "CLOSED"]}
                                          onChange={(e) => {
                                            setValue(props.tradeData._id + "." + value, e.target.value);
                                            setPositionStatus(e.target.value);
                                            props.tradeData[value] = e.target.value;
                                          }} />)
                                      }
                                      else if (value === 'strategy') {
                                        return (
                                          <DropDownSelectorsCustom
                                            className="inputBorder"
                                            tagStyleProps={{
                                              rounded: 'full'
                                            }}
                                            label=""
                                            placeholder="Type a strategy and press return to add it"
                                            onCreateItem={handleCreateItem}
                                            items={strategies}
                                            itemRenderer={customRender}
                                            createItemRenderer={customCreateItemRender}
                                            selectedItems={[strategy]}
                                            // createItemRenderer={(value) => `Click here to save ${value}`}
                                            onSelectedItemsChange={(changes) => {
                                              try {

                                                if (changes.type === "__function_remove_selected_item__" || !changes.selectedItems[1].value) {
                                                  setValue(props.tradeData._id + "." + value, 'No strategy');
                                                  setStrategy({ label: 'No strategy', value: 'No strategy' })
                                                  handleSelectedItemsChange([{ label: 'No strategy', value: 'No strategy' }])
                                                }
                                                else {

                                                  setValue(props.tradeData._id + "." + value, changes.selectedItems[1].value);
                                                  setStrategy(changes.selectedItems[1])
                                                  handleSelectedItemsChange(changes.selectedItems)


                                                }

                                              }
                                              catch (e) {
                                                console.log(e);
                                              }
                                            }}

                                          />
                                        )
                                      }
                                      else if (value === 'quantity' || value === 'contracts') {

                                        if (props.tradeData.tradeType === 'STOCK' && value === 'contracts') {
                                          return <></>
                                        }
                                        if (props.tradeData.tradeType !== 'STOCK' && value === 'quantity') {
                                          return <></>
                                        }

                                        return (
                                          <FormControl isRequired mt="4" key={index}>
                                            <FormLabel>{value.split().map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join('').match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(' ')}</FormLabel>
                                            <InputGroup>
                                              <Input
                                                id="editquantity"
                                                className="inputBorder"
                                                type={type}
                                                {...register(props.tradeData._id + "." + value)}
                                                onChange={(e) => {
                                                  setValue(props.tradeData._id + "." + value, e.target.value)
                                                }}
                                                defaultValue={props.tradeData[value]}
                                              />
                                            </InputGroup>
                                          </FormControl>
                                        )
                                      }
                                      else {
                                        return (
                                          <FormControl isRequired mt="4" key={index}>
                                            <FormLabel>{value.split().map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join('').match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(' ')}</FormLabel>
                                            <InputGroup>
                                              <Input
                                                className="inputBorder"
                                                type={type}
                                                {...register(props.tradeData._id + "." + value)}
                                                onChange={(e) => {
                                                  setValue(props.tradeData._id + "." + value, e.target.value)
                                                }}
                                                defaultValue={props.tradeData[value]}
                                              />
                                            </InputGroup>
                                          </FormControl>
                                        )
                                      }

                                    }
                                    else {
                                      return <></>
                                    }


                                  }
                                })

                                }
                                <form onSubmit={handleSubmit((data) => { updateTrade(props.tradeData._id) })}>









                                  <PrimaryButton
                                    type="submit"
                                    id="editsave"

                                  >Save</PrimaryButton>
                                </form>
                              </>}

                          </>
                        </TabPanel>
                      }

                      <TabPanel >

                        <DeleteTrade setDetailLoading={setLoading} tradeId={props.tradeData._id} APIEndpoint={props.APIEndpoint} setModal={props.setModal} getTrades={props.getTrades} />
                      </TabPanel>


                    </CustomTabPanels>

                  </CustomTabsContainer>
                }
              </>

            }


          </Box>
        }
      />
    </Box>
  )
}

function DeleteTrade(props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = React.useRef()

  function deleteTrade(tradeId) {
    props.setDetailLoading(true)


    API.deleteTrade(props.APIEndpoint, tradeId).then((data) => {
      if (data.success) {

        setTimeout(() => {

          props.getTrades();
          props.setDetailLoading(false)
          props.setModal(false);
          setIsOpen(false);
        }, 1)

      }
      else {
        alert("Failed to delete the trade...")
        props.setDetailLoading(false)

      }
    })
  }


  return (
    <>
      <NegativeButton onClick={() => setIsOpen(true)} id="deletetradebutton1">Delete trade</NegativeButton>



      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete trade
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this trade?
            </AlertDialogBody>

            <AlertDialogFooter>
              <SecondaryButton ref={cancelRef} onClick={onClose}>
                Cancel
              </SecondaryButton>
              <NegativeButton id="deletetradebutton2" onClick={() => deleteTrade(props.tradeId)} ml={3}>
                Delete
              </NegativeButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

function Status({ options, onChange, selected }) {

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
  })

  const group = getRootProps();


  return (
    <HStack mt="2"  {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={'key-x-' + value} {...radio} isChecked={value == selected ? true : false} onChange={(e) => {

            onChange(e);
          }}
          >
            {value}
          </RadioCard>
        )
      })}
    </HStack>
  )
}

// function RadioCard(props) {
//   const { getInputProps, getCheckboxProps } = useRadio(props)

//   const input = getInputProps()
//   const checkbox = getCheckboxProps()


//   return (
//     <Box as="label">
//       <input {...input} />
//       <Box
//         {...checkbox}
//         cursor="pointer"
//         borderWidth="1px"
//         borderRadius="md"
//         boxShadow="md"
//         _checked={{
//           bg: "blue.600",
//           color: "white",
//           borderColor: "blue.600",
//         }}
//         _focus={{
//           boxShadow: "outline",
//         }}
//         px={5}
//         py={3}
//       >
//         {props.children}
//       </Box>
//     </Box>
//   )
// }



const mapStateToProps = state => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(EditTrade);
