function letterValue(str) {
    var anum = {
        a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9, j: 10, k: 11,
        l: 12, m: 13, n: 14, o: 15, p: 16, q: 17, r: 18, s: 19, t: 20,
        u: 21, v: 22, w: 23, x: 24, y: 25, z: 26
    }
    if (str.length == 1) return anum[str] || '';
    return str.split('').map(letterValue);
}

const stringToColour = function (str) {

    try {

        str = letterValue(str).join(',').replace(/,/g, '').replace(/ /g, '');

        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;

    }
    catch (e) {
        console.log(e);
        return "var(--accent)"
    }

}




export default stringToColour;