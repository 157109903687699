import React from 'react';
import { Box, Button } from '@chakra-ui/react'

class ViewAllTradesButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box>
                <Button
                    maxW="200px"
                    size={'xs'}
                    key={'dataAvailable'}
                    variant="solid"
                    colorScheme="blue"
                    cursor={"pointer"}
                    onClick={() => {
                        if (this.props['setStockTicker'] !== undefined) {
                            this.props.setStockTicker(this.props.tradeData.stockTicker);
                            this.props.setModal(false);
                        }
                        else {
                            this.props.history.push(`/app/trades?stock=${this.props.tradeData.stockTicker}`)
                        }
                    }}
                >
                    View all {this.props.tradeData.stockTicker} trades
                </Button>
            </Box>
        );
    }
}

export default ViewAllTradesButton;