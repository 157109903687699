import React, { Component, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Modal, ModalOverlay, ModalContent, Box, Text, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Table, CustomThead, Tbody, Tfoot, Tr, TableCaption, Tag, Badge, TagLabel, HStack, useColorMode } from "@chakra-ui/react"
import moment from 'moment';
import { CustomModal, CustomTd, CustomTr, CustomTBody, TableFrame, CustomTh, Title, CustomToolTip } from '../../../design_library';
import { TableHeader } from '../../helpers/TableHeader';
import './Style.css';
import { calculateProfitAndLoss, calculateTotalRisk } from '../../helpers/tradeCalculations';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { LicenseManager } from "ag-grid-enterprise";
var currencyFormatter = require('currency-formatter');

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-042988 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Option Tracker Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Option Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Option Tracker )_need_to_be_licensed___( Option Tracker )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 June 2024 )____[v2]_MTcxOTM1NjQwMDAwMA==da26b5a51856f363e61b37e5775314c6");


export default function TradeList(props) {
    const { colorMode, toggleColorMode } = useColorMode();

    let newColumnDefs = [
        { "headerName": "Date Opened", "field": "dateOpened", cellRenderer: (params) => { return moment(params.value).format("MM/DD/YYYY") } },
        {
            "headerName": "Stock ticker", "field": "stockTicker",
            cellRenderer: (trade) => {
                return trade.value;
            }
        },
        {
            "headerName": "Trade Type", "field": "tradeType", cellRenderer: (trade) => {
                return trade.value === 'STOCK' ? <Tag size={"sm"} borderRadius="full" variant="solid" colorScheme={"blue"} >Stock</Tag> : trade.value === 'CALL' ? <Tag size={"sm"} borderRadius="full" variant="solid" colorScheme={"green"} >Call</Tag> : <Tag size={"sm"} borderRadius="full" variant="solid" colorScheme={"orange"} >Put</Tag>;
            }
        },
        {
            "headerName": "Action", "field": "action", cellRenderer: (trade) => {

                return trade.value && trade.value === 'BUY_TO_OPEN' ? <Badge mt="-1.5" colorScheme="red">Buy to open</Badge> : trade.value === 'SELL_TO_OPEN' ? <Badge mt="-1.5" colorScheme="green">Sell to open</Badge> : trade.value === 'BUY' ? <Badge mt="-1.5" colorScheme="blue">Buy</Badge> : <Badge mt="-1.5" colorScheme="orange">Short</Badge>;
            }
        },
        { "headerName": "Expiry", "field": "expiryDate", cellRenderer: (trade) => trade.data && trade.data.expiryDate && moment(trade.data.expiryDate).format("MM/DD/YYYY") },
        { "headerName": "Strike", "field": "strike", cellRenderer: (trade) => trade.data && trade.data.strikePrice && currencyFormatter.format(trade.data.strikePrice, { code: "USD" }) },
        { "headerName": "Status", "field": "positionStatus", cellRenderer: (trade) => trade.data && trade.data.positionStatus === 'OPEN' ? <Tag size={"sm"} borderRadius="full" variant="solid" colorScheme={"red"} > <TagLabel>Open</TagLabel> </Tag> : <Tag size={"sm"} borderRadius="full" variant="solid" bg={"darkgrey"} > <TagLabel>Closed</TagLabel> </Tag> },


        {
            headerName: "Option close price",
            field: "buyBackPricePerContract",
            cellRenderer: (props) => {
                if (!props.data) return null;
                if (props.value && props.data.tradeMarkedExpired === true) {
                    return "$0.00 (Expired)"
                }
                if (props.value && props.data.tradeType !== 'STOCK') {
                    return currencyFormatter.format(props.value, { code: 'USD' })
                }
            },

        },
        // {
        //     headerName: "Stock price at close",
        //     tooltip: "The spot price at which the trade was closed",
        //     field: "stockClosePrice",
        //     cellRenderer: (props) => {
        //         if (!props.data) return null;
        //         if (props.data.positionStatus === 'CLOSED') {
        //             return currencyFormatter.format(props.value, { code: 'USD' })
        //         }
        //     }
        // },




        {
            "headerName": "P&L", "field": "pandl", cellRenderer: (trade) => {
                if (!trade.data) return null;
                return currencyFormatter.format(calculateProfitAndLoss([trade.data]).total, { code: 'USD' });
            }
        },
        {
            "headerName": "Trade Risk", "field": "cost", cellRenderer: (trade) => {
                if (!trade.data) return null;

                return currencyFormatter.format(calculateTotalRisk([trade.data]).actualRisk, { code: 'USD' })
            }
        },
        { "headerName": "Shares", "field": "quantity", cellRenderer: (trade) => { return trade.value; } },
        { "headerName": "Contracts", "field": "contracts", cellRenderer: (trade) => { return trade.value; } },
        { "headerName": "Commission", "field": "commission", cellRenderer: (trade) => { return currencyFormatter.format(trade.value, { code: 'USD' }); } },
    ]

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [columnDefs, setColumnDefs] = useState(newColumnDefs);
    const [savedStateApplied, setSavedStateApplied] = useState(false);




    const openStoredGroups = () => {
        let groups = store.getItem(OPEN_GROUP_KEY);
        groups.forEach((groupId) => {
            let node = gridApi.getRowNode(groupId);
            try {
                node.setExpanded(true);
            }
            catch (e) {
                // console.log(e);
            }
        });
    };

    const saveState = () => {
        console.log("Saving state...")
        if (savedStateApplied === true) {
            localStorage.setItem('colStateTradeList', JSON.stringify(gridRef.current.columnApi.getColumnState()));
            localStorage.setItem('filStateTradeList', JSON.stringify(gridRef.current.api.getFilterModel()));
        }
    };

    const clearFilters = () => {
        gridRef.current.api.setFilterModel(null);
    };

    const [gridIsReady, setGridIsReady] = useState(false);
    const [gridApi, setGridApi] = useState({});
    const [openStocks, setOpenStocks] = useState([]);

    const onGridReady = (params) => {
        console.log("Ready!");

        try {
            setGridApi(params.api);
        }
        catch (e) {
            console.log(e);
        }



        console.log("Grid ready...!");
        setGridIsReady(true);



        restoreState();
        setTimeout(() => {
            setSavedStateApplied(true);
        }, 15)
    }

    function getContextMenuItems(params) {
        var result = [
            'autoSizeAll',
            'expandAll',
            'contractAll',
            'copyWithHeaders',
            'resetColumns',
            'export',
            'copy',
            'separator',
            {
                name: 'Reset table',
                action: (row) => {
                    resetState();
                },
            },
        ];

        return result;
    }

    const restoreState = () => {
        console.log("Restoring state...")
        let state = JSON.parse(localStorage.getItem('colStateTradeList'));
        let filterState = JSON.parse(localStorage.getItem('filStateTradeList'));
        gridRef.current.columnApi.applyColumnState({
            state: state,
            applyOrder: true,
        });

        gridRef.current.api.setFilterModel(filterState);

    };

    const resetState = () => {
        console.log("Reseting state...")
        gridRef.current.columnApi.resetColumnState();
    };

    function Store(store) {
        return {
            setItem: (key, item) => {
                if (!key) {
                    return null;
                }
                return store.setItem(key, JSON.stringify(item));
            },
            getItem: (key) => {
                if (!key) {
                    return null;
                }
                return JSON.parse(store.getItem(key));
            },
            clear: () => {
                return store.clear();
            },
        };
    }

    let store = new Store(window.localStorage);
    const OPEN_GROUP_KEY = 'openGroupsTradeListOnly';

    (function initialiseGroupStore() {
        let groups = store.getItem(OPEN_GROUP_KEY);
        if (!groups) groups = [];
        store.setItem(OPEN_GROUP_KEY, groups);
    })();

    function addGroupToStore(id) {
        let groups = store.getItem(OPEN_GROUP_KEY);
        if (groups.indexOf(id) > -1) {
            return;
        }
        groups.push(id);
        store.setItem(OPEN_GROUP_KEY, groups);
    }

    function removeGroupFromStore(id) {
        let groups = store.getItem(OPEN_GROUP_KEY);
        let index = groups.indexOf(id);
        if (index > -1) {
            groups.splice(index, 1);
        }
        store.setItem(OPEN_GROUP_KEY, groups);
    }

    const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        resizable: true,
        sideBar: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: false,
        suppressPivotMode: true,
        suppressPivots: true,
        enableCellTextSelection: false
    }));

    if (props.notModal === true) {
        return (
            <div className={colorMode === 'dark' ? "ag-theme-alpine-dark table " : "ag-theme-alpine table "} style={{ width: '100%', height: 'calc(100vh - 140px)' }}>


                <AgGridReact
                    maintainColumnOrder={true}
                    onFirstDataRendered={(params) => {
                        openStoredGroups();
                    }}
                    onRowGroupOpened={(params) => {
                        if (params.node.expanded) {
                            console.log('adding id to store', params.node.id);
                            addGroupToStore(params.node.id);
                        } else {
                            console.log('removing id from store', params.node.id);
                            removeGroupFromStore(params.node.id);
                        }
                    }}

                    onFilterModified={(e) => {
                        saveState();
                    }}

                    popupParent={document.body}

                    rememberGroupStateWhenNewData={true}

                    enableCellChangeFlash={true}
                    onColumnMoved={(e) => {
                        saveState();

                    }}
                    onColumnResized={(e) => {
                        saveState();
                    }}
                    onSortChanged={(e) => {
                        saveState();
                    }}

                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={props.tradeList} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    // groupDefaultExpanded={1}
                    rowSelection='multiple' // Options - allows click selection of rows
                    onCellDoubleClicked={(e) => {
                        props.setTradeData(e.data);

                    }} // Optional - registering for Grid Event
                    getContextMenuItems={getContextMenuItems}
                    groupAllowUnbalanced={false}
                    enableRangeSelection={true}
                    showOpenedGroup={true}
                    onGridReady={onGridReady}
                    components={{
                        groupColumnCellRenderer: (props) => {
                            return props.value;
                        }
                    }}
                    alwaysShowHorizontalScroll={true}
                    autoGroupColumnDef={
                        {
                            cellRendererParams: {
                                innerRenderer: "groupColumnCellRenderer",
                            },
                            pinned: "left",
                        }
                    }

                    rowGroupPanelShow={'always'}

                />

            </div>

        )
    }


    return (
        <>



            <CustomModal isOpen={props.isOpen} onClose={props.onClose} size="6xl"
                header={
                    <HStack justifyContent={'space-between'}>
                        <Title fontSize="20px" >Trades list</Title>
                        <ModalCloseButton />
                    </HStack>
                }
                body={
                    <>


                        <div className={colorMode === 'dark' ? "ag-theme-alpine-dark table " : "ag-theme-alpine table "} style={{ width: '100%', height: 'calc(100vh - 140px)' }}>


                            <AgGridReact
                                maintainColumnOrder={true}
                                onFirstDataRendered={(params) => {
                                    openStoredGroups();
                                }}
                                onRowGroupOpened={(params) => {
                                    if (params.node.expanded) {
                                        console.log('adding id to store', params.node.id);
                                        addGroupToStore(params.node.id);
                                    } else {
                                        console.log('removing id from store', params.node.id);
                                        removeGroupFromStore(params.node.id);
                                    }
                                }}

                                onFilterModified={(e) => {
                                    saveState();
                                }}

                                popupParent={document.body}

                                rememberGroupStateWhenNewData={true}

                                enableCellChangeFlash={true}
                                onColumnMoved={(e) => {
                                    saveState();

                                }}
                                onColumnResized={(e) => {
                                    saveState();
                                }}
                                onSortChanged={(e) => {
                                    saveState();
                                }}

                                ref={gridRef} // Ref for accessing Grid's API
                                rowData={props.tradeList} // Row Data for Rows
                                columnDefs={columnDefs} // Column Defs for Columns
                                defaultColDef={defaultColDef} // Default Column Properties
                                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                // groupDefaultExpanded={1}
                                rowSelection='multiple' // Options - allows click selection of rows
                                onCellDoubleClicked={(e) => {
                                    props.setTradeData(e.data);
                                }} // Optional - registering for Grid Event
                                getContextMenuItems={getContextMenuItems}
                                groupAllowUnbalanced={false}
                                enableRangeSelection={true}
                                showOpenedGroup={true}
                                onGridReady={onGridReady}
                                components={{
                                    groupColumnCellRenderer: (props) => {
                                        return props.value;
                                    }
                                }}
                                alwaysShowHorizontalScroll={true}
                                autoGroupColumnDef={
                                    {
                                        cellRendererParams: {
                                            innerRenderer: "groupColumnCellRenderer",
                                        },
                                        pinned: "left",
                                    }
                                }

                                rowGroupPanelShow={'always'}

                            />

                        </div>


                    </>
                }
                footer={null}
            >


            </CustomModal >
        </>
    )
}
