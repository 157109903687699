import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Box, } from "@chakra-ui/react"
import './style.css';
import OpenInterestCharts from './Charts/OpenInterestCharts';
import analytics from '../helpers/amplitude';
import { ChartFrame } from '../helpers/ChartFrame';

class OptionChainAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            openInterestData: [],
            puts: [],
            calls: [],
        }
    }


    componentDidMount() {
        analytics.logEvent('OptionChainAnalysis. Opened');
    }






    render() {



        return (
            <Box  >
                {this.state.loading === false &&
                    <>
                        {/* <OpenInterestCharts lastStockPrice={this.props.lastStockPrice} type="OPEN_INTEREST" data={this.props.data} colorMode={this.props.colorMode} /> */}
                        <OpenInterestCharts lastStockPrice={this.props.lastStockPrice} type="VOLUME" data={this.props.data} colorMode={this.props.colorMode} />
                    </>
                }
            </Box>
        )



    }
}



const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(OptionChainAnalysis);
