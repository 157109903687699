import { Table, useColorMode, Box } from '@chakra-ui/react';
import React from 'react';
import './ChartFrame.css';


export const ChartFrame = (props) => {
    const { colorMode } = useColorMode();
    try {

        return React.cloneElement(props.children, { colorMode, ...props })
    }
    catch (e) {
        return ''
    }
}