import { CheckIcon } from '@chakra-ui/icons';
import {
  Box, Center, HStack, Image, ModalCloseButton, Text, VStack
} from '@chakra-ui/react';
import { motion } from "framer-motion";
import * as React from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { CustomLink, CustomModal, PrimaryButton, Title } from '../../design_library';
import analytics from '../../platform/helpers/amplitude';
import apiCalls from '../../platform/helpers/apiCalls';
import styles from './Pricing.module.css';
import './PricingSVG.css';
import PlusSVGFour from './SVG/PlusFour';
import PlusPricingSVGOne from './SVG/PlusOne';
import PlusSVGThree from './SVG/PlusThree';
import PlusSVGTwo from './SVG/PlusTwo';

const App = (props) => {

  const [metadata, setMetadata] = React.useState({});


  React.useEffect(() => {
    apiCalls.getSubscriptionMetaData().then(res => {
      setMetadata({
        trial_days: res.trial_period_days,
        trial_days_loading: false,
        promo: res.sale,
        loading: false,
        iAmMounted: true
      })

    }).catch((e) => {
      console.log(e);
      setMetadata({
        trial_days: 30,
        trial_days_loading: false,
        promo: { active: false },
        loading: false,
        iAmMounted: true

      })
    })

  }, [])

  let originalMonthlyPrice = 49.99
  let monthlyPrice = 49.99
  let annualPrice = 299.99

  let accessTokenPresent = localStorage.getItem('access_token');



  if (metadata && metadata.promo && metadata.promo.active === true && !accessTokenPresent && !window.location.search.includes('?via=')) {
    monthlyPrice = monthlyPrice - (monthlyPrice * metadata.promo.percent)
    annualPrice = annualPrice - (annualPrice * metadata.promo.percent)
  }

  return (
    <Box as="section" id="pricing">

      <div className="graphicHolder">

        <motion.div animate={{ translateY: [0, 10, 0], }} transition={{ duration: 4, repeat: Infinity }} >
          <PlusPricingSVGOne />
        </motion.div>
        <motion.div animate={{ translateY: [0, 10, 0], }} transition={{ duration: 3, repeat: Infinity }} >
          <PlusSVGTwo />
        </motion.div>
        <motion.div animate={{ translateY: [0, 10, 0], }} transition={{ duration: 2.2, repeat: Infinity }} >
          <PlusSVGThree />
        </motion.div>
        <motion.div animate={{ translateY: [0, 10, 0], }} transition={{ duration: 6, repeat: Infinity }} >
          <PlusSVGFour />
        </motion.div>







      </div>


      <Center>
        <Box p={{ base: '10', md: '16' }} width="6xl" rounded="xl" mt="2" mb="8"
          display="flex" flexDir={'column'} justifyContent='center' alignItems={'center'}>
          <Image textAlign={'center'} display='flex' justifyContent={'center'} src={'../images/svg/logo.svg'} alt="Pricing card logo" style={{ maxWidth: '371px' }} marginBottom="16px" />


          <Text fontSize={'14px'} textAlign='center' fontWeight="400" opacity={0.8} maxW="372px">Get full access to all features with a subscription and take your stock & option trading experience to the next level</Text>




          <HStack
            gap={'32px'}
            marginBottom="28px"
            marginTop="44px"
            minH={'228px'}
            justifyContent='center'
            alignItems={'center'}
            flexWrap='wrap'
          >
            <Box className={styles.pricingCardBox}>
              <Box className={styles.priceBox}>
                <Text className={styles.currency}>$</Text>
                <Text className={styles.price}>{monthlyPrice.toFixed(2)}
                  {metadata && metadata.promo && metadata.promo.active === true && !accessTokenPresent && !window.location.search.includes('?via=') && <>*</>}

                </Text>
              </Box>
              <Text className={styles.frequencyBox}>Per Month</Text>
              <FeaturesModal />



            </Box>


            <Box className={styles.pricingCardBoxWithHighlight}>
              <Box className={styles.highlight}>
                SAVE ${((originalMonthlyPrice * 12) - (annualPrice)).toFixed(2)} | Annual Package
              </Box>

              <Box className={styles.center}>



                <Box className={styles.priceBoxWithHighlight}>
                  <Text className={styles.currency}>$</Text>
                  <Text className={styles.price}>{(annualPrice / 12).toFixed(2)}
                    {metadata && metadata.promo && metadata.promo.active === true && !accessTokenPresent && !window.location.search.includes('?via=') && <>*</>}
                  </Text>
                </Box>
                <Text className={styles.frequencyBox}>Per month</Text>

                <FeaturesModal />


              </Box>
            </Box>
          </HStack>

          {metadata && metadata.promo && metadata.promo.active === true && !accessTokenPresent && !window.location.search.includes('?via=') && <Text mb="24px">* This is the discounted price: Use coupon <span style={{ fontWeight: '800' }}>{metadata.promo['promo_code']}</span> at checkout for {metadata.promo.discount} off!</Text>}



          <PrimaryButton as="a" href="../app/login"
            isLoading={props.trial_days.loading}
            onClick={() => {
              analytics.logEvent('V2.Homepage.User_clicks_under_pricing_cta');
            }}
            id="bottomcta"
          >
            Start {props.trial_days.days} day free trial now
          </PrimaryButton>





        </Box>
      </Center>
    </Box>
  )
}

const FeaturesModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Box>

      <HStack >
        <IoAddCircleOutline width="20px" stroke="var(--white)" />
        <CustomLink cursor="pointer" onClick={setIsOpen} className={styles.featuresText}>All features included</CustomLink>
      </HStack>

      <CustomModal
        size="md"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Pricing"
        header={
          <HStack>
            <Title>Features</Title>
            <ModalCloseButton />
          </HStack>
        }
        body={
          <Box>
            <VStack mt="12px" alignItems={'flex-start'}>
              <FeaturesList text={'Unlimited Trades'} />
              <FeaturesList text={'Unlimited Portfolios'} />
              <FeaturesList text={'Trading Journal'} />
              <FeaturesList text={'Calendar View'} />
              <FeaturesList text={'Customizable Visualizations'} />
              <FeaturesList text={'Advanced Charting'} />
              <FeaturesList text={'Table View'} />
              <FeaturesList text={'Trade Sharing'} />
              <FeaturesList text={'Strategy Analysis'} />
              <FeaturesList text={'Performance Anaylsis'} />
              <FeaturesList text={'Custom Dashboard'} />
              <FeaturesList text={'Notifications'} />
              <FeaturesList text={'Option Screener'} />
            </VStack>
          </Box>
        }
      />

    </Box>
  )
}

const FeaturesList = ({ text }) => {
  return (
    <HStack>
      <CheckIcon width="20px" stroke="var(--green)" />
      <Text>{text}</Text>
    </HStack>
  )
}


export default App;