import { Box, Center, Heading, Link, Stack, Text } from '@chakra-ui/layout'
import Footer from '../homepage/footer/App'

import React, { Component } from 'react'
import NavBar from '../homepage/menu/App';
import ArticlesContainer from '../platform/articles/ArticlesContainer';
import { Helmet } from 'react-helmet';
import Meta from '../helpers/meta';

export class NewsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {

        window.location = 'https://blog.optiontracker.io/'
        let articleId = window.location.pathname.replace("/app/news/", "").replace("/news/", "").replace("/news", "");
        return (
            <Box width="100%" >

                {!articleId &&

                    <Meta
                        title={'Blog'}
                        description={"The Option Tracker blog. We publish articles about what's new on Option Tracker here."}
                        canonical={"https://optiontracker.io/news"}
                    />
                }

                <NavBar />
                <Box mt="-72px" height="72px"></Box>
                <Box pt="0" margin="auto" >
                    <ArticlesContainer mode="DARK" homepage={true} articleId={articleId} history={this.props.history} location={this.props.location} />
                </Box>

            </Box>
        )


    }
}

export default NewsPage;

