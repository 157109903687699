import React, { Component } from 'react'
import styles from './NiceButton.module.css';

export class NiceButton extends Component {
    render() {
        return (
            <button {...this.props} className={styles.customLink}>{this.props.children}</button>
        )
    }
}

export default NiceButton
