import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Box, Heading, Text } from '@chakra-ui/layout';
import React, { Component } from 'react';
import { FcElectricity } from 'react-icons/fc';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { connect } from 'react-redux';
import analytics from '../helpers/amplitude';

class UpgradePage extends Component {

  upgradeSubscription = async (pricingModel) => {
    analytics.logEvent('MainApp.Payments.Subscription.Upgrade');

    this.setState({ loading: true });
    let access_token = localStorage.getItem("access_token");
    fetch(this.props.settings.APIEndpoint + '/subscriptions', {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token
      },
      body: JSON.stringify({ type: pricingModel })
    }).then(response => response.json())

      .then(async result => {
        if (result.success === true) {
          alert("We have upgraded your account!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        else {
          alert("Something went wrong, please try again later or contact support.");
          analytics.logEvent('MainApp.Payments.Subscription.FailedToUpgrade');

        }

      }).catch(e => {
        this.setState({ loading: false });
      })
  }

  render() {
    return (
      <Box

        height="100%"
        overflow="hidden"
        padding="5"
        width="100%"
        flex={2}

        rounded={{ md: 'md' }}


        maxW="2xl"
        mx="auto"
        px={{ base: '6', lg: '8' }}
        textAlign="center">
        <Icon as={FcElectricity} w={126} h={126} pb="2" />
        <Heading as="h2" size="3xl" fontWeight="extrabold" letterSpacing="tight">Upgrade to premium</Heading>
        <Text mt="4" fontSize="lg">See end of day data across stocks, ETFs and options. Each trade is enabled with options & fundamentals tracking.</Text>
        <Button
          maxWidth="450px"
          mt="4"
          onClick={() => this.upgradeSubscription('PREMIUM')}
          size="lg"
          w="full"
          colorScheme="blue"
          rightIcon={<HiArrowNarrowRight />}
        >
          Upgrade to Premium ($49.99 per month)
        </Button>
      </Box>
    )
  }
}

const mapStateToProps = state => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(UpgradePage);