import React, { Component } from 'react'
import './style.css';
import { connect } from 'react-redux';

import Articles from './Articles';
import analytics from '../helpers/amplitude';
import { Spinner } from '@chakra-ui/react';

class FAQContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: '',
            loading: true,
        }
    }

    componentDidMount() {
        analytics.logEvent('FAQ.opened');

        if (this.props.match && this.props.match.params && this.props.match.params.articleId) {
            this.setState({ articleId: this.props.match.params.articleId, loading: false })
        }
        else {
            this.setState({ articleId: this.props.articleId, loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return <Spinner />
        }
        return (
            <Articles mode={this.props.mode} type="FAQ" articleId={this.state.articleId} history={this.props.history} location={this.props.location} />
        )
    }
}



const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};


export default connect(mapStateToProps)(FAQContainer);
