import React from 'react';
import { Table, useColorMode, Box } from '@chakra-ui/react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import colors from '../../helpers/colors';
import './DropDownSelectorsCustom.css';

export const DropDownSelectorsCustom = (props) => {
    const { colorMode } = useColorMode();
    return (
        <div className={colorMode === 'dark' ? "darkAutoComplete" : "lightAutoComplete"}>
            <CUIAutoComplete
                {...props}
                inputStyleProps={{ backgroundColor: 'var(--dark-gray)', border: 0 }}
                listStyleProps={{ backgroundColor: 'var(--dark-gray)' }}
                colorMode={colorMode}
            />
        </div>
    )
}

export const DropDownSelectorsCustomTable = (props) => {
    const { colorMode } = useColorMode();
    return (
        <div className={colorMode === 'dark' ? "darkAutoComplete" : "lightAutoComplete"}>
            <CUIAutoComplete
                {...props}
                inputStyleProps={{ backgroundColor: 'var(--dark-gray)', border: 0 }}
                listStyleProps={{ backgroundColor: 'var(--dark-gray)' }}
                colorMode={colorMode}
            />
        </div>
    )
}