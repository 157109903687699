import * as React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { RiArrowLeftRightLine } from "react-icons/ri";

export interface LinkGroup {
  title: string;
  links: Array<{
    label: string;
    href: string;
    badge?: React.ReactElement;
  }>;
}

export const links: LinkGroup[] = [
  {
    title: "Website",
    links: [
      { label: "Home", href: "https://optiontracker.io/" },
      { label: "Pricing", href: "https://optiontracker.io/#pricing" },
      { label: "Contact", href: "#footer" },
    ],
  },
  {
    title: "App",
    links: [
      { label: "Dashboard", href: "https://optiontracker.io/app/dashboard" },
      { label: "Trades", href: "https://optiontracker.io/app/trades" },
    ],
  },
  {
    title: "News & help",
    links: [
      { label: "News", href: "../news" },
      { label: "FAQ", href: "../faq" },
    ],
  },
];

interface SocialLink {
  label: string;
  icon: React.ReactElement;
  href: string;
}

export const socialLinks: SocialLink[] = [
  // { label: 'Slack', icon: <FaSlack />, href: 'https://join.slack.com/t/option-tracker/shared_invite/zt-xwpcg1z8-3QkesCFG1FqLQXiANeI4sQ' },
  {
    label: "Facebook",
    icon: <FaFacebook />,
    href: "https://www.facebook.com/optiontracker.io/",
  },
  {
    label: "Instagram",
    icon: <FaInstagram />,
    href: "https://www.instagram.com/optiontracker/",
  },
  {
    label: "LinkedIn",
    icon: <FaLinkedin />,
    href: "https://www.linkedin.com/company/option-tracker",
  },
  {
    label: "Twitter",
    icon: <FaTwitter />,
    href: "https://twitter.com/OptionTrackerIo",
  },
  {
    label: "Twitter",
    icon: <RiArrowLeftRightLine />,
    href: "https://stocktwits.com/optiontrackerapp",
  },
];

type FooterLink = {
  label: string;
  href: string;
};

export const footerLinks: FooterLink[] = [
  { label: "Terms of Service", href: "https://optiontracker.io/terms.html" },
  { label: "Privacy Policy", href: "https://optiontracker.io/privacy.html" },
  { label: "Sitemap", href: "https://optiontracker.io/sitemap.xml" },
  { label: "WealthBee", href: "https://wealthbee.io/" },
  { label: "ViviFit", href: "https://vivi.fit/" },
  { label: "FitBranded", href: "https://fitbranded.com/" },
  // { label: 'Affiliate', href: 'https://affiliate.optiontracker.io/' },
];
