import React, { Component } from 'react'
import styles from "./NiceModal.module.css";
import Modal from 'react-modal';

export default class NiceModal extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                className={styles.modal}

                >
                {this.props.children}
            </Modal>
        )
    }
}
