import { Box, Center } from '@chakra-ui/layout';

import React, { Component } from 'react';
import Meta from '../helpers/meta';
import { Spinner } from '../platform/components';
import amplitude from '../platform/helpers/amplitude';
import apiCalls from '../platform/helpers/apiCalls';
import { APIEndpoint } from '../platform/helpers/dataStructures';
import TradeDetailModal from '../platform/trades/TradeDetailModal';

export class SharePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: 'LOADING',
            tradeData: {}
        }
    }


    componentDidMount() {
        this.getTrade()
    }

    getTrade = () => {
        apiCalls.getASpecificTrade(APIEndpoint, this.props.match.params.tradeId).then((data) => {


            if (data.success === false) {
                window.location = "https://optiontracker.io/404";
            }
            else if (data.success === true) {

                amplitude.logEvent('V2.SharedPage.Visit:' + window.location.href)
                this.setState({ tradeData: data.trade, loading: false, state: "READY" });
            }
            // else {
            //     console.log(data);
            // }
        })
    }


    render() {

        if (this.state.state === 'LOADING') {
            return <Center h="100vh"><Spinner /></Center>
        }
        else {

            return (
                <Box width="100%" backgroundColor="#1a202c">
                    {this.props.match.params.tradeId &&

                        <Meta
                            title={'Options Tracker - Shared Trade'}
                            description={"Someone shared a trade on OptionTracker.io for you"}
                            canonical={"https://optiontracker.io/share/" + this.props.match.params.tradeId}
                        />

                    }
                    <Box pt="0" backgroundColor="#1a202c" margin="auto" height={'100vh'} >



                        <TradeDetailModal
                            modalStatus={true}
                            setModal={this.toggleTradeModal}
                            APIEndpoint={APIEndpoint}
                            toggle={this.toggleTradeModal}
                            loading={this.state.tdmLoading}
                            tradeData={this.state.tradeData}
                            optionData={this.state.tradeData.optionData}
                            shared={true}
                            subscriptionType={'PREMIUM'}
                        />


                    </Box>


                </Box>
            )
        }


    }
}

export default SharePage;

