import React, { Component } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Box } from '@chakra-ui/layout';
import { Text, Spinner } from '@chakra-ui/react';
import colors from '../../../helpers/colors';
import { LineChart } from '../../../design_library';



export default class OpenInterestCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [

        {
          "id": "Puts",
          "color": "red",
          "data": []
        },
        {
          "id": "Calls",
          "color": "green",
          "data": []
        },
      ]
    }
  }

  componentDidMount() {

    let data = this.state.data;

    this.props.data.filter((value, index) => {

      if (this.props.type === 'OPEN_INTEREST') {
        if (value.type === 'CALL') {
          data[1].data.push({ y: value.strike, x: value.openInterest })
        }
        else {
          data[0].data.push({ y: value.strike, x: value.openInterest })
        }
      }
      else if (this.props.type === 'VOLUME') {
        if (value.type === 'CALL') {
          data[1].data.push({ y: value.strike, x: value.volume })
        }
        else {
          data[0].data.push({ y: value.strike, x: value.volume })
        }
      }

      data.sort((a, b) => {
        return a.y - b.y
      })

    })


    this.setState({ data: data, loading: false });

  }

  render() {
    if (this.state.loading === true) {
      return <Spinner />
    }


    const atmZone = [
      {
        axis: 'y',
        value: this.props.lastStockPrice,
        lineStyle: { stroke: 'var(--red)', strokeWidth: 2 },
        legendOrientation: 'vertical',
      },
    ]

    return (
      <Box
        minHeight="700px"
        height="1200px"
        overflow="hidden"
        padding="5"
        pb={"45px"}
        minWidth="700px"
        flex={2}
      >
        {this.props.type === "OPEN_INTEREST" && <Text fontSize="2xl">Open Interest against strike</Text>}
        {this.props.type === "VOLUME" && <Text fontSize="2xl">Volume against strike</Text>}
        <LineChart
          markers={atmZone}
          curve="linear"

          tooltipFormat={(value) => {
            return value;
          }}


          data={this.state.data}
          margin={{ top: 80, right: 50, bottom: 120, left: 100 }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Open Interest / Volume',
            legendOffset: 36,
            legendPosition: 'middle',
            labelTextColor: 'var(--white)',

          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Strike',
            legendOffset: -80,
            legendPosition: 'middle',
            labelTextColor: 'var(--white)',

          }}

          xScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          dots={false}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }}



        />
      </Box>
    )
  }
}
