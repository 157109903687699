import React, { Component } from 'react';
import { connect } from 'react-redux';
import analytics from '../helpers/amplitude';
import Articles from './Articles';
import './style.css';
import { Spinner } from '@chakra-ui/react';


class ArticlesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            articleId: '',
            loading: true,
        }
    }

    componentDidMount() {
        analytics.logEvent('News.opened');
        if (this.props.match && this.props.match.params && this.props.match.params.articleId) {
            this.setState({ articleId: this.props.match.params.articleId, loading: false })
        }
        else {
            this.setState({ articleId: this.props.articleId, loading: false })
        }


    }

    render() {
        if (this.state.loading) {
            return <Spinner />
        }
        return (

            <Articles mode={this.props.mode} match={this.props.match} type="NEWS" articleId={this.state.articleId} history={this.props.history} location={this.props.location} />

        )
    }
}



const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};


export default connect(mapStateToProps)(ArticlesContainer);
