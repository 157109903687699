import { extendTheme } from "@chakra-ui/react";
const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
    components: {
        Modal: {
            baseStyle: {
                dialogContainer: {
                    "@supports(height: -webkit-fill-available)": {},
                },
            },
        },
    },
};

const theme = extendTheme({ config })


export default theme; 
