import React, { Component } from 'react'
import styles from './NiceLink.module.css';

export class NiceLink extends Component {
    render() {
        return (
            <a {...this.props} className={styles.customLink}>{this.props.children}</a>
        )
    }
}

export default NiceLink
