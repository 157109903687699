import moment from "moment";
import { calculateCash, calculateCashOnDate } from "./tradeCalculations";

export const getAccountValue = (props) => {
    try {


        let account = props.settings.profile.accounts.filter((value, index) => {
            if (value.selected === true) {
                return value;
            }
        })[0];


        let cash = calculateCash(props.settings.trades, account.id);

        let totalAccountValue = account.totalAccountValue;

        return parseFloat(totalAccountValue) + parseFloat(cash);
    }
    catch (e) {
        return 0;
    }
}


export const getAccountValueUpToDate = (props, unixDate) => {
    // Filter the account that has been selected

    try {

        let account = props.settings.profile.accounts.filter((value, index) => {
            return value.selected === true;
        })[0];

        // Filter trades up to the given Unix date
        let filteredTrades = props.settings.trades.filter(trade => {
            if (trade.account === account.id) {
                if (moment(trade.dateClosed).unix() <= unixDate) {
                    return trade;
                }
            }

        });




        // Calculate cash from the filtered trades
        let cash = calculateCashOnDate(filteredTrades, unixDate, account.id);

        // Calculate total account value
        let totalAccountValue = account.totalAccountValue;


        return parseFloat(totalAccountValue) + parseFloat(cash);



    }
    catch (e) {
        return 0
    }

}