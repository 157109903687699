import { Box, Button, FormControl, FormLabel, Heading, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Table, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorMode } from "@chakra-ui/react";
import moment from 'moment';
import React, { Component, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { connect } from 'react-redux';
import { BellIcon, CustomIconButton, CustomModal, CustomTBody, CustomTd, CustomTh, CustomToolTip, CustomTr, DeleteIcon, NotificationItem, PrimaryButton, Title } from "../../../design_library";
import colors from '../../../helpers/colors';
import API from "../../helpers/apiCalls";
import { TableFrame } from '../../helpers/TableFrame';
import { TableHeader } from '../../helpers/TableHeader';
import VerifyPhoneNumber from "../../Settings/VerifyPhoneNumber";
import ContextHighlightLink from "./ContextMenu/ContextHighlightLink";


class Notifications extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false,
            loading: true,
            notifications: []
        }
    }

    toggleModal = (tog) => {
        this.setState({ isOpen: tog });

        if (tog === true) {
            this.getNotificationsForTrade(this.props.data.original._id);
        }
        else {

            this.setState({ loading: false });
        }
    }


    addNotification = ({ type, threshold, value, forId, commMethod }) => {

        if (type.length < 2) {
            return;
        }
        else {

            this.setState({ loading: true });

            API.addNotification(this.props.settings.APIEndpoint, { type, threshold, value, forId, commMethod }).then((value) => {
                if (value.success === true) {
                    if (this.props.newTable === true) {
                        this.getNotificationsForTrade(this.props.data._id);
                    }
                    else {
                        this.getNotificationsForTrade(this.props.data.original._id);

                    }
                    this.props.getTrades();
                }
                else {
                    this.setState({ loading: false });
                    alert(value.msg);
                }
            })
        }
    }

    deleteNotification = (notificationId) => {

        this.setState({ loading: true });
        API.deleteNotification(this.props.settings.APIEndpoint, notificationId).then((value) => {
            if (value.success === true) {

                if (this.props.newTable === true) {
                    this.getNotificationsForTrade(this.props.data._id);
                }
                else {
                    this.getNotificationsForTrade(this.props.data.original._id);
                }
                this.props.getTrades();

            }
            else {
                this.setState({ loading: false });
                alert('Something went wrong!')
            }
        })
    }

    getNotificationsForTrade = (tradeId) => {

        this.setState({ loading: true });
        API.getNotifications(this.props.settings.APIEndpoint, tradeId).then((value) => {
            if (value.success === true) {
                console.log(value.notifications);
                this.setState({ loading: false, notifications: value.notifications });
            }
            else {
                this.setState({ loading: false });
                alert("Something went wrong retrieving notifications!")
            }
        });
    }

    componentDidMount() {

        // if (this.props.demo === true) {
        //     this.setState({
        //         notifications: []
        //     })
        // }

    }

    render() {


        if (this.props.newTable === true) {

            if (!this.props.data) {
                return null;
            }

            return (
                <ModalComponent
                    profile={this.props.settings.profile}
                    tradeData={this.props.data}
                    addNotification={this.addNotification}
                    modalStatus={this.props.notificationModalStatus}
                    toggle={this.props.toggleModal}
                    deleteNotification={this.deleteNotification}
                    loading={this.state.loading}
                    notifications={this.state.notifications}
                />


            )
        }
        else if (this.props.data.canExpand !== true) {




            let notificationsActive = false

            if (this.props.data.original.activeNotificationsIds && this.props.data.original.activeNotificationsIds.length > 0) {
                notificationsActive = true;
            }
            else {
                notificationsActive = false;
            }

            return (
                <div>




                    {this.props.noIcon === true &&
                        <ContextHighlightLink text='Notifications' onClick={() => this.setState({ isOpen: true })} />
                    }

                    {this.props.noIcon !== true &&

                        <CustomToolTip label={notificationsActive ? 'Notifications active' : 'Create notification'} >
                            <CustomIconButton
                                overrideIconColor={true}
                                onClick={() => this.toggleModal(true)}
                                activated={notificationsActive}
                                icon={<BellIcon />}
                            />
                        </CustomToolTip>
                    }

                    {this.state.isOpen === true && <ModalComponent demo={this.props.demo} profile={this.props.settings.profile} tradeData={this.props.data.original} addNotification={this.addNotification} modalStatus={this.state.isOpen} toggle={(e) => this.toggleModal(e)} deleteNotification={this.deleteNotification} loading={this.state.loading} notifications={this.state.notifications} />}
                </div>
            )
        }
        else {
            return '';
        }
    }
}

function ModalComponent(props) {

    const { colorMode } = useColorMode();

    let email = props.profile.email;
    let phoneNumberStatus = props.profile.phoneNumberStatus;

    let [value, setValue] = useState(0);
    let [threshold, setTheshold] = useState('LESS_THAN');
    let [type, setType] = useState('STOCK_PRICE');
    let [commMethod, setCommMethod] = useState('EMAIL');

    return (
        <CustomModal size="6xl" isOpen={props.modalStatus} onClose={props.toggle}
            header={
                <Title>Notifications</Title>
            }
            body={
                <Box>
                    {phoneNumberStatus !== 'approved' && props.demo !== true ?

                        <>
                            <VerifyPhoneNumber colorMode={colorMode} />
                        </>
                        :

                        <>

                            <HStack mb="4">
                                {props.loading === true && <Spinner />}
                            </HStack>
                            <Text mb="4">Notifications are checked every 30 minutes. Option data is updated once per day, fundamentals are updated once per day and stock data is updated every 30 minutes.</Text>

                            <HStack mt="15px">

                                <FormControl id="Type">
                                    <FormLabel as="legend">Type</FormLabel>
                                    <Select defaultValue={type} onChange={e => setType(e.target.value)}>
                                        <option value="STOCK_PRICE">Stock price</option>
                                        {props.tradeData.tradeType !== 'STOCK' && <option value="DT_EXPIRY">Days until expiry</option>}
                                        {props.tradeData.tradeType !== 'STOCK' && <option value="OPTION_PRICE">Option price</option>}
                                        <option value="DT_EARNINGS">Days until earnings</option>
                                    </Select>
                                </FormControl>

                                <FormControl id="Threshold">
                                    <FormLabel as="legend">Threshold</FormLabel>
                                    <Select defaultValue={threshold} onChange={e => setTheshold(e.target.value)}>
                                        <option value="LESS_THAN">Less than</option>
                                        {type !== 'DT_EXPIRY' && type !== 'DT_EARNINGS' && <option value="GREATER_THAN">Greater than</option>}
                                    </Select>
                                </FormControl>

                                <FormControl id="Value">
                                    <FormLabel as="legend">Value</FormLabel>
                                    <Input type="number" value={value} onChange={e => setValue(e.target.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel as="legend">Delivery method</FormLabel>
                                    <Select placeholder="Select delivery method" defaultValue={commMethod} onChange={e => setCommMethod(e.target.value)}>
                                        <option value="PHONE">SMS</option>
                                        <option value="EMAIL">Email</option>
                                    </Select>
                                </FormControl>

                                <FormControl id="submit">
                                    <PrimaryButton
                                        mt="8"
                                        onClick={() => {
                                            props.addNotification({ type: type, threshold: threshold, value: value, forId: props.tradeData._id, commMethod: commMethod })
                                        }}>Add</PrimaryButton>
                                </FormControl>

                            </HStack>

                            {props.notifications.length > 0 &&
                                <TableFrame size="sm" mt="20px">
                                    <TableHeader>
                                        <CustomTr>
                                            <CustomTh>Status</CustomTh>
                                            <CustomTh>Type</CustomTh>
                                            <CustomTh>Threshold</CustomTh>
                                            <CustomTh>Value</CustomTh>
                                            <CustomTh>Date created</CustomTh>
                                            <CustomTh>Date notification expires</CustomTh>
                                            <CustomTh>Method</CustomTh>
                                            <CustomTh></CustomTh>
                                        </CustomTr>
                                    </TableHeader>
                                    <CustomTBody>
                                        {props.notifications.map((value, index) => {


                                            let status = value.status;
                                            if (status === 'ACTIVE') { status = <Tag colorScheme="green" borderRadius="full" variant="solid"><TagLabel>Active</TagLabel></Tag> }
                                            else if (status === 'MESSAGE_SENT') { status = <Tag borderRadius="full" colorScheme="blue" variant="solid"><TagLabel>Sent</TagLabel></Tag> }
                                            else if (status === 'EXPIRED') { status = 'Expired' };

                                            let type = value.type;
                                            if (type === 'DT_EXPIRY') { type = 'Days until expiry' }
                                            else if (type === 'OPTION_PRICE') { type = 'Option price' }
                                            else if (type === 'STOCK_PRICE') { type = 'Stock price' }
                                            else if (type === 'DT_EARNINGS') { type = 'Days until earnings' }

                                            let threshold = value.threshold;
                                            if (threshold === 'LESS_THAN') { threshold = 'Less than' }
                                            else if (threshold === 'GREATER_THAN') { threshold = 'Greater than' }

                                            return (
                                                // <CustomTr key={'row-' + index}>
                                                //     <CustomTd>{status}</CustomTd>
                                                //     <CustomTd>{type}</CustomTd>
                                                //     <CustomTd>{threshold}</CustomTd>
                                                //     <CustomTd>{value.value}</CustomTd>
                                                //     <CustomTd>{moment(value.dateCreated).format('Do MMM YYYY')}</CustomTd>
                                                //     <CustomTd>{moment(value.expiryDate).format('Do MMM YYYY')}</CustomTd>
                                                //     <CustomTd>{value.commMethod === 'EMAIL' ? 'Email' : 'SMS'}</CustomTd>
                                                //     <CustomTd>
                                                //         <CustomIconButton onClick={() => props.deleteNotification(value._id)} icon={<DeleteIcon />} />
                                                //     </CustomTd>
                                                // </CustomTr>
                                                <NotificationItem
                                                    index={index}
                                                    status={status}
                                                    type={type}
                                                    threshold={threshold}
                                                    commMethod={commMethod}
                                                    value={value}
                                                    deleteNotification={props.deleteNotification}
                                                />
                                            )
                                        })}


                                    </CustomTBody>

                                </TableFrame>
                            }


                        </>



                    }
                </Box>
            }
        />
    )
}




const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(Notifications);
