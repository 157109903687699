import React from 'react';
import apiCalls from "./apiCalls";
const MapsSVG = React.lazy(() => import('../Onboarding/Components/Maps'));

const { Text, Box, Button, Kbd, Heading, Center, HStack, VStack, ListIcon, ListItem } = require("@chakra-ui/react");


export const CheckTourStatus = (page) => {
    return new Promise((resolve, reject) => {
        apiCalls.getEducation().then(data => {
            if (data.education === null && data.success === true) {
                apiCalls.initateEducation().then(res => {
                    resolve(!res.education[page])
                })
            }
            else if (data.education && data.success === true) {
                resolve(!data.education[page])
            }
        });
    });
}
// ''

export const MarkPageCompleted = (page, value) => {
    apiCalls.setEducation({ key: page, value }).then(data => { });
};

const GeneralTour = ({ title, line1, cta }) => {
    return (
        <Box>
            <Text fontSize="lg" fontWeight="bold">
                {title}
            </Text>
            <Text mt="2">
                {line1}
            </Text>
            <Text mt="2">
                {cta}
            </Text>
        </Box>
    )
}


const addHighlightClass = (className) => {
    try {
        document.querySelector("." + className).classList.add('highlight');
    }
    catch (e) {
        console.log(e);
    }
}

const removeHighlightClass = (className) => {
    try {
        document.querySelector("." + className).classList.remove('highlight');
    }
    catch (e) {
        console.log(e);
    }
}

const moveClass = (className, x, y, scale) => {
    try {
        document.querySelector("." + className).style.transform = `translateX(${x}%) translateY(${y}%) scale(${scale})`;
    }
    catch (e) {
        console.log(e);
    }
}


const GraphicPlaceHolder = () => {
    return (
        <Box display={{ base: 'none', lg: 'block' }} overflow="hidden" flex="1" bg="#1a202c" color="white"  >
            <MapsSVG />
        </Box>
    )
}

export const steps = {
    "SIDEBAR": [
        {
            content:
                <HStack>
                    <VStack>
                        <Box

                            onMouseEnter={() => {
                                addHighlightClass('journal');
                                moveClass('demoSVG', -60, 10, 1);
                            }}
                            onMouseLeave={() => {
                                removeHighlightClass('journal')
                            }}


                        >Test</Box>


                        <Text fontSize="lg" fontWeight="bold">
                            Welcome to Option Tracker!
                        </Text>
                        <Text mt="2">
                            On every page we will take you through a quick tour to help you get started.
                        </Text>
                        <Text mt="2">
                            If you every want to see the tours again, head to the account page and click on reset.
                        </Text>
                        <Text mt="2">
                            Click the arrows to get started. You can even use your keyboard! <Kbd>➡️</Kbd>
                        </Text>
                    </VStack>

                    <GraphicPlaceHolder />
                </HStack >,
        },
        // { selector: '#accountButton', content: <GeneralTour title="Your account" line1="This is where you can manage your account. You can change your password and adjust your subscription." cta="" />, },
        // { selector: '#accountPicker', content: <GeneralTour title="Portfolios" line1="Here you can change portfolio. If you click the drop down you can also 'Manage Portfolios'. You can set your targets here." cta="" />, },
        // { selector: '#dashboard', content: <GeneralTour title="Dashboard" line1="We're on the dashboard right now. Here you can view an overview of your selected portfolio." cta="" />, },
        // { selector: '#trades', content: <GeneralTour title="Trades" line1="This page will show you a table of your trades. Similar to your broker except you'll see opens & closes linked here." cta="" />, },
        // { selector: '#journal', content: <GeneralTour title="Journal" line1="Take notes, view your trades in a calendar view. See upcoming expiries." cta="" />, },
        // { selector: '#lookup', content: <GeneralTour title="Lookup" line1="Search for stock data and see it visualized." cta="" />, },
        // { selector: '#upload', content: <GeneralTour title="Upload" line1="Add your trades using a CSV or automatic linking through Plaid." cta="" />, },
        // { selector: '#toggleDarkLight', content: <GeneralTour title="Dark Mode / Light Mode" line1="Use this button to toggle between color modes." cta="" />, },
        // { selector: '', content: <GeneralTour title="Thats the sidebar completed!" line1="Feel free to navigate to another page." cta="" />, },

    ],
    "TRADES": [
        { selector: '', content: <GeneralTour title="Trades" line1="On this page you can see all of your trades in a table format. You can group, filter and search for different stock tickers." cta="" /> },
        { selector: '#addtrade', content: <GeneralTour title="Add trades (manual)" line1="You can add trades here using a form. It supports single & multi-leg input." cta="" />, },
        { selector: '.grouping', content: <GeneralTour title="Grouping" line1="Trades can be grouped by different metrics." cta="Here you can choose between no grouping, group by symbol, expiry, date opened or strategy." />, },
        { selector: '.tradeType', content: <GeneralTour title="Filter: Trade type" line1="Filter by Put, Call or Stock." cta="" />, },
        { selector: '.positionStatus', content: <GeneralTour title="Filter: Position Status" line1="Filter if the trade is open or closed." cta="" />, },
        { selector: '.search', content: <GeneralTour title="Filter: Stock Ticker" line1="Type a stock ticker in here to filter the table by that." cta="" />, },
        { selector: '.cXdatePicker', content: <GeneralTour title="Filter: Date" line1="Select a range of dates to filter the table by" cta="" />, },
        { selector: '#customise', content: <GeneralTour title="Configure: Customize" line1="Toggle the columns to display" cta="" />, },
        { selector: '.customTableHeader ', content: <GeneralTour title="Table Header" line1="You can click and drag table headers to re-order them." cta="" />, },
        { selector: '.toggleHelpIcon ', content: <GeneralTour title="Help" line1="If you want to see this again click this icon, otherwise send us a message with the button in the bottom right." cta="" />, },
    ],
    "JOURNAL": [
        { selector: '', content: <GeneralTour title="Journal" line1="Welcome to the journal, a place where you can view your trades in a calendar and schedule notes to be reviewed." cta="" />, },
        { selector: '.JournalSideBar', content: <GeneralTour title="Sidebar" line1="You'll see your trades for the selected day shown in the 'drawer' to the right. You can click on them to open that specific trade and add notes." cta="" />, },
        { selector: '.AddNote', content: <GeneralTour title="Add a note" line1="You can add a note. Once you've added a note you'll need to mark it as reviewed. You can do this by clicking on the day and then the note. " cta="" />, },
        { selector: '.Calendar', content: <GeneralTour title="Calendar" line1="You'll see your trades, notes and expiries listed here." cta="" />, },
        { selector: '.rbc-month-view > div:nth-child(4) > div.rbc-row-bg > div:nth-child(4)', content: <GeneralTour title="Select a day" line1="Clicking on any 'cell' will select it and display the contained data on the sidebar." cta="" />, },
    ],
    "ADD_SINGLE_LEG_TRADE_MODAL": [

        { selector: '', content: ({ goTo, inDOM }) => (<GeneralTour title="Getting started" line1="Welcome to the Add Trade page. Remember we do not execute live trades." cta="You should use this page when you've just made a trade in your brokerage account" />), },
        { selector: '#choiceSingleOrMulti', content: ({ goTo, inDOM }) => (<GeneralTour title="Single/Multi Leg" line1="You can add both single and multi-leg trades." cta="Use the multi-leg if you want to add multiple trades at the same time." />), },
        {
            selector: '#stockTicker', content: () => (<GeneralTour title="Search" line1="Start by searching for a stock ticker." cta="Enter 'AAPL' in the stock ticker field and press search." />),
            // action: (e) => {
            //     document.getElementById("stockTicker").value = "AAPL";

            //     setTimeout(() => {
            //         document.getElementById("searchButton").click();
            //     }, 50)
            // }
        },
        {
            selector: '#tradeType',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Trade type</Heading>
                    <Text>You can enter stock, put or calls.</Text>
                    <Text>Choose call or put for this example</Text>
                </div>
            ),
            position: 'bottom',

        },
        {
            selector: '#actionTab',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Action</Heading>
                    <Text>For stock you can only enter 'Buy' or 'Short'</Text>
                    <Text>For options your choice is buy to open or sell to open.</Text>
                    <Text>Choose either action</Text>
                </div>
            )
        },
        {
            selector: '.dateOpened',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Date opened</Heading>
                    <Text>This is the date that you opened the trade.</Text>
                </div>
            )
        },
        {
            selector: '#expiryDate',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Expiry Date</Heading>
                    <Text>Choose the date that your trade expires</Text>
                </div>
            ),
            position: 'bottom',
        },
        {
            selector: '#strike',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Strike</Heading>
                    <Text>The strike price of the option</Text>
                </div>
            ),
            position: 'bottom',
        },
        {
            selector: '#contracts',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Contracts</Heading>
                    <Text>The number of contracts bought or sold</Text>
                </div>
            ),
            position: 'bottom',
        },
        {
            selector: '#premium',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Premium</Heading>
                    <Text>The premium per contract of the option.</Text>
                    <Text>This field will automatically populate, double check to ensure that it is accurate as option pricing is delayed.</Text>
                </div>
            ),
            position: 'bottom',
        },
        // {
        //   selector: '#spotPrice',
        //   content: ({ }) => (
        //     <div>
        //       <Heading size="xs">Live spot price</Heading>
        //       <Text>The live spot price.</Text>
        //       <Text>This is used to calculate your live protection and risk of assignment.</Text>
        //     </div>
        //   ),
        //   position: 'bottom',
        // },
        {
            selector: '.strategyOp',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Strategy</Heading>
                    <Text>You can set a strategy, the hierachy on the trades page is: </Text>
                    <Text>Underlying → Strategy → Individual leg. </Text>
                    <Text>Setting a strategy allows you to measure them individually and track aspects such as cost basis and profits.</Text>
                    <Text>For now, we will leave strategy blank.</Text>
                </div>
            )
        },
        // {
        //   selector: '#greeks',
        //   content: ({ }) => (
        //     <div>
        //       <Heading size="xs">Greeks</Heading>
        //       <Text>When you enter an option a snapshot of the greeks is taken.</Text>
        //       <Text>Then each day the data is refreshed to see how the greeks have changed.</Text>
        //       <Text>You can use this data to understand how the greeks have an impact on the value of your options.</Text>
        //     </div>
        //   ),
        //   position: 'bottom',
        // },
        {
            selector: '#source',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Source</Heading>
                    <Text>Where you heard or found the trade.</Text>
                </div>
            ),
            position: 'bottom',
        },
        {
            selector: '#notes',
            content: ({ }) => (
                <div>
                    <Heading size="xs">Notes</Heading>
                    <Text>Why you entered the trade, or anything else you want to record.</Text>
                    <Text>You can edit this later.</Text>
                </div>
            ),
            position: 'bottom',
        },
        {
            selector: '#xxasdsda',
            content: ({ }) => (
                <div>
                    <Heading size="xs">That's it!</Heading>
                    <Text>Now you are ready to get started.</Text>
                    <Text>You can close this window by pressing <Kbd>esc</Kbd></Text>
                </div>
            ),
            position: 'bottom',
        },],
    "DASHBOARD": [
        { selector: '', content: () => (<GeneralTour title="Dashboard" line1="Great! You've added a trade. Let's give you a quick tour of the dashboard." cta="Click the arrow to get started." />), },
        { selector: '#tradeType', content: () => (<GeneralTour title="Filter: Trade Type" line1="Filter all of the charts on the page by the selected trade type" cta="" />), },
        { selector: '#actionType', content: () => (<GeneralTour title="Filter: Action" line1="Filter all of the trades by their action - buy to open, sell to open etc." cta="" />), },
        { selector: '#stockTicker', content: () => (<GeneralTour title="Filter: Stock Ticker" line1="Filter to only view a single stock ticker" cta="" />), },
        { selector: '#strategyPicker', content: () => (<GeneralTour title="Filter: Strategy" line1="Filter by a specific strategy. You can enter this on the add trade page or set it manually from the trades table." cta="" />), },
        { selector: '#sourcePicker', content: () => (<GeneralTour title="Filter: Trade Source" line1="Filter by trade source." cta="" />), },
        { selector: '#dateRangePicker', content: () => (<GeneralTour title="Filter: Date" line1="Select a date range to filter all trades by." cta="" />), },
        { selector: '#customizeButton', content: () => (<GeneralTour title="Insights" line1="You can enable and disable more panels. Click this button to see the selection." cta="" />), },
        { selector: '.drag-handle', content: () => (<GeneralTour title="Move the panels around" line1="Click and drag this handle to move the boxes around." cta="" />), },
        { selector: '.react-resizable-handle', content: () => (<GeneralTour title="Re-size panels" line1="Click and drag this icon to expand or shrink panels." cta="" />), },

    ]
}


{/* <GeneralTour title="" line1="" cta="Click here to get started." /> */ }

