import {
  Box, Flex,
  FlexProps,
  HStack, useColorMode,
  useDisclosure,
  VisuallyHidden
} from '@chakra-ui/react'
import * as React from 'react'
import { PrimaryButton, SecondaryButton } from '../../design_library'
import colors from '../../helpers/colors'
import { ToggleColorMode } from '../../helpers/ToggleColorModeButton'
import analytics from '../../platform/helpers/amplitude'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'
import { Submenu } from './Submenu'
import { ToggleButton } from './ToggleButton'
import { links } from './_data'
const MobileNavContext = (props: FlexProps) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      <Flex align="center" justify="space-between" className="nav-content__mobile" {...props} >
        <Box flexBasis="6rem">
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
        <Box as="a" rel="home" mx="auto" href="../">
          <Logo h="24px" iconColor="blue.400" />
        </Box>

        <Box visibility={{ base: 'hidden', sm: 'visible' }}>
          <SecondaryButton bg="transparent" as="a" href="../app/login?login=true"
            onClick={() => {
              analytics.logEvent('Homepage.NavBar.Desktop.Clicked');

            }}>
            Sign in
          </SecondaryButton>
        </Box>

        {/* <Box visibility={{ base: 'hidden', sm: 'visible' }}>
          <PrimaryButton as="a" href="../app/login"
            onClick={() => {
              analytics.logEvent('V2.Homepage.User_clicks_top_right_cta');
            }} >
            Try free trial
          </PrimaryButton>
        </Box> */}
      </Flex>
      <NavMenu animate={isOpen ? 'open' : 'closed'} bg={colors.marketing.background.marketing[colorMode]} >
        {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          ),
        )}


      </NavMenu>
    </>
  )
}

const DesktopNavContent = (props: any) => {


  const { colorMode, toggleColorMode } = useColorMode();

  let alreadyLoggedIn = false;
  if (localStorage.access_token) {
    alreadyLoggedIn = true;
  }


  return (
    <Flex className="nav-content__desktop" align="center" justify="space-between" {...props} >
      <Box as="a" href="../" rel="home" flex={2} >
        <VisuallyHidden>Options Tracker</VisuallyHidden>
        <Logo h="6" />
      </Box>
      <HStack id="nav__primary-menu" aria-label="Main Menu" listStyleType="none" flex={8} justifyContent='center'>
        {links.map((link, idx) => (
          <Box key={idx} id={`nav__menuitem-${idx}`}>
            {link.children ? (
              <Submenu.Desktop link={link} />
            ) : (
              <NavLink.Desktop href={link.href} color={colors.marketing.text.primary[colorMode]}>{link.label}</NavLink.Desktop>
            )}
          </Box>
        ))}
      </HStack>

      <HStack spacing="1" minW="150px" justify="flex-end" flex={2} >
        <ToggleColorMode home={true} short={true} />

        {alreadyLoggedIn === false &&
          <SecondaryButton bg="transparent" as="a" href="../app/login?login=true"
            id="signinbutton"
            onClick={() => {
              analytics.logEvent('Homepage.NavBar.Desktop.Clicked');

            }}>
            Sign in
          </SecondaryButton>
        }

        {alreadyLoggedIn === true &&
          <PrimaryButton as="a" href="../app/login"
            isLoading={props.loading}
            onClick={() => {
              analytics.logEvent('Homepage.NavBar.Desktop.Clicked');

            }}>
            {alreadyLoggedIn === true ? 'Dashboard' : `Try ${props.days ? props.days + " day" : ''} free trial`}
          </PrimaryButton>
        }
      </HStack>
    </Flex>
  )
}

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
}
