import React, { useState } from 'react';
import { Box, Tag, Button, useColorMode } from '@chakra-ui/react'
import { ChevronDown, ChevronUp, CustomModalBasic, DropDown, OutlineButton, SecondaryButton } from '../../../design_library';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import styles from './TagView.module.css';
import Select, { components, createFilter } from 'react-select'
import apiCalls from '../../helpers/apiCalls';
import stringToColour from '../../helpers/color';
import './Style.css';

const TagView = (props) => {


    const { colorMode, toggleColorMode } = useColorMode();


    let options = props.allTags !== undefined ? Array.from(props.allTags) : [];


    let newArray = {};

    options.filter((option) => {
        if (newArray[option.value]) {
        }
        else {
            newArray[option.value] = { label: option.label, value: option.value }
        }
    })


    let newArray2 = [];

    Object.keys(newArray).filter((item) => {
        newArray2.push(newArray[item]);
    })


    options = newArray2;

    const NoCross = ({ innerProps, isDisabled }) =>
        !isDisabled ? (
            <div {...innerProps}></div>
        ) : null;

    let customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--dark-gray)',
            color: 'var(--white)',
            border: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--dark-gray)',
            border: 'none',
            color: 'var(--white)',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--dark-gray)',
            border: 'none',
            color: 'var(--white)',
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--dark-gray)',
            border: 'none',
            color: 'var(--white)',
            marginRight: '5px',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--dark-gray)',
            border: 'none',
            color: 'var(--white)',
        }),
        multiValue: (provided, state) => {
            return ({
                ...provided,
                backgroundColor: stringToColour(state.data.label),
                color: "var(--white)",
            })
        },
        multiValueLabel: (provided, state) => {
            return ({
                ...provided,
                color: "white",
            })
        },
        input: (provided, state) => {
            return ({
                ...provided,
                color: "var(--white)",
            })
        },

    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
                </components.DropdownIndicator>
            )
        );
    };

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: true ? ('start') : ('any'),
    };


    return (
        <Box minW={'200px'}>
            <CreatableSelect
                menuPosition={'fixed'}
                styles={customStyles}
                style={`${styles.dropdown} ${props.newTable ? styles.newTagDropdown : ''}`}

                defaultValue={props.trade !== undefined ? props.trade.tags : []}
                components={{ DropdownIndicator, IndicatorSeparator: () => null, CrossIcon: NoCross, }}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti
                clearValue={false}
                options={options}
                placeholder="Type to add or search"
                createOptionPosition="first"
                openMenuOnFocus={false}
                openMenuOnClick={false}
                isSearchable
                filterOption={createFilter(filterConfig)}

                noOptionsMessage={({ inputValue }) => !inputValue ? 'Search or create a tag' : "No results found"}
                onKeyDown={(key) => {
                    if (key.key === 'Enter') {

                    }
                }}
                onChange={(valueType, actionTypes) => {

                    if (props.trade !== undefined) {

                        if (actionTypes.action === 'create-option' || actionTypes.action === 'remove-value' || actionTypes.action === 'select-option') {
                            let newTrade = props.trade;
                            newTrade.tags = valueType;
                            apiCalls.updateTrade('', newTrade).then((res) => {
                                if (res.success === false) {
                                    if (res.message) {
                                        alert(res.message);
                                    }
                                    else {
                                        alert(res.msg);
                                    }
                                }

                            })
                        }
                    }

                    else {
                        props.recordTagValue(valueType)
                    }

                }}

            />
        </Box>

    )

}


export default TagView;


//Trades search
//Widget, tag performance
// 