import React, { Component } from 'react'
import styles from './NiceInput.module.css';

export class NiceInput extends Component {
    render() {
        return (
            <input {...this.props} className={styles.customInput} />
        )
    }
}

export default NiceInput
