import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { HStack, IconButton, Image, InputRightElement, StackDivider } from "@chakra-ui/react";
import {
    Textarea, Modal, Tooltip, Text, Td, Th, Tbody, Tr, Thead,
    Table, Button, useDisclosure, Tag, Icon, TagLabel, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Divider, SimpleGrid, Stack, Heading, useColorModeValue as mode, Center, StatGroup, Grid, FormControl, FormLabel, InputGroup, Input, Select, FormHelperText, Spinner, Tabs, TabList, Tab, TabPanels, TabPanel, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
} from "@chakra-ui/react";
import API from "../../helpers/apiCalls";
import { APIEndpoint, myUrl } from "../../helpers/dataStructures";
import { CustomIconButton, CustomModal, CustomToolTip, NegativeButton, PrimaryButton, SecondaryButton, ShareIcon, Title } from "../../../design_library";
import ContextHighlightLink from "./ContextMenu/ContextHighlightLink";
import amplitude from "../../helpers/amplitude";



class Share extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false,
            loading: false,
            posted: false,
            link: '',
            shared: false,
            copied: false,
            linkAvailable: false,
        }
    }

    toggleModal = (tog) => {
        this.setState({ isOpen: tog });
        this.setState({ loading: false });
    }

    componentDidMount() {

        if (this.props.data.original.shared === true) {
            this.setState({ shared: true, link: myUrl + '/share/' + this.props.data.original._id });
        }
        else {
            this.setState({ shared: false });
        }

    }




    updateTrade = (post, instantRedirect) => {
        this.setState({ loading: true });
        let payload = this.props.data.original;
        payload.shared = post;
        payload.skipRecalculation = true;

        API.updateTrade(APIEndpoint, payload).then((res) => {

            if (res.success === false) {
                alert("Something went wrong. Please try again later.");
            }
            else {
                this.setState({ shared: res.trade.shared, link: myUrl + '/share/' + res.trade._id });

                if (instantRedirect === true) {
                    amplitude.logEvent('V2.TradeClosed.Toast.2.User_clicked_prompt_to_share')

                    // window.open(myUrl + '/share/' + res.trade._id, '_blank');
                    navigator.clipboard.writeText(myUrl + '/share/' + res.trade._id)
                    this.setState({ copied: true, linkAvailable: true, });
                    window.open(this.state.link, '_blank');

                    setTimeout(() => {
                        this.setState({ copied: false })
                    }, 1000);
                }
            }

            this.setState({ loading: false });

        })
    }





    visitLink = () => {
        window.open(this.state.link, '_blank');

    }

    render() {
        if (this.props.direct === true) {
            return (
                <HStack>
                    <PrimaryButton mb="2" mt="2" width="100%" onClick={() => {
                        this.updateTrade(true, true)
                    }} isLoading={this.state.loading}>
                        {this.state.copied === true && 'Copied!'}
                        {this.state.copied === false && 'Copy and open link'}
                    </PrimaryButton>
                    {/* <PrimaryButton mb="2" mt="2" width="100%" onClick={() => {
                        this.visitLink();
                    }} isLoading={this.state.loading}
                        disabled={this.state.link.length === 0}
                    >
                        Visit link
                    </PrimaryButton> */}
                </HStack>
            )
        }
        if (this.props.data.canExpand !== true) {
            return (
                <>

                    {this.props.noIcon === true &&
                        <ContextHighlightLink text='Share' onClick={() => this.setState({ isOpen: true })} />
                    }

                    {this.props.noIcon !== true &&

                        <CustomToolTip label={this.props.data.original.views + ' views'} >
                            <CustomIconButton
                                maxW="50px"
                                overrideIconColor={true}
                                onClick={() => this.toggleModal(true)}
                                activated={this.state.shared}
                                icon={<ShareIcon color="var(--light-gray)" stroke="var(--light-gray)" strokeWidth={'1.5px'} fill="var(--light-gray)" />}
                            />
                        </CustomToolTip>
                    }
                    {this.state.isOpen === true && <ModalComponent views={this.props.data.original.views} shared={this.state.shared} generateLink={this.updateTrade} profile={this.props.settings.profile} link={this.state.link} posted={this.state.posted} tradeData={this.props.data.original} shareMessage={this.shareMessage} modalStatus={this.state.isOpen} toggle={(e) => this.toggleModal(e)} loading={this.state.loading} />}
                </>
            )
        }
        else {
            return '';
        }
    }
}

function ModalComponent(props) {
    return (
        <CustomModal size="md" isOpen={props.modalStatus} onClose={props.toggle}

            header={
                <HStack>
                    <Title>Share</Title>
                    {props.shared === true &&
                        <NegativeButton varient="outline" size="xs" onClick={() => {
                            props.generateLink(props.shared === true ? false : true)
                        }} isLoading={props.loading}>
                            Unshare
                        </NegativeButton>
                    }
                </HStack>
            }
            body={
                <Box>
                    {props.shared === false &&
                        <Text fontSize={'md'} mb="4">Sharing will create a public link that will allow other people to see your trade. This can be shared with anyone. After you have shared your trade you can also unshare it.</Text>
                    }

                    {props.shared === true &&
                        <InputGroup size='md' >
                            <Input
                                pr='4.5rem'
                                type="text"
                                value={props.link}
                            />
                            {props.shared === true &&
                                < InputRightElement width='4.5rem'>
                                    <SecondaryButton h='1.75rem' size='sm' onClick={() => {
                                        navigator.clipboard.writeText(props.link)
                                    }} isLoading={props.loading}>
                                        Copy
                                    </SecondaryButton>
                                </InputRightElement>
                            }
                        </InputGroup>
                    }

                    {props.shared === true ?
                        <>
                            <HStack textAlign={'center'} mt="4" mb="4" justifyContent={'center'}>
                                <Text fontWeight={'500'} fontSize={'lg'} mr="2">Views: </Text>
                                <Text fontWeight={'600'} fontSize={'xl'} >{props.views ? props.views : 0} </Text>
                            </HStack>

                            <PrimaryButton mb="2" width="100%" onClick={() => {
                                window.open(props.link, '_blank');
                            }} isLoading={props.loading}>
                                Visit link
                            </PrimaryButton>
                        </>
                        :
                        <PrimaryButton mb="2" width="100%" onClick={() => {
                            props.generateLink(props.shared === true ? false : true)
                        }} isLoading={props.loading}>
                            Share
                        </PrimaryButton>
                    }
                </Box>
            }
        />
    )
}




const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(Share);
