import { uuid } from "uuidv4";
import moment from "moment";
var currencyFormatter = require("currency-formatter");

export const calculateAverageDaysUntilExpiry = (trades) => {
  function daysUntilExpiry(expirationDateStr) {
    const currentDate = moment();
    const expirationDate = moment(expirationDateStr);

    // Calculate the difference in days
    return expirationDate.diff(currentDate, "days");
  }

  let totalDays = 0;

  trades.forEach((trade) => {
    try {
      if (trade.positionStatus === "OPEN" && trade.tradeType !== "STOCK") {
        totalDays += daysUntilExpiry(trade.expiryDate);
      }
    } catch (e) {
      console.log(e);
    }
  });

  return (totalDays / trades.length).toFixed(2);
};

export const calculateAverageGreeks = (rows) => {
  let greeks = {
    delta: 0,
    rho: 0,
    vega: 0,
    theta: 0,
    totalTrades: 0,
    gamma: 0,
    calculated: false,
  };

  rows.filter((value, index) => {
    if (
      value.tradeType !== "STOCK" &&
      value.optionData &&
      value.positionStatus === "OPEN"
    ) {
      if (value.optionData.delta) {
        greeks.totalTrades++;
        greeks.delta += parseFloat(value.optionData.delta);
        greeks.rho += parseFloat(value.optionData.rho);
        greeks.vega += parseFloat(value.optionData.vega);
        greeks.theta += parseFloat(value.optionData.theta);
        greeks.gamma += parseFloat(value.optionData.gamma);
      }
    }
  });

  if (greeks.totalTrades > 0) {
    greeks.delta = greeks.delta / greeks.totalTrades;
    greeks.rho = greeks.rho / greeks.totalTrades;
    greeks.vega = greeks.vega / greeks.totalTrades;
    greeks.theta = greeks.theta / greeks.totalTrades;
    greeks.gamma = greeks.gamma / greeks.totalTrades;
    greeks.calculated = true;
  }

  return greeks;
};

export const calculateCash = (rows, accountId) => {
  let cashPositions = [];
  rows.filter((value, index) => {
    if (value.tradeType === "CASH" && accountId === value.account) {
      cashPositions.push(value);
    }
  });

  let cashValue = 0;
  cashPositions.filter((value, index) => {
    if (value.action === "DEPOSIT") {
      cashValue += parseFloat(value.cashAmount);
    } else if (value.action === "WITHDRAW") {
      cashValue -= parseFloat(value.cashAmount);
    }
  }, 0);

  return cashValue;
};

export const calculateCashOnDate = (rows, unixDate, accountId) => {
  let cashPositions = [];

  // Filter rows by trade type 'CASH' and up to the given Unix date
  rows.filter((value, index) => {
    if (
      value.tradeType === "CASH" &&
      moment(value.dateClosed).unix() <= unixDate &&
      accountId === value.account
    ) {
      cashPositions.push(value);
    }
  });

  let cashValue = 0;

  // Calculate the cash value based on 'DEPOSIT' and 'WITHDRAW' actions
  cashPositions.forEach((value, index) => {
    if (value.action === "DEPOSIT") {
      cashValue += parseFloat(value.cashAmount);
    } else if (value.action === "WITHDRAW") {
      cashValue -= parseFloat(value.cashAmount);
    }
  });

  return cashValue;
};

export const calculateProfitAndLoss = (rows) => {
  let unrealisedProfitAndLoss = 0;
  let totalRisk = 0;
  let realisedProfitAndLoss = 0;
  let commission = 0;
  let wins = 0;
  let losses = 0;
  let total = 0;

  let totalPotentialPremiumOnOpenTrades = 0;
  let capturedPremium = 0;
  let totalCost = 0;
  let maxPremium = 0;

  rows.filter((x) => {
    try {
      if (x.original) {
        x = x.original;
      }

      x.premium = parseFloat(x.premium);
      x.contracts = parseFloat(x.contracts);
      x.spotPriceAtTradeTime = parseFloat(x.spotPriceAtTradeTime);
      x.quantity = parseFloat(x.quantity);
      x.realisedProfitAndLoss = parseFloat(x.realisedProfitAndLoss);
      x.commission = parseFloat(x.commission);
      x.liveStockPrice = parseFloat(x.liveStockPrice);
      x.buyBackPricePerContract = parseFloat(x.buyBackPricePerContract);

      if (isNaN(x.spotPriceAtTradeTime)) x.spotPriceAtTradeTime = 0;
      if (isNaN(x.quantity)) x.quantity = 0;
      if (isNaN(x.realisedProfitAndLoss)) x.realisedProfitAndLoss = 0;
      if (isNaN(x.commission)) x.commission = 0;
      if (isNaN(x.liveStockPrice)) x.liveStockPrice = 0;
      if (isNaN(x.buyBackPricePerContract)) x.buyBackPricePerContract = 0;

      if (x.tradeType !== "CASH") {
        if (
          x.positionStatus === "OPEN" &&
          x.tradeType === "STOCK" &&
          x.action !== "SHORT" &&
          x.isDividendFlag !== true
        ) {
          if (!isNaN(x.liveStockPrice)) {
            let originalPurchase = x.quantity * x.spotPriceAtTradeTime;
            let newValue = x.quantity * x.liveStockPrice;
            let difference;
            if (originalPurchase < newValue) {
              difference = Math.abs(newValue - originalPurchase);
            } else {
              difference = -Math.abs(originalPurchase - newValue);
            }
            unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference;

            // unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference - x.commission;
            totalRisk += originalPurchase;
          }
          totalCost = totalCost + x.spotPriceAtTradeTime * x.quantity;
        } else if (
          x.positionStatus === "OPEN" &&
          x.tradeType === "STOCK" &&
          x.action === "SHORT" &&
          x.isDividendFlag !== true
        ) {
          if (!isNaN(x.liveStockPrice)) {
            let originalPurchase = x.quantity * x.spotPriceAtTradeTime;
            let newValue = x.quantity * x.liveStockPrice;
            let difference;
            if (originalPurchase > newValue) {
              difference = Math.abs(newValue - originalPurchase);
            } else {
              difference = -Math.abs(originalPurchase - newValue);
            }
            unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference;

            // unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference - x.commission;
            totalRisk += originalPurchase;
          }

          totalCost = totalCost + x.spotPriceAtTradeTime * x.quantity;
        } else if (
          x.positionStatus === "OPEN" &&
          x.tradeType === "STOCK" &&
          x.isDividendFlag === true
        ) {
          if (!isNaN(x.liveStockPrice)) {
            let originalPurchase = x.quantity * x.spotPriceAtTradeTime;
            let newValue = x.quantity * x.liveStockPrice;
            let difference;
            if (originalPurchase > newValue) {
              difference = -Math.abs(newValue - originalPurchase);
            } else {
              difference = Math.abs(originalPurchase - newValue);
            }
            unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference;

            // unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference - x.commission;
            totalRisk += originalPurchase;
          }

          totalCost = totalCost + x.spotPriceAtTradeTime * x.quantity;
        } else if (x.positionStatus === "OPEN" && x.tradeType !== "STOCK") {
          try {
            let originalPurchase = x.contracts * x.premium * 100;
            let newValue;

            if (x.optionData && x.optionData.lastPrice) {
              newValue =
                parseFloat(x.optionData.lastPrice) *
                parseFloat(x.contracts) *
                100;
            } else if (x.optionData && x.optionData.ask) {
              newValue =
                ((parseFloat(x.optionData.ask) + parseFloat(x.optionData.bid)) /
                  2) *
                parseFloat(x.contracts) *
                100;
            } else {
              newValue = 0;
            }

            if (x.optionData) {
              totalRisk += x.contracts * x.premium * 100;

              let difference;
              if (x.action === "SELL_TO_OPEN") {
                maxPremium =
                  parseFloat(maxPremium) + x.premium * 100 * x.contracts;

                totalPotentialPremiumOnOpenTrades =
                  totalPotentialPremiumOnOpenTrades +
                  parseFloat(x.premium * 100) * parseFloat(x.contracts);

                if (originalPurchase < newValue) {
                  difference = -Math.abs(newValue - originalPurchase);
                } else {
                  difference = Math.abs(originalPurchase - newValue);
                }
              } else if (x.action === "BUY_TO_OPEN") {
                if (originalPurchase < newValue) {
                  difference = Math.abs(newValue - originalPurchase);
                } else {
                  difference = -Math.abs(originalPurchase - newValue);
                }
              }

              unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference;

              totalCost = totalCost + x.premium * x.contracts * 100;

              // unrealisedProfitAndLoss = unrealisedProfitAndLoss + difference - x.commission;
            } else {
              if (x.action === "SELL_TO_OPEN") {
                totalPotentialPremiumOnOpenTrades =
                  totalPotentialPremiumOnOpenTrades +
                  parseFloat(x.premium * 100) * parseFloat(x.contracts);
              }

              // unrealisedProfitAndLoss = unrealisedProfitAndLoss + (parseFloat(x.premium * 100) * parseFloat(x.contracts));
            }
          } catch (e) {
            console.log("Error retreiving option data...", e);
          }
        } else if (
          !isNaN(x.realisedProfitAndLoss) &&
          x.positionStatus === "CLOSED"
        ) {
          if (x.tradeType !== "STOCK") {
            realisedProfitAndLoss =
              realisedProfitAndLoss + x.realisedProfitAndLoss * 100;
            totalRisk += x.contracts * x.premium * 100;

            if (x.action === "SELL_TO_OPEN") {
              capturedPremium =
                parseFloat(capturedPremium) + x.realisedProfitAndLoss * 100;
              maxPremium =
                parseFloat(maxPremium) + x.premium * 100 * x.contracts;
            }
          } else {
            realisedProfitAndLoss =
              realisedProfitAndLoss + x.realisedProfitAndLoss;
            totalRisk += x.quantity * x.spotPriceAtTradeTime;
          }

          if (parseFloat(x.realisedProfitAndLoss) >= 0) {
            wins++;
          }

          if (parseFloat(x.realisedProfitAndLoss) < 0) {
            losses++;
          }

          total++;
        } else if (x.tradeType !== "STOCK" && x.positionStatus === "CLOSED") {
          // Missing realised P&L for some reason...

          let premium = x.premium;
          let buyBackPricePerContract = parseFloat(x.buyBackPricePerContract);
          let contracts = parseFloat(x.contracts);

          if (x.action === "SELL_TO_OPEN") {
            x.realisedProfitAndLoss =
              (premium - buyBackPricePerContract) * contracts;
          } else if (x.action === "BUY_TO_OPEN") {
            x.realisedProfitAndLoss =
              (buyBackPricePerContract - premium) * contracts;
          }

          if (parseFloat(x.realisedProfitAndLoss) >= 0) {
            wins++;
          }

          if (parseFloat(x.realisedProfitAndLoss) < 0) {
            losses++;
          }

          realisedProfitAndLoss =
            parseFloat(realisedProfitAndLoss) +
            parseFloat(x.realisedProfitAndLoss);
        }

        if (
          !isNaN(x.commission) &&
          x.commission !== 0 &&
          x.commission !== "" &&
          x.commission !== null
        ) {
          commission += Math.abs(parseFloat(x.commission));
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  let winRateAsPercentage = (wins / total) * 100;

  if (isNaN(winRateAsPercentage)) {
    winRateAsPercentage = 0;
  }

  commission = -(commission * -1);

  return {
    winRateAsPercentage,
    wins,
    losses,
    commission: -commission,
    unrealised: unrealisedProfitAndLoss,
    realised: realisedProfitAndLoss,
    total: unrealisedProfitAndLoss + realisedProfitAndLoss,
    totalIncComm: unrealisedProfitAndLoss + realisedProfitAndLoss - commission,
    totalRisk,
    percentageTotal:
      ((unrealisedProfitAndLoss + realisedProfitAndLoss) / totalRisk) * 100,
    outstandingPremiumOnOpenTrades: totalPotentialPremiumOnOpenTrades,
    capturedPremium,
    maxPremium,
  };
};

export const calculateCostBasis = (rows) => {
  let total = 0;

  rows.filter((y, index) => {
    if (y === undefined) {
      return false;
    }
    if (y.original) {
      y = y.original;
    }

    if (y.tradeType !== "STOCK") {
      if (y.action === "SELL_TO_OPEN") {
        //Credit

        if (y.positionStatus === "CLOSED") {
          total += parseFloat(y.realisedProfitAndLoss) * 100;
        } else {
          total += parseFloat(y.contracts * y.premium) * 100;
        }
      } else {
        //Debit
        if (y.positionStatus === "CLOSED") {
          total += parseFloat(y.realisedProfitAndLoss) * 100;
        } else {
          total -= parseFloat(y.contracts * y.premium) * 100;
        }
      }
    } else {
      if (y.positionStatus === "CLOSED") {
        total += parseFloat(y.realisedProfitAndLoss);
      } else {
        total -= parseFloat(y.quantity) * parseFloat(y.spotPriceAtTradeTime);
      }
    }
  });

  return total;
};

export const calculateCredits = (rows) => {
  let total = 0;

  rows.filter((y, index) => {
    if (y.original) {
      y = y.original;
    }

    //Profit
    //STO
    //Short

    if (y.tradeType !== "STOCK" && y.action === "SELL_TO_OPEN") {
      let initialAmountReceived = y.contracts * y.premium;
      total += initialAmountReceived * 100;
    } else if (
      y.tradeType !== "STOCK" &&
      y.action === "BUY_TO_OPEN" &&
      y.positionStatus === "CLOSED"
    ) {
      total += (y.contracts * y.premium + y.realisedProfitAndLoss) * 100;
    } else if (
      y.tradeType === "STOCK" &&
      y.action === "BUY" &&
      y.positionStatus === "CLOSED"
    ) {
      total += y.quantity * y.spotPriceAtTradeTime + y.realisedProfitAndLoss;
    }
  });

  return total;
};

export const calculateCommission = (rows) => {
  let total = calculateProfitAndLoss(rows);
  return total.commission;

  // rows.filter((y, index) => {
  //     if (y.original) {
  //         y = y.original;
  //     }
  //     total += y.commission;

  // });

  // return -total;
};

export const calculateDebits = (rows) => {
  let total = 0;
  rows.filter((y, index) => {
    if (y.original) {
      y = y.original;
    }

    if (
      y.tradeType !== "STOCK" &&
      y.positionStatus === "CLOSED" &&
      y.action === "SELL_TO_OPEN"
    ) {
      total -= (y.realisedProfitAndLoss - y.contracts * y.premium) * 100;
    } else if (y.tradeType !== "STOCK" && y.action === "BUY_TO_OPEN") {
      total += y.contracts * y.premium * 100;
    } else if (y.tradeType === "STOCK" && y.action === "BUY") {
      total += y.quantity * y.spotPriceAtTradeTime;
    }
  });

  return total;
};

export const calculateCumulativeCostBasis = (rows) => {
  let numberOfSharesOpen = 0;
  let premiumsReceived = 0;
  let hasStock = false;

  rows.filter((value, index) => {
    if (value.subRows && value.subRows.length > 0) {
      value.subRows.filter((value, index) => {
        let trade = value.original;
        if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
          numberOfSharesOpen += trade.quantity;
          hasStock = true;
        }
      });
    } else {
      let trade = value.original;
      if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
        numberOfSharesOpen += trade.quantity;
        hasStock = true;
      }
    }
  });

  rows.filter((value, index) => {
    value.subRows.filter((value, index) => {
      let trade = value.original;
      if (
        trade.realisedProfitAndLoss &&
        trade.tradeType !== "STOCK" &&
        trade.positionStatus === "CLOSED"
      ) {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss) * 1000;
      } else if (
        trade.realisedProfitAndLoss &&
        trade.tradeType === "STOCK" &&
        trade.positionStatus === "CLOSED"
      ) {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
      }
    });
  });

  return { result: premiumsReceived / numberOfSharesOpen, hasStock: hasStock };
};

const usd = (value) => {
  return currencyFormatter.format(value, { code: "USD" });
};

export const calaculateBreakevenTableVersion = (rows) => {
  let stockTicker = "";
  let totalValueOfPositions = 0;
  let hasStock = false;
  let hasEverOwnedStock = false;
  let totalSharesHeld = 0;

  let totalSharesEverHeld = 0;
  let totalValueOfSharesHeld = 0;

  rows.filter((value, index) => {
    if (value.subRows && value.subRows.length > 0) {
      value.subRows.filter((value, index) => {
        let trade;
        if (value.original) {
          trade = value.original;
        } else {
          trade = value;
        }
        stockTicker = trade.stockTicker;
        if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
          totalValueOfPositions += trade.quantity * trade.spotPriceAtTradeTime;
          totalSharesHeld += trade.quantity;
          hasStock = true;
          hasEverOwnedStock = true;

          totalSharesEverHeld += trade.quantity;
          totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
        } else if (
          trade.tradeType === "STOCK" &&
          trade.positionStatus === "CLOSED"
        ) {
          hasEverOwnedStock = true;

          totalSharesEverHeld += trade.quantity;
          totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
        }
      });
    } else {
      let trade;
      if (value.original) {
        trade = value.original;
      } else {
        trade = value;
      }
      stockTicker = trade.stockTicker;
      if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
        totalValueOfPositions += trade.quantity * trade.spotPriceAtTradeTime;
        totalSharesHeld += trade.quantity;
        hasStock = true;
        hasEverOwnedStock = true;

        totalSharesEverHeld += trade.quantity;
        totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
      } else if (
        trade.tradeType === "STOCK" &&
        trade.positionStatus === "CLOSED"
      ) {
        hasEverOwnedStock = true;

        totalSharesEverHeld += trade.quantity;
        totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
      }
    }
  });

  let averagePrice = 0;
  let averagePriceUsed = false;

  if (hasEverOwnedStock === true && hasStock === false) {
    averagePrice = totalValueOfSharesHeld / totalSharesEverHeld;
    averagePriceUsed = true;
  }

  let premiumsReceived = 0;
  let commission = 0;

  rows.filter((value, index) => {
    if (value.subRows && value.subRows.length > 0) {
      value.subRows.filter((value, index) => {
        let trade;
        if (value.original) {
          trade = value.original;
        } else {
          trade = value;
        }
        if (trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
          premiumsReceived += parseFloat(trade.realisedProfitAndLoss) * 100;
        }
        if (!trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
          let pandl = calculateProfitAndLoss([trade]);
          premiumsReceived += parseFloat(pandl.total);
        } else if (trade.realisedProfitAndLoss && trade.tradeType === "STOCK") {
          premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
        }

        // else if (!trade.realisedProfitAndLoss && trade.tradeType === 'STOCK') {
        //     premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
        // }

        if (
          !isNaN(trade.commission) &&
          trade.commission !== 0 &&
          trade.commission !== "" &&
          trade.commission !== null
        ) {
          commission += parseFloat(trade.commission);
        }
      });
    } else {
      let trade;
      if (value.original) {
        trade = value.original;
      } else {
        trade = value;
      }
      if (trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss) * 100;
      }
      if (!trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
        let pandl = calculateProfitAndLoss([trade]);
        premiumsReceived += parseFloat(pandl.total);
      } else if (trade.realisedProfitAndLoss && trade.tradeType === "STOCK") {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
      }

      if (
        !isNaN(trade.commission) &&
        trade.commission !== 0 &&
        trade.commission !== "" &&
        trade.commission !== null
      ) {
        commission += parseFloat(trade.commission);
      } else {
      }
    }
  });

  let newRows = [];
  rows.filter((value, index) => {
    let trade;
    if (value.original) {
      trade = value.original;
    } else {
      trade = value;
    }
    try {
      if (value.subRows && value.subRows.length > 0) {
        value.subRows.filter((value, index) => {
          if (value.subRows && value.subRows.length > 0) {
            value.subRows.filter((value, index) => {
              if (
                value.original.tradeType !== "STOCK" &&
                value.original.positionStatus !== "OPEN"
              ) {
                newRows.push(value);
              }
            });
          } else {
            if (
              value.original.tradeType !== "STOCK" &&
              value.original.positionStatus !== "OPEN"
            ) {
              newRows.push(value);
            }
          }
        });
      } else {
        if (trade.tradeType !== "STOCK" && trade.positionStatus !== "OPEN") {
          newRows.push(trade);
        } else {
          // console.log(value);
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  let pandl = calculateProfitAndLoss(newRows);
  premiumsReceived = parseFloat(pandl.total);

  // let dividendsReceivedAndOpen = 0;
  // rows.filter((value, index) => {
  //     if (value.original.tradeType === 'STOCK' && value.original.positionStatus === 'OPEN' && value.original.isDividendFlag === true) {
  //         if (value.original.liveStockPrice) {
  //             dividendsReceivedAndOpen += parseFloat(value.original.quantity) * parseFloat(value.original.liveStockPrice);
  //         }
  //         else {
  //             dividendsReceivedAndOpen += parseFloat(value.original.quantity) * parseFloat(value.original.spotPriceAtTradeTime);
  //         }
  //     }
  // })

  // premiumsReceived += dividendsReceivedAndOpen;

  // if (isNaN(commission)) {
  //     commission = 0;
  // }

  let breakeven;

  let explainer = "";
  let explainerFormat = "";

  // let pandl = calculateProfitAndLoss(rows);

  if (averagePriceUsed === true) {
    try {
      breakeven =
        (totalValueOfSharesHeld - premiumsReceived + parseFloat(commission)) /
        totalSharesEverHeld;
      explainerFormat = `Breakeven (${usd(
        breakeven.toFixed(2)
      )}) = ((Value of current stock positions (${usd(
        totalValueOfPositions.toFixed(2)
      )}) - realised profit (${usd(
        premiumsReceived.toFixed(2)
      )})) + commission (${usd(
        commission.toFixed(2)
      )})) / Total # of shares ever owned (${totalSharesEverHeld.toFixed(0)})`;
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      breakeven =
        (totalValueOfPositions - premiumsReceived + parseFloat(commission)) /
        totalSharesHeld;
      explainerFormat = `Breakeven (${usd(
        breakeven.toFixed(2)
      )}) = ((Value of current stock positions (${usd(
        totalValueOfPositions.toFixed(2)
      )}) - realised profit (${usd(
        premiumsReceived.toFixed(2)
      )})) + commission (${usd(
        commission.toFixed(2)
      )})) / Total # of shares currently owned (${totalSharesHeld.toFixed(0)})`;
    } catch (e) {
      console.log(e);
    }
  }

  if (breakeven < 0) {
    breakeven = 0;
  }

  if (Math.abs(breakeven) === Infinity) {
    breakeven = undefined;
  }

  return {
    explainerFormat,
    result: breakeven,
    hasStock: hasStock,
    hasEverOwnedStock,
  };
};

export const calaculateBreakevenTableVersionIncludingStock = (rows) => {
  let stockTicker = "";
  let totalValueOfPositions = 0;
  let hasStock = false;
  let hasEverOwnedStock = false;
  let totalSharesHeld = 0;

  let totalSharesEverHeld = 0;
  let totalValueOfSharesHeld = 0;

  rows.filter((value, index) => {
    if (value.subRows && value.subRows.length > 0) {
      value.subRows.filter((value, index) => {
        let trade;
        if (value.original) {
          trade = value.original;
        } else {
          trade = value;
        }
        stockTicker = trade.stockTicker;
        if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
          totalValueOfPositions += trade.quantity * trade.spotPriceAtTradeTime;
          totalSharesHeld += trade.quantity;
          hasStock = true;
          hasEverOwnedStock = true;

          totalSharesEverHeld += trade.quantity;
          totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
        } else if (
          trade.tradeType === "STOCK" &&
          trade.positionStatus === "CLOSED"
        ) {
          hasEverOwnedStock = true;

          totalSharesEverHeld += trade.quantity;
          totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
        }
      });
    } else {
      let trade;
      if (value.original) {
        trade = value.original;
      } else {
        trade = value;
      }
      stockTicker = trade.stockTicker;
      if (trade.tradeType === "STOCK" && trade.positionStatus === "OPEN") {
        totalValueOfPositions += trade.quantity * trade.spotPriceAtTradeTime;
        totalSharesHeld += trade.quantity;
        hasStock = true;
        hasEverOwnedStock = true;

        totalSharesEverHeld += trade.quantity;
        totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
      } else if (
        trade.tradeType === "STOCK" &&
        trade.positionStatus === "CLOSED"
      ) {
        hasEverOwnedStock = true;

        totalSharesEverHeld += trade.quantity;
        totalValueOfSharesHeld += trade.quantity * trade.spotPriceAtTradeTime;
      }
    }
  });

  let averagePrice = 0;
  let averagePriceUsed = false;

  if (hasEverOwnedStock === true && hasStock === false) {
    averagePrice = totalValueOfSharesHeld / totalSharesEverHeld;
    averagePriceUsed = true;
  }

  let premiumsReceived = 0;
  let commission = 0;

  rows.filter((value, index) => {
    if (value.subRows && value.subRows.length > 0) {
      value.subRows.filter((value, index) => {
        let trade;
        if (value.original) {
          trade = value.original;
        } else {
          trade = value;
        }
        if (trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
          premiumsReceived += parseFloat(trade.realisedProfitAndLoss) * 100;
        }
        if (!trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
          let pandl = calculateProfitAndLoss([trade]);
          premiumsReceived += parseFloat(pandl.total);
        } else if (trade.realisedProfitAndLoss && trade.tradeType === "STOCK") {
          premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
        }

        // else if (!trade.realisedProfitAndLoss && trade.tradeType === 'STOCK') {
        //     premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
        // }

        if (
          !isNaN(trade.commission) &&
          trade.commission !== 0 &&
          trade.commission !== "" &&
          trade.commission !== null
        ) {
          commission += parseFloat(trade.commission);
        }
      });
    } else {
      let trade;
      if (value.original) {
        trade = value.original;
      } else {
        trade = value;
      }
      if (trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss) * 100;
      }
      if (!trade.realisedProfitAndLoss && trade.tradeType !== "STOCK") {
        let pandl = calculateProfitAndLoss([trade]);
        premiumsReceived += parseFloat(pandl.total);
      } else if (trade.realisedProfitAndLoss && trade.tradeType === "STOCK") {
        premiumsReceived += parseFloat(trade.realisedProfitAndLoss);
      }

      if (
        !isNaN(trade.commission) &&
        trade.commission !== 0 &&
        trade.commission !== "" &&
        trade.commission !== null
      ) {
        commission += parseFloat(trade.commission);
      } else {
      }
    }
  });

  // let newRows = [];
  // rows.filter((value, index) => {
  //     let trade;
  //     if (value.original) {
  //         trade = value.original;
  //     }
  //     else {
  //         trade = value;
  //     }
  //     try {

  //         if (value.subRows && value.subRows.length > 0) {
  //             value.subRows.filter((value, index) => {
  //                 if (value.subRows && value.subRows.length > 0) {
  //                     value.subRows.filter((value, index) => {
  //                         if (value.original.tradeType !== 'STOCK' && value.original.positionStatus !== 'OPEN') {
  //                             newRows.push(value);
  //                         }
  //                     })
  //                 }
  //                 else {
  //                     if (value.original.tradeType !== 'STOCK' && value.original.positionStatus !== 'OPEN') {
  //                         newRows.push(value);
  //                     }
  //                 }
  //             })
  //         }
  //         else {
  //             if (trade.tradeType !== 'STOCK' && trade.positionStatus !== 'OPEN') {
  //                 newRows.push(trade);
  //             }
  //             else {
  //                 // console.log(value);
  //             }
  //         }
  //     }
  //     catch (e) {
  //         console.log(e);
  //     }

  // });

  let pandl = calculateProfitAndLoss(rows);
  premiumsReceived = parseFloat(pandl.total);

  // let dividendsReceivedAndOpen = 0;
  // rows.filter((value, index) => {
  //     if (value.original.tradeType === 'STOCK' && value.original.positionStatus === 'OPEN' && value.original.isDividendFlag === true) {
  //         if (value.original.liveStockPrice) {
  //             dividendsReceivedAndOpen += parseFloat(value.original.quantity) * parseFloat(value.original.liveStockPrice);
  //         }
  //         else {
  //             dividendsReceivedAndOpen += parseFloat(value.original.quantity) * parseFloat(value.original.spotPriceAtTradeTime);
  //         }
  //     }
  // })

  // premiumsReceived += dividendsReceivedAndOpen;

  // if (isNaN(commission)) {
  //     commission = 0;
  // }

  let breakeven;

  let explainer = "";
  let explainerFormat = "";

  // let pandl = calculateProfitAndLoss(rows);

  if (averagePriceUsed === true) {
    try {
      breakeven =
        (totalValueOfSharesHeld - premiumsReceived + parseFloat(commission)) /
        totalSharesEverHeld;
      explainerFormat = `Breakeven (${usd(
        breakeven.toFixed(2)
      )}) = ((Value of current stock positions (${usd(
        totalValueOfPositions.toFixed(2)
      )}) - realised profit (${usd(
        premiumsReceived.toFixed(2)
      )})) + commission (${usd(
        commission.toFixed(2)
      )})) / Total # of shares ever owned (${totalSharesEverHeld.toFixed(0)})`;
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      breakeven =
        (totalValueOfPositions - premiumsReceived + parseFloat(commission)) /
        totalSharesHeld;
      explainerFormat = `Breakeven (${usd(
        breakeven.toFixed(2)
      )}) = ((Value of current stock positions (${usd(
        totalValueOfPositions.toFixed(2)
      )}) - realised profit (${usd(
        premiumsReceived.toFixed(2)
      )})) + commission (${usd(
        commission.toFixed(2)
      )})) / Total # of shares currently owned (${totalSharesHeld.toFixed(0)})`;
    } catch (e) {
      console.log(e);
    }
  }

  if (breakeven < 0) {
    breakeven = 0;
  }

  if (Math.abs(breakeven) === Infinity) {
    breakeven = undefined;
  }

  return {
    explainerFormat,
    result: breakeven,
    hasStock: hasStock,
    hasEverOwnedStock,
  };
};

export const calculateAnnualisedReturnAtOpen = (rows) => {
  let openReturn = 0;
  let openTotalTrades = 0;
  rows.filter((value) => {
    if (value.action === "SELL_TO_OPEN" && value.positionStatus === "OPEN") {
      if (value.AnnualisedReturn !== "Infinity") {
        openReturn += parseFloat(value.AnnualisedReturn);
        openTotalTrades++;
      }
    }
  });

  return (openReturn / openTotalTrades) * 100;
};

export const calculateAnnualisedReturnAtClose = (rows) => {
  let closedValue = 0;
  let closedTotalTrades = 0;

  const isValidNumber = (num) =>
    !isNaN(num) && num !== Infinity && num !== -Infinity;

  rows.forEach((tradeData) => {
    if (tradeData.positionStatus === "OPEN") return;

    closedTotalTrades++;
    let closedAnnualisedReturn = 0;
    const a = moment(tradeData.dateOpened);
    const b = moment(tradeData.dateClosed);
    let totalRisk, percentageProfit;

    if (tradeData.tradeType === "PUT" || tradeData.tradeType === "CALL") {
      totalRisk =
        parseFloat(tradeData.contracts) *
        parseFloat(tradeData.strikePrice) *
        100;
    } else {
      totalRisk =
        parseFloat(tradeData.quantity) *
        parseFloat(tradeData.spotPriceAtTradeTime) *
        100;
    }

    percentageProfit =
      (parseFloat(tradeData.realisedProfitAndLoss) / totalRisk) * 100;
    closedAnnualisedReturn = (percentageProfit / -a.diff(b, "days")) * 365;

    if (isValidNumber(closedAnnualisedReturn)) {
      closedValue += closedAnnualisedReturn;
    }
  });

  const value = (closedValue / closedTotalTrades) * 100;
  return isValidNumber(value) ? value : 0;
};

// export const calaculateBreakevenIndependentVersion = (rows) => {

//     let liveStockPrice = 0;
//     let initialPrice = 0;
//     let premiumsReceived = 0;
//     let hasStock = false;

//     rows.filter((value, index) => {
//         let trade = value;
//         if (trade.tradeType === 'STOCK') {
//             initialPrice = trade.spotPriceAtTradeTime;
//             hasStock = true;
//         }
//     })

//     rows.filter((value, index) => {
//         let trade = value;
//         if (trade.liveStockPrice && trade.positionStatus === 'OPEN') {
//             liveStockPrice = trade.liveStockPrice;
//         }
//         if (trade.realisedProfitAndLoss && trade.tradeType !== 'STOCK') {
//             premiumsReceived += parseFloat(trade.realisedProfitAndLoss / trade.contracts);
//         }
//     });

//     let finalValue = initialPrice - premiumsReceived;
//     return { result: finalValue, hasStock: hasStock };
// }

export const calculateTotalRisk = (rows) => {
  let tradesArray = [];
  let tradesRiskDetail = [];

  //Prepare trade data (only open trades)

  let newArray = [...rows];

  newArray.filter((value) => {
    if (!value) {
      return;
    } else if (value.positionStatus === "OPEN") {
      if (value.tradeType === "STOCK" && value.quantity > 0) {
        tradesArray.push({
          id: uuid(),
          quantity: value.quantity,
          contracts: value.contracts,
          stockTicker: value.stockTicker,
          strikePrice: value.strikePrice,
          expiryDate: value.expiryDate,
          spotPriceAtTradeTime: value.spotPriceAtTradeTime,
          tradeType: value.tradeType,
          action: value.action,
          premium: value.premium,
          isCovering: false,
          isCovered: false,
          coveredById: null,
          notes: "",
          negatesRiskOfTradeId: undefined,
        });
      } else if (value.tradeType !== "STOCK" && value.contracts > 0) {
        tradesArray.push({
          id: uuid(),
          quantity: value.quantity,
          contracts: value.contracts,
          stockTicker: value.stockTicker,
          strikePrice: value.strikePrice,
          expiryDate: value.expiryDate,
          spotPriceAtTradeTime: value.spotPriceAtTradeTime,
          tradeType: value.tradeType,
          action: value.action,
          premium: value.premium,
          isCovering: false,
          isCovered: false,
          coveredById: null,
          notes: "",
          negatesRiskOfTradeId: undefined,
        });
      }
    }
  });

  // Calculate risk of each individual trade
  tradesArray.filter((value, index) => {
    if (value.tradeType === "STOCK" && value.action === "BUY") {
      //LIMITED RISK
      value.calculatedTotalRisk = value.quantity * value.spotPriceAtTradeTime;
      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });

      return value;
    } else if (value.tradeType === "STOCK" && value.action === "SHORT") {
      //UNLIMITED RISK (Off set by owning stock)
      value.calculatedTotalRisk = value.quantity * value.spotPriceAtTradeTime;
      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });

      return value;
    } else if (value.tradeType === "PUT" && value.action === "BUY_TO_OPEN") {
      //LIMITED RISK
      value.calculatedTotalRisk = value.contracts * value.premium * 100;
      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });

      return value;
    } else if (value.tradeType === "PUT" && value.action === "SELL_TO_OPEN") {
      //LIMITED RISK (offset by buying long put)
      value.calculatedTotalRisk =
        value.contracts * value.strikePrice * 100 -
        value.contracts * value.premium * 100;
      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });

      return value;
    } else if (value.tradeType === "CALL" && value.action === "BUY_TO_OPEN") {
      //LIMITED RISK
      value.calculatedTotalRisk = value.contracts * value.premium * 100;
      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });

      return value;
    } else if (value.tradeType === "CALL" && value.action === "SELL_TO_OPEN") {
      //UNLIMITED RISK (off set by owning call or stock)
      value.calculatedTotalRisk =
        value.contracts * value.strikePrice * 100 -
        value.contracts * value.premium * 100;

      tradesRiskDetail.push({
        ...value,
        totalRisk: value.calculatedTotalRisk,
        reducesRisk: false,
      });
      return value;
    }
  });

  //Combine all trades??

  let negatedRisk = 0;

  //Check for offset

  tradesArray.filter((value, index) => {
    // ** Long/LongPut ** //
    //Check for any puts that have been bought as these limit downside risk.
    if (
      value.tradeType === "STOCK" &&
      value.action === "BUY" &&
      value.quantity > 0
    ) {
      //LIMITED RISK
      tradesArray.filter((v, i) => {
        if (
          v.tradeType === "PUT" &&
          v.action === "BUY_TO_OPEN" &&
          v.stockTicker === value.stockTicker &&
          v.contracts > 0
        ) {
          let contractEntitlement = Math.floor((v.contracts * 100) / 100) * 100;
          if (value.quantity > contractEntitlement) {
            v.contracts = 0;
            value.quantity -= contractEntitlement;
            negatedRisk += contractEntitlement * v.strikePrice;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (
            value.quantity < contractEntitlement &&
            value.quantity % contractEntitlement
          ) {
            let contractsThatCanBeUsed =
              Math.floor((value.quantity % contractEntitlement) / 100) * 100;
            v.contracts -= contractsThatCanBeUsed / 100;
            value.quantity -= contractsThatCanBeUsed;
            negatedRisk += contractsThatCanBeUsed * v.strikePrice;

            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (value.quantity === contractEntitlement) {
            v.contracts = 0;
            value.quantity = 0;
            negatedRisk += contractEntitlement * v.strikePrice;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          }
          return v;
        }
      });
    }

    // ** Short/LongCall ** //
    //Check for any puts that have been bought as these limit downside risk.
    else if (
      value.tradeType === "STOCK" &&
      value.action === "SHORT" &&
      value.quantity > 0
    ) {
      //LIMITED RISK

      tradesArray.filter((v, i) => {
        if (
          v.tradeType === "CALL" &&
          v.action === "BUY_TO_OPEN" &&
          v.stockTicker === value.stockTicker &&
          v.contracts > 0
        ) {
          let contractEntitlement = Math.floor((v.contracts * 100) / 100) * 100;
          if (value.quantity > contractEntitlement) {
            v.contracts = 0;
            value.quantity -= contractEntitlement;
            negatedRisk += contractEntitlement * v.strikePrice;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (
            value.quantity < contractEntitlement &&
            value.quantity % contractEntitlement
          ) {
            let contractsThatCanBeUsed =
              Math.floor((value.quantity % contractEntitlement) / 100) * 100;
            v.contracts -= contractsThatCanBeUsed / 100;
            value.quantity -= contractsThatCanBeUsed;
            negatedRisk += contractsThatCanBeUsed * v.strikePrice;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (value.quantity === contractEntitlement) {
            v.contracts = 0;
            value.quantity = 0;
            negatedRisk += contractEntitlement * v.strikePrice;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          }
          return v;
        }
      });
    } else if (
      value.tradeType === "CALL" &&
      value.action === "SELL_TO_OPEN" &&
      value.contracts > 0
    ) {
      //LIMITED RISK
      tradesArray.filter((v, i) => {
        if (
          v.tradeType === "CALL" &&
          v.action === "BUY_TO_OPEN" &&
          v.stockTicker === value.stockTicker &&
          v.contracts > 0
        ) {
          let contractEntitlement = v.contracts;
          if (value.contracts > contractEntitlement) {
            v.contracts = 0;
            value.quantity -= contractEntitlement;
            negatedRisk += contractEntitlement * v.strikePrice * 100;

            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (
            value.contracts < contractEntitlement &&
            value.contracts % contractEntitlement
          ) {
            let contractsThatCanBeUsed =
              Math.floor((value.contracts % contractEntitlement) / 100) * 100;
            v.contracts -= contractsThatCanBeUsed / 100;
            value.contracts -= contractsThatCanBeUsed;
            negatedRisk += contractsThatCanBeUsed * v.strikePrice * 100;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractsThatCanBeUsed * v.strikePrice * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (value.contracts === contractEntitlement) {
            negatedRisk += contractEntitlement * v.strikePrice * 100;
            v.contracts = 0;
            value.contracts = 0;

            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          }

          return v;
        } else if (
          v.tradeType === "STOCK" &&
          v.action === "BUY" &&
          v.stockTicker === value.stockTicker &&
          v.quantity > 99
        ) {
          let contractEntitlement = Math.floor(v.quantity / 100);
          if (value.contracts > contractEntitlement) {
            v.quantity = 0;
            value.quantity -= contractEntitlement;
            negatedRisk += contractEntitlement * v.spotPriceAtTradeTime * 100;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk:
                    contractEntitlement * v.spotPriceAtTradeTime * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (
            value.contracts < contractEntitlement &&
            value.contracts % contractEntitlement
          ) {
            let contractsThatCanBeUsed =
              Math.floor((value.contracts % contractEntitlement) / 100) * 100;
            v.quantity -= contractsThatCanBeUsed / 100;
            value.contracts -= contractsThatCanBeUsed;
            negatedRisk +=
              contractsThatCanBeUsed * v.spotPriceAtTradeTime * 100;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk:
                    contractsThatCanBeUsed * v.spotPriceAtTradeTime * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (value.contracts === contractEntitlement) {
            v.quantity = 0;
            value.contracts = 0;
            negatedRisk += contractEntitlement * v.spotPriceAtTradeTime * 100;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk:
                    contractEntitlement * v.spotPriceAtTradeTime * 100,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          }

          return v;
        }
      });
    } else if (
      value.tradeType === "PUT" &&
      value.action === "SELL_TO_OPEN" &&
      value.contracts > 0
    ) {
      //LIMITED RISK
      tradesArray.filter((v, i) => {
        if (
          v.tradeType === "PUT" &&
          v.action === "BUY_TO_OPEN" &&
          v.stockTicker === value.stockTicker &&
          v.contracts > 0
        ) {
          let contractEntitlement = v.contracts;
          if (value.contracts > contractEntitlement) {
            v.contracts = 0;
            value.quantity -= contractEntitlement;
            negatedRisk += contractEntitlement * v.strikePrice * 100;

            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (
            value.contracts < contractEntitlement &&
            value.contracts % contractEntitlement
          ) {
            let contractsThatCanBeUsed =
              Math.floor((value.contracts % contractEntitlement) / 100) * 100;

            v.contracts -= contractsThatCanBeUsed / 100;
            value.contracts -= contractsThatCanBeUsed;
            negatedRisk += contractsThatCanBeUsed * v.strikePrice * 100;
            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          } else if (value.contracts === contractEntitlement) {
            v.contracts = 0;
            value.contracts = 0;
            negatedRisk += contractEntitlement * v.strikePrice * 100;

            tradesRiskDetail.forEach((x, i) => {
              if (x.id === v.id) {
                tradesRiskDetail[i] = {
                  ...x,
                  negatedRisk: contractEntitlement * v.strikePrice,
                  reducesRisk: true,
                  negatesRiskOfTradeId: value.id,
                };
              }
            });
          }

          return v;
        }
      });
    }
    //ADD CALL

    //If I own a STO call. The downside can be offset by buying a long call

    //If I own a STO put. The downside can be offset by buying a long put.

    return value;
  });

  let totalRisk = 0;
  let costOfOption = 0;
  tradesArray.filter((value, index) => {
    if (isNaN(value.calculatedTotalRisk)) {
    } else {
      totalRisk += value.calculatedTotalRisk;
    }

    if (value.tradeType !== "STOCK") {
      if (isNaN(value.contracts) || isNaN(value.premium)) {
      } else {
        costOfOption += value.contracts * value.premium * 100;
      }
    }
  });

  return {
    totalRisk,
    negatedRisk,
    costOfOption,
    actualRisk: totalRisk - negatedRisk,
    tradesRiskDetail,
  };
};

export const multiRiskCalc = (trades) => {
  let maxRisk = 0;
  let shortCallPremium = 0;
  let shortCallStrike = 0;
  let longCallPremium = 0;
  let longCallStrike = 0;
  let shortPutPremium = 0;
  let shortPutStrike = 0;
  let longPutPremium = 0;
  let longPutStrike = 0;

  trades.forEach((trade) => {
    switch (trade.tradeType) {
      case "CALL":
        if (trade.action === "SELL_TO_OPEN") {
          shortCallPremium += trade.premium * trade.contracts;
          shortCallStrike += trade.strikePrice * trade.contracts;
        } else if (trade.action === "BUY_TO_OPEN") {
          longCallPremium += trade.premium * trade.contracts;
          longCallStrike += trade.strikePrice * trade.contracts;
        }
        break;
      case "PUT":
        if (trade.action === "SELL_TO_OPEN") {
          shortPutPremium += trade.premium * trade.contracts;
          shortPutStrike += trade.strikePrice * trade.contracts;
        } else if (trade.action === "BUY_TO_OPEN") {
          longPutPremium += trade.premium * trade.contracts;
          longPutStrike += trade.strikePrice * trade.contracts;
        }
        break;
    }
  });

  if (shortCallPremium > 0 && longCallPremium > 0) {
    maxRisk +=
      (longCallStrike - shortCallStrike) * 100 -
      (shortCallPremium - longCallPremium) * 100;
  } else if (shortCallPremium > 0) {
    maxRisk += shortCallPremium * 100;
  } else if (longCallPremium > 0) {
    maxRisk += longCallPremium * 100;
  }

  if (shortPutPremium > 0 && longPutPremium > 0) {
    maxRisk +=
      (shortPutStrike - longPutStrike) * 100 -
      (shortPutPremium - longPutPremium) * 100;
  } else if (shortPutPremium > 0) {
    maxRisk += shortPutPremium * 100;
  } else if (longPutPremium > 0) {
    maxRisk += longPutPremium * 100;
  }

  return maxRisk;
};

export const calculateDaysUntilExpiry = (trade) => {
  if (trade.tradeType === "STOCK") {
    return "";
  }

  var a = moment();
  var b = moment(trade.expiryDate);

  return "(" + (parseFloat(b.diff(a, "days")) + 1).toFixed(0) + "d)";
};
export const calculateDaysUntilExpiryAsNumber = (trade) => {
  if (trade.tradeType === "STOCK") {
    return 999999999;
  }

  var a = moment();
  var b = moment(trade.expiryDate);

  return (parseFloat(b.diff(a, "days")) + 0).toFixed(0);
};

export const calculateNumberOfStockTrades = (trades) => {
  let object = {
    stock: 0,
    puts: 0,
    calls: 0,
    stockOnly: true,
  };

  if (trades.row.subRows.length > 0) {
    trades.row.subRows.forEach((value, index) => {
      if (value.subRows.length > 0) {
        value.subRows.forEach((value, index) => {
          if (value.original.tradeType === "STOCK") {
            object.stock++;
          } else {
            if (value.original.tradeType === "PUT") {
              object.puts++;
            } else if (value.original.tradeType === "CALL") {
              object.calls++;
            }
          }
        });
      } else {
        if (value.original.tradeType === "STOCK") {
          object.stock++;
        } else {
          if (value.original.tradeType === "PUT") {
            object.puts++;
          } else if (value.original.tradeType === "CALL") {
            object.calls++;
          }
        }
      }
    });
  } else {
    // console.log(trades)
    if (trades.row.original.tradeType === "STOCK") {
      object.stock++;
    } else {
      if (trades.row.original.tradeType === "PUT") {
        object.puts++;
      } else if (trades.row.original.tradeType === "CALL") {
        object.calls++;
      }
    }
  }

  if (object.puts > 0 || object.calls > 0) {
    object.stockOnly = false;
  }

  return object;
};

export const getExpiryDateFromArrayOfTrades = (trades) => {
  let expiryDate = "";

  if (trades.subRows && trades.subRows.length > 0) {
    trades.subRows.forEach((value, index) => {
      if (value.original.tradeType !== "STOCK") {
        expiryDate = value.original.expiryDate;
      }
    });
  } else {
    trades.forEach((value, index) => {
      if (value.subRows.length > 0) {
        value.subRows.forEach((value, index) => {
          if (value.original.tradeType !== "STOCK") {
            expiryDate = value.original.expiryDate;
          }
        });
      } else {
        if (value.original.tradeType !== "STOCK") {
          expiryDate = value.original.expiryDate;
        }
      }
    });
  }

  if (expiryDate.length > 0) {
    var a = moment();
    var b = moment(expiryDate);
    return (parseFloat(b.diff(a, "days")) + 0).toFixed(0);
  } else {
    return 999999999;
  }
};
