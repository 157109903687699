
import amplitude from 'amplitude-js';

const logEvent = async (message) => {
    amplitude.getInstance().init("8d502256174ed7d512d53d41877f095d");
    amplitude.getInstance().logEvent(message);
}

export default {logEvent};

