import React, { Component, useState } from "react";
import moment from "moment";
import './Trades.css';
import API from "../helpers/apiCalls";
import { FcAbout, FcAlarmClock, FcDisclaimer } from "react-icons/fc";
import { useForm, Controller } from "react-hook-form";
import { Flex, grid, HStack, Icon, Image, StackProps, useColorMode, useColorModeValue, VStack, WrapItem } from '@chakra-ui/react'
import "react-datepicker/dist/react-datepicker.css";
import AddTrade from "./subcomponents/AddTrade";
import {
  Box,
  Button,
  Divider,
  Tooltip,
  SimpleGrid,
  Stack,
  Heading,
  useColorModeValue as mode,
  Center,
  StatGroup, Grid, InputRightElement
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import Share from "./subcomponents/Share";
import CloseTrade from "./subcomponents/CloseTrade";
import Notifications from "./subcomponents/Notifications";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import EditTrade from "./subcomponents/EditTrade";
import {
  Spinner
} from '../components/index';
import {
  Modal,
  ModalOverlay,
  Select,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Spinner as ChakraSpinner
} from "@chakra-ui/react";
import {
  Tag,
  TagLabel,
} from "@chakra-ui/react";
// import DataPanel from "./subcomponents/DataPanel";
import HistoricalOptionData from "./subcomponents/HistoricalOptionData";
import amplitude from 'amplitude-js';
import FundamentalData from "./subcomponents/FundamentalData";
import ChartData from "./subcomponents/ChartData";
import UpgradePage from "../payments/UpgradePage";
import ViewAllTradesButton from "./subcomponents/ViewAllTradesButton";
import TradeTypeView from "./subcomponents/TradeTypeView";
import TradeModalLoading from "./subcomponents/TradeModalLoading";
import apiCalls from "../helpers/apiCalls";
import analytics from '../helpers/amplitude';

import { APIEndpoint, myUrl } from "../helpers/dataStructures";
import { ToggleColorMode } from "../../helpers/ToggleColorModeButton";
import { TabsAlt } from "../helpers/Tabs";
import { ChartFrame } from "../helpers/ChartFrame";
import NotesModal from "./subcomponents/NotesModal";
import NotesTextArea from "./subcomponents/NotesTextArea";
import { CustomTabList, CustomTabPanels, CustomTabsContainer, DataPanel, InsetTab, InsetTabList, InsetTabPanels, InsetTabsContainer, PositionStatusComponent, StatWidget, StockPriceWidget, TradeActionComponent, TradeTypeComponent } from "../../design_library";
import MetaData from "./subcomponents/MetaData";
import { calculateProfitAndLoss } from "../helpers/tradeCalculations";
var currencyFormatter = require('currency-formatter');


export default function TradeDetailModal(props) {
  if (props.shared && props.shared === true) {
    let id = props.tradeData._id;
    apiCalls.recordTradeView(APIEndpoint, id);
    analytics.logEvent('SharedTrade.Viewed');
  }

  // console.log(props.tradeData)

  //Props pass in trade data + get new trade data
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, defaultValues },
  } = useForm();
  // const [data, setData] = useState(null);


  const [showEditTradeScreen, setShowEditTradeScreen] = useState(null);
  const [showCloseTradeScreen, setShowCloseTradeScreen] = useState(false);
  const [loadingDetail, setLoading] = useState(false);
  const [assignedBool, setAssignedBool] = useState(false);
  const { colorMode } = useColorMode();

  // const onSubmit = (expired) => {


  //   setLoading(true);



  //   amplitude.getInstance().logEvent('MainApp.TradesTable.ClosedTrade.Submit');



  //   let payload = props.tradeData;
  //   payload.positionStatus = 'CLOSED';
  //   payload.assignedBool = assignedBool;
  //   payload.dateClosed = getValues('dateClosed') === undefined ? moment().format('MM/DD/YYYY') : getValues('dateClosed');
  //   payload.stockClosePrice = getValues('stockClosePrice');
  //   payload.spotPriceAtAssignment = assignedBool === true ? props.tradeData.strikePrice : undefined;
  //   payload.buyBackPricePerContract = assignedBool === true ? 0 : getValues('buyBackPricePerContract');

  //   if (expired === true) {
  //     payload.dateClosed = moment(props.tradeData.expiryDate).format('MM/DD/YYYY');
  //     payload.buyBackPricePerContract = 0;
  //   }


  //   API.updateTrade(props.APIEndpoint, payload).then((res) => {
  //     if (res.success === false) {
  //       if (res.message) {
  //         alert(res.message);
  //       }
  //       else {
  //         alert(res.msg);
  //       }
  //       setLoading(false);

  //     }
  //     else {
  //       props.getTrades();
  //       setLoading(false);
  //       props.toggle();
  //     }
  //   })


  // };

  // const updateTrade = (id) => {
  //   setLoading(true);


  //   amplitude.getInstance().logEvent('MainApp.TradesTable.UpdateTrade.Submit');

  //   let payload = props.tradeData;
  //   payload = { ...props.tradeData, ...getValues()[id] };
  //   payload.skipRecalculation = true;



  //   API.updateTrade(props.APIEndpoint, payload).then((res) => {
  //     if (res.success === false) {
  //       alert(res);
  //     }
  //     else {
  //       props.getTrades();
  //       setLoading(false);
  //       props.toggle();

  //     }
  //   })
  // }



  if (props.loading) {
    return (
      <TradeModalLoading modalStatus={props.modalStatus} toggle={props.toggle} />
    )
  }


  let optionExpired = false;
  if (!props.tradeData) {
    return null;
  }
  else if (props.tradeData.tradeType === 'PUT' || props.tradeData.tradeType === 'CALL') {
    if (!props.tradeData.optionData) {
      var a = moment();
      var b = moment(props.tradeData.expiryDate);
      if (-a.diff(b, 'days') < 0) {
        optionExpired = true;

      }
      else {
      }
    }
    else {

    }
  }

  let daysBetweenOpenAndClose = 0;
  if (props.tradeData.tradeType !== 'STOCK' && props.tradeData.positionStatus === 'CLOSED') {
    var a = moment(props.tradeData.dateOpened);
    var b = moment(props.tradeData.dateClosed);
    daysBetweenOpenAndClose = b.diff(a, 'days');
  }



  let closedAnnualisedReturn = 0;
  let openForDays = 0;
  if (props.tradeData.tradeType !== 'STOCK' && props.tradeData.positionStatus === 'CLOSED') {
    var a = moment(props.tradeData.dateOpened).tz("Europe/London");
    var b = moment(props.tradeData.dateClosed).tz("Europe/London");
    let totalRisk = (props.tradeData.contracts * props.tradeData.strikePrice) * 100;
    let percentageProfit = (props.tradeData.realisedProfitAndLoss / totalRisk) * 100;
    let diff = -a.diff(b, 'days');
    if (diff === 0) {
      diff = 1;
    }
    closedAnnualisedReturn = (percentageProfit / diff) * 365;
    openForDays = (-a.diff(b, 'days'));

  }


  let stockCurrentValue = 0;
  if (props.tradeData.tradeType === 'STOCK') {
    stockCurrentValue = props.tradeData.liveStockPrice * props.tradeData.quantity;
  }


  let array = Object.keys(props.tradeData);


  let pandl = calculateProfitAndLoss([props.tradeData])

  let sharestring = `Trade on ${props.tradeData.stockTicker} ${props.tradeData.tradeType} for P&L of: ${currencyFormatter.format(pandl.total, { code: 'USD' })}`;

  let contractNameWillBe = '';
  try {
    contractNameWillBe = `O:${props.tradeData.stockTicker + moment(props.tradeData.expiryDate).format("YYMMDD") + props.tradeData.tradeType[0] + "000" + props.tradeData.strikePrice + "000"}`
  }
  catch (e) {
  }
  return (
    <Modal size="full" isOpen={props.modalStatus} onClose={props.toggle} zIndex="99999">
      <ModalContent>
        {props.shared !== true &&
          <ModalCloseButton id="closebutton" />
        }

        <ModalBody
          background={'var(--dark-gray)'}
          padding="20px"
        >



          {props.shared === true &&
            <HStack justifyContent={'space-between'}>




              {colorMode === 'light' ?
                <a href="https://optiontracker.io/"><Image src={'../images/svg/logo-long-black.svg'} maxW="300px" pt="4" pl="4" style={{ cursor: 'pointer' }} onClick={() => { window.location = "https://optiontracker.io/" }} /></a>
                :
                <a href="https://optiontracker.io/"><Image src={'../images/svg/logo-long-white.svg'} maxW="300px" pt="4" pl="4" style={{ cursor: 'pointer' }} onClick={() => { window.location = "https://optiontracker.io/" }} /></a>
              }

              <HStack padding="4">
                <TwitterShareButton
                  beforeOnClick={() => {
                    amplitude.logEvent("V2.SharePage.TwitterClicked")
                  }}
                  url={window.location.href}
                  title={sharestring}
                  via="optiontracker.io"
                  hashtags={['options', 'trading', 'optiontracker']}
                  related={["OptionTrackerIo"]}
                >
                  <TwitterIcon round={true} size={46} />
                </TwitterShareButton>

                <FacebookShareButton
                  url={window.location.href}
                  quote={sharestring}
                  hashtag={['options']}
                  beforeOnClick={() => {
                    amplitude.logEvent("V2.SharePage.FacebookClicked")
                  }}
                >
                  <FacebookIcon round={true} size={46} />
                </FacebookShareButton>

                <RedditShareButton
                  beforeOnClick={() => {
                    amplitude.logEvent("V2.SharePage.RedditClicked")
                  }}
                  url={window.location.href}
                  title={sharestring}
                >
                  <RedditIcon round={true} size={46} />
                </RedditShareButton>

                <EmailShareButton
                  beforeOnClick={() => {
                    amplitude.logEvent("V2.SharePage.EmailClicked")
                  }}
                  url={window.location.href}

                  subject={sharestring}
                  body={`${sharestring}. Visit this trade on Option Tracker at `}
                >
                  <EmailIcon round={true} size={46} />
                </EmailShareButton>

                <ToggleColorMode home={true} short={true} />
              </HStack>
            </HStack>
          }




          {props.loading ?
            <Box>
              <Center>
                <Spinner />
              </Center>
            </Box>
            :
            <>
              <InsetTabsContainer isLazy>


                {loadingDetail ?
                  <Spinner />
                  :
                  <>





                    <InsetTabsContainer>

                      <HStack
                        paddingTop="30px"
                      >
                        <InsetTabList dark={true}>
                          <InsetTab invert={true}>Overview</InsetTab>
                          {props.tradeData.tradeType !== 'STOCK' && <InsetTab invert={true}>Option changes</InsetTab>}
                          {props.shared !== true && <InsetTab invert={true}>Fundamentals</InsetTab>}
                          {/* {props.shared !== true && <Tab>Notes</Tab>} */}
                        </InsetTabList>

                        {props.shared !== true &&

                          <HStack justifyContent={'space-between'} >
                            {/* <ViewAllTradesButton history={props.history} tradeData={props.tradeData} setModal={props.setModal} setStockTicker={props.setStockTicker} /> */}

                            <HStack>
                              <CloseTrade data={{ original: props.tradeData }} getTrades={props.getTrades !== undefined ? props.getTrades : () => { return; }} />
                              <EditTrade data={{ original: props.tradeData }} getTrades={props.getTrades !== undefined ? props.getTrades : () => { return; }} />
                              <Notifications data={{ original: props.tradeData }} getTrades={props.getTrades !== undefined ? props.getTrades : () => { return; }} />
                              <Share data={{ original: props.tradeData }} getTrades={props.getTrades !== undefined ? props.getTrades : () => { return; }} />
                            </HStack>

                            {/* <TradeTypeView type="Single trade" color="blue" /> */}
                          </HStack>
                        }
                      </HStack>


                      {/* <StockPriceWidget logoUrl={props.tradeData.logoUrl} code={props.tradeData.stockTicker} /> */}


                      <InsetTabPanels>
                        <TabPanel >

                          {props.shared === true ?
                            <Box maxW={'100%'} height="500px" >
                              <ChartFrame>
                                <ChartData
                                  symbol={props.tradeData.stockTicker}
                                  tradeData={props.tradeData}
                                />
                              </ChartFrame>
                            </Box>
                            :
                            <Box >
                              <ChartFrame>
                                <ChartData
                                  symbol={props.tradeData.stockTicker}
                                  tradeData={props.tradeData}
                                />
                              </ChartFrame>
                            </Box>
                          }


                          {/* <Heading  size='md'>Trade details</Heading> */}
                          <Box mt="20px">

                            <NotesTextArea data={props.tradeData} shared={props.shared} />
                          </Box>

                          <StatGroup mt="5" mb="6" justifyContent="start">
                            <SimpleGrid columns={5} width="100%" gridAutoRows="75px" minChildWidth="180px">
                              <WrapItem>
                                <Box onClick={() => {

                                  if (props['setStockTicker'] !== undefined) {
                                    props.setStockTicker(props.tradeData.stockTicker);
                                    props.setModal(false);
                                  }
                                  else {

                                    if (props.shared !== true) {
                                      props.history.push(`/app/trades?stock=${props.tradeData.stockTicker}`)

                                    }
                                  }
                                }}
                                  cursor="pointer">
                                  <DataPanel id="stockTickerCellModal" data={props.tradeData.stockTicker} label="Ticker" />
                                </Box>
                              </WrapItem>


                              {props.tradeData.liveStockPrice !== undefined && <DataPanel type="BASIC" data={"$" + props.tradeData.liveStockPrice} label="Live spot" />}

                              <DataPanel type="BASIC_TAG_POSITION_STATUS" data={<PositionStatusComponent tradeData={props.tradeData} />} label="Status" />
                              <DataPanel type="BASIC_TAG_TRADE_TYPE" data={<TradeTypeComponent tradeType={props.tradeData.tradeType} isDividendFlag={props.tradeData.isDividendFlag} />} label="Type" />
                              <DataPanel type="BASIC_TAG_ACTION" data={<TradeActionComponent action={props.tradeData.action} />} label="Action" />
                              {props.tradeData.spotPriceAtTradeTime && <DataPanel type="BASIC" data={"$" + props.tradeData.spotPriceAtTradeTime.toFixed(2)} label="Spot price at open" />}
                              <DataPanel type="BASIC" data={
                                props.tradeData.tradeType === 'STOCK' ?
                                  //is stock
                                  currencyFormatter.format((props.tradeData.spotPriceAtTradeTime * props.tradeData.quantity), { code: 'USD' })

                                  :
                                  //is option
                                  props.tradeData.action === 'BUY_TO_OPEN' ?
                                    currencyFormatter.format((((props.tradeData.premium * props.tradeData.contracts) * 100)), { code: 'USD' })
                                    :
                                    currencyFormatter.format(((((props.tradeData.strikePrice * props.tradeData.contracts) - props.tradeData.premium * props.tradeData.contracts) * 100)), { code: 'USD' })

                              } label="Initial Risk" />
                              {props.tradeData.tradeType === 'STOCK' && props.tradeData.positionStatus === "OPEN" && <DataPanel type="BASIC" data={currencyFormatter.format(stockCurrentValue, { code: 'USD' })} label="Current value" />}
                              {props.tradeData.tradeType !== 'STOCK' && props.tradeData.positionStatus === "OPEN" && props.tradeData.optionData && <DataPanel type="BASIC" data={currencyFormatter.format((((props.tradeData.optionData.ask + props.tradeData.optionData.bid) / 2) * props.tradeData.contracts) * 100, { code: 'USD' })} label="Current value" />}
                              <DataPanel id="sharesdatamodal" type="BASIC" data={props.tradeData.quantity} label="Shares" />
                              <DataPanel type="BASIC" data={props.tradeData.contracts} label="Contracts" />
                              <DataPanel type="BASIC" data={props.tradeData.strikePrice} label="Strike" />
                              <DataPanel type="BASIC" data={moment(props.tradeData.dateOpened).format('MM/DD/YYYY')} label="Date opened" />
                              {props.tradeData.premium && props.tradeData.tradeType !== 'STOCK' && <DataPanel type="BASIC" data={"$" + (props.tradeData.premium).toFixed(2)} label="Initial Premium" />}
                              {props.tradeData.tradeType !== 'STOCK' && <DataPanel type="BASIC" data={moment(props.tradeData.expiryDate).calendar(null, { lastDay: '[Yesterday]', sameDay: '[Today]', nextDay: '[Tomorrow]', lastWeek: '[last] dddd', nextWeek: 'dddd', sameElse: 'L' })} label="Expiry date" />}
                              {props.tradeData.tradeType !== 'STOCK' && <DataPanel type="BASIC" data={(props.tradeData.AnnualisedReturn * 100).toFixed(2) + "%"} label="Annualized return" />}
                              {props.tradeData.tradeType !== 'STOCK' && <DataPanel type="BASIC" data={(props.tradeData.potentialReturnPercentage * 100).toFixed(2) + "%"} label="Potential return" />}
                            </SimpleGrid>
                          </StatGroup>

                          {props.tradeData.positionStatus === 'CLOSED' &&
                            <>
                              <Divider mt="5" mb="5" />
                              <Heading size='md'>Closure details</Heading>
                              <Text fontSize="sm" color="grey">Info from closing the trade</Text>
                              <StatGroup mt="5" mb="6" justifyContent="start">
                                <SimpleGrid columns={5} width="100%" gridAutoRows="105px" minChildWidth="180px">

                                  {props.tradeData.tradeType !== 'STOCK' ?
                                    <>
                                      <DataPanel type="BASIC" data={moment(props.tradeData.dateClosed).calendar(null, { lastDay: '[Yesterday]', sameDay: '[Today]', nextDay: '[Tomorrow]', lastWeek: '[last] dddd', nextWeek: 'dddd', sameElse: 'L' })} label="Closed on" />
                                      <DataPanel type="BASIC" data={currencyFormatter.format(props.tradeData.realisedProfitAndLoss * 100, { code: 'USD' })} label="Realized P&L" />
                                      <DataPanel type="BASIC" data={openForDays + " days"} label="Open for" />
                                      <DataPanel type="BASIC" data={currencyFormatter.format(props.tradeData.buyBackPricePerContract, { code: 'USD' })} label="Per contract final value" />
                                      {!isNaN(closedAnnualisedReturn) && <DataPanel type="BASIC" value={(closedAnnualisedReturn * 100).toFixed(2) + "%"} label="Annualized return (at close)" />}
                                    </>
                                    :
                                    <>
                                      <DataPanel type="BASIC" data={moment(props.tradeData.dateClosed).calendar(null, { lastDay: '[Yesterday]', sameDay: '[Today]', nextDay: '[Tomorrow]', lastWeek: '[last] dddd', nextWeek: 'dddd', sameElse: 'L' })} label="Closed on" />
                                      <DataPanel type="BASIC" data={currencyFormatter.format(props.tradeData.realisedProfitAndLoss, { code: 'USD' })} label="Realized profit" />
                                      {() => {
                                        try {
                                          return <>{props.tradeData && props.tradeData.stockClosePrice && <DataPanel type="BASIC" data={"$" + (props.tradeData.stockClosePrice ? (props.tradeData.stockClosePrice) : '').toFixed(2)} label="Spot price at close" />}</>
                                        }
                                        catch (e) {
                                          return null;
                                        }
                                      }}
                                    </>
                                  }

                                </SimpleGrid>
                              </StatGroup>






                            </>
                          }


                          <MetaData columns={props.columns} row={props.row} />





                        </TabPanel>
                        {props.tradeData.tradeType !== 'STOCK' &&
                          <TabPanel>

                            <>
                              <>
                                {props.tradeData.initialIV && props.tradeData.optionData !== undefined ?
                                  <>
                                    <Text fontSize={'22px'} color="var(--white)">Changes</Text>
                                    <Divider pt={'16px'} />
                                    <StatGroup justifyContent="start" pt="20px" mb="38px">
                                      <SimpleGrid columns={5} width="100%" gridAutoRows="105px" minChildWidth="180px">
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.delta} label={'Delta'} data={props.tradeData.initialDelta && props.tradeData.initialDelta.toFixed(2)} type="PERCENTAGE" />
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.gamma} label={'Gamma'} data={props.tradeData.initialGamma && props.tradeData.initialGamma.toFixed(2)} type="PERCENTAGE" />
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.impliedVolatility} label={'IV'} data={(props.tradeData.initialIV).toFixed(2)} type="PERCENTAGE" />
                                        <DataPanel format={(value) => value && "$" + parseFloat(value).toFixed(2)} change={props.tradeData.optionData.lastPrice} label={'Last price'} data={props.tradeData.initialLastPrice.toFixed(2)} type="NUMERICAL" />
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.rho} label={'Rho'} data={props.tradeData.initialRho.toFixed(2)} type="PERCENTAGE" />
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.theta} label={'Theta'} data={props.tradeData.initialTheta.toFixed(2)} type="PERCENTAGE" />
                                        <DataPanel format={(value) => value && parseFloat(value).toFixed(2) + "%"} change={props.tradeData.optionData.vega} label={'Vega'} data={props.tradeData.initialVega.toFixed(2)} type="PERCENTAGE" />
                                      </SimpleGrid>
                                    </StatGroup>

                                    {props.tradeData.optionData && props.tradeData.optionData.contractName &&
                                      <ChartFrame>
                                        <HistoricalOptionData contractName={props.tradeData.optionData.contractName} />
                                      </ChartFrame>
                                    }

                                    {props.dataOffBy50Cent === true && <p style={{ fontSize: 12, paddingTop: 5 }}>The chart above shows you the next closest option data (usually the option 50c below) as data for your option was not available.</p>}
                                  </>
                                  :
                                  <>

                                    {optionExpired ?
                                      <>

                                        {optionExpired === true ?
                                          <Box
                                            maxW="2xl"
                                            mx="auto"
                                            px={{ base: '6', lg: '8' }}
                                            py={{ base: '16', sm: '20' }}
                                            textAlign="center">
                                            <Icon as={FcDisclaimer} w={126} h={126} pb="6" />
                                            <Heading as="h2" size="3xl" fontWeight="extrabold" letterSpacing="tight">Option expired</Heading>
                                          </Box>
                                          : <Text>This option has not yet expired.</Text>}
                                      </>
                                      :


                                      <ChartFrame>

                                        {/* {props.tradeData.optionData && props.tradeData.optionData.contractName && */}
                                        <HistoricalOptionData contractName={contractNameWillBe} />
                                        {/* } */}


                                      </ChartFrame>
                                    }
                                  </>
                                }

                              </>
                            </>

                          </TabPanel>
                        }

                        <TabPanel>
                          {props.shared !== true &&
                            <ChartFrame>
                              <FundamentalData tradeData={props.tradeData} />
                            </ChartFrame>
                          }
                        </TabPanel>



                      </InsetTabPanels>
                    </InsetTabsContainer>










                  </>

                }
              </InsetTabsContainer>

            </>


          }


        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

