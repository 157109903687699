import { Divider, Heading, Text, StatGroup, SimpleGrid } from '@chakra-ui/react';
import React, { Component } from 'react';
import { DataPanel } from '../../../design_library';
const MetaData = (props) => {

    if (!props.columns) {
        return null;
    }

    return (
        <div>
            {/* <Divider mt="5" mb="5" />
            <Heading size='md'>Metadata</Heading>
            <Text fontSize="sm" color="grey">Addition information that is available, not all fields will have data.</Text>

            <StatGroup mt="5" mb="6" justifyContent="start">
                <SimpleGrid columns={4} width="100%" gridAutoRows="105px" minChildWidth="180px" spacing={'20px'}>
                    {props.columns.map((column, index) => {

                        if (['actions', 'assignedAt', 'positionStatus', 'buyBackPricePerContract', 'spotPriceAtAssignment', 'tradeType'].includes(column.accessor)) {
                            return null;
                        }
                        try {
                            let value = null;
                            if (props.aggregated === true) {
                                value = column.Aggregated({ row: props.row })

                            }
                            else {
                                value = column.Cell({ row: props.row })

                            }
                            return (<DataPanel type="CELL" data={value} label={column.Header} />)
                        }
                        catch (e) {
                            return null;
                        }

                    })}
                </SimpleGrid>
            </StatGroup> */}
        </div>
    )
}

export default MetaData;