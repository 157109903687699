import { Box } from '@chakra-ui/react';
import React from 'react';
import { NavContent } from './NavContent';

export const NavBar = (props: any) => {

  return (
    <Box as="header" position="relative" zIndex="10">
      <Box as="nav" aria-label="Main navigation" maxW="8xl" mx="auto" px={{ base: '6', md: '8' }}>
        <NavContent.Mobile display={{ base: 'flex', lg: 'none' }} />
        <NavContent.Desktop display={{ base: 'none', lg: 'flex' }} days={props.days} loading={props.loading}
        />
      </Box>
    </Box>
  )
}


export default NavBar;