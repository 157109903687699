import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Spinner} from '../../../components/index';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { area, curveMonotoneY, curveMonotoneX, curveCardinal, curveLinear} from 'd3-shape'
import { AspectRatio, Box, Heading, Stack } from '@chakra-ui/layout';

function sortNumber(a, b) {
    return a - b
  }

class ScatterPlotChart extends Component {
    constructor() {
        super();
        this.state = {
            loading:true,
            dataset:[],
            nodeSize:0,
        }
    }

    componentDidMount() {

        let newDataSet =  {
            "id": "All trades",
            "data": [],
            "minY": 0
        };
        let trendlineDataset =  {
            "id": "Trendline",
            "color": "grey",
            "data": [],
            "minY": 0
        };

        function dynamicSort(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        let data = this.props.data.sort(dynamicSort(this.props.x));

        let x_coords = [];
        let y_coords = [];

        data.filter((value, index) => {
            if(typeof value[this.props.x] !== "undefined" && typeof value[this.props.y] !== "undefined" && value[this.props.x] !== null & value[this.props.y] !== null) {

                if(parseFloat(value[this.props.y]) < newDataSet.minY) {
                    newDataSet.minY = parseFloat(value[this.props.y]);
                }

                x_coords.push(parseFloat(value[this.props.x]));
                y_coords.push(parseFloat(value[this.props.y]));


                newDataSet.data.push({
                    x:parseFloat(value[this.props.x]),
                    y:parseFloat(((value[this.props.y]).toFixed(2))),
                    low:parseFloat(value[this.props.y])-(parseFloat(value[this.props.y])*0.2),
                    high:parseFloat(value[this.props.y])+(parseFloat(value[this.props.y])*0.2)
                })
            }
        });

        
          const trendline = linearRegression(y_coords, x_coords)
          const lowest_x = x_coords.sort(sortNumber)[0]
          const highest_x = x_coords.sort(sortNumber)[x_coords.length - 1]

          trendlineDataset.data = [{ x:lowest_x, y:trendline(lowest_x) }, {x:highest_x, y: trendline(highest_x)}];
       

        this.setState({dataset:[newDataSet, trendlineDataset], loading:false})

        setTimeout(() => {
            this.setState({nodeSize:10})
        }, 200)


    }
    
    render() { 

        if(this.state.loading) {
            return (<ContainerBox><Spinner/></ContainerBox>)
        }
        else {
            return (<ContainerBox><MyResponsiveScatterPlot titleText={this.props.title} formatX={this.props.formatX} formatY={this.props.formatY} data={this.state.dataset} xLabel={this.props.xLabel} yLabel={this.props.yLabel} nodeSize={this.state.nodeSize}/></ContainerBox>)
        }

       
    }
}


function linearRegression(y, x) {
    var lr = {}
    var n = y.length
    var sum_x = 0
    var sum_y = 0
    var sum_xy = 0
    var sum_xx = 0
    var sum_yy = 0
  
    for (var i = 0; i < y.length; i++) {
      sum_x += x[i]
      sum_y += y[i]
      sum_xy += x[i] * y[i]
      sum_xx += x[i] * x[i]
      sum_yy += y[i] * y[i]
    }
  
    lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
    lr["intercept"] = (sum_y - lr.slope * sum_x) / n
    lr["r2"] = Math.pow(
      (n * sum_xy - sum_x * sum_y) /
        Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
      2
    )
  
    return x => {
      return lr.slope * x + lr.intercept
    }
  }

const ContainerBox = ({children}) => (
    <AspectRatio maxW="1800px" ratio={4 / 2} 
    rounded={{ md: 'lg' }}
    bg={'white'}
    shadow="base"
    overflow="hidden"
    padding="5"
    margin="5"
  >{children}</AspectRatio>
)

const MyResponsiveScatterPlot = ({ data, xLabel, yLabel, nodeSize, formatX, formatY, titleText }) => (
    <ResponsiveScatterPlot
        data={data}
        titleText={titleText}
        margin={{ top: 100, right: 70, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        enableGridX={false}
        xFormat={function(e){return e+"%"}}
        yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        yFormat={function(e){return "$"+e+""}}
        blendMode="normal"
        axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36, format: formatX }}
        axisRight={{ orient: 'right', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 0 ,format: formatY }}
        nodeSize={nodeSize}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xLabel,
            legendPosition: 'middle',
            legendOffset: 46,
            format: formatX
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLabel,
            legendPosition: 'middle',
            legendOffset: -60,
            format: formatY
        }}
        layers={[
            'grid',
            'axes',
            AreaLayer,
            BelowZeroWarning,
            'nodes',
            'markers',
            Title
        ]}
        markers={[
            {
                axis: 'y',
                value: 0,
                lineStyle: { stroke: '#b0413e', strokeWidth: 1 },
            },
        ]}

    />
)

const Title = ({ width, height, titleText }) => {
    // You can console.log(data) to see what other properties are available

    const style = {fontWeight: 'bold'}

    return (
        <text 
            x={-40}
            y={-50}
            style={style}
        >
            {titleText}
        </text>
    )
} 

const AreaLayer = ({ nodes, xScale, yScale }) => {
    let data = nodes.filter((value) => value.id === 'Trendline.0' || value.id === 'Trendline.1');
    const areaGenerator = area()
        .x(d => xScale(d.data.x))
        .y0(d => yScale(d.data.y-(d.data.y*0.05)))
        .y1(d => yScale(d.data.y+(d.data.y*0.05)))
        .curve(curveLinear)

    return <path d={areaGenerator(data)} fill="rgba(140, 219, 243, 0.5)" />
}
 
const BelowZeroWarning = ({ nodes, xScale, yScale, data }, ) => {
    const areaGenerator = area()
        .x(d => xScale(d.data.x))
        .y0(d => yScale(0))
        .y1(d => yScale(data[0].minY))
        .curve(curveLinear)
    return <path d={areaGenerator(nodes)}  fill="rgba(232, 193, 160, .15)" />
}
 
const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
  };

export default connect(mapStateToProps)(ScatterPlotChart);
