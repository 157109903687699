import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { IconButton } from "@chakra-ui/react";
import { IoDocumentTextOutline, IoDocumentText } from "react-icons/io5"
import { Textarea, Modal, Tooltip, Text, Button, useDisclosure, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, } from "@chakra-ui/react";
import API from "../../helpers/apiCalls";

class NotesModal extends Component {

  constructor() {
    super();
    this.state = {
      isOpen: false
    }
  }

  toggleModal = (tog) => {
    this.setState({ isOpen: tog })
  }

  render() {
    if (this.props.data.canExpand !== true) {

      let notes = undefined;
      if (this.props.data.original) {
        if (this.props.data.original.notes && this.props.data.original.notes !== undefined) {
          notes = this.props.data.original.notes;
        }
        else {
          notes = '';
        }
      }
      else {
        notes = '';
      }

      return (
        <div>
          <Tooltip label={notes.length < 1 ? 'Add a note' : 'View notes'} >
            <IconButton
              variant="ghost"
              color={notes.length < 1 ? 'grey' : 'blue.400'}
              //   bg={notes.length < 1 ? 'lightgrey.100' : 'blue.100' }
              fontSize="20px"
              onClick={() => this.setState({ isOpen: true })}
              icon={<IoDocumentTextOutline />}
              minW={'30px'}
              maxH={'30px'}

            />
          </Tooltip>

          <ModalComponent APIEndpoint={this.props.settings.APIEndpoint} isOpen={this.state.isOpen} toggleModal={this.toggleModal} notes={this.props.data.values.notes} data={this.props.data.original} />
        </div>
      )
    }
    else {
      return '';
    }
  }
}

function ModalComponent(props) {

  const [notes, setNotes] = useState(props.notes);
  const [loading, setLoading] = useState(false);


  const saveText = () => {
    setLoading(true);

    let payload = props.data;
    payload.notes = notes;
    payload.skipRecalculation = true;

    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        if (res.message) {
          alert(res.message);
        }
        else {
          alert(res.msg);
        }
        setLoading(false);
      }
      else {
        setLoading(false);
        props.toggleModal(false);

      }
    })


  }



  return (
    <>
      <Modal isOpen={props.isOpen} size="5xl" onClose={() => props.toggleModal(false)} >
        <ModalOverlay bg='blackAlpha.300'
          backdropFilter='blur(10px) ' />
        <ModalContent>
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              minHeight={'300px'}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any notes here"
              size="sm"
            />
          </ModalBody>

          <ModalFooter>
            <Text marginRight={"15px"}>{notes && notes.length}/1000</Text>
            <Button colorScheme="blue" mr={3} onClick={() => saveText()} loading={loading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


const mapStateToProps = state => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(NotesModal);
