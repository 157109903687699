import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalOverlay, ModalContent, ModalHeader, Center, Spinner, } from '@chakra-ui/react'

class TradeModalLoading extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal size="6xl" isOpen={this.props.modalStatus} onClose={this.props.toggle}>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) ' />
                <ModalContent>
                    <ModalHeader>

                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Center height="200px">
                            <Spinner />
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }
}

export default TradeModalLoading;