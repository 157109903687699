import { Table, Tabs, useColorMode, Text } from '@chakra-ui/react';
import React from 'react';
import colors from '../../helpers/colors';
import './Tabs.css';
export const TabsAlt = (props) => {
    const { colorMode } = useColorMode();
    return (
        <Tabs
            {...props}
            mt="2"
            isFitted
            isLazy
            colorScheme={'blue'}
        // variant='soft-rounded'
        >
            {props.children}
        </Tabs >
    )
}