import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { useColorMode } from "@chakra-ui/react"
import React from 'react'
import { RoundIcon } from '../design_library'
export const ToggleColorMode = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();

    if (props.short === true) {
        return (<RoundIcon
            short={true}
            onClick={toggleColorMode}

            isActive={props.home} aria-label="toggle color mode" icon={colorMode === 'light' ? <SunIcon color={"var(--white)"} /> : <MoonIcon color={"var(--light-gray)"} />}
            label={
                colorMode === 'light' ? 'Dark Mode' : 'Light Mode'
            }
        />)
    }

    return (



        <RoundIcon

            onClick={toggleColorMode}

            isActive={props.home} aria-label="toggle color mode" icon={colorMode === 'light' ? <SunIcon color={"var(--white)"} /> : <MoonIcon color={"var(--light-gray)"} />}
            label={
                colorMode === 'light' ? 'Dark Mode' : 'Light Mode'
            }
        />
    )

}