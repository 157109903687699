import React, { Component } from 'react'
import { SimpleGrid, Box, Container, AspectRatio, HStack, VStack } from '@chakra-ui/layout';
import { connect } from 'react-redux';
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Tooltip,
    Text,
    Center,
    Image,
    Icon,
    Heading,
} from "@chakra-ui/react"
import { ResponsiveBar } from '@nivo/bar'
import stringToColour from '../../helpers/color';
import { Spinner } from '../../components';
import moment from 'moment';
import { area, curveMonotoneX } from 'd3-shape'
import { FaDatabase } from 'react-icons/fa';
import { FcDecision } from 'react-icons/fc';
import { Panel } from '../../helpers/Panel';
import colors from '../../../helpers/colors';
import { BarChart, CustomTag, CustomToolTip, NoDataWidget, WidgetHeader, WidgetHeaderTitleOnly, WidgetPanel, WidgetPanelWithOverFlow } from '../../../design_library';
import apiCalls from '../../helpers/apiCalls';
import { getColor } from '../../trades/subcomponents/svgs/ChartSvgs';
var currencyFormatter = require('currency-formatter');

class NewsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            news: [],

        }
    }

    componentDidMount() {
        console.log(this.props.trades)
        if (this.props.trades.length > 0) {
            let uniqueStocks = [...new Set(this.props.trades.map((item) => {
                if (item.positionStatus === 'OPEN' && item.stockTicker !== undefined) {
                    return item.stockTicker;
                }
            }))];

            let filterOutUndefined = uniqueStocks.filter(el => {
                if (el !== undefined) {
                    return el;
                }
            });

            filterOutUndefined.forEach((stock) => {
                this.getNews(stock);
            })
        }
    }

    getNews = (stockTicker) => {
        this.setState({ loading: true });

        apiCalls.getNews(stockTicker).then((res) => {
            if (res.success === true) {
                let newNews = res.data;
                newNews.filter((news) => {
                    news.stockTicker = stockTicker;
                })
                this.setState({
                    news: [...this.state.news, ...newNews],
                    loading: false
                })
            }
        });

    }


    render() {






        if (this.state.loading === true) {
            return (
                <WidgetPanel
                >
                    <WidgetHeader
                        toggleWidget={(id) => this.props.toggleWidget(id)}
                        label={'News'} tooltip="Displays news from the open positions in your account. This is updated every 6 hours" />
                    <Spinner />
                </WidgetPanel>
            )
        }



        if (this.props.trades.length < 1 || this.state.news.length < 1) {
            return (
                <WidgetPanel >
                    <WidgetHeader
                        toggleWidget={(id) => this.props.toggleWidget(id)}
                        label={'24hr News'} tooltip="Displays news from the open positions in your account. This is updated every 6 hours" />
                    <NoDataWidget label="No news available for this stock!" />
                </WidgetPanel>
            )
        }

        if (this.props.noHeader === true) {
            return (
                <WidgetPanelWithOverFlow>
                    <WidgetHeaderTitleOnly />
                    <NewsListOnly news={this.state.news} />
                </WidgetPanelWithOverFlow>)
        }

        return (
            <WidgetPanelWithOverFlow>
                <WidgetHeader
                    toggleWidget={(id) => this.props.toggleWidget(id)}
                    label={'24hr News'} tooltip="Displays news from the open positions in your account. This is updated every 6 hours" />
                <NewsListOnly news={this.state.news} />
            </WidgetPanelWithOverFlow>)
    }
}

const groupByDay = (data) => {
    const grouped = {};

    data.forEach(item => {
        // Extract just the date without the time
        const day = item.date.split('T')[0];

        // If this day isn't a key in our grouped object, create it
        if (!grouped[day]) {
            grouped[day] = [];
        }

        // Push this item into the appropriate group in our grouped object
        grouped[day].push(item);
    });

    return grouped;
}

const NewsListOnly = ({ news }) => {

    const groupedArticles = groupByDay(news);

    return (
        <Box mt="2">
            <Box borderBottom="1px" borderColor="var(--gray)" mb={2} cursor="pointer" >
                {Object.keys(groupedArticles).map((key, index) => (
                    <HStack justifyContent={'space-between'} border="var(--gray) solid 2px" borderRadius={'6px'} mb={4} background={'var(--dark-gray)'} padding={2}>
                        <Box width={'100%'}>
                            <Text paddingLeft="4px" size="xs" fontWeight="bold" mb={2} color="var(--grey)" >{moment(key).fromNow()}</Text>

                            {groupedArticles[key].map((news, index) => {
                                return (
                                    <Box key={index} p={1} mb={1} mt={1} cursor="pointer" onClick={() => { window.open(news.link, '_blank') }}>
                                        <HStack justifyContent={'space-between'} spacing={'2px'}>
                                            <VStack justifyContent={'flex-start'} borderBottom={'1px solid var(--gray)'} textAlign='left' alignItems={'flex-start'} flex='6'>
                                                <HStack justifyContent={'space-between'} width="100%" pb={2}>
                                                    <Box>
                                                        <Text size="xxs" >{news.title}</Text>
                                                    </Box>
                                                    <CustomTag backgroundColor={'var(--bg)'}>{news.stockTicker}</CustomTag>
                                                </HStack>
                                            </VStack>
                                            <Box>
                                            </Box>
                                        </HStack>
                                    </Box>
                                )
                            })}
                        </Box>
                    </HStack>
                ))}
            </Box>

        </Box>
    )

}


const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(NewsPanel);
