// noprotect

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Switch, Redirect
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux'
import store from './redux/store';
import { ThemeProvider } from 'styled-components';
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { hydrate, render } from "react-dom";
import * as serviceWorker from './serviceWorker';
import moment from 'moment-timezone';
import { Box, Center, Spinner, Heading } from '@chakra-ui/react';
import FAQPage from './FAQ/FAQPage';
import NewsPage from './News/NewsPage';
import SharePage from './share/SharePage';
import { ColorModeScript } from '@chakra-ui/react'
import theme from './themeConfig';
import colors from './helpers/colors';
import DesignLibrary from './design_library';
// import App from './homepage/App';
// import MainApp from './platform/MainApp';

moment.tz.setDefault("Europe/London");

const App = React.lazy(() => import('./homepage/App'));
const FourOhFour = React.lazy(() => import('./404'));
const MainApp = React.lazy(() => import('./platform/MainApp'));


if (module.hot) {
  module.hot.accept();
}


const Loading = () => (
  <Box>
    <Center height="100vh" flexDir="column">
      <Heading mt="2" size="md">Loading...</Heading>
    </Center>
  </Box>
);


let env = '';
if (window.location.href.includes('localhost')) {
  env = 'local';
}
else if (window.location.href.includes('beta')) {
  env = 'beta';
}
else if (window.location.href.includes('alpha')) {
  env = 'alpha';
}
else {
  env = 'production_v2';
}


try {
  Sentry.init({
    dsn: "https://fa1f8273c60a46bc991c326eacea464c@o572819.ingest.sentry.io/5722676",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    environment: env,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
catch (e) {
  console.log("Sentry could not load...", e)
}


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<Loading />}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/share/:tradeId" component={SharePage} status={200} />
              {/* <Route path="/news/" component={NewsPage} status={200} /> */}
              {/* <Route path="/news/:articleId" component={NewsPage} status={200} /> */}
              <Route path="/app/" component={MainApp} status={200} />
              <Route path='/404' exact={true} component={FourOhFour} status={404} />
              <Route path="/" exact={true} component={App} status={200} />
              <Redirect from='*' to='/404' />
            </Switch>
          </Router>
        </Provider>
      </ChakraProvider>
    </Suspense>
    , rootElement);
} else {
  render(
    <Suspense fallback={<Loading />}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              {/* <Route path="/news/" component={NewsPage} status={200} /> */}
              {/* <Route path="/news/:articleId?" component={NewsPage} status={200} /> */}
              <Route path="/share/:tradeId?" component={SharePage} status={200} />
              <Route path="/app/" component={MainApp} status={200} />
              <Route path='/404' exact={true} component={FourOhFour} status={404} />
              <Route path="/" exact={true} component={App} status={200} />
              <Redirect from='*' to='/404' />
            </Switch>
          </Router>
        </Provider>
      </ChakraProvider>
    </Suspense>
    , rootElement);
}




serviceWorker.unregister();
