import { DragHandleIcon } from '@chakra-ui/icons';
import { Icon, useColorMode } from '@chakra-ui/react';
import React, { Component } from 'react'
import colors from '../../../helpers/colors';
import './DragHandle.css';

export const DragHandle = (props) => {
    const { colorMode } = useColorMode();

    if (props.right === true) {
        return (
            <div className={"drag-handle right-handle " + colorMode + '-handle'} >
                <DragHandleIcon style={{ transform: 'rotate(90deg)' }} />
            </div>
        )
    }
    return (
        <div className={"drag-handle " + colorMode + '-handle'} >
            <DragHandleIcon style={{ transform: 'rotate(90deg)' }} />
        </div>
    )
}