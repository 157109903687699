import moment from 'moment';
var currencyFormatter = require('currency-formatter');

let calculateGreeksComparison = async (trades, tradeType, action, formatted) => {

    let winningTrades = [];
    let loosingTrades = [];

    let totalProfit = 0;
    let averageProfit = 0;

    let totalLoss = 0;
    let averageLoss = 0;

    let winInitialIVCount = 0;
    let winInitialIV = 0;
    let averageWinInitialIV = 0;

    let lossInitialIVCount = 0;
    let lossInitialIV = 0;
    let averageLossInitialIV = 0;

    let winInitialDeltaCount = 0;
    let winInitialDelta = 0;
    let averageWinInitialDelta = 0;

    let lossInitialDeltaCount = 0;
    let lossInitialDelta = 0;
    let averageLossInitialDelta = 0;

    let winInitialVegaCount = 0;
    let winInitialVega = 0;
    let averageWinInitialVega = 0;

    let lossInitialVegaCount = 0;
    let lossInitialVega = 0;
    let averageLossInitialVega = 0;

    let winInitialThetaCount = 0;
    let winInitialTheta = 0;
    let averageWinInitialTheta = 0;

    let lossInitialThetaCount = 0;
    let lossInitialTheta = 0;
    let averageLossInitialTheta = 0;

    let winInitialRhoCount = 0;
    let winInitialRho = 0;
    let averageWinInitialRho = 0;

    let lossInitialRhoCount = 0;
    let lossInitialRho = 0;
    let averageLossInitialRho = 0;

    //AnnualisedReturn

    //DTE
    let lossInitialDTECount = 0;
    let lossInitialDTE = 0;
    let averageLossInitialDTE = 0;

    let winInitialDTECount = 0;
    let winInitialDTE = 0;
    let averageWinInitialDTE = 0;

    //actualDaysOpen
    let lossInitialDTEOpenCount = 0;
    let lossInitialDTEOpen = 0;
    let averageLossInitialDTEOpen = 0;

    let winInitialDTEOpenCount = 0;
    let winInitialDTEOpen = 0;
    let averageWinInitialDTEOpen = 0;

    //InitialPremium
    let lossInitialPremiumCount = 0;
    let lossInitialPremium = 0;
    let averageLossInitialPremium = 0;

    let winInitialPremiumCount = 0;
    let winInitialPremium = 0;
    let averageWinInitialPremium = 0;

    //Annualised
    let lossAnnualisedPremiumCount = 0;
    let lossAnnualisedPremium = 0;
    let averageLossAnnualisedPremium = 0;

    let winAnnualisedPremiumCount = 0;
    let winAnnualisedPremium = 0;
    let averageWinAnnualisedPremium = 0;


    trades.forEach((tradeData, index) => {
        if (tradeData.positionStatus === "CLOSED" && tradeData.tradeType === tradeType && tradeData.action === action && moment(tradeData.dateOpened).isAfter('2021-04-15')) {
            //check date was after 15th April;

            if (tradeData.realisedProfitAndLoss < 0) {
                loosingTrades.push(tradeData);

                //Total / Average Loss
                totalLoss += tradeData.realisedProfitAndLoss;
                averageLoss = totalLoss / loosingTrades.length;



                //Loss IV
                let initialIV = tradeData.initialIV;
                if (!isNaN(initialIV) && initialIV !== undefined) {
                    if (initialIV > 5) {
                        initialIV = initialIV / 100;
                        lossInitialIV += initialIV;
                    }
                    else {
                        lossInitialIV += initialIV;
                    }

                    lossInitialIVCount++;
                    averageLossInitialIV = lossInitialIV / lossInitialIVCount;
                }

                //Loss Delta
                let initialDelta = tradeData.initialDelta;

                // initialDelta = Math.abs(initialDelta); // Is this correct for new data?
                if (!isNaN(initialDelta) && initialDelta !== undefined) {
                    if (initialDelta > 5) {
                        initialDelta = initialDelta / 100;
                        lossInitialDelta += initialDelta;
                    }
                    else {
                        lossInitialDelta += initialDelta;
                    }

                    lossInitialDeltaCount++;

                    averageLossInitialDelta = lossInitialDelta / lossInitialDeltaCount;
                }

                //Loss Vega
                let initialVega = tradeData.initialVega;
                if (!isNaN(initialVega) && initialVega !== undefined) {
                    if (initialVega > 5) {
                        initialVega = initialVega / 100;
                        lossInitialVega += initialVega;
                    }
                    else {
                        lossInitialVega += initialVega;
                    }

                    lossInitialVegaCount++;

                    averageLossInitialVega = lossInitialVega / lossInitialVegaCount;
                }

                //Loss Theta
                let initialTheta = tradeData.initialTheta;

                if (!isNaN(initialTheta) && initialTheta !== undefined) {
                    if (initialTheta > 5) {
                        initialTheta = initialTheta / 100;
                        lossInitialTheta += initialTheta;
                    }
                    else {
                        lossInitialTheta += initialTheta;
                    }

                    lossInitialThetaCount++;

                    averageLossInitialTheta = lossInitialTheta / lossInitialThetaCount;
                }

                //Loss Rho
                let initialRho = tradeData.initialRho;

                if (!isNaN(initialRho) && initialRho !== undefined) {
                    if (initialRho > 5) {
                        initialRho = initialRho / 100;
                        lossInitialRho += initialRho;
                    }
                    else {
                        lossInitialRho += initialRho;
                    }

                    lossInitialRhoCount++;

                    averageLossInitialRho = lossInitialRho / lossInitialRhoCount;
                }

                //Loss DTE
                if (tradeData.expiryDate !== null && tradeData.dateOpened !== null && tradeData.dateClosed !== null) {
                    var dateOpened = moment(tradeData.dateOpened);
                    var dateClosed = moment(tradeData.dateClosed);
                    var expiryDate = moment(tradeData.expiryDate);


                    // console.log("Total time trade was open " + dateClosed.diff(dateOpened,'days'))

                    //Initial days to expiry
                    lossInitialDTE += expiryDate.diff(dateOpened, 'days');
                    lossInitialDTECount++;
                    averageLossInitialDTE = lossInitialDTE / lossInitialDTECount;

                    //Actual days open
                    lossInitialDTEOpen += dateClosed.diff(dateOpened, 'days');
                    lossInitialDTEOpenCount++;
                    averageLossInitialDTEOpen = lossInitialDTEOpen / lossInitialDTEOpenCount;


                    //initial premium
                    lossInitialPremiumCount++;
                    lossInitialPremium += tradeData.premium / tradeData.strikePrice;
                    averageLossInitialPremium = lossInitialPremium / lossInitialPremiumCount;


                    //annualised premium
                    lossAnnualisedPremiumCount++;
                    lossAnnualisedPremium += ((tradeData.premium / tradeData.strikePrice) / lossInitialDTEOpen) * 365;
                    averageLossAnnualisedPremium = lossAnnualisedPremium / lossAnnualisedPremiumCount;
                }


            }
            else {
                winningTrades.push(tradeData);

                //Total / Average Profit
                totalProfit += tradeData.realisedProfitAndLoss;
                averageProfit = totalProfit / winningTrades.length;


                ///Win IV
                let initialIV = tradeData.initialIV;

                if (!isNaN(initialIV) && initialIV !== undefined) {
                    if (initialIV > 5) {
                        initialIV = initialIV / 100;
                        winInitialIV += initialIV;
                    }
                    else {
                        winInitialIV += initialIV;
                    }

                    winInitialIVCount++;

                    averageWinInitialIV = winInitialIV / winInitialIVCount;
                }

                ///Win Delta
                let initialDelta = tradeData.initialDelta;
                // initialDelta = Math.abs(initialDelta); // Is this correct for new data?
                if (!isNaN(initialDelta) && initialDelta !== undefined) {
                    if (initialDelta > 5) {
                        initialDelta = initialDelta / 100;
                        winInitialDelta += initialDelta;
                    }
                    else {
                        winInitialDelta += initialDelta;
                    }

                    winInitialDeltaCount++;

                    averageWinInitialDelta = winInitialDelta / winInitialDeltaCount;
                }

                ///Win Vega
                let initialVega = tradeData.initialVega;
                if (!isNaN(initialVega) && initialVega !== undefined) {
                    if (initialVega > 5) {
                        initialVega = initialVega / 100;
                        winInitialVega += initialVega;
                    }
                    else {
                        winInitialVega += initialVega;
                    }

                    winInitialVegaCount++;

                    averageWinInitialVega = winInitialVega / winInitialVegaCount;
                }

                ///Win Theta
                let initialTheta = tradeData.initialTheta;
                if (!isNaN(initialTheta) && initialTheta !== undefined) {
                    if (initialTheta > 5) {
                        initialTheta = initialTheta / 100;
                        winInitialTheta += initialTheta;
                    }
                    else {
                        winInitialTheta += initialTheta;
                    }

                    winInitialThetaCount++;

                    averageWinInitialTheta = winInitialTheta / winInitialThetaCount;
                }

                ///Win Rho
                let initialRho = tradeData.initialRho;
                if (!isNaN(initialRho) && initialRho !== undefined) {
                    if (initialRho > 5) {
                        initialRho = initialRho / 100;
                        winInitialRho += initialRho;
                    }
                    else {
                        winInitialRho += initialRho;
                    }

                    winInitialRhoCount++;

                    averageWinInitialRho = winInitialRho / winInitialRhoCount;
                }

                //win DTE
                if (tradeData.expiryDate !== null && tradeData.dateOpened !== null && tradeData.dateClosed !== null) {
                    var dateOpened = moment(tradeData.dateOpened);
                    var dateClosed = moment(tradeData.dateClosed);
                    var expiryDate = moment(tradeData.expiryDate);


                    // console.log("Total time trade was open " + dateClosed.diff(dateOpened,'days'))

                    //Initial days to expiry
                    winInitialDTE += expiryDate.diff(dateOpened, 'days');
                    winInitialDTECount++;
                    averageWinInitialDTE = winInitialDTE / winInitialDTECount;

                    //Actual days open
                    winInitialDTEOpen += dateClosed.diff(dateOpened, 'days');
                    winInitialDTEOpenCount++;
                    averageWinInitialDTEOpen = winInitialDTEOpen / winInitialDTEOpenCount;


                    //initial premium
                    winInitialPremiumCount++;
                    winInitialPremium += tradeData.premium / tradeData.strikePrice;
                    averageWinInitialPremium = winInitialPremium / winInitialPremiumCount;


                    //annualised premium
                    winAnnualisedPremiumCount++;
                    winAnnualisedPremium += ((tradeData.premium / tradeData.strikePrice) / winInitialDTEOpen) * 365;
                    averageWinAnnualisedPremium = winAnnualisedPremium / winAnnualisedPremiumCount;
                }

            }
        }



    });

    if (formatted === true) {
        let array = [
            {
                type: 'card',
                change: 'increase',
                name: 'Total Profit',
                value: currencyFormatter.format(totalProfit * 100, { code: 'USD' }),
            },
            {
                type: 'card',
                change: 'increase',
                name: 'Average Profit',
                value: currencyFormatter.format(averageProfit * 100, { code: 'USD' })
            },
            {
                type: 'card',
                change: 'decrease',
                name: 'Total Loss',
                value: currencyFormatter.format(totalLoss * 100, { code: 'USD' })
            },
            {
                type: 'card',
                change: 'decrease',
                name: 'Average Loss',
                value: currencyFormatter.format(averageLoss * 100, { code: 'USD' })
            },
            {
                type: 'compare',
                name: 'IV',
                leftValue: (averageWinInitialIV * 100).toFixed(2) + "%",
                rightValue: (averageLossInitialIV * 100).toFixed(2) + "%",
                description: averageWinInitialIV > averageLossInitialIV ? 'You win more trades when IV is higher' : 'You win more trades when IV is lower.'
            },
            {
                type: 'compare',
                name: 'Delta',
                leftValue: (averageWinInitialDelta).toFixed(3),
                rightValue: (averageLossInitialDelta).toFixed(3),
                description: averageWinInitialDelta > averageLossInitialDelta ? 'You win more trades when delta is higher' : 'You win more trades when delta is lower'

            },
            {
                type: 'compare',
                name: 'Vega',
                leftValue: (averageWinInitialVega).toFixed(3),
                rightValue: (averageLossInitialVega).toFixed(3),
                description: averageWinInitialVega > averageLossInitialVega ? 'You win more trades when vega is higher.' : 'You win more trades when vega is lower'

            },
            {
                type: 'compare',
                name: 'Theta',
                leftValue: (averageWinInitialTheta).toFixed(3),
                rightValue: (averageLossInitialTheta).toFixed(3),
                description: averageWinInitialTheta > averageLossInitialTheta ? 'You win more trades when theta is higher' : 'You win more trades when theta is lower'

            },
            {
                type: 'compare',
                name: 'Rho',
                leftValue: (averageWinInitialRho).toFixed(3),
                rightValue: (averageLossInitialRho).toFixed(3),
                description: averageWinInitialRho > averageLossInitialRho ? 'You win more trades when rho is higher' : 'You win more trades when rho is lower'

            },
            {
                type: 'compare',
                name: 'DTE',
                leftValue: averageWinInitialDTE.toFixed(0),
                rightValue: averageLossInitialDTE.toFixed(0),
                description: averageWinInitialDTE > averageLossInitialDTE ? 'You win more trades when the initial DTE is higher' : 'You win more trades when the initial DTE is lower'

            },
            {
                type: 'compare',
                name: 'Days open',
                leftValue: averageWinInitialDTEOpen.toFixed(0),
                rightValue: averageLossInitialDTEOpen.toFixed(0),
                description: averageWinInitialDTEOpen > averageLossInitialDTEOpen ? 'You win more trades when you keep the trade open for longer' : 'You win more trades when you close your trades earlier'
            },
            {
                type: 'compare',
                name: 'Initial Return',
                leftValue: (averageWinInitialPremium * 100).toFixed(2) + "%",
                rightValue: (averageLossInitialPremium * 100).toFixed(2) + "%",
                description: averageWinInitialPremium > averageLossInitialPremium ? 'You win more trades when you have a higher initial premium' : 'You win more trades when you have a lower initial premium'

            },
            {
                type: 'compare',
                name: 'Initial Annualized Premium',
                leftValue: (averageWinAnnualisedPremium * 100).toFixed(2) + "%",
                rightValue: (averageLossAnnualisedPremium * 100).toFixed(2) + "%",
                description: averageWinAnnualisedPremium > averageLossAnnualisedPremium ? 'You win more trades when you have a higher annualized return premium' : 'You win more trades when you have a lower annualized return'

            },

        ];
        return array;

    }
    else {
        let array = [
            {
                name: 'IV',
                winAverage: averageWinInitialIV,
                lossAverage: averageLossInitialIV,
                direction: averageWinInitialIV > averageLossInitialIV ? 'more' : 'less'
            },
            {
                name: 'Delta',
                winAverage: averageWinInitialDelta,
                lossAverage: averageLossInitialDelta,
                direction: averageWinInitialDelta > averageLossInitialDelta ? 'more' : 'less'

            },
            {
                name: 'Vega',
                winAverage: averageWinInitialVega,
                lossAverage: averageLossInitialVega,
                direction: averageWinInitialVega > averageLossInitialVega ? 'more' : 'less'

            },
            {
                name: 'Theta',
                winAverage: averageWinInitialTheta,
                lossAverage: averageLossInitialTheta,
                direction: averageWinInitialTheta > averageLossInitialTheta ? 'more' : 'less'

            },
            {
                name: 'Rho',
                winAverage: averageWinInitialRho,
                lossAverage: averageLossInitialRho,
                direction: averageWinInitialRho > averageLossInitialRho ? 'more' : 'less'

            },
            {
                name: 'Initial DTE',
                winAverage: averageWinInitialDTE,
                lossAverage: averageLossInitialDTE,
                direction: averageWinInitialDTE > averageLossInitialDTE ? 'more' : 'less'

            },
            {
                name: 'Days open',
                winAverage: averageWinInitialDTEOpen,
                lossAverage: averageLossInitialDTEOpen,
                direction: averageWinInitialDTEOpen > averageLossInitialDTEOpen ? 'more' : 'less'
            },
            {
                name: 'Initial Premium',
                winAverage: averageWinInitialPremium,
                lossAverage: averageLossInitialPremium,
                direction: averageWinInitialPremium > averageLossInitialPremium ? 'more' : 'less'

            },
            {
                name: 'Initial Annualized Premium',
                winAverage: averageWinAnnualisedPremium,
                lossAverage: averageLossAnnualisedPremium,
                direction: averageWinAnnualisedPremium > averageLossAnnualisedPremium ? 'more' : 'less'

            },

        ];
        return array;
    }


}



export default { calculateGreeksComparison };