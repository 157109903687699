let APIEndpoint = "";
let APIEndpointProcessor = "";
let stripeKey = "";
let myUrl = "";
let domain = "";

if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "development") {
  console.log("Dev environment");
  APIEndpoint = "http://localhost:3123/api/v1";
  APIEndpointProcessor = "http://localhost:3123/api/v1";
  // stripeKey =
  //   "pk_test_51In46lIrJCrAOA7KLcokNMwugCHeYSdaZOzl3C79F3bworDni6zdIjQ251KDLkVp82qGuwpLAnvWTNo81opbUHfk00M4JFt3pF";
  stripeKey =
    "pk_live_51In46lIrJCrAOA7KdaDf0wZ2ahjiiOXdtY5Zx1DAXjbaDpxhPYgszVBopdthG9q8jr37OO6NkXyNNhHxcEpQ9u9100Dbt1H4Ml";
  myUrl = "http://localhost:3000";
  domain = "localhost";
} else {
  stripeKey =
    "pk_live_51In46lIrJCrAOA7KdaDf0wZ2ahjiiOXdtY5Zx1DAXjbaDpxhPYgszVBopdthG9q8jr37OO6NkXyNNhHxcEpQ9u9100Dbt1H4Ml";
  myUrl = "https://optiontracker.io";
  APIEndpoint = "https://api.optiontracker.io/api/v1";
  APIEndpointProcessor = "https://uploads.optiontracker.io/api/v1";
}

if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "development") {
  console.log("Dev environment");
  APIEndpoint = "http://localhost:3123/api/v1";
  APIEndpointProcessor = "http://localhost:3123/api/v1";
  stripeKey =
    "pk_live_51In46lIrJCrAOA7KdaDf0wZ2ahjiiOXdtY5Zx1DAXjbaDpxhPYgszVBopdthG9q8jr37OO6NkXyNNhHxcEpQ9u9100Dbt1H4Ml";
  myUrl = "http://localhost:3000";
  domain = "localhost";
} else {
  stripeKey =
    "pk_live_51In46lIrJCrAOA7KdaDf0wZ2ahjiiOXdtY5Zx1DAXjbaDpxhPYgszVBopdthG9q8jr37OO6NkXyNNhHxcEpQ9u9100Dbt1H4Ml";
  myUrl = "https://optiontracker.io";
  APIEndpoint = "https://api.optiontracker.io/api/v1";
  APIEndpointProcessor = "https://uploads.optiontracker.io/api/v1";

  if (typeof window !== "undefined") {
    if (window.location.hostname === "alpha.optiontracker.io") {
      APIEndpoint = "https://alpha-api.optiontracker.io/api/v1";
    }
  }
}

const defaultLayoutOld = {
  chartsConfig: [
    {
      id: "widgetNewsPanel",
      readableName: "News",
      description:
        "A list of news for your open positions sorted by most recent to oldest ",
      enabled: false,
      type: "Number",
    },
    {
      id: "journalWidget",
      readableName: "Journal / Calendar",
      description: "Displays all of your trades and charts in one screen",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetAiO",
      readableName: "All in One",
      description:
        "Month by month view of annualised, commission, win rate, P&L, and outstanding premium. ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAverageDeltaOpenTrades",
      readableName: "Average Delta",
      description: "Displays average delta on open trades  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetDividendPandL",
      readableName: "Dividend P&L",
      description: " Displays total P&L from dividends  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAverageWinVsAverageLoss",
      readableName: "Average Win vs Average Loss",
      description:
        " Displays your average wins in $ V.s. your average loss in $.  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetTagPerformance",
      readableName: "Tag Performance Table",
      description:
        " Displays a table of your different tags and their performance.  ",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetAnnualComparison",
      readableName: "Annual Comparison",
      description: " Shows prior years stats against this year  ",
      enabled: false,
      type: "Table",
    },

    {
      id: "TotalRiskWidgetChart",
      readableName: "Account Risk",
      description:
        " This chart tracks your total risk over time. It is updated every evening at 12PM.  ",
      enabled: false,
      type: "Chart",
    },
    {
      id: "accountBalanceWidgetChart",
      readableName: "Account Balance",
      description:
        " This chart tracks your balance over time. It is updated every evening at 12PM.  ",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetAnnualizedReturn",
      readableName: "Annualized Return (Open)",
      description:
        "The annualized return of your open trades. Used primarily with the wheel to calculate if you are meeting your targets.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAnnualizedReturnClosed",
      readableName: "Annualized Return (Closed)",
      description: "The annualized return of your closed trades. ",
      enabled: false,
      type: "Number",
    },
    // {
    //     "id": "widgetBalance",
    //     "readableName": "Balance",
    //     "description": "[initial portfolio value] + [realized P&L] + [cash]",
    //     "enabled": false,
    //     "type": "Number"
    // },
    {
      id: "widgetCashBalance",
      readableName: "Cash Balance",
      description:
        "[initial portfolio value] - [cost of trades] + [outstanding credits on open trades (not adjusted for P&L)]",
      enabled: false,
      type: "Number",
    },
    {
      id: "bestDaysClosed",
      readableName: "Days: Closed",
      description:
        "Displays performance of your trades based on day they were closed",
      enabled: false,
      type: "Chart",
    },
    {
      id: "bestDaysOpen",
      readableName: "Days: Open",
      description:
        "Displays performance of your trades based on day they were opened",
      enabled: false,
      type: "Chart",
    },
    {
      id: "diversificationTable",
      readableName: "Diversification Table",
      description:
        "A pie chart that shows your breakdown by symbol and then by sector. You can click on the sector to see a breakdown.",
      enabled: true,
      type: "Chart",
    },
    {
      id: "greekImpact",
      readableName: "Greek Impact",
      description:
        "A table that displays the relationship between greeks and your profits. ",
      enabled: false,
      type: "Table",
    },
    {
      id: "githubCalendar",
      readableName: "Trading Mini Calendar",
      description: " A mini calendar that shows your trading activity.  ",
      enabled: false,
      type: "Table",
    },
    {
      id: "loosing",
      readableName: "Losing",
      description: "Displays your top losing stock tickers by $ amount.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetOpenPositions",
      readableName: "Open Positions",
      description: "The number of open positions.",
      enabled: false,
      type: "Number",
    },
    {
      id: "optionAnalysis",
      readableName: "Option Analysis",
      description:
        "This displays how the greeks affect your trades and which greeks are making or losing trades for you.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetPotentialProfit",
      readableName: "Potential Profit",
      description:
        "This is the total premium on sold options that is not yet realized.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetPremiumPerDay",
      readableName: "Premium Per Day",
      description:
        "The total profit per day being earnt from 'sell to open' options.",
      enabled: false,
      type: "Number",
    },
    {
      id: "profitsByDate",
      readableName: "Profits By Date",
      description:
        "A scatter graph with trades plotted by date with P&L. You can click on a node to see the trade.",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetRealisedProfit",
      readableName: "Realised Profit",
      description: "Calculated from your closed trades.",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetPremiumVsTwelveMonthRisk",
      readableName: "Risk Adjusted 12M Return",
      description:
        " Measures the profit your investment relative to the risk represented over the past 12 months.  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "source",
      readableName: "Source",
      description: "Displays performance of your trade sources",
      enabled: false,
      type: "Chart",
    },
    {
      id: "strategy",
      readableName: "Strategy",
      description: "Displays performance of strategies",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetTav",
      readableName: "Total Account Value",
      description:
        "[Initial portfolio value] + [unrealized P&L] + [realized P&L] + [credits received on open trades] - [commission]",
      enabled: false,
      type: "Number",
    },
    {
      id: "insightAvgDTE",
      readableName: "Average DTE",
      description:
        "Displays the average number of days until expiry across all open options.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetTotalPremiumEarnt",
      readableName: "Total Premium Earnt",
      description:
        " Measures the profit your investment relative to the risk represented over the past 12 months. ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetTotalProfit",
      readableName: "Total Profit",
      description:
        " The combination of all of your closed trades and your unrealized profit. ",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetTotalRisk",
      readableName: "Total Risk",
      description: " Calcuated from the risk of all your open trades. ",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetTotalTrades",
      readableName: "Total Trades",
      description: "The total number of trades you have recorded.",
      enabled: false,
      type: "Number",
    },
    {
      id: "trades",
      readableName: "Trades",
      description:
        "A chart showing your performance over time. It can be configured. ",
      enabled: true,
      type: "Chart",
    },
    {
      id: "tradesTable",
      readableName: "Trades Table",
      description:
        "A table that shows your % return by month. You can click on the month to see a breakdown.",
      enabled: true,
      type: "Table",
    },
    {
      id: "uncoveredStock",
      readableName: "Uncovered Stock",
      description: "Displays if you have uncovered stock. ",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetUnrealisedPandL",
      readableName: "Unrealised Profit",
      description:
        "The total unrealized P&L from open trades. This uses delayed pricing and the option mid price.",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetWinRate",
      readableName: "Win Rate",
      description: "The % of trades that you have won.",
      enabled: false,
      type: "Percentage",
    },
    {
      id: "winning",
      readableName: "Winning",
      description: "Displays your top winning trades by $ amount.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetCommission",
      readableName: "Commission",
      description: "Displays your total commission.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetPutCashRequirement",
      readableName: "Put Cash Requirement",
      description:
        "Displays the total cash requirement for your put options if you were to exercise them today.",
      enabled: false,
      type: "Number",
    },
  ],
  layout: {
    lg: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetNewsPanel",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "journalWidget",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAnnualComparison",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 0,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 1,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 1,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetPutCashRequirement",
        moved: true,
        static: false,
      },
    ],
    md: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetNewsPanel",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "journalWidget",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 5,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 1,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 4,
        x: 2,
        y: 1,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 5,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    sm: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetNewsPanel",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "journalWidget",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 1,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 2,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 6,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 6,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    xs: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetNewsPanel",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "journalWidget",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 4,
        x: 0,
        y: 4,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 9,
        i: "tradesTable",
        moved: true,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 12,
        i: "diversificationTable",
        moved: true,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    xxs: [],
  },
};

const defaultLayout = {
  chartsConfig: [
    {
      id: "widgetAiO",
      readableName: "All in One",
      description:
        "Month by month view of annualised, commission, win rate, P&L, and outstanding premium. ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAverageDaysOpen",
      readableName: "Average days open",
      description: "The average number of days that a trad is open",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAverageDeltaOpenTrades",
      readableName: "Average Delta",
      description: "Displays average delta on open trades  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetDividendPandL",
      readableName: "Dividend P&L",
      description: " Displays total P&L from dividends  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAverageWinVsAverageLoss",
      readableName: "Average Win vs Average Loss",
      description:
        " Displays your average wins in $ V.s. your average loss in $.  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetNewsPanel",
      readableName: "News",
      description:
        "A list of news for your open positions sorted by most recent to oldest ",
      enabled: false,
      type: "Number",
    },
    {
      id: "journalWidget",
      readableName: "Journal / Calendar",
      description: "Displays all of your trades and charts in one screen",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetTagPerformance",
      readableName: "Tag Performance Table",
      description:
        " Displays a table of your different tags and their performance.  ",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetAnnualComparison",
      readableName: "Annual Comparison",
      description: " Shows prior years stats against this year  ",
      enabled: false,
      type: "Table",
    },
    {
      id: "TotalRiskWidgetChart",
      readableName: "Account Risk",
      description:
        " This chart tracks your total risk over time. It is updated every evening at 12PM.  ",
      enabled: false,
      type: "Chart",
    },
    {
      id: "accountBalanceWidgetChart",
      readableName: "Account Balance",
      description:
        " This chart tracks your balance over time. It is updated every evening at 12PM.  ",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetAnnualizedReturn",
      readableName: "Annualized Return (Open)",
      description:
        "The annualized return of your open trades. Used primarily with the wheel to calculate if you are meeting your targets.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetAnnualizedReturnClosed",
      readableName: "Annualized Return (Closed)",
      description: "The annualized return of your closed trades. ",
      enabled: false,
      type: "Number",
    },
    // {
    //     "id": "widgetBalance",
    //     "readableName": "Balance",
    //     "description": "[initial portfolio value] + [realized P&L] + [cash]",
    //     "enabled": false,
    //     "type": "Number"
    // },
    {
      id: "widgetCashBalance",
      readableName: "Cash Balance",
      description:
        "[initial portfolio value] - [cost of trades] + [outstanding credits on open trades (not adjusted for P&L)]",
      enabled: false,
      type: "Number",
    },
    {
      id: "bestDaysClosed",
      readableName: "Days: Closed",
      description:
        "Displays performance of your trades based on day they were closed",
      enabled: false,
      type: "Chart",
    },
    {
      id: "bestDaysOpen",
      readableName: "Days: Open",
      description:
        "Displays performance of your trades based on day they were opened",
      enabled: false,
      type: "Chart",
    },
    {
      id: "diversificationTable",
      readableName: "Diversification Table",
      description:
        "A pie chart that shows your breakdown by symbol and then by sector. You can click on the sector to see a breakdown.",
      enabled: true,
      type: "Chart",
    },
    {
      id: "greekImpact",
      readableName: "Greek Impact",
      description:
        "A table that displays the relationship between greeks and your profits. ",
      enabled: false,
      type: "Table",
    },
    {
      id: "githubCalendar",
      readableName: "Trading Mini Calendar",
      description: " A mini calendar that shows your trading activity.  ",
      enabled: false,
      type: "Table",
    },
    {
      id: "loosing",
      readableName: "Losing",
      description: "Displays your top stock tickers by $ amount.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetOpenPositions",
      readableName: "Open Positions",
      description: "The number of open positions.",
      enabled: false,
      type: "Number",
    },
    {
      id: "optionAnalysis",
      readableName: "Option Analysis",
      description:
        "This displays how the greeks affect your trades and which greeks are making or losing trades for you.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetPotentialProfit",
      readableName: "Potential Profit",
      description:
        "This is the total premium on sold options that is not yet realized.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetPremiumPerDay",
      readableName: "Premium Per Day",
      description:
        "The total profit per day being earnt from 'sell to open' options.",
      enabled: false,
      type: "Number",
    },
    {
      id: "profitsByDate",
      readableName: "Profits By Date",
      description:
        "A scatter graph with trades plotted by date with P&L. You can click on a node to see the trade.",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetRealisedProfit",
      readableName: "Realised Profit",
      description: "Calculated from your closed trades.",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetPremiumVsTwelveMonthRisk",
      readableName: "Risk Adjusted 12M Return",
      description:
        " Measures the profit your investment relative to the risk represented over the past 12 months.  ",
      enabled: false,
      type: "Number",
    },
    {
      id: "source",
      readableName: "Source",
      description: "Displays performance of your trade sources",
      enabled: false,
      type: "Chart",
    },
    {
      id: "strategy",
      readableName: "Strategy",
      description: "Displays performance of strategies",
      enabled: false,
      type: "Chart",
    },
    {
      id: "widgetTav",
      readableName: "Total Account Value",
      description:
        "[Initial portfolio value] + [unrealized P&L] + [realized P&L] + [credits received on open trades] - [commission]",
      enabled: false,
      type: "Number",
    },
    {
      id: "insightAvgDTE",
      readableName: "Average DTE",
      description:
        "Displays the average number of days until expiry across all open options.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetTotalPremiumEarnt",
      readableName: "Total Premium Earnt",
      description:
        " Measures the profit your investment relative to the risk represented over the past 12 months. ",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetTotalProfit",
      readableName: "Total Profit",
      description:
        " The combination of all of your closed trades and your unrealized profit. ",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetTotalRisk",
      readableName: "Total Risk",
      description: " Calcuated from the risk of all your open trades. ",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetTotalTrades",
      readableName: "Total Trades",
      description: "The total number of trades you have recorded.",
      enabled: false,
      type: "Number",
    },
    {
      id: "trades",
      readableName: "Trades",
      description:
        "A chart showing your performance over time. It can be configured. ",
      enabled: true,
      type: "Chart",
    },
    {
      id: "tradesTable",
      readableName: "Trades Table",
      description:
        "A table that shows your % return by month. You can click on the month to see a breakdown.",
      enabled: true,
      type: "Table",
    },
    {
      id: "uncoveredStock",
      readableName: "Uncovered Stock",
      description: "Displays if you have uncovered stock. ",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetUnrealisedPandL",
      readableName: "Unrealised Profit",
      description:
        "The total unrealized P&L from open trades. This uses delayed pricing and the option mid price.",
      enabled: true,
      type: "Number",
    },
    {
      id: "widgetWinRate",
      readableName: "Win Rate",
      description: "The % of trades that you have won.",
      enabled: false,
      type: "Percentage",
    },
    {
      id: "winning",
      readableName: "Winning",
      description: "Displays your top winning trades by $ amount.",
      enabled: false,
      type: "Table",
    },
    {
      id: "widgetCommission",
      readableName: "Commission",
      description: "Displays your total commission.",
      enabled: false,
      type: "Number",
    },
    {
      id: "widgetPutCashRequirement",
      readableName: "Put Cash Requirement",
      description:
        "Displays the total cash requirement for your put options if you were to exercise them today.",
      enabled: false,
      type: "Number",
    },
  ],
  layout: {
    lg: [
      {
        w: 8,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetAiO",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 0,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 1,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 1,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    md: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 5,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 1,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 4,
        x: 2,
        y: 1,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 5,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    sm: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 1,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 2,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 6,
        i: "tradesTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 6,
        i: "diversificationTable",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    xs: [
      {
        w: 2,
        h: 4,
        x: 1,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetAverageWinVsAverageLoss",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "widgetTotalProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "widgetRealisedProfit",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "widgetUnrealisedPandL",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "widgetTotalRisk",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 4,
        x: 0,
        y: 4,
        i: "trades",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 9,
        i: "tradesTable",
        moved: true,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 12,
        i: "diversificationTable",
        moved: true,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 1,
        i: "widgetCommission",
        moved: true,
        static: false,
      },
    ],
    xxs: [],
  },
};

const originalLayout = defaultLayout;
let customStrategies = [
  { label: "Calls", value: "Calls" },
  { label: "Puts", value: "Puts" },
  { label: "Cash Secured Put", value: "Cash Secured Put" },
  { label: "Covered Call", value: "Covered Call" },
  { label: "Covered Put", value: "Covered Put" },
  { label: "Vertical Call", value: "Vertical Call" },
  { label: "Vertical Put", value: "Vertical Put" },
  { label: "Calendar Call", value: "Calendar Call" },
  { label: "Calendar Put", value: "Calendar Put" },
  { label: "Diagonal Call", value: "Diagonal Call" },
  { label: "Diagonal Put", value: "Diagonal Put" },
  { label: "Ratio Call", value: "Ratio Call" },
  { label: "Ratio Put", value: "Ratio Put" },
  { label: "Butterfly Call", value: "Butterfly Call" },
  { label: "Butterfly Put", value: "Butterfly Put" },
  { label: "Condor Call", value: "Condor Call" },
  { label: "Condor Put", value: "Condor Put" },
  { label: "Iron Butterfly", value: "Iron Butterfly" },
  { label: "Iron Condor", value: "Iron Condor" },
  { label: "Buy Write", value: "Buy Write" },
  { label: "Sell Write", value: "Sell Write" },
  { label: "Straddle", value: "Straddle" },
  { label: "Strangle", value: "Strangle" },
  { label: "Collar", value: "Collar" },
  { label: "Collar with Equity", value: "Collar with Equity" },
  { label: "Synthetic", value: "Synthetic" },
  { label: "Rollout", value: "Rollout" },
  { label: "Rollout Spread", value: "Rollout Spread" },
  { label: "Rollout Straddle", value: "Rollout Straddle" },
  { label: "Wheel", value: "Wheel" },
  { label: "Custom", value: "Custom" },
  { label: "Scalp", value: "Scalp" },
  { label: "Long Term", value: "Long Term" },
  { label: "Short Term", value: "Short Term" },
  { label: "YOLO", value: "YOLO" },
  { label: "Double Calendar (Long)", value: "Double Calendar (Long)" },
  { label: "Double Calendar (Short)", value: "Double Calendar (Short)" },
  { label: "Double Diagonal (Long)", value: "Double Diagonal (Long)" },
  { label: "Double Diagonal (Short)", value: "Double Diagonal (Short)" },
  { label: "Future (Long)", value: "Future (Long)" },
  { label: "Future (Short)", value: "Future (Short)" },
  { label: "Future Calendar (Long)", value: "Future Calendar (Long)" },
  { label: "Future Calendar (Short)", value: "Future Calendar (Short)" },
  { label: "Iron Condor (Long)", value: "Iron Condor (Long)" },
  { label: "Iron Condor (Short)", value: "Iron Condor (Short)" },
  { label: "Iron Fly (Long)", value: "Iron Fly (Long)" },
  { label: "Iron Fly (Short)", value: "Iron Fly (Short)" },
  { label: "Jade Lizard (Long)", value: "Jade Lizard (Long)" },
  { label: "Jade Lizard (Short)", value: "Jade Lizard (Short)" },
  { label: "Naked (Long Call)", value: "Naked (Long Call)" },
  { label: "Naked (Long Put)", value: "Naked (Long Put)" },
  { label: "Naked (Short Call)", value: "Naked (Short Call)" },
  { label: "Naked (Short Put)", value: "Naked (Short Put)" },
  { label: "Ratio (Long Call)", value: "Ratio (Long Call)" },
  { label: "Ratio (Long Put)", value: "Ratio (Long Put)" },
  { label: "Ratio (Short Call)", value: "Ratio (Short Call)" },
  { label: "Ratio (Short Put)", value: "Ratio (Short Put)" },
  { label: "Rev Big Lizard (Long)", value: "Rev Big Lizard (Long)" },
  { label: "Rev Big Lizard (Short)", value: "Rev Big Lizard (Short)" },
  { label: "Rev Jade Lizard (Long)", value: "Rev Jade Lizard (Long)" },
  { label: "Rev Jade Lizard (Short)", value: "Rev Jade Lizard (Short)" },
  { label: "Risk Reversal (Long)", value: "Risk Reversal (Long)" },
  { label: "Stock (Long)", value: "Stock (Long)" },
  { label: "Stock (Short)", value: "Stock (Short)" },
  { label: "Straddle (Long)", value: "Straddle (Long)" },
  { label: "Straddle (Short)", value: "Straddle (Short)" },
  { label: "Strangle (Long)", value: "Strangle (Long)" },
  { label: "Strangle (Short)", value: "Strangle (Short)" },
  { label: "Synthetic (Long)", value: "Synthetic (Long)" },
  { label: "Synthetic (Short)", value: "Synthetic (Short)" },
  {
    label: "Unbalanced Butterfly (Long Call)",
    value: "Unbalanced Butterfly (Long Call)",
  },
  {
    label: "Unbalanced Butterfly (Long Put)",
    value: "Unbalanced Butterfly (Long Put)",
  },
  {
    label: "Unbalanced Butterfly (Short Call)",
    value: "Unbalanced Butterfly (Short Call)",
  },
  {
    label: "Unbalanced Butterfly (Short Put)",
    value: "Unbalanced Butterfly (Short Put)",
  },
  { label: "Vertical (Long Call)", value: "Vertical (Long Call)" },
  { label: "Vertical (Long Put)", value: "Vertical (Long Put)" },
  { label: "Vertical (Short Call)", value: "Vertical (Short Call)" },
  { label: "Vertical (Short Put)", value: "Vertical (Short Put)" },
  { label: "Big Lizard (Long)", value: "Big Lizard (Long)" },
  { label: "Big Lizard (Short)", value: "Big Lizard (Short)" },
  {
    label: "Broken Wing Butterfly (Long Call)",
    value: "Broken Wing Butterfly (Long Call)",
  },
  {
    label: "Broken Wing Butterfly (Long Put)",
    value: "Broken Wing Butterfly (Long Put)",
  },
  {
    label: "Broken Wing Butterfly (Short Call)",
    value: "Broken Wing Butterfly (Short Call)",
  },
  {
    label: "Broken Wing Butterfly (Short Put)",
    value: "Broken Wing Butterfly (Short Put)",
  },
  { label: "Butterfly (Long Call)", value: "Butterfly (Long Call)" },
  { label: "Butterfly (Long Put)", value: "Butterfly (Long Put)" },
  { label: "Butterfly (Short Call)", value: "Butterfly (Short Call)" },
  { label: "Butterfly (Short Put)", value: "Butterfly (Short Put)" },
  { label: "Calendar (Long Call)", value: "Calendar (Long Call)" },
  { label: "Calendar (Long Put)", value: "Calendar (Long Put)" },
  { label: "Calendar (Short Call)", value: "Calendar (Short Call)" },
  { label: "Calendar (Short Put)", value: "Calendar (Short Put)" },
  { label: "Collar (Long)", value: "Collar (Long)" },
  { label: "Collar (Short)", value: "Collar (Short)" },
  { label: "Condor (Call)", value: "Condor (Call)" },
  { label: "Condor (Put)", value: "Condor (Put)" },
  { label: "Covered (Call)", value: "Covered (Call)" },
  { label: "Covered (Put)", value: "Covered (Put)" },
  { label: "Covered Strangle (Long)", value: "Covered Strangle (Long)" },
  { label: "Covered Strangle (Short)", value: "Covered Strangle (Short)" },
  { label: "Crypto (Long)", value: "Crypto (Long)" },
  { label: "Crypto (Short)", value: "Crypto (Short)" },
  { label: "Diagonal (Long Call)", value: "Diagonal (Long Call)" },
  { label: "Diagonal (Long Put)", value: "Diagonal (Long Put)" },
  { label: "Diagonal (Short Call)", value: "Diagonal (Short Call)" },
  { label: "Diagonal (Short Put)", value: "Diagonal (Short Put)" },
];
export {
  defaultLayout,
  originalLayout,
  customStrategies,
  APIEndpoint,
  APIEndpointProcessor,
  stripeKey,
  myUrl,
  domain,
  defaultLayoutOld,
};
