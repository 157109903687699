import React, { Component, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { IconButton, Spinner, Box } from "@chakra-ui/react";
import { IoDocumentTextOutline, IoDocumentText } from "react-icons/io5"
import { Textarea, Modal, Tooltip, Text, Button, useDisclosure, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, } from "@chakra-ui/react";
import API from "../../helpers/apiCalls";
import "./NotesArea.css";
import { CustomTextArea } from "../../../design_library";

class NotesTextArea extends Component {

    constructor() {
        super();
    }

    render() {
        if (this.props.data.canExpand !== true) {
            let notes = this.props.data.notes;
            if (this.props.shared === true) {
                return <></>
            }
            else {
                return (
                    <ModalComponent APIEndpoint={this.props.settings.APIEndpoint} notes={notes} tradeData={this.props.data} />
                )
            }
        }
        else {
            return '';
        }
    }
}

function ModalComponent(props) {

    const [notes, setNotes] = useState(props.notes);
    const [loading, setLoading] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [didMount, setDidMount] = useState(false)
    useEffect(() => { setDidMount(true) }, [])
    useEffect(() => {
        if (didMount) {
            clearTimeout(typingTimeout);
            setTypingTimeout(setTimeout(() => {
                saveText();
            }, 250));
        }
    }, [notes]);

    const saveText = () => {
        setLoading(true);
        let payload = props.tradeData;
        payload.notes = notes;
        payload.skipRecalculation = true;

        API.updateTrade(props.APIEndpoint, payload).then((res) => {
            if (res.success === false) {
                if (res.message) {
                    alert(res.message);
                }
                else {
                    alert(res.msg);
                }
                setLoading(false);
            }
            else {
                setLoading(false);

            }
        })
    }

    const handleChange = (e) => {
        setNotes(e.target.value);
    }





    return (
        <Box>
            <CustomTextArea
                minHeight={'100px'}
                value={notes}
                onChange={(e) => { handleChange(e); }}
                placeholder="Enter any notes here"
                size="sm"
            />
        </Box>
    )
}


const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};

export default connect(mapStateToProps)(NotesTextArea);
