import { Table, useColorMode } from '@chakra-ui/react';
import React from 'react';
import colors from '../../helpers/colors';

export const TableFrame = (props) => {
    const { colorMode } = useColorMode();
    return (
        <Table
            {...props}

            sx={
                {

                    scrollbarWidth: 'none',
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }
            }

        >
            {props.children}
        </Table >
    )
}