import { APIEndpoint, domain, myUrl, stripeKey } from "../../platform/helpers/dataStructures";


const defaultState = {
  host: myUrl,
  domain: domain,
  APIEndpoint: APIEndpoint,
  title: "App",
  stripeKey: stripeKey,
  userData: {},
  profile: {
    email: "",
    websiteBeingProvisioned: false,
  },
  loggedInData: {},
  loggedInData: {
    loggedIn: false,
    subscriptionDetails: {},
    APIStatus: true,
  },
  trades: [],
  changingAccount: false,
  ephemeralTradeStore: []

};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'PROFILE': {
      state.profile = action.profile
      return { ...state };
    }
    case 'SET_USER_DATA': {
      state.userData = action.userData
      return { ...state, userData: action.userData };
    }
    case 'CHANGING_ACCOUNT': {
      return { ...state, changingAccount: action.changingAccount };
    }
    case 'SET_TRADES': {
      state.trades = action.trades
      return { ...state };
    }
    case 'SET_EMPHEMERAL_TRADES': {
      state.ephemeralTradeStore = action.trades
      return { ...state };
    }
    case 'SUBSCRIPTION': {
      state.loggedInData.subscriptionDetails = action.subscriptionDetails
      return { ...state };
    }
    case 'CLEAR_SUBSCRIPTION_DATA': {
      state.loggedInData.subscriptionDetails = {}
      return { ...state };
    }
    case 'WEBSITES': {
      state.loggedInData.websites = action.websites
      return { ...state };
    }
    case 'CLEAR_LOGGEDIN_DATA': {
      // state.loggedInData = {
      //   loggedIn:false,
      //   subscriptionDetails:{},
      //   APIStatus:true,
      //   websiteBeingProvisioningStage:''
      // };
      state = {};
      return { ...state };
    }
    case 'SET_LOGGED_IN': {
      state.loggedInData.loggedIn = true;
      return { ...state };
    }
    case 'API_STATUS': {
      state.loggedInData.APIStatus = action.status;
      return { ...state };
    }
    default:
      return state;
  }
}
