import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import './markdown.css';
import './style.css';
import API from '../helpers/apiCalls';
import { connect } from 'react-redux';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Link, FormLabel, Textarea, Stack, Icon, Flex, Button, Text, Box, Image, HStack, VStack, Divider, SimpleGrid } from "@chakra-ui/react"
import { InfoIcon, SearchIcon } from '@chakra-ui/icons';
import { IoNewspaperOutline } from 'react-icons/io5';
import queryString from 'query-string';
import lunr from 'lunr'
import moment from 'moment';
import { SkeletonText } from "@chakra-ui/react"
import { uuid } from 'uuidv4';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import Pricing from '../../homepage/pricing/App'
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Helmet } from "react-helmet";
import markdownToTxt from 'markdown-to-txt';
import { ChevronLeft, ChevronRight, NegativeButton, PrimaryButton, RightArrowIcon, SecondaryButton, TextInput } from '../../design_library';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from '@chakra-ui/react'
import { PricingCard } from '../dashboard/NewUserPage/PricingCard';
import apiCalls from '../helpers/apiCalls';
import amplitude from '../helpers/amplitude';
import Meta from '../../helpers/meta';
if (typeof String.prototype.replaceAll == "undefined") {
    String.prototype.replaceAll = function (match, replace) {
        return this.replace(new RegExp(match, 'g'), () => replace);
    }
}


class FAQ extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialFAQ: [],
            faqs: [],
            loading: false,
            selectedItem: '',
            title: '',
            description: '',
            featuredImage: '',
            activeMarkdown: '',
            activeArticle: {},
            type: props.type,
            feedbackProvided: false,
            feedbackProvidedPositive: false,
            feedbackProvidedNegative: false,
            isAdmin: false,
            viewMarkdown: false,
            isSaving: false,
            editTitle: '',
            editDescription: '',
            editFeaturedImage: '',
            editMarkdown: '',
            selectedTab: 'write',
            page: 0,
            pages: 0,

            trial_days: 30,
            trial_days_loading: true,
        }
    }

    componentDidMount() {
        this.setState({ loading: true });


        let page = parseInt(window.location.search.replace('?page=', ''));
        if (isNaN(page)) {
            page = 1;
        }
        this.setState({ page: page }, () => {
            if (this.props.settings.profile.role === 'admin') {
                this.setState({
                    isAdmin: true
                });
            }

            if (this.props.articleId && this.props.articleId !== undefined && this.props.articleId.length > 0) {
                console.log("Getting specific article...")
                this.getSpecificArticle();
            }
            else {
                console.log("Getting all articles...")

                this.getArticles();

            }

        });





        if (navigator.userAgent !== 'ReactSnap') {
            apiCalls.getSubscriptionMetaData().then(res => {
                this.setState({
                    trial_days: res.trial_period_days,
                    trial_days_loading: false,
                    promo: res.sale,
                    loading: false
                })

            }).catch((e) => {
                // console.log(e);
                this.setState({
                    trial_days: 30,
                    trial_days_loading: false,
                    promo: { active: false },
                    loading: false
                })
            })

        }
        else {

            this.setState({
                trial_days: 30,
                trial_days_loading: false,
                promo: { active: false },
                loading: false
            })
        }

    }

    getSpecificArticle = () => {


        API.getSpecificArticle(this.props.articleId).then((res) => {
            if (res.success === true) {
                this.setState({ faqs: res.data, initialFAQ: res.data, loading: false });


                if (this.props.articleId && this.props.articleId.length > 0) {
                    let articleId = this.props.articleId;
                    let article = res.data.filter((value) => {
                        let title = value.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '_');
                        if (title === articleId.toLowerCase()) {
                            return value.markdown;
                        }
                    });
                    if (article.length > 0) {
                        this.setState({ selectedItem: this.state.faqs[0]._id, authorName: article[0].authorName, activeMarkdown: article[0].markdown, featuredImage: article[0].featuredImage, title: article[0].title, description: article[0].description, created: article[0].created })
                    }
                }

                else {
                    this.setState({ authorName: this.state.faqs[0].authorName, activeMarkdown: this.state.faqs[0].markdown, created: this.state.faqs[0].created })

                }
            }
            else {
                this.setState({ faqs: '', loading: false });
                alert("Could not get the FAQ library!")
            }
        });

    }

    getArticles = () => {
        if (this.props.settings.profile.role === 'admin') {

            API.getArticlesAdmin(this.props.settings.APIEndpoint, this.state.type).then((res) => {
                if (res.success === true) {
                    this.setState({ faqs: res.data, initialFAQ: res.data, loading: false });
                    if (this.props.articleId && this.props.articleId.length > 0) {
                        let articleId = this.props.articleId;


                        let article = res.data.filter((value) => {

                            let title = value.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '_');
                            if (title === articleId.toLowerCase()) {
                                return value.markdown;
                            }
                        });


                        if (article.length > 0) {
                            this.setState({ activeMarkdown: article[0].markdown, featuredImage: article[0].featuredImage, title: article[0].title, description: article[0].description, created: article[0].created })
                        }
                    }

                    else {
                        this.setState({ activeMarkdown: this.state.faqs[0].markdown, created: this.state.faqs[0].created })

                    }
                }
                else {
                    this.setState({ faqs: '', loading: false });
                    alert("Could not get the FAQ library!")
                }
            });
        }
        else {

            API.getArticles(this.props.settings.APIEndpoint, this.state.type, this.state.page).then((res) => {
                if (res.success === true) {
                    // console.log(res)
                    this.setState({ pages: res.pages, page: res.page, faqs: res.data, initialFAQ: res.data, loading: false });


                    if (this.props.articleId && this.props.articleId.length > 0) {
                        let articleId = this.props.articleId;
                        let article = res.data.filter((value) => {
                            let title = value.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '_');
                            if (title === articleId.toLowerCase()) {
                                return value.markdown;
                            }
                        });
                        if (article.length > 0) {
                            this.setState({ selectedItem: this.state.faqs[0]._id, authorName: article[0].authorName, activeMarkdown: article[0].markdown, featuredImage: article[0].featuredImage, title: article[0].title, description: article[0].description, created: article[0].created })
                        }
                    }

                    else {
                        this.setState({ authorName: this.state.faqs[0].authorName, activeMarkdown: this.state.faqs[0].markdown, created: this.state.faqs[0].created })

                    }
                }
                else {
                    this.setState({ faqs: '', loading: false });
                    alert("Could not get the FAQ library!")
                }
            });
        }

    }



    updateArticle = () => {
        this.setState({ isSaving: true });
        let id = this.state.selectedItem;
        let selectedObject = this.state.faqs.filter((value) => {
            if (value._id === id) {
                return value;
            }
        });

        let object = selectedObject[0];
        object.updated = Date.now();
        object.title = this.state.editTitle;
        object.description = this.state.editDescription;
        object.featuredImage = this.state.editFeaturedImage;
        object.markdown = this.state.editMarkdown;
        object.authorName = this.props.settings.profile.name;

        if (object.new && object.new === true) {
            API.createArticle(this.props.settings.APIEndpoint, object).then((data) => {
                if (data.success === false) {
                    this.setState({ isSaving: false });
                    alert("Could not save.");
                    // console.log(data);
                }
                else {
                    this.setState({ isSaving: false, authorName: data.data.authorName, viewMarkdown: false, activeMarkdown: data.data.markdown, title: data.data.title, description: data.data.description });
                    this.getArticles();
                }
            })
        }
        else {
            API.updateArticle(this.props.settings.APIEndpoint, object._id, object).then((data) => {
                if (data.success === false) {
                    this.setState({ isSaving: false });
                    alert("Could not save.");
                    // console.log(data);

                }
                else {
                    this.setState({ isSaving: false, viewMarkdown: false, authorName: data.data.authorName, activeMarkdown: data.data.markdown, title: data.data.title, description: data.data.description, featuredImage: data.data.featuredImage });
                    this.getArticles();
                }
            })

        }
    }



    search = (text) => {
        let articles = this.state.initialFAQ;
        var idx = lunr(function () {
            this.field('title');
            this.field('description');
            // this.field('markdown');
            this.ref('_id')

            articles.forEach(function (doc) {
                this.add(doc)
            }, this)
        });

        let search = idx.search('*' + text + '*');

        let array = [];

        search.filter((value) => {
            array.push(value.ref);
        });
        let newList = [];
        this.state.initialFAQ.filter((value, index) => {
            if (array.includes(value._id)) {
                newList.push(value);
            }
        })
        this.setState({ faqs: newList });
    }

    selectArticle = (value) => {



        let title = value.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '_');
        this.setState({ selectedItem: value._id, activeMarkdown: value.markdown, authorName: value.authorName, title: value.title, description: value.description, created: value.created, featuredImage: value.featuredImage });

        if (window.location.pathname.includes('app/') === true) {
            this.props.history.push({
                pathname: this.props.type === 'NEWS' ? '/app/news/' + title : '/app/faq/' + title,
            });
        }
        else {
            this.props.history.push({
                pathname: this.props.type === 'NEWS' ? '/news/' + title : '/faq/' + title,
            });
        }

        if (this.state.isAdmin === false) {
            API.articleView(this.props.settings.APIEndpoint, value._id);
        }


    }

    deleteArticle = () => {

        if (window.confirm('Are you sure you want to delete this article?')) {
            this.setState({ isSaving: true });
            let id = this.state.selectedItem;
            let selectedObject = this.state.faqs.filter((value) => {
                if (value._id === id) {
                    return value;
                }
            });



            let object = selectedObject[0];
            API.deleteArticle(this.props.settings.APIEndpoint, object._id).then((data) => {
                if (data.success === false) {
                    this.setState({ isSaving: false });
                    alert("Could not delete.");
                    // console.log(data);
                }
                else {
                    this.setState({ isSaving: false, viewMarkdown: false, activeMarkdown: '', title: '', description: '' });
                    this.getArticles();
                }
            })
        }

    }

    vote = (direction) => {
        if (direction === 'UP') {
            this.setState({ feedbackProvidedPositive: true });
            API.voteOnArticle(this.props.settings.APIEndpoint, this.state.selectedItem, direction);
        }
        else {
            this.setState({ feedbackProvidedNegative: true });
            API.voteOnArticle(this.props.settings.APIEndpoint, this.state.selectedItem, direction);
            alert("Please let us know in the chat how you think can improve?")
            // window.Intercom('show');
        }

        this.setState({ feedbackProvided: true });
    }

    addArticle = () => {
        let articles = this.state.faqs;
        articles.reverse();
        let id = uuid();
        let obj = {
            "created": Date.now(),
            "helpfulVotes": "0",
            "mehVotes": "0",
            "unhelpfulVotes": "0",
            "viewCount": "0",
            "_id": id,
            "description": "Description goes here",
            "markdown": "Markdown goes here",
            "title": "Title goes here",
            "type": this.props.type,
            "updated": Date.now(),
            new: true,
        }
        articles.push(obj);
        articles.reverse();

        this.setState({ faqs: articles, selectedItem: id, viewMarkdown: true, editTitle: obj.title, editDescription: obj.description, editMarkdown: obj.markdown, activeMarkdown: obj.markdown, editFeaturedImage: obj.featuredImage });
    }

    render() {

        let url = [];

        if (this.state.activeMarkdown.length > 0) {
            try {
                url = this.state.activeMarkdown.match(/!\[.*?\]\((.*?)\)/)[1];
            }
            catch (e) {

            }
        }

        let ldJson = {};

        if (this.props.type === 'NEWS') {
            ldJson = {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://optiontracker.io/news/" + this.state.title.replaceAll('/', '_').replaceAll(' ', '-').toLowerCase()
                },
                "headline": this.state.title,
                "image": this.state.featuredImage,
                "author": {
                    "@type": "Organization",
                    "name": "Option Tracker",
                    "url": "https://optiontracker.io/"
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "Option Tracker",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://optiontracker.io/images/sigma-black-large.png"
                    }
                },
                "datePublished": moment(this.state.created).format('YYYY-MM-DD')
            }
        }
        else {
            ldJson = {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": {
                    "@type": "Question",
                    "name": this.state.title,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": this.state.description
                    }
                }
            }

        }

        let extraDesc = '';
        if (this.state.description.length < 110) {
            extraDesc = ". You can read more on Option Tracker."

        }

        return (
            <div >
                <Flex flex="1" pt="4" className="barX" justifyContent={this.props.mode === 'DARK' ? "center" : ""} >
                    {!this.state.selectedItem &&
                        < Flex
                            direction="column"
                            maxW="1400px"
                            width="100%"
                        >
                            {/* {!this.state.selectedItem &&
                                <Box maxW={'300px'}>

                                    <TextInput placeholder="Search" size="lg" maxWidth="300px" autoFocus onChange={(e) => {
                                        this.search(e.target.value);
                                    }} />
                                </Box>
                            } */}
                            <Box display="flex" flexDir="column" overflow="scroll" mt="4"
                                borderTopRightRadius={'6px'}
                            >

                                {this.state.loading && this.state.faqs.length === 0 &&
                                    <>
                                        <Stack>
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                        </Stack>

                                    </>}

                                {this.state.faqs[0] &&
                                    <ListItem isAdmin={this.state.isAdmin} type={this.state.type} selectedItem={this.state.selectedItem} value={this.state.faqs[0]} selectArticle={this.selectArticle} />
                                }

                                <SimpleGrid minChildWidth="360px" spacing="20px" columns={'2'}>

                                    {this.state.faqs && this.state.faqs.map((value, index) => {
                                        if (index === 0) {
                                            return null;
                                        }
                                        return (
                                            <ListItem isAdmin={this.state.isAdmin} type={this.state.type} selectedItem={this.state.selectedItem} key={index} value={value} selectArticle={this.selectArticle} />
                                        )
                                    })}
                                </SimpleGrid>
                            </Box>

                            {this.state.faqs && this.state.faqs.map((value, index) => {
                                if (index === 0) {
                                    return null;
                                }
                                return (
                                    <a style={{ display: 'none' }} href={window.location.origin + "/news/" + value.title.replaceAll('/', '_').replaceAll(' ', '-').toLowerCase()}>{value.title}</a>
                                )
                            })}

                        </Flex>
                    }
                    {this.state.selectedItem &&
                        <Flex flex="1" px="4" display="flex" flexDir="column" maxW="100%" alignItems={'center'}
                            paddingTop="20px"
                        >



                            <Box h="full" w="100%" overflow="scroll" className="markdown-body" >
                                <Breadcrumb className="customBreadcrumb" spacing='8px' separator={<RightArrowIcon stroke="white" />} >
                                    <BreadcrumbItem>
                                        <BreadcrumbLink href='../news'>News</BreadcrumbLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem isCurrentPage>
                                        <BreadcrumbLink href='#'>{this.state.title}</BreadcrumbLink>
                                    </BreadcrumbItem>
                                </Breadcrumb>

                                {this.state.loading && this.state.faqs.length === 0 &&
                                    <>
                                        <Stack>
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                            <SkeletonText height="60px" />
                                        </Stack>
                                    </>}

                                {this.state.isAdmin === true && this.state.viewMarkdown === false ?
                                    <>

                                        <Meta
                                            title={this.state.title || this.props.type === 'NEWS' ? 'News' : 'FAQ'}
                                            description={!this.props.match.params.articleId ?
                                                "The Option Tracker news page. We publish articles about what's new on Option Tracker here."
                                                :
                                                this.state.description + extraDesc
                                            }
                                            canonical={"https://optiontracker.io/news/" + this.state.title.replaceAll('/', '_').replaceAll(' ', '-').toLowerCase()}
                                            script={<script type="application/ld+json">
                                                {JSON.stringify(ldJson)}
                                            </script>}
                                        />



                                        <Text fontSize={'34px'}>{this.state.title}</Text>
                                        <Text fontSize={'18px'}>{this.state.description}</Text>
                                        <Box display={'flex'} justifyContent='center' alignItems={'center'} paddingBottom="20px">
                                            {this.state.featuredImage && <Image src={this.state.featuredImage} minW="100%" />}
                                        </Box>
                                        <SocialBar name={this.state.authorName} updated={this.state.updated} created={this.state.created} />
                                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkToc]}>{this.state.activeMarkdown}</ReactMarkdown>



                                        {/* {this.state.faqs.length !== 0 && <ReactMarkdown remarkPlugins={[remarkGfm, remarkToc]} > {this.state.activeMarkdown} </ReactMarkdown>} */}
                                    </>
                                    :
                                    <>
                                        {this.state.isAdmin === true &&
                                            <>
                                                {this.state.faqs.length !== 0 &&
                                                    <>
                                                        <FormLabel as="legend">Title:</FormLabel>
                                                        <Textarea onChange={(e) => {
                                                            this.setState({ editTitle: e.target.value });
                                                        }}>
                                                            {this.state.editTitle}
                                                        </Textarea>
                                                        <FormLabel as="legend">Description:</FormLabel>
                                                        <Textarea onChange={(e) => {
                                                            this.setState({ editDescription: e.target.value });
                                                        }}>
                                                            {this.state.editDescription}
                                                        </Textarea>

                                                        <FormLabel as="legend">Featured Image (URL):</FormLabel>
                                                        <Textarea onChange={(e) => {
                                                            this.setState({ editFeaturedImage: e.target.value });
                                                        }}>
                                                            {this.state.editFeaturedImage}
                                                        </Textarea>




                                                        <ReactMde
                                                            value={this.state.editMarkdown}
                                                            onChange={(e) => {
                                                                this.setState({ editMarkdown: e });
                                                            }}
                                                            selectedTab={this.state.selectedTab}
                                                            onTabChange={(tab) => this.setState({ selectedTab: tab })}
                                                            generateMarkdownPreview={(markdown) =>
                                                                Promise.resolve(<ReactMarkdown>{this.state.editMarkdown}</ReactMarkdown>)
                                                            }
                                                            childProps={{
                                                                writeButton: {
                                                                    tabIndex: -1
                                                                }
                                                            }}
                                                        />



                                                    </>
                                                }
                                            </>
                                        }


                                    </>
                                }

                                {this.state.isAdmin === false &&
                                    <>
                                        {this.state.faqs.length !== 0 &&

                                            <>

                                                {this.state.title.length > 0 &&
                                                    <Meta
                                                        title={this.state.title}
                                                        description={this.state.description + extraDesc}
                                                        canonical={"https://optiontracker.io/news/" + this.state.title.replaceAll('/', '_').replaceAll(' ', '-').toLowerCase()}
                                                        type="article"
                                                        script={<script type="application/ld+json">
                                                            {JSON.stringify(ldJson)}
                                                        </script>}
                                                    />

                                                }
                                                <Text fontSize={'34px'} as="h1">{this.state.title}</Text>
                                                <Text fontSize={'18px'} as="h2">{this.state.description}</Text>
                                                <Box display={'flex'} justifyContent='center' alignItems={'center'} paddingBottom="20px">
                                                    {this.state.featuredImage && <Image src={this.state.featuredImage} minW="100%" />}
                                                </Box>
                                                <SocialBar name={this.state.authorName} updated={this.state.updated} created={this.state.created} />
                                                <ReactMarkdown remarkPlugins={[remarkGfm, remarkToc]}>{this.state.activeMarkdown}</ReactMarkdown>


                                            </>
                                        }
                                    </>}



                                {this.state.loading === false &&
                                    <>
                                        {this.state.feedbackProvided === false ?
                                            <Box display="flex" flexDir="row" h="5vh" justifyContent="center" alignItems="center">
                                                <Text fontStyle="italic" fontWeight="400" textAlign="right" paddingTop="15px">{this.state.type === 'FAQ' ? 'Was this article helpful?' : 'Did you find this interesting?'}</Text>
                                                <Box onClick={() => this.vote('UP')} cursor="pointer" ml="3" fontSize="26px">😁</Box>
                                                <Box onClick={() => this.vote('MEH')} cursor="pointer" ml="3" fontSize="26px">😐</Box>
                                                <Box onClick={() => this.vote('DOWN')} cursor="pointer" ml="3" fontSize="26px">😢</Box>
                                            </Box>
                                            :
                                            <Box display="flex" h="5vh" justifyContent="center" alignItems="center">
                                                <Text textAlign="center">Thank you for your feedback.</Text>
                                            </Box>
                                        }
                                    </>
                                }


                                <Box >
                                    <Pricing

                                        amplitude={amplitude}
                                        trial_days={{
                                            days: this.state.trial_days,
                                            loading: this.state.trial_days_loading
                                        }}
                                    />
                                </Box>

                            </Box>




                        </Flex>
                    }



                </Flex>

                <Box>
                    {/* <HStack padding="20px">
                        <Button onClick={() => {
                            this.setState({
                                page: this.state.page - 1
                            }, () => {
                                this.getArticles();
                            });
                        }}>Previous</Button>
                        <Text>Page: {parseInt(this.state.page + 1)} of {Math.floor(this.state.pages)}</Text>
                        <Button onClick={() => {
                            this.setState({
                                page: this.state.page + 1
                            }, () => {
                                this.getArticles();
                            });
                        }}>Next</Button>
                    </HStack> */}

                    {this.state.pages !== 0 &&
                        <HStack padding="20px" justifyContent={'center'}>

                            {this.state.page > 1 && <a className="articleArrow" aria-label='previous page' href={"../news?page=" + Math.floor(parseInt(this.state.page) - 1)} >
                                <ChevronLeft />
                            </a>}
                            <Text className="articlePages">{parseInt(this.state.page)} of {Math.floor(this.state.pages - 1)}</Text>

                            {Array(this.state.pages - 1).map((index) => {
                                return <Text>{index}</Text>
                            })}
                            {this.state.page < this.state.pages - 1 && <a aria-label='next page' className="articleArrow2" href={"../news?page=" + (parseFloat(this.state.page) + 1)} ><ChevronRight /> </a>}
                        </HStack>
                    }




                </Box>

                {/* Admin panel */}
                {
                    this.state.isAdmin && this.state.selectedItem &&
                    <Box zIndex="999" position="fixed" bottom="10px" right="10px" borderRadius="8px" padding="5px" bg="var(--bg)">
                        {this.state.viewMarkdown === true ? <SecondaryButton onClick={() => this.setState({ viewMarkdown: false })}>Display markdown</SecondaryButton> : <SecondaryButton onClick={() => {
                            let selectedObject = this.state.faqs.find(x => x._id === this.state.selectedItem);
                            this.setState({ viewMarkdown: true, editTitle: selectedObject.title, editFeaturedImage: selectedObject.featuredImage, editMarkdown: selectedObject.markdown, editDescription: selectedObject.description });
                        }}>Display preview</SecondaryButton>}

                        {this.state.viewMarkdown === true && <PrimaryButton ml="2" isLoading={this.state.isSaving} onClick={() => this.updateArticle()}>Save article</PrimaryButton>}
                        <NegativeButton ml="2" isLoading={this.state.isSaving} onClick={() => this.deleteArticle()}>Delete article</NegativeButton>
                    </Box>
                }
                {
                    this.state.isAdmin && !this.state.selectedItem &&
                    <Box zIndex="999" position="fixed" bottom="10px" right="10px" borderRadius="8px" padding="5px" bg="var(--bg)">
                        <PrimaryButton ml="2" isLoading={this.state.isSaving} onClick={() => this.addArticle()}>New article</PrimaryButton>
                    </Box>
                }




            </div >
        )
    }
}


const ListItem = ({ value, index, selectArticle, selectedItem, type, isAdmin }) => {


    let title = value.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '_');
    return (
        <a href={window.location.origin + "/news/" + value.title.replaceAll('/', '_').replaceAll(' ', '-').toLowerCase()}>
            <Box minHeight="450px" cursor="pointer" borderRadius="6px" mt="1" key={index} display="flex" flexDir="column"
                // onClick={() => selectArticle(value)}
                p="2" pl="4" pr="4" border={selectedItem === value._id ? (type === 'FAQ' ? "solid 1px var(--accent)" : 'solid 1px var(--accent)') : 'transparent 1px solid'}>
                {value.featuredImage && <Image src={value.featuredImage} maxW="1400px" className="aspectRatioSet" />}
                {!value.featuredImage && <Box display="flex" justifyContent="center" alignItems="center" fontSize="36px" textAlign={'center'} padding="20px" maxW="1400px" className="aspectRatioSet" backgroundColor={'var(--dark-gray)'}>
                    {value.title[0]}</Box>}

                <Link m={'0px'} p={'0px'} fontWeight="500" fontSize="28px"
                    as="h1"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    opacity={1}
                >

                    {value.title}
                </Link>
                {type !== "FAQ" && <Text fontSize="14px" >{value.updated ? moment(value.updated).format('MMMM YYYY') : moment(value.created).format('dd MM YYYY hh:mm z')}</Text>}
                <Text as="h2" m={'0px'} fontWeight="400" p={'0px'}
                    fontSize="16px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    opacity={0.8}
                >{value.description}</Text>
                {isAdmin &&
                    <Box >
                        <Text fontSize="md">Views: {value.viewCount}</Text>
                        <Text fontSize="md">😁 {value.helpfulVotes} | 😐 {value.mehVotes} | 😢 {value.unhelpfulVotes}</Text>
                    </Box>
                }
                <Link href={"https://optiontracker.io/" + type.toLowerCase() + "/" + title}></Link>

                <Box width="100%" height="1.2px" backgroundColor={'var(--gray)'} marginTop="20px" marginBottom={"20px"} />

            </Box>
        </a>
    )
}

const SocialBar = ({ name, updated, created }) => {
    return (
        <Box>
            <HStack>
                <Avatar name={name} size="lg" />

                <VStack paddingLeft="12px" alignItems={'flex-start'} spacing={'2px'}>
                    <Text fontSize="16px" >{name}</Text>
                    <Text fontSize="14px" opacity={0.8}>{updated ? moment(updated).calendar() : moment(created).calendar()}</Text>
                </VStack>


            </HStack>
            <Box width="100%" height="1.2px" backgroundColor={'var(--gray)'} marginTop="20px" marginBottom={"20px"} />
        </Box>
    )
}

const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
};


export default connect(mapStateToProps)(FAQ);
