import React, { PureComponent } from 'react'
import './ContextHighlightLink.css';

export default class ContextHighlightLink extends PureComponent {
    render() {
        return (
            <div id={this.props.id} onClick={this.props.onClick} className="highlightLink" >{this.props.text}</div>
        )
    }
}
