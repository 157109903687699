import * as React from 'react'

export interface Link {
  label: string
  href?: string
  children?: Array<{
    label: string
    description?: string
    href: string
    icon?: React.ReactElement
  }>
}

export const links: Link[] = [
  { label: 'Supported Brokerages', href: '../#upload' },
  { label: 'Pricing', href: '../#pricing' },
  // { label: 'Contact', href: '#' },
  // { label: 'FAQ', href: 'https://help.optiontracker.io/en/' },
  // { label: 'Blog', href: 'https://blog.optiontracker.io/' },
  // { label: 'Affiliate', href: 'https://affiliate.optiontracker.io/' },
  // {
  //   label: 'FAQ',
  //   children: [
  //     { label: 'News', href: '../news', description:'Our latest updates', icon: <IoNewspaper/> },
  //     { label: 'FAQ', href: '../faq', description:'Frequent asked questions', icon: <IoHelp/> },
  //   ],
  // },
  // { label: 'Sign in', href: '../app/login' },
]
