import { APIEndpoint, APIEndpointProcessor } from "./dataStructures";

const { uuid } = require("uuidv4");

const SomethingWentWrong = (e) => {
  // alert("Something went wrong!!!");
  console.log({ e });
};

let checkIfLoggedIn = async () => {
  let access_token = localStorage.getItem("access_token");

  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let warmUpAi = async () => {
  let access_token = localStorage.getItem("access_token");

  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/ai", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let sendMessageToAI = async (message, sessionId) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/ai/" + sessionId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ message: message }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let login = async (APIEndpoint, username, password) => {
  return fetch(APIEndpoint + "/users/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username, password: password }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        localStorage.setItem("access_token", res.token);
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

// let submitStripeId = async (APIEndpoint, stripeId, stripeCSRFid) => {
//   console.log(APIEndpoint + "/payments/setup/id")
//   return fetch(APIEndpoint + "/payments/setup/id", {
//       "method": "POST", "headers": { "Content-Type": "application/json" },
//       "body": JSON.stringify({ "stripeCSRFid": stripeCSRFid, "stripeId": stripeId}) })
//   .then((res) => res.json())
//   .then((res) => {
//     console.log(res);
//       if(res.success === true) {
//           return res;
//       } else {
//           return res;
//       }
// //   }) .catch((e) => {
// SomethingWentWrong(e);
// console.log("API Call, error getting profile", e)
//       });
// };

let register = async (
  APIEndpoint,
  username,
  password,
  name,
  email,
  passwordconfirm,
  termsAndConditions,
  privacyPolicy,
  hear
) => {
  if (termsAndConditions === "on") {
    termsAndConditions = true;
  }
  if (privacyPolicy === "on") {
    privacyPolicy = true;
  }
  return fetch(APIEndpoint + "/users/register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: username,
      password: password,
      name: name,
      email: email,
      passwordconfirm: password,
      termsAndConditions: termsAndConditions,
      privacyPolicy: privacyPolicy,
      whereDidYouHearAboutUs: hear,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getSubscription = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/subscriptions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let resetPassword = async (APIEndpoint, email) => {
  return fetch(APIEndpoint + "/users/reset", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let setSettings = async (
  APIEndpoint,
  { annualProfitTarget, totalAccountValue }
) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/targets", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ annualProfitTarget, totalAccountValue }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let addAccount = async (
  APIEndpoint,
  { name, annualProfitTarget, totalAccountValue }
) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/accounts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ name, annualProfitTarget, totalAccountValue }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let updateAccount = async (
  APIEndpoint,
  accounts,
  accountId,
  name,
  annualProfitTarget,
  totalAccountValue
) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }

    // this.props.settings.APIEndpoint, this.props.settings.profile.accounts, accountId, {name, annualProfitTarget, totalAccountValue}
    let newAccounts = accounts.filter((value, index) => {
      if (value.id === accountId) {
        value.name = name;
        value.annualProfitTarget = annualProfitTarget;
        value.totalAccountValue = totalAccountValue;
        value.selected = true;
        return value;
      } else {
        value.selected = false;
        return value;
      }
    });

    return fetch(APIEndpoint + "/users/accounts/" + accountId, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ accounts: newAccounts }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let replaceAccounts = async (APIEndpoint, accounts) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/accounts/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ accounts: accounts }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let deleteAccount = async (APIEndpoint, accountId) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/accounts/" + accountId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let setPreferences = async (
  APIEndpoint,
  {
    hiddenColumns,
    hiddenColumnsEnabled,
    rowMonthColoursEnabled,
    viewEnabled,
    visibleColumnsOrder,
  }
) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/preferences", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({
        hiddenColumns,
        hiddenColumnsEnabled,
        rowMonthColoursEnabled,
        viewEnabled,
        visibleColumnsOrder,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let setInterests = async ({
  questionKnowledge,
  questionProfessionalTrader,
  questionFrequencyOfTrades,
}) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/users/interests", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({
        questionKnowledge,
        questionProfessionalTrader,
        questionFrequencyOfTrades,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let setCustomDashboardConfiguration = async (
  APIEndpoint,
  dashboardConfiguration
) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/customdashboard", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ dashboardConfiguration: dashboardConfiguration }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let getCustomDashboardConfiguration = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/customdashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let setDashboardConfiguration = async (APIEndpoint, dashboardConfiguration) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/dashboard", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ dashboardConfiguration: dashboardConfiguration }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let getDashboardConfiguration = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/dashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let addTrade = async (APIEndpoint, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let manageCash = async (payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/cash", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let updateTrade = async (x, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + payload._id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let markAssigned = async (tradeId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + tradeId + "/markAssigned", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, markAssigned", e);
    });
};

let updateOption = async ({ lastPrice, _id }) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + _id + "/option", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ lastPrice }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let recordTradeView = async (APIEndpoint, tradeId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + tradeId + "/views", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let addNotification = async (
  APIEndpoint,
  { type, threshold, value, forId, tradeData, commMethod }
) => {
  let access_token = localStorage.getItem("access_token");

  return fetch(APIEndpoint + "/notifications/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({
      type,
      threshold,
      value,
      forId,
      tradeData,
      commMethod,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getNotifications = async (APIEndpoint, tradeId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notifications/?tradeId=" + tradeId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getAnalytics = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getUploadedFile = async (APIEndpoint, id) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/uploads/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getUsers = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let runUpdate = async (type) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/updates/" + type, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error starting update", e);
    });
};

let getLogs = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/logs", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting logs", e);
    });
};

let getUsersTrades = async (APIEndpoint, id) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/users/" + id + "/trades", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let adminPatchUser = async (APIEndpoint, payload) => {
  if (payload && payload._id === undefined) {
    payload = payload.jsObject;
  }

  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/analytics/users/" + payload._id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

// let getSingleNotification = async (APIEndpoint, tradeId) => {
//   let access_token = localStorage.getItem("access_token");
//   return fetch(APIEndpoint + "/notifications/"+tradeId, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: access_token
//     },
//   }).then((res) => res.json())
//   .then((res) => {
//       if(res.success === true) {
//           return res;
//       } else {
//           return res;
//       }
//   }) .catch((e) => {
// SomethingWentWrong(e);
// console.log("API Call, error getting profile", e)
//       });
// }

let deleteNotification = async (APIEndpoint, notificationId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notifications/" + notificationId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let uploadCSV = async (
  APIEndpoint,
  payload,
  csvType,
  selectedAccount,
  overlappingTrades,
  deleteAllTrades
) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(
    APIEndpointProcessor +
      "/uploads?broker=" +
      csvType +
      "&account=" +
      selectedAccount +
      "&noOverlappingTrades=" +
      overlappingTrades +
      "&deleteAllTrades=" +
      deleteAllTrades,
    {
      method: "POST",
      headers: {
        Authorization: access_token,
        "Idempotency-Key": uuid(),
      },
      body: payload,
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getArticles = async (APIEndpoint, type, page) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles?type=" + type + "&page=" + page, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getSpecificArticle = async (title) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/" + title, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let getArticlesAdmin = async (APIEndpoint, type) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/admin?type=" + type, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let voteOnArticle = async (APIEndpoint, articleId, direction) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/" + articleId + "/vote/" + direction, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let updateArticle = async (APIEndpoint, articleId, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/" + articleId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let createArticle = async (APIEndpoint, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let articleView = async (APIEndpoint, articleId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/" + articleId + "/view", {
    method: "POST",
    headers: {
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};
let deleteArticle = async (APIEndpoint, articleId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/articles/" + articleId + "", {
    method: "DELETE",
    headers: {
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let deleteImportedTrades = async (
  APIEndpoint,
  importedTradeBatch,
  newImportedTradesArray
) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/uploads", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({
      importId: importedTradeBatch,
      newUserArray: newImportedTradesArray,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getTrades = async (
  APIEndpoint,
  { tradeType, positionStatus, stockTicker, accountId, tags, force }
) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(
    APIEndpoint +
      "/trades?" +
      "tradeType=" +
      tradeType +
      "&positionStatus=" +
      positionStatus +
      "&stockTicker=" +
      stockTicker +
      "&accountId=" +
      accountId +
      "&tags=" +
      JSON.stringify(tags) +
      "&force=" +
      force,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getASpecificTrade = async (APIEndpoint, tradeId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + tradeId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let deleteTrade = async (APIEndpoint, tradeId, arrayOfTradeIds) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/" + tradeId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ tradeIds: arrayOfTradeIds }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let deleteAllAccountTrades = async (APIEndpoint, account) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/trades/accounts/" + account, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let checkTokenIsValid = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/users/profile", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: _checkTokenIsValid: Success");
      return { data: message, success: true };
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: _checkTokenIsValid: Failure", e);
      return { success: false };
    });
};

let getStockPrice = async (APIEndpoint, stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  stockTicker = stockTicker.replace("/", `%2F`);

  return fetch(APIEndpoint + "/stocks/" + stockTicker, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: _retrieveStockPrice: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: _retrieveStockPrice: Failure", e);
      return e;
    });
};

let getStockPriceByDate = async (APIEndpoint, stockTicker, date) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/dates/" + date, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: _retrieveStockPriceByDate: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: _retrieveStockPriceDate: Failure", e);
      return e;
    });
};

let getOptionData = async (APIEndpoint, stockTicker, withGreeks) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(
    APIEndpoint +
      "/stocks/" +
      stockTicker +
      "/options" +
      "?" +
      "withGreeks=" +
      withGreeks,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      json: true,
    }
  )
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: _retrieveOptionsData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: _retrieveOptionsData: Failure", e);
      return e;
    });
};

let getMultiOptionData = async (options) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/options", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(options),
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getMultiOptionData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getMultiOptionData: Failure", e);
      return e;
    });
};

let searchForStock = async (APIEndpoint, stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/search/" + stockTicker, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: _searchForStockData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: _searchForStockData: Failure", e);
      return e;
    });
};

let searchForStockDataLocally = async (stockTicker) => {
  let access_token = localStorage.getItem("access_token");

  stockTicker = stockTicker.replace("/", `%2F`);

  return fetch(APIEndpoint + "/searches/local/" + stockTicker, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: searchForStockDataLocally: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: searchForStockDataLocally: Failure", e);
      return e;
    });
};

let getHistoricOptionData = async (APIEndpoint, contractName) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/options/" + contractName, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getHistoricOptionData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getHistoricOptionData: Failure", e);
      return e;
    });
};

let getFundamentalData = async (APIEndpoint, stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/fundamentals", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getFundamentalData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getFundamentalData: Failure", e);
      return e;
    });
};

let getTechnicals = async (stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(
    APIEndpoint + "/stocks/search/" + stockTicker + "/fundamentals/technicals",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      json: true,
    }
  )
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getFundamentalData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getFundamentalData: Failure", e);
      return e;
    });
};

let getIntegrations = async (type, accountId) => {
  let access_token = localStorage.getItem("access_token");

  return fetch(
    APIEndpointProcessor +
      "/integrations?type=" +
      type +
      "&accountId=" +
      accountId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      json: true,
    }
  )
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getIntegrations: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getIntegrations: Failure", e);
      return e;
    });
};

let getSpecificIntegration = async (id) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpointProcessor + "/integrations/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getSpecificIntegration: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getSpecificIntegration: Failure", e);
      return e;
    });
};

let runIBExecutionQuery = async (id) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(
    APIEndpointProcessor + "/integrations/" + id + "?ibTradeExecution=true",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      json: true,
    }
  )
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: runExecutionQuery: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: runExecutionQuery: Failure", e);
      return e;
    });
};

let updateIntegration = async (integrationId, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpointProcessor + "/integrations/" + integrationId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        console.log("Helper: updateIntegration: Success");

        return res;
      } else {
        console.log("Helper: updateIntegration: Failure");

        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error updating integration", e);
    });
};

let createIntegration = async (type, accountId) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(
    APIEndpointProcessor +
      "/integrations?type=" +
      type +
      "&accountId=" +
      accountId,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        console.log("Helper: createIntegration: Success");

        return res;
      } else {
        console.log("Helper: createIntegration: Failure");

        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);

      console.log("Helper: createIntegration: Failure", e);
    });
};

let getDividendData = async (stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/dividends", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getDividendData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getDividendData: Failure", e);
      return e;
    });
};

let getMultipleFundamentalData = async (APIEndpoint, payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/fundamentals/" + payload, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getMultipleFundamentalData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getMultipleFundamentalData: Failure", e);
      console.log(e);
      return e;
    });
};

let getChartData = async (APIEndpoint, stockTicker, periodParams) => {
  let access_token = localStorage.getItem("access_token");

  let url = "";
  if (periodParams !== undefined) {
    url =
      APIEndpoint +
      "/stocks/" +
      stockTicker +
      "/charts?" +
      "from=" +
      periodParams.from +
      "&to=" +
      periodParams.to;
  } else {
    url = APIEndpoint + "/stocks/" + stockTicker + "/charts";
  }

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getChartData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getChartData: Failure", e);
      return e;
    });
};

let getIntradayChartData = async (APIEndpoint, stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/charts/intraday", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    json: true,
  })
    .then((message) => {
      return message.json();
    })
    .then((message) => {
      console.log("Helper: getIntradayChartData: Success");
      return message;
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("Helper: getIntradayChartData: Failure", e);
      return e;
    });
};

let sendFeedback = async (APIEndpoint, { message, type, image }) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/feedbacks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      body: JSON.stringify({ message, type, image }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let getStripePortal = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token === null) {
    return false;
  } else {
    if (access_token.length < 2) {
      return false;
    }
    return fetch(APIEndpoint + "/subscriptions/stripe/portal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          return res;
        } else {
          return res;
        }
      })
      .catch((e) => {
        SomethingWentWrong(e);
        console.log("API Call, error getting profile", e);
      });
  }
};

let phoneVerify = async (APIEndpoint, phoneNumber) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/users/twilio/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ phoneNumber }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getNews = async (stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/news", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting stock news", e);
    });
};

let getSentiment = async (stockTicker) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/stocks/" + stockTicker + "/sentiment", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting stock sentiment", e);
    });
};

let phoneCheck = async (APIEndpoint, code) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/users/twilio/check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ code }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let postToSlack = async (APIEndpoint, trade) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/posts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(trade),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let addNoteToCalendar = async (
  APIEndpoint,
  { note, date, stockTicker, type }
) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ note, stockTicker, date, type }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let updateNoteOnCalendar = async (APIEndpoint, note) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notes/" + note._id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(note),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getAllCalendarNotes = async (APIEndpoint) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notes", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let deleteCalendarNote = async (APIEndpoint, id) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/notes/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getEducation = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/educations/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let deleteEducation = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/educations/", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let setEducation = async (payload) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/educations/", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let initateEducation = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/educations/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getBalance = async (accountId) => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/balances/" + accountId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getSiteNotifications = async () => {
  let access_token = localStorage.getItem("access_token");
  return fetch(APIEndpoint + "/sitenotifications/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let markSiteNotificationRead = async (notificationId) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/sitenotifications/" + notificationId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let createSiteNotification = async ({ userId, type, message, title }) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/sitenotifications/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ userId, type, message, title }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let createReferral = async ({ referredUserEmail }) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/referrals/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ referredUserEmail }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getReferral = async () => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/referrals/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getScreenerData = async (params) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/screeners/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting getScreenerData", e);
    });
};

let getScreenerConfig = async () => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/screeners/config", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getSubscriptionMetaData = async () => {
  return fetch(APIEndpoint + "/subscriptions/metadata", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let getUploadState = async (fileId) => {
  return fetch(APIEndpoint + "/uploads/" + fileId, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting profile", e);
    });
};

let forceUpdateFinancialData = async () => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/data", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting forceUpdateFinancialData", e);
    });
};

let autoAssignStrategies = async (account) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/strategies?account=" + account, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting autoAssignStrategies", e);
    });
};

let assignTradeLink = async (trades, ID) => {
  let access_token = localStorage.getItem("access_token");

  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/tradeLinks", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ trades: trades, id: ID }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting assignTradeLink", e);
    });
};

let consolidateTrades = async (trades) => {
  if (trades.length === 0) {
    trades = [];
  }
  let access_token = localStorage.getItem("access_token");
  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/tradeTools/consolidateTrades", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
    body: JSON.stringify({ trades }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting consolidateTrades", e);
    });
};

let revertConsolidatedTrades = async () => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length < 2) {
    return false;
  }
  return fetch(APIEndpoint + "/tradeTools/consolidateTrades", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      SomethingWentWrong(e);
      console.log("API Call, error getting consolidateTrades", e);
    });
};

export default {
  revertConsolidatedTrades,
  consolidateTrades,
  assignTradeLink,
  autoAssignStrategies,
  forceUpdateFinancialData,
  getUploadState,
  getSubscriptionMetaData,
  getScreenerData,
  getScreenerConfig,
  createSiteNotification,
  markSiteNotificationRead,
  getSiteNotifications,
  getBalance,
  initateEducation,
  deleteEducation,
  updateNoteOnCalendar,
  getAllCalendarNotes,
  deleteCalendarNote,
  postToSlack,
  addNoteToCalendar,
  sendFeedback,
  getChartData,
  getIntradayChartData,
  setInterests,
  getFundamentalData,
  getDividendData,
  getHistoricOptionData,
  setPreferences,
  updateTrade,
  getMultiOptionData,
  searchForStock,
  getOptionData,
  getStockPrice,
  uploadCSV,
  getTrades,
  addTrade,
  manageCash,
  setSettings,
  checkTokenIsValid,
  checkIfLoggedIn,
  login,
  register,
  getSubscription,
  resetPassword,
  deleteTrade,
  deleteAllAccountTrades,
  getStockPriceByDate,
  addAccount,
  updateAccount,
  deleteAccount,
  replaceAccounts,
  deleteImportedTrades,
  addNotification,
  deleteNotification,
  getSpecificArticle,
  getNotifications,
  phoneVerify,
  getNews,
  getSentiment,
  phoneCheck,
  getArticles,
  voteOnArticle,
  updateArticle,
  createArticle,
  deleteArticle,
  setDashboardConfiguration,
  setCustomDashboardConfiguration,
  articleView,
  getArticlesAdmin,
  getAnalytics,
  getUploadedFile,
  markAssigned,
  getUsers,
  runUpdate,
  adminPatchUser,
  getUsersTrades,
  getLogs,
  getMultipleFundamentalData,
  // getSingleNotification
  getDashboardConfiguration,
  getCustomDashboardConfiguration,
  getASpecificTrade,
  recordTradeView,
  getStripePortal,
  getEducation,
  setEducation,
  searchForStockDataLocally,
  createReferral,
  getReferral,
  getIntegrations,
  getSpecificIntegration,
  createIntegration,
  updateIntegration,
  runIBExecutionQuery,
  warmUpAi,
  updateOption,
  sendMessageToAI,
  getTechnicals,
};
