import { Box, Button, Skeleton, Text } from "@chakra-ui/react";
import { uuid4 } from "@sentry/utils";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import moment, { unix } from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { uuid } from "uuidv4";
// import { Chart, ChartCanvas } from "react-stockcharts";
// import {
//     Annotate, LabelAnnotation, SvgPathAnnotation,
//     sellPath,
// } from "react-stockcharts/lib/annotation";

// import { XAxis, YAxis } from "react-stockcharts/lib/axes";
// import {
//     CrossHairCursor, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY
// } from "react-stockcharts/lib/coordinates";
// import { ema, sma } from "react-stockcharts/lib/indicator";
// import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
// import {
//     CandlestickSeries,
//     LineSeries
// } from "react-stockcharts/lib/series";
// import { MovingAverageTooltip, OHLCTooltip } from "react-stockcharts/lib/tooltip";
// import { last } from "react-stockcharts/lib/utils";
import API from "../../helpers/apiCalls";
import { ChartFrame } from "../../helpers/ChartFrame";
import { stock_path, getPath, getColor } from "./svgs/ChartSvgs";
import { TVChartContainer } from "./TVChartContainer";
import apiCalls from "../../helpers/apiCalls";

class ChartData extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      valid: false,
      chartData: [],
      entryDate: "",
      spotPriceAtTradeTime: "",
      dateClosed: "",
      type: "DAILY",
      earningsData: [],
      dividendData: [],
    };
  }

  componentDidMount() {
    this.setUpEarningsData();
    this.getDailyData();
    this.getDividendData();
    // this.getIntradayChartData();
  }

  getDividendData = () => {
    apiCalls.getDividendData(this.props.stockTicker).then((dividendData) => {
      let dividendDataArray = [];
      for (let key in dividendData.data) {
        console.log("Dividend Data", dividendData.data[key]);
        dividendDataArray.push({
          data: dividendData.data[key],
          id: key,
          time: moment(dividendData.data[key].date).valueOf() / 1000,
          color: "red",
          label: "Div",
          minSize: 30,
          tooltip: [
            "Dividend",
            moment(dividendData.data[key].date).format("MMM Do YYYY"),
            "Value: " + dividendData.data[key].value,
            "Currency: " + dividendData.data[key].currency,
            "Declaration: " + dividendData.data[key].declarationDate,
            "Record: " + dividendData.data[key].recordDate,
          ],
        });
      }

      this.setState({ dividendData: dividendDataArray });

      //convert this into an array
    });
  };

  setUpEarningsData = () => {
    try {
      let earningsData = this.props.fundamentalData.Earnings.History;

      let earningsDataArray = [];

      for (let key in earningsData) {
        earningsDataArray.push({
          data: earningsData[key],
          id: key,
          time: moment(earningsData[key].reportDate).valueOf() / 1000,
          color: "green",
          label: "Ea",
          minSize: 30,
          tooltip: [
            "Earnings",
            moment(earningsData[key].reportDate).format("MMM Do YYYY"),
            "EPS Actual: " +
              earningsData[key].epsActual +
              " vs " +
              earningsData[key].epsEstimate,
            "Surprise: " + earningsData[key].surprisePercent + "%",
          ],
        });
      }

      this.setState({ earningsData: earningsDataArray });
    } catch (e) {
      console.log("Earnings data not ready..", e);
    }
  };

  getDailyData = () => {
    this.setState({ loading: true, type: "DAILY" });

    if (this.props.standalone === true) {
      if (this.props.stockTicker.length > 0) {
        this.setState({ stockTicker: this.props.stockTicker });
        API.getChartData(
          this.props.settings.APIEndpoint,
          this.props.stockTicker
        ).then((chartData) => {
          // if (chartData.data === undefined || chartData.data.data.length < 5) {
          //     this.setState({ loading: false, valid: false });
          //     this.props.onError('Could not find that stock chart');
          //     alert("We could not find that stock. Please message support and it will be added. [DAILY]");
          // }
          // else {
          let data = chartData.data.data.filter((value, index) => {
            value.date = moment(value.date).toDate();
            return value;
          });
          this.setState({ chartData: data, loading: false, valid: true });

          // }
        });
      } else {
        this.setState({ loading: false, valid: false });
      }
    } else if (this.props.multi === true) {
      this.setState({ stockTicker: this.props.tradeData[0].stockTicker });

      let annotations = [];
      this.props.tradeData.filter((value, index) => {
        annotations.push({
          entryDate: value.dateOpened,
          dateClosed: value.dateClosed,
          spotPriceAtTradeTime: value.spotPriceAtTradeTime,
          tradeType: value.tradeType,
          action: value.action,
          quantity: value.quantity,
          strikePrice: value.strikePrice,
          contracts: value.contracts,
          positionStatus: value.positionStatus,
          notes: value.notes,
          tradeSource: value.tradeSource,
        });
      });

      API.getChartData(
        this.props.settings.APIEndpoint,
        this.props.tradeData[0].stockTicker
      )
        .then((chartData) => {
          let data = chartData.data.data.filter((value, index) => {
            value.date = moment(value.date).toDate();
            return value;
          });
          this.setState({
            chartData: data,
            loading: false,
            valid: true,
            annotations: annotations,
          });
        })
        .catch((e) => {
          this.setState({ chartData: [], loading: false, valid: false });
        });
    } else {
      this.setState({ stockTicker: this.props.tradeData.stockTicker });
      let entryDate = this.props.tradeData.dateOpened;
      let dateClosed = this.props.tradeData.dateClosed;
      let spotPriceAtTradeTime = this.props.tradeData.spotPriceAtTradeTime;
      this.setState({
        entryDate,
        spotPriceAtTradeTime,
        dateClosed,
        tradeType: this.props.tradeData.tradeType,
        action: this.props.tradeData.action,
      });
      API.getChartData(
        this.props.settings.APIEndpoint,
        this.props.tradeData.stockTicker
      )
        .then((chartData) => {
          let data = chartData.data.data.filter((value, index) => {
            value.date = moment(value.date).toDate();
            return value;
          });
          this.setState({
            chartData: data,
            loading: false,
            valid: true,
            spotPriceAtTradeTime: this.props.tradeData.spotPriceAtTradeTime,
            tradeType: this.props.tradeData.tradeType,
            action: this.props.tradeData.action,
            strikePrice: this.props.tradeData.strikePrice,
          });
        })
        .catch((e) => {
          this.setState({ chartData: [], loading: false, valid: false });
        });
    }
  };

  getIntradayChartData = () => {
    let symbol = this.props.stockTicker;
    if (this.props.symbol !== undefined) {
      symbol = this.props.symbol;
    }
    if (this.props.multi === true) {
      symbol = this.props.tradeData[0].stockTicker;
    }

    this.setState({ loading: true });
    API.getIntradayChartData(this.props.settings.APIEndpoint, symbol).then(
      (chartData) => {
        if (chartData.data === undefined || chartData.data.data.length < 5) {
          this.setState({ loading: false, valid: false });
          // this.props.onError('Could not find that stock chart');
          alert(
            "We could not find that stock. Please message support and it will be added. [INTRADAY]"
          );
        } else {
          let data = chartData.data.data.filter((value, index) => {
            value.date = moment(value.date).toDate();
            return value;
          });
          this.setState({
            chartData: data,
            loading: false,
            valid: true,
            type: "INTRADAY",
          });
        }
      }
    );
  };

  formatMarkData = (tradeData) => {
    if (!tradeData || !tradeData.dateOpened) {
      return [];
    }
    let array = [];
    if (
      tradeData.dateOpened !== undefined &&
      !isNaN(moment(tradeData.dateClosed).unix().valueOf())
    ) {
      array.push({
        id: tradeData._id + "-open",
        time: moment(
          tradeData.dateOpened +
            (tradeData.timeOpened !== undefined
              ? " " + tradeData.timeOpened
              : "")
        )
          .add(6, "hours")
          .unix()
          .valueOf(),
        color:
          tradeData.tradeType === "STOCK"
            ? "blue"
            : tradeData.tradeType === "CALL"
            ? "green"
            : "yellow",
        text:
          tradeData.tradeType === "STOCK"
            ? `You bought ${tradeData.quantity} of ${tradeData.stockTicker} at ${tradeData.spotPriceAtTradeTime}`
            : `${tradeData.action === "SELL_TO_OPEN" ? "STO" : "BTO"} ${
                tradeData.contracts
              }x ${tradeData.stockTicker} ${moment(tradeData.expiryDate).format(
                "MM/DD/YYYY"
              )} ${tradeData.strikePrice.toFixed(2)} at ${tradeData.premium} ${
                tradeData.tradeType === "CALL" ? "C" : "P"
              } `,
        label: "O",
        labelFontColor: "black",
        minSize: 14,
        tradeData: tradeData,
        two_character_bar_marks_labels: true,
      });
    }
    if (
      tradeData.dateClosed !== undefined &&
      !isNaN(moment(tradeData.dateClosed).unix().valueOf())
    ) {
      array.push({
        id: tradeData._id + "-closed",
        time: moment(
          tradeData.dateClosed +
            (tradeData.timeClosed !== undefined
              ? " " + tradeData.timeClosed
              : "")
        )
          .unix()
          .valueOf(),
        color:
          tradeData.tradeType === "STOCK"
            ? "blue"
            : tradeData.tradeType === "CALL"
            ? "green"
            : "yellow",
        text:
          tradeData.tradeType === "STOCK"
            ? `You sold ${tradeData.quantity} of ${tradeData.stockTicker} at $${tradeData.spotPriceAtTradeTime}`
            : `${tradeData.action === "SELL_TO_CLOSE" ? "STC" : "BTC"} ${
                tradeData.contracts
              }x ${tradeData.stockTicker} ${moment(tradeData.expiryDate).format(
                "MM/DD/YYYY"
              )} ${tradeData.strikePrice.toFixed(2)} at $${
                tradeData.buyBackPricePerContract
              } ${tradeData.tradeType === "CALL" ? "C" : "P"}. P&L: $${(
                tradeData.realisedProfitAndLoss * 100
              ).toFixed(2)}`,
        label: "C",
        labelFontColor: "black",
        minSize: 14,
        tradeData: tradeData,
      });
    }

    // if (tradeData.positionStatus === 'OPEN' && tradeData.tradeType !== 'STOCK') {
    //     array.push({
    //         id: tradeData._id + '-expiry',
    //         time: moment(tradeData.expiryDate).unix().valueOf(),
    //         color: 'red',
    //         text: `EXPIRY: ${tradeData.action === 'SELL_TO_CLOSE' ? 'STC' : 'BTC'} ${tradeData.contracts}x ${tradeData.stockTicker} ${moment(tradeData.expiryDate).format('MM/DD/YYYY')} ${tradeData.strikePrice.toFixed(2)} at ${tradeData.premium} ${tradeData.tradeType === 'CALL' ? 'C' : 'P'}. `,
    //         label: '',
    //         labelFontColor: 'black',
    //         minSize: 10,
    //         tradeData: tradeData,
    //     })
    // }

    return array;
  };

  render() {
    if (this.state.loading === true) {
      return <Skeleton height="500px"></Skeleton>;
    }
    if (this.state.chartData.length < 1) {
      return <></>;
    }

    // const ema6 = ema()
    //     .options({
    //         windowSize: 6,
    //         sourcePath: "close",
    //     })
    //     .skipUndefined(true)
    //     .merge((d, c) => { d.ema20 = c; })
    //     .accessor(d => d.ema20)
    //     .stroke("blue");

    // const sma21 = sma()
    //     .options({ windowSize: 21 })
    //     .merge((d, c) => { d.sma20 = c; })
    //     .accessor(d => d.sma20);

    if (this.state.loading === false && this.state.valid === false) {
      return <Box></Box>;
    } else {
      // const calculatedData = ema6(sma21(this.state.chartData));

      const { ratio } = this.state;
      const height = 500;
      // const width = this.props.standalone === true ? 1150 : 1050;
      const width =
        window.innerWidth > 1150
          ? this.props.standalone === true
            ? 1150
            : 1120
          : window.innerWidth - 50;
      var margin = { left: 50, right: 50, top: 20, bottom: 30 };
      var gridHeight = height - margin.top - margin.bottom;
      var gridWidth = width - margin.left - margin.right;
      var showGrid = true;
      var yGrid = showGrid
        ? {
            innerTickSize: -1 * gridWidth,
            tickStrokeDasharray: "Solid",
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1,
          }
        : {};
      var xGrid = showGrid
        ? {
            innerTickSize: -1 * gridHeight,
            tickStrokeDasharray: "Solid",
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1,
          }
        : {};
      // const xScaleProvider = discontinuousTimeScaleProvider
      //     .inputDateAccessor(d => {
      //         if (this.state.type === 'INTRADAY') {
      //             return moment(d.datetime).toDate();
      //         }
      //         else {
      //             return d.date
      //         }
      //     });
      // const {
      //     data,
      //     xScale,
      //     xAccessor,
      //     displayXAccessor,
      // } = xScaleProvider(this.state.chartData);

      let length = 100;
      // if (data.length < 100) {
      //     length = data.length - 1;
      // }
      // const xExtents = [
      //     xAccessor(last(data)) + 1,
      //     xAccessor(data[data.length - length])
      // ];

      let annotations = [];

      if (this.props.multi === undefined) {
        annotations = this.formatMarkData(this.props.tradeData);
      } else {
        //Multiple trades.
        this.props.tradeData.forEach((element) => {
          let result = this.formatMarkData(element);
          annotations = [...annotations, ...result];
        });
      }

      return (
        <TVChartContainer
          multi={this.props.multi}
          user={this.props.settings.profile}
          colorMode={this.props.colorMode}
          stockTicker={this.state.stockTicker}
          annotations={annotations}
          timescaleMarks={[
            ...this.state.earningsData,
            ...this.state.dividendData,
          ]}
        />
      );
    }
  }
}

// {this.state.type === 'DAILY' ? <Button size='xs' onClick={this.getIntradayChartData}>Switch to intraday</Button> :
// <Button size='xs' onClick={this.getDailyData}>Switch to daily</Button>
// }

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(ChartData);

// <>
// <ChartCanvas height={height}
//     width={width}
//     ratio={2}
//     type={'svg'}
//     seriesName={this.state.stockTicker}
//     data={data}
//     xScale={xScale}
//     xAccessor={xAccessor}
//     displayXAccessor={displayXAccessor}
//     xExtents={xExtents}
//     margin={margin}

// >

//     <Chart id={1} yExtents={d => [d.high, d.low]}
//     >
//         <XAxis axisAt="bottom" orient="bottom" ticks={12}  {...xGrid}
//             tickStroke={this.props.colorMode === 'dark' ? '#ffffff' : '#000000'}
//             stroke={this.props.colorMode === 'dark' ? '#ffffff' : '#000000'}
//         />
//         <YAxis axisAt="left" orient="left"
//             tickStroke={this.props.colorMode === 'dark' ? '#ffffff' : '#000000'}
//             stroke={this.props.colorMode === 'dark' ? '#ffffff' : '#000000'}
//             ticks={16} {...yGrid} />
//         <CandlestickSeries
//             fill={d => d.close > d.open ? "green" : "red"}
//             stroke={d => d.close > d.open ? "green" : "red"}
//             wickStroke={d => d.close > d.open ? "green" : "red"}
//             opacity={1}
//         />
//         <MouseCoordinateY
//             at="right"
//             orient="right"
//             displayFormat={format(".2f")}
//             margin={{ right: 100, top: 200 }} />
//         {/* <OHLCTooltip origin={[140, -30]}
//             tickStroke="#FFFFFF"
//             stroke="#FFFFFF"
//             fill="#FFFFFF"

//         /> */}
//         <MouseCoordinateX
//             at="bottom"
//             orient="bottom"
//             displayFormat={this.state.type === 'DAILY' ? timeFormat("%Y-%m-%d") : timeFormat("%I:%M")} />
//         <MouseCoordinateY
//             at="left"
//             orient="left"
//             displayFormat={format(".4s")} />

//         {this.props.multi === true && this.state.annotations.map((annotation, index) => {

//             return (
//                 <Annotate key={'up-' + index} with={SvgPathAnnotation}
//                     when={d => {
//                         if (moment(d.date).format('DD MMMM YYYY HH:mm') === moment(annotation.entryDate).format('DD MMMM YYYY HH:mm')) { return true; }
//                     }}
//                     usingProps={{
//                         path: (_ref) => { return getPath(_ref, annotation.tradeType, annotation.action, 'OPEN') },
//                         fill: () => { return getColor(annotation.tradeType, annotation.action, 'OPEN') },

//                         y: (props) => {

//                             if (annotation.tradeType === 'STOCK') {
//                                 return props.yScale(annotation.spotPriceAtTradeTime);

//                             }
//                             else if (annotation.tradeType === 'CALL' || annotation.tradeType === 'PUT') {
//                                 return props.yScale(annotation.strikePrice);

//                             }

//                             if (this.state.spotPriceAtTradeTime === undefined) {
//                                 return props.yScale((props.datum.close + props.datum.open) / 2);
//                             }
//                             else {
//                                 return props.yScale(annotation.spotPriceAtTradeTime);
//                             }
//                         },
//                         tooltip: d => 'When you opened a trade',

//                     }} />

//             )
//         })}
//         {this.props.multi === true && this.state.annotations.map((annotation, index) => {

//             return (
//                 <Annotate key={'down-' + index} with={SvgPathAnnotation} when={d => {
//                     if (this.state.type === 'DAILY') {
//                         if (moment(d.date).format('DD MMMM YYYY') === moment(annotation.dateClosed).format('DD MMMM YYYY')) {
//                             return true
//                         }
//                     }
//                     else {
//                         if (moment(d.datetime).format('DD MM YYYY HH:mm') === moment(annotation.dateClosed).format('DD MM YYYY HH:mm')) {
//                             return true
//                         }
//                     }
//                 }}
//                     usingProps={{
//                         path: (_ref) => { return getPath(_ref, annotation.tradeType, annotation.action, 'CLOSE') },
//                         fill: () => { return getColor(annotation.tradeType, annotation.action, 'CLOSE') },

//                         y: (props) => {

//                             return props.yScale(props.datum.close);
//                         },
//                         tooltip: d => 'When you closed a trade',
//                     }} />

//             )
//         })}

//         {this.props.multi === undefined &&
//             <>
//                 <Annotate with={SvgPathAnnotation}
//                     when={d => {
//                         if (this.state.type === 'DAILY') {
//                             if (moment(d.date).format('DD MMMM YYYY') === moment(this.state.entryDate).format('DD MMMM YYYY')) {
//                                 return true
//                             }
//                         }
//                         else {

//                             // console.log({
//                             //     dateTime: moment(d.datetime).tz("America/New_York").format('DD MM YYYY HH:mm'),
//                             //     entryDate: moment(this.state.entryDate).format('DD MM YYYY HH:mm'),
//                             //     timezone: moment.tz.guess()
//                             // })
//                             if (moment(d.datetime).format('DD MM YYYY HH:mm') === moment(this.state.entryDate).format('DD MM YYYY HH:mm')) {
//                                 return true
//                             }
//                         }
//                     }}
//                     usingProps={annotationBuyProps} />

//                 <Annotate with={SvgPathAnnotation}
//                     when={d => {
//                         if (this.state.type === 'DAILY') {
//                             if (moment(d.date).format('DD MMMM YYYY') === moment(this.state.dateClosed).format('DD MMMM YYYY')) {
//                                 return true
//                             }
//                         }
//                         else {
//                             if (moment(d.datetime).format('DD MM YYYY HH:mm') === moment(this.state.dateClosed).format('DD MM YYYY HH:mm')) {
//                                 return true
//                             }
//                         }
//                     }}
//                     usingProps={annotationCloseProps} />
//             </>
//         }

//         <LineSeries yAccessor={sma21.accessor()} stroke={sma21.stroke()} />
//         <LineSeries yAccessor={ema6.accessor()} stroke={ema6.stroke()} />
//         <CurrentCoordinate yAccessor={sma21.accessor()} fill={sma21.stroke()} />
//         <CurrentCoordinate yAccessor={ema6.accessor()} fill={ema6.stroke()} />

//         {/* <MovingAverageTooltip
//             onClick={e => console.log(e)}
//             origin={[-0, -40]}
//             options={[
//                 {
//                     yAccessor: sma21.accessor(),
//                     type: sma21.type(),
//                     stroke: sma21.stroke(),
//                     windowSize: sma21.options().windowSize,
//                 },
//                 {
//                     yAccessor: ema6.accessor(),
//                     type: ema6.type(),
//                     stroke: ema6.stroke(),
//                     windowSize: ema6.options().windowSize,
//                 },

//             ]}
//         /> */}

//     </Chart>
//     <CrossHairCursor stroke={this.props.colorMode === 'dark' ? '#ffffff' : '#000000'} />

// </ChartCanvas>
// </>
