import React from 'react';
import { Box, Tag, Button } from '@chakra-ui/react'


class TradeTypeView extends React.Component {
    render() {

        if (this.props.type === 'Single trade') {
            return null
        }
        return (
            <Box >
                <Tag
                    maxW="200px"
                    size={'xs'}
                    key={'dataAvailable'}
                    borderRadius="full"
                    variant="solid"
                    colorScheme={this.props.color}
                    fontSize="xs"
                    p="5px"
                    fontWeight={'600'}
                >
                    {this.props.type}
                </Tag>
            </Box>
        );
    }
}

export default TradeTypeView;