import { Box } from '@chakra-ui/layout';
import NavBar from '../homepage/menu/App';

import React, { Component } from 'react';
import FAQContainer from '../platform/articles/FAQContainer';

export class FAQPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    componentDidMount() {

    }

    render() {

        return (

            <Box >

                {/* {!this.props.match.params.articleId &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>FAQ</title>
                        <link rel="canonical" href="https://optiontracker.io/faq" />
                        <meta name="description" content="The Options Tracker FAQ page. Here you can find answers to common questions that our users ask us." />
                    </Helmet>

                } */}


                <NavBar />
                <Box mt="-72px" height="72px"></Box>
                <Box pt="0" margin="auto" >
                    <FAQContainer mode="DARK" homepage={true} history={this.props.history} articleId={this.props.match.params.articleId} location={this.props.location} />
                </Box>

            </Box>
        )

    }
}

export default FAQPage;

