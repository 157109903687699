import React, {Component} from 'react'
import { SimpleGrid, Box, Container, AspectRatio  } from '@chakra-ui/layout';
import {connect} from 'react-redux';
import { Spinner } from '../../components';
import API from '../../helpers/apiCalls';
import ScatterPlot from './chart-renders/ScatterPlot';


class ChartContainer extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

 

    render() { 
            if(this.state.loading === true) {
                return <Spinner/>
            }
          return (
              <Box>
                    <ScatterPlot title={"The effect of IV on profits"} data={this.props.trades} x={'initialIV'} y={'realisedProfitAndLoss'} xLabel="Implied volatility" yLabel="Realised profits" formatX={(e) => ""+(e*100).toFixed(0)+"%"} formatY={(e) => "$"+(e*100).toFixed(0)+""}/>
                    <ScatterPlot title={"The effect of your initial delta on profits"} data={this.props.trades} x={'initialDelta'} y={'realisedProfitAndLoss'}  xLabel="Starting delta" yLabel="Realised profits" formatX={(e) => ""+(e*100).toFixed(0)+"%"} formatY={(e) => "$"+(e*100).toFixed(0)+""}/>
                    <ScatterPlot title={"The effect of DTE on profits"} data={this.props.trades} x={'initialDaysToExpiry'} y={'realisedProfitAndLoss'}  xLabel="Initial DTE" yLabel="Realised profits" formatX={(e) => ""+(e).toFixed(0)+""} formatY={(e) => "$"+(e*100).toFixed(0)+""}/>
              </Box>
          )
    }
}
 
const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
  };

export default connect(mapStateToProps)(ChartContainer);
