import { AddIcon, CheckIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  useRadio,
  useRadioGroup,
  StackDivider,
  VStack,
  useColorMode,
  calc,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, set, useForm } from "react-hook-form";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import {
  CustomDatePicker,
  CustomDateRangePicker,
  CustomInputGroup,
  DropDown,
  InsetTab,
  InsetTabList,
  InsetTabPanels,
  InsetTabsContainer,
  NegativeButton,
  PrimaryButton,
  RadioCard,
  SecondaryButton,
  TextInput,
} from "../../../design_library";
import colors from "../../../helpers/colors";
import { Spinner } from "../../components/index";
import apiCalls from "../../helpers/apiCalls";
import API from "../../helpers/apiCalls";
import {
  CheckTourStatus,
  MarkPageCompleted,
  steps,
} from "../../helpers/CheckTourStatus";
import { APIEndpoint, customStrategies } from "../../helpers/dataStructures";
import { DropDownSelectorsCustom } from "../../helpers/DropDownSelectorsCustom";
import greeksComparison from "../../helpers/greeksComparison";
import { TabsAlt } from "../../helpers/Tabs";
import { fixTimezoneOffset } from "../../helpers/time";
import AddTradeMultiLeg from "./AddTrade-MultiLeg";
import styles from "./AddTrade.module.css";
import TagView from "./TagView";

class AddTrade extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      modalIsOpen: false,
      errors: {},
      stockData: {},
      open: false,
    };
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  addTrade = async (payload, error) => {
    this.setState({ loading: true });
    API.addTrade(this.props.settings.APIEndpoint, payload)
      .then((res) => {
        if (res.success === true) {
          this.setState({ modalIsOpen: false });

          if (this.props.refresh) {
            setTimeout(() => {
              this.setState({ loading: false });

              this.props.refresh();

              setTimeout(() => {
                this.props.refresh();
                this.props.closeWindow();
              }, 1500);
            }, 50);
          }

          if (this.props.completeIntroduction) {
            this.setState({ loading: false });

            this.props.completeIntroduction();
          }
        } else {
          if (res.err) {
            alert(res.err[0].message);
          } else {
            alert("Something went wrong...");
          }
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        if (error !== true) {
          this.addTrade(payload, true);
        } else {
          alert("Something has gone wrong, please try again.");
          this.setState({ loading: false });
        }
      });
  };

  componentDidMount() {}

  render() {
    return (
      <>
        {this.props.introduction === true ? (
          <SecondaryButton
            backgroundColor="var(--bg)"
            border="solid var(--accent) 1px"
            width="100%"
            onClick={() => this.setState({ modalIsOpen: true })}
            isLoading={this.state.loading}
            loadingText=""
          >
            Add trade
          </SecondaryButton>
        ) : (
          // <FormControl display="flex" flexDir="row" >
          //   <IconButton
          //     id="addtrade"
          //     pr="5"
          //     pl="4"
          //     aria-label=""
          //     colorScheme="blue"
          //     icon={
          //       <>
          //         <AddIcon mr="1" width="3" height="3" />
          //         <Text fontWeight='500' lineHeight={'100%'}>Add trade</Text>
          //       </>
          //     }
          //     onClick={() => this.setState({ modalIsOpen: true })}
          //     isLoading={this.state.loading}
          //     loadingText=""
          //   ></IconButton>
          // </FormControl>
          <></>
        )}

        <AddTradeForm
          allTags={this.props.allTags}
          setWidth={this.props.setWidth}
          trades={this.props.trades}
          loading={this.state.loading}
          refresh={this.props.refresh}
          addTrade={this.addTrade}
          toggleModal={() => {
            this.setState({ modalIsOpen: !this.state.modalIsOpen });
            this.props.closeWindow();
          }}
          APIEndpoint={this.props.settings.APIEndpoint}
          profile={this.props.settings.profile}
          predefinedStock={this.props.predefinedStock}
        />
      </>
    );
  }
}

function AddTradeForm(props) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, defaultValues },
  } = useForm();

  const [data, setData] = useState(null);
  const [stockName, setStockName] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [optionsData, setOptionData] = useState(null);
  const [tradeType, setTradeType] = useState("STOCK");
  const [action, setAction] = useState("BUY");
  const [leg, setLegType] = useState("SINGLE");
  const [dividend, setDividendType] = useState("CASH");
  const [subDividend, setSubDividendType] = useState("SHARES");
  const [totalSharesDividendRecieved, setTotalSharesDividendRecieved] =
    useState(0);
  const [perShareDollar, setPerShareDollar] = useState(0);
  const [divDollarReceived, setdivDollarReceived] = useState(0);

  const [expiryDate, setExpiryDate] = useState(moment().format("MM/DD/YYYY"));
  const [loading, setLoading] = useState(false);
  const [expiryOptions, setExpiryOptions] = useState([]);
  const [strikePrices, setStrikePrices] = useState([]);
  const [strikePrice, setStrikePrice] = useState([]);
  const [specificOptionData, setSpecificOptionData] = useState([]);
  const [specificContractData, setSpecificContractData] = useState(null);
  const [stockSearchErrorMessage, setStockSearchErrorMessage] = useState("");
  const [initialInTheMoney, setInitialInTheMoney] = useState(null);
  const [overrideStrikeInput, setOverrideStrikeInput] = useState(false);
  const [overrideExpiryDateInput, setOverrideExpiryDateInput] = useState(false);
  const [overrideStockTickerInput, setOverrideStockTickerInput] =
    useState(false);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(0);
  const [greeksData, setGreeksData] = useState([]);
  const [strategies, setStrategies] = useState([...customStrategies]);
  const [strategy, setStrategy] = useState({
    label: "No strategy",
    value: "No strategy",
  });
  const [tags, setTags] = useState([]);
  const [sources, setSources] = useState([
    { label: "No source", value: "No source" },
  ]);
  const [source, setSource] = useState({
    label: "No source",
    value: "No source",
  });
  const [dateOfSpotPrice, setDateOfSpotPrice] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);

  const [dividendList, setDividendList] = useState([]);
  const [selectedDividendDropdown, setSelectedDividendDropdown] =
    useState(null);

  //Trade type radio group
  const tradeTypeOptions = ["Stock", "Call", "Put", "Dividend"];
  const tradeTypeFunctions = useRadioGroup({
    name: "tradeType",
    defaultValue: "Stock",
    onChange: (e) => {
      let realValue = e.toUpperCase();
      setLoading(true);
      setTradeType(realValue);

      if (realValue === "STOCK") {
        stockFunctions.setValue("Buy");
      } else if (realValue === "DIVIDEND") {
        stockFunctions.setValue("Buy");
        setAction("BUY");

        apiCalls.getDividendData(getValues("stockTicker")).then((data) => {
          setDividendList(data.data);

          if (data.data.length > 0) {
            let lastDividend = data.data[data.data.length - 1];
            setValue(
              "dateOpened",
              moment(lastDividend.paymentDate, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              )
            );
          }
        });
        //getDividend data
      } else {
        actionFunctions.setValue("Buy to open");
      }

      if (realValue !== "STOCK") {
        setAction("BUY_TO_OPEN");

        if (props.trades && props.trades.length > 0) {
          greeksComparison
            .calculateGreeksComparison(
              props.trades,
              realValue,
              "SELL_TO_OPEN",
              false
            )
            .then((array) => {
              setGreeksData(array);
            });
        }

        API.getOptionData(props.APIEndpoint, getValues("stockTicker"))
          .then((res) => {
            if (res.success === true) {
              setOptionData(res.data);
              let result = res.data.data.map((a) => a.expirationDate);
              setExpiryOptions(result);
              if (result.length > 0) {
                setOverrideExpiryDateInput(false);
                setOverrideStrikeInput(false);
              }
              setLoading(false);
            } else {
              console.log(
                "Something went wrong with getting the options data..."
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log("Something went wrong getting options data.");
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    },
  });
  const tradeTypeGroup = tradeTypeFunctions.getRootProps();

  //Dividend type radio group
  const dividendOptions = ["Cash", "Re-invest"];
  const dividendFunctions = useRadioGroup({
    name: "dividend",
    defaultValue: "Cash",
    onChange: (e) => {
      let realValue = e;
      if (realValue === "Cash") {
        realValue = "CASH";
      } else if (realValue === "Re-invest") {
        realValue = "REINVEST";
      }
      setDividendType(realValue);
    },
  });
  const dividendGroup = dividendFunctions.getRootProps();

  //Dividend type radio group
  const subDivOptions = ["Shares", "$ Per-share"];
  const subDivFunctions = useRadioGroup({
    name: "subDividend",
    defaultValue: "Shares",
    onChange: (e) => {
      let realValue = e;
      if (realValue === "Shares") {
        realValue = "SHARES";
      } else if (realValue === "$ Per-share") {
        realValue = "DOLLAR";
      }
      setSubDividendType(realValue);
    },
  });
  const subDivGroup = subDivFunctions.getRootProps();

  //Action type radio group
  const legOptions = ["Single-leg", "Multi-leg"];
  const legFunctions = useRadioGroup({
    name: "legs",
    defaultValue: "Single-leg",
    onChange: (e) => {
      let realValue = e;
      if (realValue === "Single-leg") {
        realValue = "SINGLE";
      } else if (realValue === "Multi-leg") {
        realValue = "MULTI";
      }
      setLegType(realValue);
    },
  });
  const legGroup = legFunctions.getRootProps();

  //Action type radio group
  const actionsOptions = ["Buy to open", "Sell to open"];
  const actionFunctions = useRadioGroup({
    name: "action",
    defaultValue: "Buy to open",
    onChange: (e) => {
      let realValue = e;
      if (realValue === "Sell to open") {
        realValue = "SELL_TO_OPEN";
      } else if (realValue === "Buy to open") {
        realValue = "BUY_TO_OPEN";
      }

      setAction(realValue);

      if (props.trades && props.trades.length > 0) {
        greeksComparison
          .calculateGreeksComparison(props.trades, tradeType, realValue, false)
          .then((array) => {
            setGreeksData(array);
          });
      }
    },
  });
  const actionGroup = actionFunctions.getRootProps();

  //Stock type radio group
  const stockOptions = ["Buy", "Short"];
  const stockFunctions = useRadioGroup({
    name: "stockActions",
    defaultValue: "Buy",
    onChange: (e) => {
      let realValue = e;
      if (realValue === "Buy") {
        realValue = "BUY";
      } else if (realValue === "Short") {
        realValue = "SHORT";
      }
      setAction(realValue);
    },
  });
  const stockGroup = stockFunctions.getRootProps();

  // const [, setDaysUntilExpiry] = useState(0);

  const [initialDeltaValidationMessage, setInitialDeltaValidationMessage] =
    useState(0);
  const [initialGammaValidationMessage, setInitialGammaValidationMessage] =
    useState(0);
  const [initialRhoValidationMessage, setinItialRhoValidationMessage] =
    useState(0);
  const [initialThetaValidationMessage, setInitialThetaValidationMessage] =
    useState(0);
  const [initialVegaValidationMessage, setInitialVegaValidationMessage] =
    useState(0);
  const [initialIVValidationMessage, setInitialIVValidationMessage] =
    useState(0);

  const onSubmit = (data, e) => {
    let difference = undefined;
    let protection = undefined;
    let initialPremiumPerDay = undefined;
    let totalRisk = undefined;
    let potentialReturnPercentage = undefined;
    let AnnualisedReturn = undefined;
    if (data.tradeType !== "STOCK" && data.tradType !== "DIVIDEND") {
      var b = moment(data.dateOpened, "MM/DD/YYYY");
      var a = moment(data.expiryDate, "MM/DD/YYYY");
      difference = a.diff(b, "days");

      protection =
        (data.spotPriceAtTradeTime - data.strikePrice) /
        data.spotPriceAtTradeTime;

      initialPremiumPerDay = data.premium / difference;

      totalRisk = data.spotPriceAtTradeTime * data.contracts * 100;

      potentialReturnPercentage = (data.premium / totalRisk) * 100;

      AnnualisedReturn = (potentialReturnPercentage / difference) * 365;
    } else {
      totalRisk = data.spotPriceAtTradeTime * data.quantity;
    }

    let accountId = props.profile.accounts.filter((value, index) => {
      if (value.selected === true) {
        return value;
      }
    });
    accountId = accountId[0].id;

    let payload = {
      stockTicker: data.stockTicker,
      tradeType: tradeType,
      action: action,
      strategy: strategy.value,
      positionStatus: "OPEN",
      description: undefined,
      dateOpened: data.dateOpened,
      quantity: data.quantity,
      dateClosed: data.dateClosed,
      expiryDate: data.expiryDate,
      initialDaysToExpiry: difference,
      contracts: data.contracts,
      premium: data.initialLastPrice,
      spotPriceAtTradeTime: data.spotPriceAtTradeTime,
      strikePrice: data.strikePrice,
      protection: protection,
      initialPremiumPerDay: initialPremiumPerDay,
      initialDelta: data.initialDelta,
      initialGamma: data.initialGamma,
      initialInTheMoney: data.initialInTheMoney,
      initialIntrinsicValue: data.initialIntrinsicValue,
      initialLastPrice: data.initialLastPrice,
      initialRho: data.initialRho,
      initialTheoretical: data.initialTheoretical,
      initialTheta: data.initialTheta,
      initialTimeValue: data.initialTimeValue,
      initialVega: data.initialVega,
      initialIV: data.initialIV,
      // initialOTM:data.initialOTM,
      potentialProfit: data.potentialProfit,
      totalRisk: totalRisk,
      potentialReturnPercentage: potentialReturnPercentage,
      AnnualisedReturn: AnnualisedReturn,
      assignedBool: undefined,
      spotPriceAtAssignment: undefined,
      buyBackPricePerContract: undefined,
      tradeSource: source.value,
      notes: data.notes,
      realisedProfitAndLoss: undefined,
      account: accountId,
      overrideTicker: overrideStockTickerInput,
      tags: tags,

      commission: parseFloat(data.commission),
    };

    if (tradeType === "DIVIDEND" && dividend === "CASH") {
      payload.tradeType = "STOCK";
      payload.action = "BUY";
      payload.isDividendFlag = true;
      payload.positionStatus = "CLOSED";
      payload.dateClosed = payload.dateOpened;
    }
    if (tradeType === "DIVIDEND" && dividend === "REINVEST") {
      payload.tradeType = "STOCK";
      payload.action = "BUY";
      payload.isDividendFlag = true;
      payload.positionStatus = "OPEN";
    }

    payload = Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v != null)
    );

    props.addTrade(payload);
  };

  const handleCreateItem = (item) => {
    setStrategies((curr) => [...curr, item]);
    setStrategy((curr) => item);
  };
  const handleCreateItemSource = (item) => {
    setSources((curr) => [...curr, item]);
    setSource((curr) => item);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setStrategy({ label: "No strategy", value: "No strategy" });
      } else {
        if (selectedItems.length > 1) {
          setStrategy(selectedItems[1]);
        } else {
          setStrategy({ label: "No strategy", value: "No strategy" });
        }
      }
    }
  };
  const handleSelectedItemsChangeSource = (selectedItems) => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setSource({ label: "No source", value: "No source" });
      } else {
        if (selectedItems.length > 1) {
          setSource(selectedItems[1]);
        } else {
          setSource({ label: "No source", value: "No source" });
        }
      }
    }
  };

  const customRender = (selected) => {
    return (
      <Flex flexDir="row" alignItems="center">
        <Text>{selected.label}</Text>
      </Flex>
    );
  };

  const customCreateItemRender = (value) => {
    return (
      <Text>
        <Box as="span">Create</Box>{" "}
        <Box as="span" bg="red.300" fontWeight="bold">
          "{value}"
        </Box>
      </Text>
    );
  };

  const skipSearch = () => {
    setOverrideStockTickerInput(true);

    setLoading(true);
    setStockName(getValues("stockTicker").toUpperCase());
    setStockData(null);
    setOptionData(null);
    setTradeType("STOCK");
    setExpiryDate(null);
    setExpiryOptions([]);
    setStrikePrices([]);
    setStrikePrice([]);
    setSpecificOptionData([]);
    setSpecificContractData(null);
    setStockSearchErrorMessage(null);
    setInitialInTheMoney(null);
    setValue("tradeType", null);
    setValue("spotPriceAtTradeTime", 0);
    let strategies = [];
    props.trades.filter((value, index) => {
      if (value.strategy !== undefined) {
        strategies.push({ label: value.strategy, value: value.strategy });
      }
    });

    const unique = [...new Set(strategies.map((item) => item.value))];
    let stratOb = [];
    unique.filter((value, index) => {
      stratOb.push({ label: value, value: value });
    });
    setStrategies(stratOb);

    let sources = [];
    props.trades.filter((value, index) => {
      if (value.tradeSource !== undefined) {
        sources.push({ label: value.tradeSource, value: value.tradeSource });
      }
    });

    const uniqueSource = [...new Set(sources.map((item) => item.value))];
    let sourceOb = [];
    uniqueSource.filter((value, index) => {
      sourceOb.push({ label: value, value: value });
    });
    setSources(sourceOb);
    setLoading(false);

    setValue("stockTicker", getValues("stockTicker").toUpperCase());
    return "";
  };

  useEffect(() => {
    if (props.predefinedStock) {
      setValue(
        "stockTicker",
        props.predefinedStock.toUpperCase().replace("%2F", "/")
      );
      search();
    }
  }, [props.predefinedStock]);

  const search = () => {
    setLoading(true);
    setStockName(null);
    setStockData(null);
    setOptionData(null);
    setTradeType("STOCK");
    setExpiryDate(null);
    setExpiryOptions([]);
    setStrikePrices([]);
    setStrikePrice([]);
    setSpecificOptionData([]);
    setSpecificContractData(null);
    setStockSearchErrorMessage(null);
    setInitialInTheMoney(null);

    let stockTicker = getValues("stockTicker").replace("/", "%2F");

    setValue("tradeType", null);
    API.searchForStock(props.APIEndpoint, stockTicker)
      .then((data) => {
        setStockSearchErrorMessage(null);
        if (data.success === true) {
          setValue("stockTicker", data.data.Code);
          let name = data.data.Name;
          API.getStockPrice(props.APIEndpoint, getValues("stockTicker"))
            .then((data) => {
              try {
                setStockData(data.data);

                if (name === undefined) {
                  name = data.data.stockTicker;
                }
                setStockName(name);

                setValue("spotPriceAtTradeTime", data.data.close || 0);

                let strategies = [...customStrategies];
                let stratOb = [];
                let sources = [];
                let sourceOb = [];

                if (props.trades) {
                  props.trades.filter((value, index) => {
                    if (value.strategy !== undefined) {
                      strategies.push({
                        label: value.strategy,
                        value: value.strategy,
                      });
                    }
                  });

                  const unique = [
                    ...new Set(strategies.map((item) => item.value)),
                  ];
                  unique.filter((value, index) => {
                    stratOb.push({ label: value, value: value });
                  });
                  setStrategies(stratOb);

                  props.trades.filter((value, index) => {
                    if (value.tradeSource !== undefined) {
                      sources.push({
                        label: value.tradeSource,
                        value: value.tradeSource,
                      });
                    }
                  });

                  const uniqueSource = [
                    ...new Set(sources.map((item) => item.value)),
                  ];
                  uniqueSource.filter((value, index) => {
                    sourceOb.push({ label: value, value: value });
                  });
                }

                setSources(sourceOb);

                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            })
            .catch((e) => {
              console.log(e);
              setStockSearchErrorMessage(
                "There was an error retrieving the stock data."
              );
              setLoading(false);
            });
        } else {
          //something went wrong...
          setStockSearchErrorMessage(data.message);
          setOverrideStrikeInput(true);
          setOverrideExpiryDateInput(true);

          setStockName(stockTicker.toUpperCase().replace("%2F", ""));

          //Set stock price
          setValue("spotPriceAtTradeTime", 0);

          let strategies = [];
          let stratOb = [];
          let sources = [];
          let sourceOb = [];

          if (props.trades) {
            props.trades.filter((value, index) => {
              if (value.strategy !== undefined) {
                strategies.push({
                  label: value.strategy,
                  value: value.strategy,
                });
              }
            });

            const unique = [...new Set(strategies.map((item) => item.value))];
            unique.filter((value, index) => {
              stratOb.push({ label: value, value: value });
            });
            setStrategies(stratOb);

            props.trades.filter((value, index) => {
              if (value.tradeSource !== undefined) {
                sources.push({
                  label: value.tradeSource,
                  value: value.tradeSource,
                });
              }
            });

            const uniqueSource = [
              ...new Set(sources.map((item) => item.value)),
            ];
            uniqueSource.filter((value, index) => {
              sourceOb.push({ label: value, value: value });
            });
          }

          setSources(sourceOb);

          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Something went wrong with the stock request.", e);
        setStockSearchErrorMessage("We could not find that stock.");
        setLoading(false);
      });
    return "";
  };

  const setDate = (e, field) => {
    setValue(
      "dateOpened",
      moment(fixTimezoneOffset(e)).tz("Europe/London").format("MM/DD/YYYY")
    );
    field.field.onChange(e);

    let stockTicker = getValues("stockTicker");
    setPriceLoading(true);

    let today = moment();
    let date = moment(fixTimezoneOffset(e))
      .tz("Europe/London")
      .format("YYYY-MM-DD");

    if (!today.isSame(date, "d")) {
      API.getStockPriceByDate(props.APIEndpoint, stockTicker, date).then(
        (data) => {
          if (data.success === false) {
            setValue("spotPriceAtTradeTime", 0);
            setDateOfSpotPrice(
              moment(fixTimezoneOffset(e))
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            );
            setPriceLoading(false);
          } else if (data.data) {
            setValue("spotPriceAtTradeTime", data.data.close.toFixed(2));
            // console.log('y', data.data.close.toFixed(2));

            setDateOfSpotPrice(
              moment(fixTimezoneOffset(e))
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            );
            setPriceLoading(false);
          } else {
            // alert("Failed to get live spot price for that date.")
            setPriceLoading(false);
          }
        }
      );
    } else {
      API.getStockPrice(props.APIEndpoint, getValues("stockTicker")).then(
        (data) => {
          if (data.success === false) {
            setValue("spotPriceAtTradeTime", 0);
            setDateOfSpotPrice(null);
            setPriceLoading(false);
          } else {
            try {
              setValue("spotPriceAtTradeTime", data.data.close.toFixed(2));

              // console.log('x', data.data.close.toFixed(2));
            } catch (e) {
              setValue("spotPriceAtTradeTime", 0);
            }
            setDateOfSpotPrice(null);
            setPriceLoading(false);
          }
        }
      );
    }
  };

  const calcDiv = () => {
    let spot = parseFloat(getValues("spotPriceAtTradeTime"));
    let quantity = parseFloat(perShareDollar);
    let perShare = totalSharesDividendRecieved;
    let dollarRecieved = quantity * perShare;
    setdivDollarReceived(dollarRecieved);
    let additionalShares = (dollarRecieved / spot).toFixed(2);

    setValue("quantity", additionalShares);
  };

  return (
    <>
      <InsetTabsContainer isLazy>
        <InsetTabList id="choiceSingleOrMulti">
          <InsetTab>Single-Leg</InsetTab>
          <InsetTab>Multi-leg</InsetTab>
        </InsetTabList>

        {loading === true && (
          <>
            <Box margin="20px">
              <Spinner />
            </Box>
          </>
        )}

        <InsetTabPanels>
          <TabPanel padding={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Stack spacing="4">
                <VStack width="full" spacing="6">
                  {stockName && (
                    <FormControl>
                      <FormLabel fontWeight={600}>Trade type</FormLabel>
                      <CustomInputGroup>
                        <HStack width="100%" {...tradeTypeGroup} id="tradeType">
                          {tradeTypeOptions.map((value) => {
                            const radio = tradeTypeFunctions.getRadioProps({
                              value,
                            });
                            return (
                              <RadioCard key={value} {...radio}>
                                {value}
                              </RadioCard>
                            );
                          })}
                        </HStack>
                      </CustomInputGroup>
                    </FormControl>
                  )}

                  {stockName && tradeType === "DIVIDEND" && (
                    <FormControl>
                      <FormLabel fontWeight={600}>Action</FormLabel>
                      <CustomInputGroup>
                        <HStack width="100%" {...dividendGroup} id="tradeType">
                          {dividendOptions.map((value) => {
                            const radio = dividendFunctions.getRadioProps({
                              value,
                            });
                            return (
                              <RadioCard key={value} {...radio}>
                                {value}
                              </RadioCard>
                            );
                          })}
                        </HStack>
                      </CustomInputGroup>
                    </FormControl>
                  )}

                  {stockName && tradeType !== "DIVIDEND" && (
                    <>
                      <FormControl>
                        <FormLabel fontWeight={600}>Action</FormLabel>
                        <CustomInputGroup>
                          {stockName && tradeType !== "STOCK" && (
                            <HStack
                              width="100%"
                              {...actionGroup}
                              id="actionTab"
                            >
                              {actionsOptions.map((value) => {
                                const radio = actionFunctions.getRadioProps({
                                  value,
                                });
                                return (
                                  <RadioCard key={value} {...radio}>
                                    {value}
                                  </RadioCard>
                                );
                              })}
                            </HStack>
                          )}

                          {stockName && tradeType === "STOCK" && (
                            <HStack width="100%" {...stockGroup} id="actionTab">
                              {stockOptions.map((value) => {
                                const radio = stockFunctions.getRadioProps({
                                  value,
                                });
                                return (
                                  <RadioCard key={value} {...radio}>
                                    {value}
                                  </RadioCard>
                                );
                              })}
                            </HStack>
                          )}
                        </CustomInputGroup>
                      </FormControl>
                    </>
                  )}

                  {/* {stockName && tradeType === 'DIVIDEND' &&
                    <>
                      <FormControl>
                        <FormLabel fontWeight={600}>Action</FormLabel>
                        <CustomInputGroup>
                          {stockName && tradeType !== "STOCK" && (
                            <HStack width="100%" {...dividendGroup} id="dividendTab">
                              {dividendOptions.map((value) => {
                                const radio = dividendFunctions.getRadioProps({ value })
                                return (
                                  <RadioCard key={value} {...radio}>
                                    {value}
                                  </RadioCard>
                                )
                              })}
                            </HStack>

                          )}
                        </CustomInputGroup>
                      </FormControl>
                    </>
                  } */}
                </VStack>

                {stockName && (
                  <FormControl className="dateOpened" id="dateOpened">
                    {tradeType !== "DIVIDEND" && (
                      <>
                        <FormLabel fontWeight={600}>Date opened</FormLabel>
                        <CustomInputGroup>
                          <Controller
                            control={control}
                            name="ReactDatepickerDateOpened"
                            render={(field) => (
                              <CustomDatePicker
                                id="addtradedatepicker"
                                placeholderText="Select date trade was opened"
                                onChange={(e) => {
                                  setDate(e, field);
                                }}
                                selected={field.field.value}
                              />
                            )}
                          />
                        </CustomInputGroup>
                      </>
                    )}

                    {tradeType === "DIVIDEND" && !loading && (
                      <>
                        {/* Here */}
                        <FormLabel fontWeight={600}>Date received</FormLabel>
                        <CustomInputGroup>
                          {dividendList && dividendList.length <= 0 && (
                            <Controller
                              control={control}
                              name="ReactDatepickerDateOpened"
                              render={(field) => (
                                <CustomDatePicker
                                  placeholderText="Select date dividend was received"
                                  onChange={(e) => {
                                    setDate(e, field);
                                  }}
                                  selected={field.field.value}
                                />
                              )}
                            />
                          )}

                          {dividendList && dividendList.length > 0 && (
                            <InputGroup>
                              <Select
                                size="lg"
                                onChange={(e) => {
                                  let date = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD");
                                  setSelectedDividendDropdown(e.target.value);
                                  setValue("dateOpened", date);
                                  let getDiv = {};
                                  dividendList.filter((value) => {
                                    if (value.paymentDate === e.target.value) {
                                      getDiv = value;
                                    }
                                  });

                                  let relevantTrades = [];
                                  let totalQuantity = 0;
                                  props.trades.filter((value) => {
                                    if (
                                      value.positionStatus === "OPEN" &&
                                      value.stockTicker ===
                                        getValues("stockTicker")
                                    ) {
                                      if (
                                        moment(value.dateOpened).isBefore(
                                          moment(getDiv.recordDate)
                                        )
                                      ) {
                                        relevantTrades.push(value);
                                        totalQuantity += value.quantity;
                                      }
                                    }
                                  });

                                  setValue(
                                    "quantity",
                                    (getDiv.value * totalQuantity).toFixed(4)
                                  );

                                  API.getStockPriceByDate(
                                    APIEndpoint,
                                    getValues("stockTicker"),
                                    e.target.value
                                  ).then((data) => {
                                    try {
                                      if (data.success === true) {
                                        // here
                                        console.log({ tradeType, dividend });
                                        if (
                                          tradeType === "DIVIDEND" &&
                                          dividend === "CASH"
                                        ) {
                                          setValue("spotPriceAtTradeTime", 1);
                                        } else {
                                          setValue(
                                            "spotPriceAtTradeTime",
                                            data.data.close.toFixed(2)
                                          );
                                        }
                                        setDateOfSpotPrice(
                                          moment(date)
                                            .tz("Europe/London")
                                            .format("YYYY-MM-DD")
                                        );

                                        setPriceLoading(false);
                                      }
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  });
                                }}
                              >
                                <option value={""}>
                                  Select a payment date
                                </option>
                                {dividendList &&
                                  dividendList.map((value, index) => {
                                    return (
                                      <option
                                        value={value.paymentDate}
                                        key={index}
                                      >
                                        {value.paymentDate} (Freq:{" "}
                                        {value.period}, Value: {value.value})
                                      </option>
                                    );
                                  })}
                              </Select>
                            </InputGroup>
                          )}
                        </CustomInputGroup>
                      </>
                    )}
                  </FormControl>
                )}

                {stockName &&
                  tradeType !== "STOCK" &&
                  tradeType !== "DIVIDEND" && (
                    <VStack width="full" spacing="6">
                      {loading ? (
                        <FormControl id="expiryDate">
                          <FormLabel fontWeight={600}>Expiry date</FormLabel>
                          <CustomInputGroup>
                            <Select isLoading={true}>
                              <option value={""}>Loading...</option>
                            </Select>
                          </CustomInputGroup>
                        </FormControl>
                      ) : (
                        <>
                          {tradeType !== "STOCK" && (
                            <FormControl id="expiryDate">
                              <FormLabel fontWeight={600}>
                                Expiry date
                              </FormLabel>

                              {overrideExpiryDateInput === false ? (
                                <CustomInputGroup>
                                  <Select
                                    style={{
                                      backgroundColor: "var(--dark-gray)",
                                      border: 0,
                                    }}
                                    onChange={(e) => {
                                      try {
                                        console.log(
                                          moment(e.target.value).format(
                                            "MM/DD/YYYY"
                                          )
                                        );

                                        setValue(
                                          "expiryDate",
                                          moment(e.target.value).format(
                                            "MM/DD/YYYY"
                                          )
                                        );
                                        setLoading(true);
                                        let exDate = moment(
                                          e.target.value
                                        ).format("YYYY-MM-DD");
                                        let specificOption =
                                          optionsData.data.find((x) => {
                                            console.log(
                                              x.expirationDate,
                                              exDate
                                            );
                                            return x.expirationDate === exDate;
                                          });

                                        setSpecificOptionData(
                                          specificOption.options[tradeType]
                                        );
                                        let distilledStrikePrices =
                                          specificOption.options[tradeType].map(
                                            (a) => "" + a.strike
                                          );
                                        setStrikePrices(distilledStrikePrices);

                                        //Calculate days until expiry
                                        var expiryDate = moment(e.target.value);
                                        var dateOpened =
                                          getValues("dateOpened");
                                        dateOpened = moment(dateOpened);
                                        let daysUntilExpiryValue =
                                          expiryDate.diff(dateOpened, "days");

                                        if (
                                          greeksData[0].lossAverage === 0 &&
                                          greeksData[1].lossAverage === 0 &&
                                          greeksData[2].lossAverage === 0 &&
                                          greeksData[3].lossAverage === 0 &&
                                          greeksData[4].lossAverage === 0 &&
                                          greeksData[5].lossAverage === 0
                                        ) {
                                          setDaysUntilExpiry(<></>);
                                        } else {
                                          if (
                                            greeksData[5].direction === "less"
                                          ) {
                                            if (
                                              daysUntilExpiryValue <
                                              greeksData[5].lossAverage
                                            ) {
                                              setDaysUntilExpiry(
                                                <FormHelperText color="#e53e3e">
                                                  {daysUntilExpiryValue} days
                                                  until expiry. Your trading
                                                  data shows you win more trades
                                                  when your DTE is below{" "}
                                                  {greeksData[5].lossAverage.toFixed(
                                                    0
                                                  )}{" "}
                                                  days.{" "}
                                                </FormHelperText>
                                              );
                                            } else {
                                              setDaysUntilExpiry(
                                                <FormHelperText color="#37a169">
                                                  {daysUntilExpiryValue} days
                                                  until expiry. Your trading
                                                  data shows you lose more
                                                  trades when your DTE is above{" "}
                                                  {greeksData[5].lossAverage.toFixed(
                                                    0
                                                  )}{" "}
                                                  days.{" "}
                                                </FormHelperText>
                                              );
                                            }
                                          } else {
                                            if (
                                              daysUntilExpiryValue >
                                              greeksData[5].lossAverage
                                            ) {
                                              setDaysUntilExpiry(
                                                <FormHelperText color="#e53e3e">
                                                  {daysUntilExpiryValue} days
                                                  until expiry. Your trading
                                                  data shows you win more trades
                                                  when your DTE is above{" "}
                                                  {greeksData[5].lossAverage.toFixed(
                                                    0
                                                  )}{" "}
                                                  days.{" "}
                                                </FormHelperText>
                                              );
                                            } else {
                                              setDaysUntilExpiry(
                                                <FormHelperText color="#37a169">
                                                  {daysUntilExpiryValue} days
                                                  until expiry. Your trading
                                                  data shows you lose more
                                                  trades when your DTE is below{" "}
                                                  {greeksData[5].lossAverage.toFixed(
                                                    0
                                                  )}{" "}
                                                  days.{" "}
                                                </FormHelperText>
                                              );
                                            }
                                          }
                                        }
                                      } catch (e) {
                                        console.log(e);
                                      }
                                      setLoading(false);
                                    }}
                                    value={expiryDate}
                                  >
                                    <option value={""}>Select a expiry</option>
                                    {expiryOptions.map((value, index) => {
                                      return (
                                        <option value={value} key={index}>
                                          {moment(value).format("Do MMM YYYY")}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                </CustomInputGroup>
                              ) : (
                                <CustomInputGroup>
                                  <Controller
                                    control={control}
                                    name="ReactDatepickerExpiryDate"
                                    render={(field) => {
                                      return (
                                        <CustomDatePicker
                                          placeholderText="Select expiry date"
                                          onChange={(e) => {
                                            setExpiryDate(
                                              moment(
                                                fixTimezoneOffset(e)
                                              ).format("MM/DD/YYYY")
                                            );
                                            setValue(
                                              "expiryDate",
                                              moment(
                                                fixTimezoneOffset(e)
                                              ).format("MM/DD/YYYY")
                                            );
                                            field.field.onChange(e);
                                          }}
                                          selected={field.field.value}
                                        />
                                      );
                                    }}
                                  />
                                </CustomInputGroup>
                              )}

                              <InputRightElement width="7.5rem">
                                <Button
                                  h="1.75rem"
                                  size="sm"
                                  onClick={() => {
                                    if (overrideExpiryDateInput === false) {
                                      setOverrideExpiryDateInput(true);
                                      setOverrideStrikeInput(true);
                                    } else {
                                      setOverrideExpiryDateInput(false);
                                    }
                                  }}
                                >
                                  {overrideExpiryDateInput === false
                                    ? "Override"
                                    : "Cancel"}
                                </Button>
                              </InputRightElement>
                              {daysUntilExpiry !== 0 && daysUntilExpiry}
                            </FormControl>
                          )}

                          {tradeType !== "STOCK" && (
                            <FormControl id="strike">
                              <FormLabel fontWeight={600}>
                                Strike Price
                              </FormLabel>
                              {overrideStrikeInput === false ? (
                                <Select
                                  style={{
                                    backgroundColor: "var(--dark-gray)",
                                    border: 0,
                                  }}
                                  onChange={(e) => {
                                    try {
                                      setLoading(true);

                                      setStrikePrice(e.target.value);
                                      setValue("strikePrice", e.target.value);

                                      let specificContract =
                                        specificOptionData.find(
                                          (x) =>
                                            "" + x.strike ===
                                            "" + e.target.value
                                        );

                                      setSpecificContractData(specificContract);

                                      //  setValue('initialDelta', specificContract.delta);
                                      setValue(
                                        "initialDelta",
                                        specificContract.delta
                                      );
                                      setValue(
                                        "initialGamma",
                                        specificContract.gamma
                                      );
                                      setValue(
                                        "initialInTheMoney",
                                        specificContract.inTheMoney
                                      );
                                      setInitialInTheMoney(
                                        specificContract.inTheMoney
                                      );
                                      setValue(
                                        "initialIntrinsicValue",
                                        specificContract.intrinsicValue
                                      );
                                      setValue(
                                        "initialLastPrice",
                                        specificContract.lastPrice
                                      );
                                      setValue(
                                        "initialRho",
                                        specificContract.rho
                                      );
                                      setValue(
                                        "initialTheoretical",
                                        specificContract.theoretical
                                      );
                                      setValue(
                                        "initialTheta",
                                        specificContract.theta
                                      );
                                      setValue(
                                        "initialTimeValue",
                                        specificContract.timeValue
                                      );
                                      setValue(
                                        "initialVega",
                                        specificContract.vega
                                      );
                                      setValue(
                                        "initialIV",
                                        specificContract.impliedVolatility
                                      );

                                      if (
                                        greeksData[0].lossAverage === 0 &&
                                        greeksData[1].lossAverage === 0 &&
                                        greeksData[2].lossAverage === 0 &&
                                        greeksData[3].lossAverage === 0 &&
                                        greeksData[4].lossAverage === 0 &&
                                        greeksData[5].lossAverage === 0
                                      ) {
                                        setDaysUntilExpiry(<></>);
                                        setInitialIVValidationMessage(<></>);
                                        setInitialDeltaValidationMessage(<></>);
                                        setInitialGammaValidationMessage(<></>);
                                        setInitialVegaValidationMessage(<></>);
                                        setInitialThetaValidationMessage(<></>);
                                        setinItialRhoValidationMessage(<></>);
                                      } else {
                                        //IV
                                        if (
                                          greeksData[0].direction === "less"
                                        ) {
                                          if (
                                            specificContract.impliedVolatility <
                                            greeksData[0].lossAverage * 100
                                          ) {
                                            setInitialIVValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when your IV is
                                                below{" "}
                                                {(
                                                  greeksData[0].lossAverage *
                                                  100
                                                ).toFixed(2)}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialIVValidationMessage(
                                              <FormHelperText color="red">
                                                Your trading data shows you lose
                                                more trades when your IV is
                                                above{" "}
                                                {(
                                                  greeksData[0].lossAverage *
                                                  100
                                                ).toFixed(2)}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        } else {
                                          if (
                                            specificContract.impliedVolatility >
                                            greeksData[0].lossAverage * 100
                                          ) {
                                            setInitialIVValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when your IV is
                                                above{" "}
                                                {(
                                                  greeksData[0].lossAverage *
                                                  100
                                                ).toFixed(2)}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialIVValidationMessage(
                                              <FormHelperText color="red">
                                                Your trading data shows you lose
                                                more trades when your IV is
                                                below{" "}
                                                {(
                                                  greeksData[0].lossAverage *
                                                  100
                                                ).toFixed(2)}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        }

                                        //Delta
                                        if (
                                          greeksData[1].direction === "less"
                                        ) {
                                          if (
                                            specificContract.delta <
                                            greeksData[1].lossAverage
                                          ) {
                                            setInitialDeltaValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when your delta is
                                                below{" "}
                                                {greeksData[1].lossAverage.toFixed(
                                                  2
                                                )}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialDeltaValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when your delta is
                                                above{" "}
                                                {greeksData[1].lossAverage.toFixed(
                                                  4
                                                )}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        } else {
                                          if (
                                            specificContract.delta >
                                            greeksData[1].lossAverage
                                          ) {
                                            setInitialDeltaValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when your delta is
                                                above{" "}
                                                {greeksData[1].lossAverage.toFixed(
                                                  2
                                                )}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialDeltaValidationMessage(
                                              <FormHelperText color="red">
                                                Your trading data shows you lose
                                                more trades when your delta is
                                                below{" "}
                                                {greeksData[1].lossAverage.toFixed(
                                                  4
                                                )}
                                                .{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        }

                                        //Vega
                                        if (
                                          greeksData[2].direction === "less"
                                        ) {
                                          let value = greeksData[2].lossAverage;
                                          let name = "vega";
                                          let greek = specificContract.vega;

                                          if (greek < value) {
                                            setInitialVegaValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialVegaValidationMessage(
                                              <FormHelperText color="red">
                                                Your trading data shows you lose
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        } else {
                                          let value = greeksData[2].lossAverage;
                                          let name = "vega";
                                          let greek = specificContract.vega;

                                          if (greek > value) {
                                            setInitialVegaValidationMessage(
                                              <FormHelperText color="green">
                                                Your trading data shows you win
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialVegaValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        }
                                        //Theta
                                        if (
                                          greeksData[3].direction === "less"
                                        ) {
                                          let value = greeksData[3].lossAverage;
                                          let name = "theta";
                                          let greek = specificContract.theta;

                                          if (greek < value) {
                                            setInitialThetaValidationMessage(
                                              <FormHelperText color="green">
                                                {" "}
                                                Your trading data shows you win
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setInitialThetaValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        } else {
                                          let value = greeksData[3].lossAverage;
                                          let name = "theta";
                                          let greek = specificContract.theta;

                                          if (greek > value) {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="green">
                                                {" "}
                                                Your trading data shows you win
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        }
                                        //Rho
                                        if (
                                          greeksData[4].direction === "less"
                                        ) {
                                          let value = greeksData[4].lossAverage;
                                          let name = "theta";
                                          let greek = specificContract.rho;

                                          if (greek < value) {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="green">
                                                {" "}
                                                Your trading data shows you win
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        } else {
                                          let value = greeksData[4].lossAverage;
                                          let name = "rho";
                                          let greek = specificContract.rho;

                                          if (greek > value) {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="green">
                                                {" "}
                                                Your trading data shows you win
                                                more trades when {name} is above{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          } else {
                                            setinItialRhoValidationMessage(
                                              <FormHelperText color="red">
                                                {" "}
                                                Your trading data shows you lose
                                                more trades when {name} is below{" "}
                                                {value.toFixed(4)}.{" "}
                                              </FormHelperText>
                                            );
                                          }
                                        }
                                      }
                                    } catch (e) {
                                      console.log(e);
                                    }
                                    setLoading(false);
                                  }}
                                  value={strikePrice}
                                >
                                  <option value={""}>Select a strike</option>
                                  {strikePrices.map((value, index) => {
                                    return (
                                      <option key={index} value={value}>
                                        {value}
                                      </option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <CustomInputGroup>
                                  <Input
                                    style={{
                                      backgroundColor: "var(--dark-gray)",
                                      border: 0,
                                    }}
                                    type="number"
                                    step={0.5}
                                    placeholder="Strike"
                                    {...register("strikePrice")}
                                  />
                                </CustomInputGroup>
                              )}

                              <InputRightElement width="7.5rem">
                                <Button
                                  h="1.75rem"
                                  size="sm"
                                  onClick={() => {
                                    if (overrideStrikeInput === false) {
                                      setOverrideStrikeInput(true);
                                    } else {
                                      setOverrideStrikeInput(false);
                                    }
                                  }}
                                >
                                  {overrideStrikeInput === false
                                    ? "Override"
                                    : "Cancel"}
                                </Button>
                              </InputRightElement>
                            </FormControl>
                          )}

                          {tradeType !== "STOCK" && (
                            <FormControl id="contracts">
                              <FormLabel fontWeight={600}>Contracts</FormLabel>

                              <CustomInputGroup>
                                <Input
                                  style={{
                                    backgroundColor: "var(--dark-gray)",
                                    border: 0,
                                  }}
                                  type="number"
                                  placeholder="Enter contracts quantity"
                                  {...register("contracts")}
                                />
                              </CustomInputGroup>
                            </FormControl>
                          )}

                          {tradeType !== "STOCK" && (
                            <FormControl id="premium">
                              <FormLabel fontWeight={600}>Premium</FormLabel>
                              <Input
                                style={{
                                  backgroundColor: "var(--dark-gray)",
                                  border: 0,
                                }}
                                type="number"
                                placeholder="Premium"
                                step=".000001"
                                {...register("initialLastPrice")}
                              />
                            </FormControl>
                          )}
                        </>
                      )}
                    </VStack>
                  )}

                {stockName && tradeType === "DIVIDEND" && (
                  <FormControl>
                    <FormLabel fontWeight={600}>Dividend Entry</FormLabel>
                    <CustomInputGroup>
                      <HStack width="100%" {...subDivGroup} id="subDivType">
                        {subDivOptions.map((value) => {
                          const radio = subDivFunctions.getRadioProps({
                            value,
                          });
                          return (
                            <RadioCard key={value} {...radio}>
                              {value}
                            </RadioCard>
                          );
                        })}
                      </HStack>
                    </CustomInputGroup>
                  </FormControl>
                )}

                {stockName &&
                  tradeType !== "CALL" &&
                  tradeType !== "PUT" &&
                  tradeType !== "DIVIDEND" && (
                    <VStack width="full" spacing="6">
                      <FormControl>
                        <FormLabel fontWeight={600}>Shares</FormLabel>
                        <Input
                          id="addtradeshares"
                          style={{
                            backgroundColor: "var(--dark-gray)",
                            border: 0,
                          }}
                          type="number"
                          placeholder="Enter shares quantity"
                          {...register("quantity")}
                        />
                      </FormControl>

                      {loading ? (
                        <Spinner />
                      ) : (
                        <FormControl id="spotPrice">
                          <FormLabel fontWeight={600}>
                            Spot price{" "}
                            {dateOfSpotPrice !== null ? (
                              <span style={{ color: "var(--accent)" }}>
                                {" "}
                                (Price on{" "}
                                {moment(dateOfSpotPrice).format("MM/DD/YYYY")})
                              </span>
                            ) : (
                              <span style={{ color: "var(--accent)" }}>
                                {" "}
                                (Latest spot)
                              </span>
                            )}{" "}
                          </FormLabel>
                          <Input
                            id="addtradespotprice"
                            style={{
                              backgroundColor: "var(--dark-gray)",
                              border: 0,
                            }}
                            type="number"
                            placeholder="Enter the spot price of the stock"
                            step=".000001"
                            {...register("spotPriceAtTradeTime")}
                          />
                        </FormControl>
                      )}
                    </VStack>
                  )}

                {stockName &&
                  tradeType !== "CALL" &&
                  tradeType !== "PUT" &&
                  tradeType === "DIVIDEND" && (
                    <VStack width="full" spacing="6">
                      {subDividend === "SHARES" && (
                        <>
                          <FormControl>
                            <FormLabel fontWeight={600}>Shares</FormLabel>
                            <Input
                              id="addtradeshares"
                              style={{
                                backgroundColor: "var(--dark-gray)",
                                border: 0,
                              }}
                              type="number"
                              placeholder="Enter shares quantity"
                              {...register("quantity")}
                            />
                          </FormControl>

                          {loading ? (
                            <Spinner />
                          ) : (
                            <FormControl id="spotPrice">
                              <FormLabel fontWeight={600}>
                                Spot price{" "}
                                {dateOfSpotPrice !== null ? (
                                  <span style={{ color: "var(--accent)" }}>
                                    {" "}
                                    (Price on{" "}
                                    {moment(dateOfSpotPrice).format(
                                      "MM/DD/YYYY"
                                    )}
                                    )
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--accent)" }}>
                                    {" "}
                                    (Latest spot)
                                  </span>
                                )}{" "}
                              </FormLabel>
                              <Input
                                id="addtradespotprice"
                                style={{
                                  backgroundColor: "var(--dark-gray)",
                                  border: 0,
                                }}
                                type="number"
                                placeholder="Enter the spot price of the stock"
                                step=".000001"
                                {...register("spotPriceAtTradeTime")}
                              />
                            </FormControl>
                          )}
                        </>
                      )}
                      {subDividend === "DOLLAR" && (
                        <>
                          <FormControl>
                            <FormLabel fontWeight={600}>
                              Total shares owned
                            </FormLabel>
                            <Input
                              id="addtradeshares"
                              style={{
                                backgroundColor: "var(--dark-gray)",
                                border: 0,
                              }}
                              type="number"
                              placeholder="Enter total shares dividend recieved on"
                              onChange={(e) => {
                                setTotalSharesDividendRecieved(
                                  parseFloat(e.target.value)
                                );

                                calcDiv();
                              }}
                              value={totalSharesDividendRecieved}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel fontWeight={600}>
                              $ Per share recieved
                            </FormLabel>
                            <Input
                              id="addtradeshares"
                              style={{
                                backgroundColor: "var(--dark-gray)",
                                border: 0,
                              }}
                              type="number"
                              placeholder="$ per share recieved on dividend "
                              onChange={(e) => {
                                setPerShareDollar(parseFloat(e.target.value));

                                calcDiv();
                              }}
                              value={perShareDollar}
                            />
                          </FormControl>

                          {loading ? (
                            <Spinner />
                          ) : (
                            <FormControl id="spotPrice">
                              <FormLabel fontWeight={600}>
                                Spot price{" "}
                                {dateOfSpotPrice !== null ? (
                                  <span style={{ color: "var(--accent)" }}>
                                    {" "}
                                    (Price on{" "}
                                    {moment(dateOfSpotPrice).format(
                                      "MM/DD/YYYY"
                                    )}
                                    )
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--accent)" }}>
                                    {" "}
                                    (Latest spot)
                                  </span>
                                )}{" "}
                              </FormLabel>
                              <Input
                                id="addtradespotprice"
                                style={{
                                  backgroundColor: "var(--dark-gray)",
                                  border: 0,
                                }}
                                type="number"
                                placeholder="Enter the spot price of the stock"
                                {...register("spotPriceAtTradeTime")}
                                onChange={(e) => {
                                  setValue(
                                    "spotPriceAtTradeTime",
                                    e.target.value
                                  );
                                  calcDiv();
                                }}
                              />
                            </FormControl>
                          )}

                          <FormControl>
                            <FormLabel fontWeight={600}>
                              Additional shares{" "}
                              {/* {divDollarReceived && `($${divDollarReceived})`} */}
                            </FormLabel>
                            <Input
                              id="addtradeshares"
                              style={{
                                backgroundColor: "var(--dark-gray)",
                                border: 0,
                              }}
                              type="number"
                              placeholder="Calculate additional shares"
                              {...register("quantity")}
                            />
                          </FormControl>
                        </>
                      )}
                    </VStack>
                  )}

                {stockName && (
                  <FormControl id="commission">
                    <FormLabel fontWeight={600}>Commission ($)</FormLabel>
                    <Input
                      style={{ backgroundColor: "var(--dark-gray)", border: 0 }}
                      type="number"
                      placeholder="Enter commission ($)"
                      step=".000001"
                      id="addtradecommission"
                      {...register("commission")}
                    />
                  </FormControl>
                )}

                {stockName && (
                  <VStack width="full" spacing="1">
                    <FormControl className="strategyOp">
                      <FormLabel fontWeight={600}>Strategy</FormLabel>
                      <DropDownSelectorsCustom
                        tagStyleProps={{
                          rounded: "full",
                        }}
                        label=""
                        placeholder="Enter or select your strategy here"
                        onCreateItem={handleCreateItem}
                        items={strategies}
                        itemRenderer={customRender}
                        selectedItems={[strategy]}
                        createItemRenderer={(value) =>
                          `Click here to save ${value}`
                        }
                        onSelectedItemsChange={(changes) => {
                          if (
                            changes.type === "__function_remove_selected_item__"
                          ) {
                            handleSelectedItemsChange([
                              { label: "No strategy", value: "No strategy" },
                            ]);
                          } else {
                            handleSelectedItemsChange(changes.selectedItems);
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl id="source" mt="2" mb="2">
                      <FormLabel fontWeight={600}>Tags</FormLabel>
                      <TagView
                        allTags={props.allTags}
                        recordTagValue={(tag) => {
                          setTags(tag);
                        }}
                      />
                    </FormControl>

                    <FormControl id="source">
                      <FormLabel fontWeight={600}>Trade source</FormLabel>
                      <DropDownSelectorsCustom
                        tagStyleProps={{
                          rounded: "full",
                        }}
                        label=""
                        style={{
                          backgroundColor: "var(--dark-gray)",
                          border: 0,
                        }}
                        placeholder="Enter where you came accross the trade"
                        onCreateItem={handleCreateItemSource}
                        items={sources}
                        itemRenderer={customRender}
                        // createItemRenderer={customCreateItemRender}
                        selectedItems={[source]}
                        createItemRenderer={(value) =>
                          `Click here to save ${value}`
                        }
                        onSelectedItemsChange={(changes) => {
                          if (
                            changes.type === "__function_remove_selected_item__"
                          ) {
                            handleSelectedItemsChange([
                              { label: "No strategy", value: "No strategy" },
                            ]);
                          } else {
                            handleSelectedItemsChangeSource(
                              changes.selectedItems
                            );
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl id="notes">
                      <FormLabel fontWeight={600}>Notes</FormLabel>
                      <CustomInputGroup>
                        <Textarea
                          style={{
                            backgroundColor: "var(--dark-gray)",
                            border: 0,
                          }}
                          placeholder="Add a note to make it easier to navigate the deal"
                          {...register("notes")}
                        />
                      </CustomInputGroup>
                    </FormControl>
                  </VStack>
                )}
              </Stack>

              <HStack mt="20px" justifyContent={"flex-end"}>
                <NegativeButton
                  dark={true}
                  onClick={() => props.toggleModal()}
                  mr="5"
                >
                  Close
                </NegativeButton>

                {stockName && (
                  <PrimaryButton
                    isDisabled={loading}
                    isLoading={props.loading}
                    onClick={handleSubmit(onSubmit)}
                    id="addtradebuttonform"
                  >
                    Add trade
                  </PrimaryButton>
                )}
              </HStack>
            </form>
          </TabPanel>
          <TabPanel padding={0}>
            <AddTradeMultiLeg
              setWidth={props.setWidth}
              trades={props.trades}
              loading={props.loading}
              addTrade={props.addTrade}
              refresh={props.refresh}
              closeModal={() => props.toggleModal()}
              predefinedStock={props.predefinedStock}
              allTags={props.allTags}
            />
          </TabPanel>
        </InsetTabPanels>
      </InsetTabsContainer>
    </>
  );
}

const FieldGroup = (props) => {
  const { title, extra, children, ...flexProps } = props;
  return (
    <Stack
      direction={{
        base: "column",
        md: "row",
      }}
      spacing="6"
      py="4"
      {...flexProps}
    >
      <Box minW="3xs">
        {title && (
          <Heading fontWeight="semibold" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
        {extra && (
          <Tag mt="1" colorScheme={"green"} size="md">
            {extra}
          </Tag>
        )}
      </Box>
      {children}
    </Stack>
  );
};

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(AddTrade);
