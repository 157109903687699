import { Text } from '@chakra-ui/layout'
import React, { Component } from 'react'

export default class FundamentalTrackingUpgrade extends Component {
    render() {
        return (
            <div>
                {/* <Text>Fundamental Tracking upgrade for premium users</Text> */}
            </div>
        )
    }
}
