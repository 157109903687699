import { Box, Button, Center, FormControl, FormHelperText, FormLabel, HStack, Input, InputGroup, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, Tooltip, VStack } from "@chakra-ui/react";
import moment from 'moment';
import React, { Component, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { IoCartOutline } from "react-icons/io5";
import { connect, Provider } from 'react-redux';
import { CustomDatePicker, WidgetPanel, CustomIconButton, CustomModal, CustomToolTip, DeleteIcon, NegativeButton, SocialSharePanel, PrimaryButton, SecondaryButton, Title, CustomText } from "../../../design_library";
import API from "../../helpers/apiCalls";
import { fixTimezoneOffset } from "../../helpers/time";
import CalledAway from "../CalledAway";
import styles from "../Trades.module.css";
import ContextHighlightLink from "./ContextMenu/ContextHighlightLink";
import { useToast } from '@chakra-ui/react'
import Share from "./Share";
import store from '../../../redux/store';
import { calculateProfitAndLoss } from "../../helpers/tradeCalculations";
import amplitude from "../../helpers/amplitude";
import { FaShoppingCart } from "react-icons/fa";
import { Spinner } from "../../components";
import Confetti from "react-dom-confetti";
import { useReward } from 'react-rewards';

var currencyFormatter = require('currency-formatter');



class CloseTrade extends Component {

  constructor() {
    super();
    this.state = {
      isOpen: false
    }
  }

  toggleModal = (tog) => {
    this.setState({ isOpen: tog })
  }

  render() {


    if (this.props.newTable === true) {

      if (!this.props.data) {
        return null;
      }

      if (this.props.deleteTradeModalStatus === true) {
        return (

          <ModalComponent APIEndpoint={this.props.settings.APIEndpoint}
            trades={this.props.trades}
            isOpen={this.props.deleteTradeModalStatus}
            toggleModal={this.props.toggleModal}
            data={this.props.data}
            getTrades={this.props.getTrades} />
        )
      }
      else {
        return null;
      }
    }

    else if (this.props.data.canExpand !== true && this.props.data) {

      let notes = '';
      if (this.props.data.original) {
        if (this.props.data.original.notes) {
          notes = this.props.data.original.notes;
        }
      }

      // if(this.props.data.original.positionStatus === 'CLOSED') {
      //   return null
      // }


      return (
        <div>
          {this.props.noIcon === true && <ContextHighlightLink id="rcclosetrade" text='Close trade' onClick={() => this.setState({ isOpen: true })} />}
          {this.props.noIcon !== true &&
            <CustomToolTip label={this.props.data.original.positionStatus === 'CLOSED' ? 'Trade closed' : 'Close trade'} >
              <CustomIconButton
                padding={'4px'}
                onClick={() => this.setState({ isOpen: true })}
                icon={<FaShoppingCart />}
                overrideIconColor={true}
                isDisabled={this.props.data.original.positionStatus === 'CLOSED' ? true : false}
                redBackground={this.props.data.original.positionStatus === 'OPEN' ? true : false}
              />
            </CustomToolTip>
          }

          {this.props.data.original.positionStatus === 'OPEN' && <ModalComponent APIEndpoint={this.props.settings.APIEndpoint}
            trades={this.props.trades}

            isOpen={this.state.isOpen} toggleModal={this.toggleModal} data={this.props.data.original} getTrades={this.props.getTrades} />}
        </div>
      )
    }
    else {


      if (this.props.openTrades === true) {
        return (
          <div>
            <CustomToolTip label={'Close trades'} >
              <CustomIconButton
                onClick={() => this.setState({ isOpen: true })}
                icon={<DeleteIcon />}
                overrideIconColor={true}
                redBackground={true}
              />
            </CustomToolTip>

            <ModalComponent APIEndpoint={this.props.settings.APIEndpoint}
              trades={this.props.trades}
              isOpen={this.state.isOpen} toggleModal={this.toggleModal} data={this.props.trades} getTrades={this.props.getTrades} />
          </div>
        )
      }
      else {
        return '';
      }
    }
  }
}

function ModalComponent(props) {


  const [loading, setLoading] = useState(false);




  return (
    <>
      <TradeDetailModal
        trades={props.trades}
        modalStatus={props.isOpen}
        setModal={(value) => props.toggleModal(value)}
        APIEndpoint={props.APIEndpoint}
        toggle={(value) => props.toggleModal(value)}
        loading={loading}
        tradeData={props.data}
        getTrades={props.getTrades}
      />
    </>
  )
}



function TradeDetailModal(props) {

  let trade = props.tradeData;

  //Props pass in trade data + get new trade data
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, defaultValues },
  } = useForm();
  // const [data, setData] = useState(null);


  const [showEditTradeScreen, setShowEditTradeScreen] = useState(null);
  const [showCloseTradeScreen, setShowCloseTradeScreen] = useState(false);
  const [loadingDetail, setLoading] = useState(false);
  const [assignedBool, setAssignedBool] = useState(false);
  const toast = useToast()

  // useEffect(() => {
  //   toast({
  //     position: 'bottom-left',
  //     duration: 9000,
  //     render: () => (
  //       <Provider store={store}>
  //         <ToastComp />
  //       </Provider>
  //     ),
  //   })
  // }, [])


  const onSubmit = (expired, assigned) => {




    let payload = props.tradeData;
    payload.positionStatus = 'CLOSED';
    payload.quantity = getValues('quantity');
    payload.contracts = getValues('contracts');

    payload.close = true;



    payload.assignedBool = assignedBool;

    if (assigned === true) {
      payload.assignedBool = true;
    }

    payload.dateClosed = getValues('dateClosed') === undefined ? moment().format('MM/DD/YYYY') : getValues('dateClosed');
    payload.stockClosePrice = getValues('stockClosePrice');
    payload.spotPriceAtAssignment = assignedBool === true ? props.tradeData.strikePrice : undefined;
    payload.buyBackPricePerContract = assignedBool === true ? 0 : getValues('buyBackPricePerContract');

    if (expired === true) {
      payload.dateClosed = moment(props.tradeData.expiryDate).format('MM/DD/YYYY');
      payload.buyBackPricePerContract = 0;
      payload.tradeMarkedExpired = true;
    }

    payload.commission = getValues('commission');

    if (payload.commission === undefined || payload.commission === null || isNaN(payload.commission)) {
      payload.commission = 0;
    }

    setLoading(true);

    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        if (res.message) {
          alert(res.message);
        }
        else {
          alert(res.msg);
        }
        setLoading(false);

      }
      else {

        props.getTrades(true);
        setTimeout(() => {
          setTimeout(() => {
            props.toggle();


            toast({
              position: 'bottom-left',
              duration: 9000,
              render: () => (
                <Provider store={store}>
                  <ToastComp closedTrade={res.trade} />
                </Provider>
              ),
            })

            amplitude.logEvent('V2.TradeClosed.Toast.1.User_prompt_to_share_trade')
            setLoading(false);

          }, 1)
        }, 5)


      }
    })
  };

  const ToastComp = ({ closedTrade }) => {



    const [isActive, setIsActive] = useState(false);
    const [pandl, setPandL] = useState({});
    const { reward, isAnimating } = useReward('rewardId', 'confetti');


    useEffect(() => {
      let pandl = calculateProfitAndLoss([closedTrade]);
      setPandL(pandl);

      if (pandl.total < 0) {

      }
      else if (pandl.total > 0) {
        reward();
        setIsActive(true);
      }
    }, [closedTrade])




    return (
      <>
        <Box pos={'absolute'} top="0" left="50%">
          {/* <Confetti active={isActive} config={{ spread: 100, width: "10px", height: "10px", dragFriction: 0.05, stagger: 10.5, startVelocity: 20, elementCount: 200, }} /> */}

          <span id="rewardId" style={{ width: 2, height: 2, background: "red" }} />

        </Box>
        <SocialSharePanel>

          <Text pt={4} fontWeight={500}>{props.tradeData.stockTicker} closed with P&L {currencyFormatter.format((pandl.total), { code: 'USD' })}</Text>
          <CustomText>We've closed your trade. Click here to share it...</CustomText>
          <Share direct={true} data={{ original: closedTrade }} getTrades={props.getTrades !== undefined ? props.getTrades : () => { return; }} />
        </SocialSharePanel>
      </>
    )
  }


  const updateTrade = (id) => {
    setLoading(true);



    let payload = props.tradeData;
    payload = { ...props.tradeData, ...getValues()[id] };



    API.updateTrade(props.APIEndpoint, payload).then((res) => {
      if (res.success === false) {
        alert(res);
      }
      else {
        // props.getTrades();
        setLoading(false);
        props.toggle();

      }
    })
  }

  if (props.loading) {
    return (
      <>
        <CustomModal size="6xl" isOpen={props.modalStatus} onClose={props.toggle}
          body={<Center height="200px">
            <Spinner />
          </Center>}
        />



      </>
    )
  }

  let optionExpired = false;
  if (!props.tradeData) {

    if (props.trades.length > 0) {

    }
    else {
      return null;
    }
  }
  else if (props.tradeData.tradeType === 'PUT' || props.tradeData.tradeType === 'CALL') {
    if (!props.optionData) {
      var a = moment();
      var b = moment(props.tradeData.expiryDate);
      if (-a.diff(b, 'days') < 0) {
        optionExpired = true;

      }
      else {
      }
    }
    else {

    }
  }



  let closedAnnualisedReturn = 0;
  let openForDays = 0;
  if (props.tradeData.tradeType !== 'STOCK' && props.tradeData.positionStatus === 'CLOSED') {
    var a = moment(props.tradeData.dateOpened);
    var b = moment(props.tradeData.dateClosed);
    let totalRisk = (props.tradeData.contracts * props.tradeData.strikePrice) * 100;
    let percentageProfit = (props.tradeData.realisedProfitAndLoss / totalRisk) * 100;
    closedAnnualisedReturn = (percentageProfit / (-a.diff(b, 'days'))) * 365;
    openForDays = (-a.diff(b, 'days'));
  }


  let array = Object.keys(props.tradeData);



  return (
    <CustomModal size="6xl" minHeight="600px" isOpen={props.modalStatus} onClose={props.toggle}
      header={
        <Title>Close trade</Title>
      }

      body={

        <Box>

          {props.loading ?
            <Box>
              <Center>
                <Spinner />
              </Center>
            </Box>
            :
            <>
              {loadingDetail ?
                <Box>
                  <Center>
                    <VStack>
                      <Spinner />
                    </VStack>
                  </Center>
                </Box>
                :
                <>
                  {props.tradeData.positionStatus === 'OPEN' &&
                    // <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={5}>
                      <FormControl >
                        <FormLabel>Date closed</FormLabel>
                        <InputGroup>
                          <Controller
                            control={control}
                            name="ReactDatepicker"
                            render={(field) => (
                              <CustomDatePicker
                                id="closedatepicker"
                                defaultValue={moment().format('MM/DD/YYYY')}
                                placeholderText="Select date trade was closed"
                                onChange={(e) => {
                                  setValue("dateClosed", moment(fixTimezoneOffset(e)).tz("Europe/London").format("MM/DD/YYYY"));
                                  field.field.onChange(e);
                                }}
                                selected={field.field.value}
                              />
                            )}
                          />
                        </InputGroup>
                      </FormControl>

                      {props.tradeData.tradeType === 'STOCK' ?
                        <FormControl >
                          <FormLabel># of shares</FormLabel>
                          <InputGroup>
                            <Input
                              style={{ backgroundColor: 'var(--dark-gray)', border: 0 }}

                              type="number"
                              placeholder="Quantity"
                              step="1"
                              defaultValue={props.tradeData.quantity}
                              {...register("quantity")}
                            />
                          </InputGroup>
                        </FormControl>
                        :
                        <FormControl >
                          <FormLabel># of contracts</FormLabel>
                          <InputGroup>
                            <Input
                              style={{ backgroundColor: 'var(--dark-gray)', border: 0 }}

                              type="number"
                              step="1"
                              placeholder="Contracts"
                              defaultValue={props.tradeData.contracts}
                              {...register("contracts")}
                            />
                          </InputGroup>
                        </FormControl>
                      }

                      {props.tradeData.tradeType === 'PUT' &&
                        <>

                          <FormControl >
                            <FormLabel>Assigned?</FormLabel>
                            <InputGroup>
                              <Select
                                {...register("assignedBool")}
                                value={assignedBool}

                                onChange={(e) => {
                                  if (e.target.value === 'false') {
                                    setAssignedBool(false);
                                  }
                                  else {
                                    setAssignedBool(true);

                                  }
                                }}
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Select>
                            </InputGroup>

                          </FormControl>
                        </>

                      }

                      {assignedBool !== true && props.tradeData.tradeType !== 'STOCK' &&
                        <FormControl >
                          <FormLabel>What price did you buy back or sell your contracts for?</FormLabel>
                          <InputGroup>
                            <Input
                              style={{ backgroundColor: 'var(--dark-gray)', border: 0 }}

                              type="number"
                              placeholder="Close price"
                              step=".000001"
                              {...register("buyBackPricePerContract")}
                            />
                          </InputGroup>
                          <FormHelperText>This is the value of the option. For example if you sold a Put or $1 premium ($100 in your account) and then later bought it back for $0.10 (a 90% profit) you'd enter 0.10 here.</FormHelperText>
                          <FormHelperText>Enter 0.00 if the trade expired. </FormHelperText>

                        </FormControl>
                      }

                      {props.tradeData.tradeType === 'STOCK' &&
                        <>
                          <FormControl >
                            <FormLabel>What spot price did you sell your stock at?</FormLabel>
                            <InputGroup>
                              <Input
                                style={{ backgroundColor: 'var(--dark-gray)', border: 0 }}

                                type="number"
                                id="salesprice"
                                placeholder="Sale price"
                                step=".000001"
                                {...register("stockClosePrice")}
                              />
                            </InputGroup>
                          </FormControl>
                        </>
                      }


                      <>
                        <FormControl >
                          <FormLabel>Commission (e.g. $0.00)</FormLabel>
                          <InputGroup>
                            <Input
                              style={{ backgroundColor: 'var(--dark-gray)', border: 0 }}
                              defaultValue={0}
                              type="number"
                              placeholder="Commission"
                              step=".000001"
                              {...register("commission")}
                            />
                          </InputGroup>
                        </FormControl>
                      </>


                      <HStack justifyContent="flex-end">
                        <PrimaryButton
                          id="closetradebutton"
                          onClick={() => {
                            onSubmit(false);
                          }}
                        >
                          Close trade
                        </PrimaryButton>

                        {props.tradeData.tradeType !== 'STOCK' &&
                          <SecondaryButton
                            onClick={() => {
                              onSubmit(true);
                            }}

                          >
                            Quick: Trade expired
                          </SecondaryButton>
                        }

                        {/* <CalledAway tradeData={props.tradeData}
                            onSubmit={onSubmit}
                            trades={props.trades}
                          /> */}

                        {props.tradeData.tradeType === 'PUT' &&
                          <CustomToolTip label={"Using this button will also create a stock position"}>

                            <SecondaryButton

                              onClick={() => {
                                setAssignedBool(true);
                                setTimeout(() => {
                                  onSubmit(false, true);
                                }, 500)
                              }}

                            >
                              Quick: Assigned
                            </SecondaryButton>
                          </CustomToolTip>
                        }


                        <NegativeButton onClick={() => props.toggle()}>Close screen</NegativeButton>


                      </HStack>

                    </Stack>
                    // </form>
                  }
                </>






              }

            </>
          }
        </Box>
      }

    />
  )

}



const mapStateToProps = state => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(CloseTrade);
