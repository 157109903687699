import { Box, Heading, Text } from '@chakra-ui/react'
import React, { Component } from 'react'
import Wave from 'react-wavify'
import styles from './PoolContainer.module.css';

export const PoolContainer = ({ value, max, label, suffix }) => {


    return (
        <>
            <Box backgroundColor={'var(--bg)'} borderRadius="full" className={styles.waveContainer}>

                <Box className={styles.label}>
                    <Heading fontSize="xl" color={'white'}>{(value).toFixed(0) + suffix}</Heading>
                    <Text fontSize="md" color={'white'}>OF {max} {label}</Text>
                </Box>

                <Wave mask="url(#mask)" fill="#276AAD" className={styles.wave}
                    paused={false}
                    options={{
                        height: 100 - value,
                        amplitude: 15,
                        speed: 0.15,
                        points: 4
                    }}
                >
                    <defs>
                        <linearGradient id="gradient" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="white" />
                        </linearGradient>
                        <mask id="mask">
                            <rect x="0" y="0" width="200px" height="200px" fill="url(#gradient)" />
                        </mask>
                    </defs>
                </Wave>
                <Wave mask="url(#mask)" fill="#2595FD" className={styles.wave2}
                    paused={false}
                    options={{
                        height: 100 - value,
                        amplitude: 20,
                        speed: 0.20,
                        points: 2
                    }}
                >
                    <defs>
                        <linearGradient id="gradient" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="white" />
                        </linearGradient>
                        <mask id="mask">
                            <rect x="0" y="0" width="200px" height="200px" fill="url(#gradient)" />
                        </mask>
                    </defs>
                </Wave>

            </Box>
        </>
    )
}

