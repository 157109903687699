import { chakra, HTMLChakraProps, useColorMode } from '@chakra-ui/react'
import * as React from 'react'
import colors from '../../helpers/colors'

interface NavLinkProps extends HTMLChakraProps<'a'> {
  active?: boolean
}


const DesktopNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const { active, ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <chakra.a
      ref={ref}
      display="inline-block"
      px="4"
      py="6"
      fontWeight="semibold"
      aria-current={active ? 'page' : undefined}
      color={'white'}
      transition="all 0.2s"
      {...rest}
      _hover={{ color: 'blue.500' }}
      _active={{ color: 'blue.600' }}
      _activeLink={{
        color: 'blue.600',
        fontWeight: 'bold',
      }}
      onClick={() => {
        if (rest.children === 'Contact') {
          // window.Intercom('show');
          // $crisp.push(['do', 'chat:open']);
        }
      }}
    />
  )
})
DesktopNavLink.displayName = 'DesktopNavLink'

export const MobileNavLink = (props: NavLinkProps) => {
  const { active, ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <chakra.a
      aria-current={active ? 'page' : undefined}
      w="full"
      display="flex"
      alignItems="center"
      height="14"
      fontWeight="semibold"
      borderBottomWidth="1px"
      {...rest}
      color={colors.marketing.text.primary[colorMode]}
      onClick={() => {
        if (rest.children === 'Contact') {
          // window.Intercom('show');
        }
      }}
    />
  )
}

export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink,
}
