import React, {Component} from 'react'
import { SimpleGrid, Box, Container, AspectRatio  } from '@chakra-ui/layout';
import {connect} from 'react-redux';
import { Spinner } from '../components';
import API from '../helpers/apiCalls';
import ChartsContainer from './charts/ChartsContainer';


class DataContainer extends Component {
    constructor() {
        super();
        this.state = {
            trades:[],
            loading:true
        }
    }

    componentDidMount() {
        this.getTrades({});
    }
    
    getTrades = () => {
        this.setState({ loading: true });
        API.getTrades(this.props.settings.APIEndpoint, {}).then((res) => {
            this.setState({ trades: res.trades, loading:false });
        });
    };

    render() { 
        if(this.state.trades.length === 0) {
            return null;
        }
        if(this.state.loading === true) {
            return <Spinner/>
        }
        return (
            <Box>
                {/* <ChartsContainer trades={this.state.trades} /> */}
        </Box>
        )
    }
}
 
const mapStateToProps = state => {
    const { settings } = state;
    return { settings };
  };

export default connect(mapStateToProps)(DataContainer);
