import React, { Component } from "react";
import { Spinner } from "../../components";
import API from "../../helpers/apiCalls";
import { ResponsiveLine } from "@nivo/line";
import { Box, Divider, Text, Spacer } from "@chakra-ui/layout";
import moment from "moment";
import { connect } from "react-redux";
import UpgradePage from "../../payments/UpgradePage";
import colors from "../../../helpers/colors";
import { APIEndpoint } from "../../helpers/dataStructures";
import {
  CustomTab,
  CustomTabList,
  CustomTabPanels,
  CustomTabsContainer,
  InsetTab,
  InsetTabList,
  InsetTabPanels,
  InsetTabsContainer,
  LineChart,
} from "../../../design_library";
import { linearGradientDef } from "@nivo/core";
import { TabPanel } from "@chakra-ui/react";
var currencyFormatter = require("currency-formatter");

class HistoricalOptionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      display: false,
      chartData: [],
      volumeChart: [],
      priceChart: [],
      message: "",
    };
  }

  componentDidMount() {
    console.log(this.props.contractName);

    if (this.props.demo === true) {
      this.getOptionData("O:SPY251219C00650000");
    } else {
      this.getOptionData(this.props.contractName);
    }
  }

  getOptionData = (contractName) => {
    this.setState({ loading: true, display: false });
    API.getHistoricOptionData(APIEndpoint, contractName)
      .then((data) => {
        if (data.success === false) {
          this.setState({
            loading: false,
            display: false,
          });
        } else {
          //

          let array = data.data.dataArray;

          if (this.props.demo === true) {
            array = array.slice(0, 10);
          }

          // if (array.length === 0) {
          //   this.setState({ loading: false, display: false });
          // }
          // else {
          let impliedVolatility = {
            id: "impliedVolatility",
            data: [],
          };
          let lastPrice = {
            id: "lastPrice",
            data: [],
          };
          let rho = {
            id: "rho",
            data: [],
          };
          let theoretical = {
            id: "theoretical",
            data: [],
          };
          let theta = {
            id: "theta",
            data: [],
          };
          let vega = {
            id: "vega",
            data: [],
          };
          let volume = {
            id: "volume",
            data: [],
          };
          let timeValue = {
            id: "timeValue",
            data: [],
          };
          let gamma = {
            id: "gamma",
            data: [],
          };
          let delta = {
            id: "delta",
            data: [],
          };
          let daysBeforeExpiration = {
            id: "daysBeforeExpiration",
            data: [],
          };
          let high = {
            id: "high",
            data: [],
          };
          let low = {
            id: "low",
            data: [],
          };
          let bid = {
            id: "bid",
            data: [],
          };
          let close = {
            id: "close",
            data: [],
          };
          let ask = {
            id: "ask",
            data: [],
          };
          let openInterest = {
            id: "openInterest",
            data: [],
          };

          array.forEach((element, index) => {
            let date = moment(element.updatedAt).format("Do MMM YY");
            impliedVolatility.data.push({
              x: date,
              y: Math.abs(parseFloat(element.impliedVolatility)),
            });
            lastPrice.data.push({
              x: date,
              y: Math.abs(parseFloat(element.lastPrice)),
            });
            rho.data.push({
              x: date,
              y: Math.abs(parseFloat(element.rho)) * 100,
            });
            theta.data.push({
              x: date,
              y: Math.abs(parseFloat(element.theta)) * 100,
            });
            vega.data.push({
              x: date,
              y: Math.abs(parseFloat(element.vega)) * 100,
            });
            volume.data.push({
              x: date,
              y: Math.abs(parseFloat(element.volume)),
            });
            timeValue.data.push({
              x: date,
              y: Math.abs(parseFloat(element.timeValue)),
            });
            gamma.data.push({
              x: date,
              y: Math.abs(parseFloat(element.gamma)) * 100,
            });
            delta.data.push({
              x: date,
              y: Math.abs(parseFloat(element.delta)) * 100,
            });
            daysBeforeExpiration.data.push({
              x: date,
              y: Math.abs(parseFloat(element.daysBeforeExpiration)),
            });
            bid.data.push({ x: date, y: Math.abs(parseFloat(element.bid)) });
            ask.data.push({ x: date, y: Math.abs(parseFloat(element.ask)) });
            high.data.push({ x: date, y: Math.abs(parseFloat(element.high)) });
            low.data.push({ x: date, y: Math.abs(parseFloat(element.low)) });
            close.data.push({
              x: date,
              y: Math.abs(parseFloat(element.close)),
            });
            openInterest.data.push({
              x: date,
              y: Math.abs(parseFloat(element.openInterest)),
            });
          });

          let dIvChart = [impliedVolatility, delta];
          let gvtrChart = [rho, theta, vega, gamma];

          let priceChart = [low, high, bid, ask, close];
          let volumeChart = [volume, openInterest];

          if (priceChart[0].data.length === 0) {
            this.setState({
              loading: false,
              display: false,
              message: "No data available for this contract",
            });
          } else {
            this.setState({
              loading: false,
              dIvChart: dIvChart,
              priceChart: priceChart,
              volumeChart: volumeChart,
              gvtrChart: gvtrChart,
              display: true,
              message: "",
            });
          }
        }
        // }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false, display: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    // if(this.props.settings.profile.subscriptionType !== 'PREMIUM') {
    //   return <UpgradePage/>
    // }

    if (this.state.display === false) {
      return <Text>{this.state.message}</Text>;
    }

    return (
      <>
        <InsetTabsContainer
          borderRadius="12px"
          pt={this.props.demo === true && "180px"}
          justifyContent="center"
        >
          <InsetTabList dark={true} justifyContent="center">
            <InsetTab>Price</InsetTab>
            <InsetTab>Delta & IV</InsetTab>
            <InsetTab>Gamma, Vega, Theta & Rho</InsetTab>
            <InsetTab>Volume</InsetTab>
          </InsetTabList>
          <InsetTabPanels pt="20px">
            {[
              {
                name: "Price chart",
                object: "priceChart",
                legend: "Option price ($)",
                format: (value) => {
                  return currencyFormatter.format(value, { code: "USD" });
                },
              },
              {
                name: "Delta & IV",
                object: "dIvChart",
                legend: "Greek value (%)",
                format: (value) => {
                  return value + "%";
                },
              },
              {
                name: "Gamma, Vega, Theta & Rho",
                object: "gvtrChart",
                legend: "Greek value (%)",
                format: (value) => {
                  return value + "%";
                },
              },
              {
                name: "Volume chart",
                object: "volumeChart",
                legend: "Volume",
                format: (value) => {
                  return value;
                },
              },
            ].map((value, index) => {
              return (
                <TabPanel key={index}>
                  <Box
                    minHeight={this.props.demo === true ? "500px" : "600px"}
                    height={this.props.demo === true ? "500px" : "700px"}
                    overflow="hidden"
                  >
                    {this.props.demo !== true && (
                      <Text fontSize={"22px"} color="var(--white)">
                        {value.name}
                      </Text>
                    )}
                    {this.props.demo !== true && (
                      <Divider pt={"16px"} pb="20px" />
                    )}
                    <Spacer height="20px" />
                    <LineChart
                      curve="linear"
                      yScale={{
                        type: "linear",
                        min: "0",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                      }}
                      data={this.state[value.object]}
                      margin={{ top: 10, right: 60, bottom: 300, left: 80 }}
                      axisBottom={{
                        tickRotation: 45,
                        legendOffset: -80,
                        legendPosition: "start",
                      }}
                      axisLeft={{
                        format: (v) => {
                          return value.format(v);
                        },
                        labelTextColor: "var(--white)",
                      }}
                      gradient={true}
                      tooltipFormat={value.format}
                    />
                  </Box>
                </TabPanel>
              );
            })}
          </InsetTabPanels>
        </InsetTabsContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(HistoricalOptionData);
