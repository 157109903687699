import React, { Component } from "react";
import { SimpleGrid, Box, Container, AspectRatio } from "@chakra-ui/layout";
import { connect } from "react-redux";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Tooltip,
  Text,
  Center,
  Image,
  Icon,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { Spinner } from "../../components";
import moment from "moment";
import { area, curveMonotoneX } from "d3-shape";
import { FaDatabase } from "react-icons/fa";
import { FcBiomass } from "react-icons/fc";
import { Panel } from "../../helpers/Panel";
import colors from "../../../helpers/colors";
import { DragHandle } from "./DragHandle";
import apiCalls from "../../helpers/apiCalls";
import { ResponsiveLine } from "@nivo/line";
import "./AccountBalance.css";
import {
  LineChart,
  WidgetHeader,
  WidgetPanel,
  CustomInputGroup,
  ItemHeading,
  DropDown,
  NoDataWidget,
} from "../../../design_library";
import {
  calculateProfitAndLoss,
  calculateTotalRisk,
} from "../../helpers/tradeCalculations";
import { uuid } from "uuidv4";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../helpers/localstorage";
import { getAccountValue } from "../../helpers/accountBalance";
var currencyFormatter = require("currency-formatter");

class AccountBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      balance: [],
      period: "1_MONTH",
      defaultValue: { label: "1 month", value: "1_MONTH" },
      granularity: "DAILY",
      originalData: [],
      lowestValueForBaseLine: 9999999999999,
    };
  }

  componentDidMount() {
    if (this.props.demo === true) {
      this.setState({
        loading: false,
        balance: [
          {
            id: "Total",
            color: "red",
            data: [
              {
                x: "03 Apr 2022",
                y: 39724,
              },
              {
                x: "05 Apr 2022",
                y: 39724,
              },
              {
                x: "06 Apr 2022",
                y: 39575,
              },
              {
                x: "07 Apr 2022",
                y: 39692,
              },
              {
                x: "08 Apr 2022",
                y: 39868.5,
              },
              {
                x: "09 Apr 2022",
                y: 40261,
              },
              {
                x: "10 Apr 2022",
                y: 40422,
              },
              {
                x: "12 Apr 2022",
                y: 40422,
              },
              {
                x: "13 Apr 2022",
                y: 39972,
              },
              {
                x: "14 Apr 2022",
                y: 39750,
              },
              {
                x: "15 Apr 2022",
                y: 39319,
              },
              {
                x: "16 Apr 2022",
                y: 39545,
              },
              {
                x: "17 Apr 2022",
                y: 39545,
              },
            ],
          },
        ],
      });
    } else {
      if (getFromLocalStorage("balanceWidgetDefault") !== null) {
        this.setState({
          defaultValue: getFromLocalStorage("balanceWidgetDefault"),
          period: getFromLocalStorage("balanceWidgetDefault").value,
        });
      }

      this.getData();
    }
  }

  getData = () => {
    this.setState({ lowestValueForBaseLine: 999999999999999 });

    let selectedAccount = this.props.settings.profile.accounts[0].id;
    this.props.settings.profile.accounts.forEach((element) => {
      if (element.selected === true) {
        selectedAccount = element.id;
      }
    });

    apiCalls.getBalance(selectedAccount).then((data) => {
      if (data.success === true && data.balance !== null) {
        let unrealisedData = {
          id: "Unrealized",
          color: "hsl(12,100%,46%)",
          data: [],
        };
        let realisedData = {
          id: "Realized",
          color: "hsl(12,100%,46%)",
          data: [],
        };
        let totalData = {
          id: "Balance",
          color: "hsl(12,100%,46%)",
          data: [],
        };

        let totalRisk = {
          id: "Total Risk",
          color: "hsl(12,100%,46%)",
          data: [],
        };

        data.balance.data.map((item) => {
          let commission = item.PandL.commission || 0;
          let outstandingPremiumOnOpenTrades =
            item.PandL.outstandingPremiumOnOpenTrades || 0;

          unrealisedData.data.push({
            x: moment(item.date).format("DD MMM YYYY"),
            y: item.PandL.unrealised,
          });
          realisedData.data.push({
            x: moment(item.date).format("DD MMM YYYY"),
            y: item.PandL.realised,
          });
          totalData.data.push({
            x: moment(item.date).format("DD MMM YYYY"),
            y: item.PandL.total + getAccountValue(this.props) - commission,
          });

          if (data.balance.totalRisk) {
            totalRisk.data.push({
              x: moment(item.date).format("DD MMM YYYY"),
              y: item.totalRisk.totalRisk,
            });
          }
        });

        // let trades = this.props.trades;
        // let totalRiskCalc = calculateTotalRisk(this.props.trades);
        let totalPLCalc = calculateProfitAndLoss(this.props.trades);

        unrealisedData.data.push({
          x: "Today",
          y: totalPLCalc.unrealised,
        });

        realisedData.data.push({
          x: "Today",
          y: totalPLCalc.realised,
        });

        totalData.data.push({
          x: "Today",
          y:
            totalPLCalc.total +
            getAccountValue(this.props) -
            totalPLCalc.commission,
        });
        // totalRisk.data.push({
        //     "x": "Today",
        //     "y": totalRiskCalc.totalRisk
        // });

        let sevenDaysAgo = moment().subtract(7, "day");
        let fourteenDaysAgo = moment().subtract(14, "day");
        let monthAgo = moment().subtract(1, "month");
        let threeMonthAgo = moment().subtract(3, "month");
        let sixMonthAgo = moment().subtract(6, "month");
        let oneYearAgo = moment().subtract(12, "month");
        let threeYearsAgo = moment().subtract(3, "year");
        let fiveYearAgo = moment().subtract(5, "year");

        let newArray = [];
        console.log(newArray.length);
        let newewstLowValue = this.state.lowestValueForBaseLine;

        [totalData].filter((value, key) => {
          let newData = [];
          value.data.filter((value2, key) => {
            let date = moment(value2.x, "DD MMM YYYY");

            if (this.state.period === "ALL_TIME") {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (date._i === "Today") {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "7_DAYS" &&
              date.isAfter(sevenDaysAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "14_DAYS" &&
              date.isAfter(fourteenDaysAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "1_MONTH" &&
              date.isAfter(monthAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "3_MONTHS" &&
              date.isAfter(threeMonthAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "6_MONTHS" &&
              date.isAfter(sixMonthAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "1_YEAR" &&
              date.isAfter(oneYearAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "3_YEARS" &&
              date.isAfter(threeYearsAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            } else if (
              this.state.period === "5_YEARS" &&
              date.isAfter(fiveYearAgo)
            ) {
              newData.push(value2);
              if (value2.y < newewstLowValue) {
                newewstLowValue = value2.y;
              }
            }
          });
          value.data = newData;
          newArray.push(value);
        });

        console.log(newArray.length);

        totalData = newArray;

        this.setState({
          loading: false,
          balance: [...totalData],
          originalData: [totalData],
          lowestValueForBaseLine: newewstLowValue,
        });

        //here
      } else {
        this.setState({
          loading: false,
          balance: [],
          originalData: [],
        });
      }
    });
  };

  render() {
    if (this.props.demo === true) {
      return (
        <Box height="100%" width="100%">
          <LineChart
            data={this.state.balance}
            keys={["Total"]}
            margin={{ top: 40, right: 20, bottom: 160, left: 80 }}
            tooltipFormat={(value) => {
              return currencyFormatter.format(value, { code: "USD" });
            }}
          />
        </Box>
      );
    } else {
      return (
        <WidgetPanel>
          <WidgetHeader
            toggleWidget={(id) => this.props.toggleWidget(id)}
            label="Account Balance"
            tooltip="Displays your realised & unrealised P&L. This is recorded at 12PM ET"
          />
          {this.state.loading === true ? (
            <Spinner />
          ) : (
            <Box height="100%" width="100%" flex={2} overflow="hidden">
              <HStack width="100%" pb="20px" pt="20px" pl="2px">
                <CustomInputGroup flex="1">
                  <ItemHeading>Period</ItemHeading>
                  <DropDown
                    dark={true}
                    defaultValue={this.state.defaultValue}
                    placeholder="1 month"
                    options={[
                      { value: "ALL_TIME", label: "All time" },
                      { value: "5_YEARS", label: "5 years" },
                      { value: "3_YEAR", label: "3 years" },
                      { value: "1_YEAR", label: "1 year" },
                      { value: "6_MONTHS", label: "6 months" },
                      { value: "3_MONTHS", label: "3 months" },
                      { value: "1_MONTH", label: "1 month" },
                      { value: "14_DAYS", label: "14 days" },
                      { value: "7_DAYS", label: "7 days" },
                    ]}
                    onChange={(e, actionType) => {
                      this.setState({ period: e.value }, () => {
                        this.getData();
                      });

                      saveToLocalStorage("balanceWidgetDefault", e);
                    }}
                  ></DropDown>
                </CustomInputGroup>
                {/* <CustomInputGroup flex="1">
                                    <ItemHeading>Granularity</ItemHeading>
                                    <DropDown
                                        dark={true}
                                        placeholder="Daily"
                                        options={[
                                            { value: 'DAILY', label: 'Daily' },
                                            { value: 'MONTHLY', label: 'Monthly' },
                                            { value: 'YEARLY', label: 'Yearly' },
                                        ]}
                                        onChange={(e, actionType) => {
                                            this.setState({ granularity: e.value }, () => {
                                                this.getData();
                                            });
                                        }}
                                    >
                                    </DropDown>
                                </CustomInputGroup> */}
              </HStack>

              {this.state.balance.length === 0 && <NoDataWidget />}
              {this.state.balance.length > 0 && (
                <LineChart
                  key={uuid()}
                  lowestValueForBaseLine={this.state.lowestValueForBaseLine}
                  data={this.state.balance}
                  keys={["Total"]}
                  margin={{ top: 20, right: 20, bottom: 240, left: 80 }}
                  gradient={true}
                  tooltipFormat={(value) => {
                    return currencyFormatter.format(value, { code: "USD" });
                  }}
                />
              )}
            </Box>
          )}
        </WidgetPanel>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(AccountBalance);
