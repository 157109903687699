import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { VStack } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  StackDivider,
  Input,
  InputGroup,
  Heading,
  Tag,
  InputRightElement,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  FormHelperText,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import { Spinner } from "../../components/index";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import moment from "moment";
import React, { Component, useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { Spinner as CustomSpinner } from "../../components/index";
import API from "../../helpers/apiCalls";
import { customStrategies } from "../../helpers/dataStructures";
import { fixTimezoneOffset } from "../../helpers/time";
import styles from "./AddTrade.module.css";
import "./AddTrade-MultiLeg.css";
import { IoRemove, IoRemoveCircleSharp } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { TableHeader } from "../../helpers/TableHeader";
import { DropDownSelectorsCustom } from "../../helpers/DropDownSelectorsCustom";
import {
  CustomDatePicker,
  CustomIconButton,
  CustomTBody,
  CustomTd,
  CustomTh,
  CustomToolTip,
  CustomTr,
  DeleteIcon,
  NegativeButton,
  PrimaryButton,
  SecondaryButton,
  TableFrame,
} from "../../../design_library";
import TagView from "./TagView";

var uuid = require("uuid");
var currencyFormatter = require("currency-formatter");

const FieldGroup = (props) => {
  const { title, extra, button, children, ...flexProps } = props;
  return (
    <Stack
      direction={{
        base: "column",
        md: "row",
      }}
      spacing="6"
      py="4"
      {...flexProps}
    >
      <Box minW="3xs">
        {title && (
          <Heading fontWeight="semibold" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
        {extra && (
          <Tag mt="1" colorScheme={"green"} size="lg">
            {extra}
          </Tag>
        )}
        {button}
      </Box>
      {children}
    </Stack>
  );
};

// const SelectTemplate = (props) => {
//   let [value, setValue] = useState("");

//   return (
//     <Select
//       mt="2"
//       size="lg"
//       onChange={(e) => {
//         setValue(e.target.value);

//         let value = e.target.value;

//         if (value === 'Stock') {
//           props.setTradesRowObject([
//             { id: uuid.v4(), tradeType: 'STOCK', action: "BUY", positionStatus: "OPEN", quantity: "100", status: "Ready", }
//           ])
//         }
//         else if (value === 'Call') {
//           props.setTradesRowObject([
//             { id: uuid.v4(), tradeType: 'CALL', action: "BUY_TO_OPEN", positionStatus: "OPEN", quantity: "1", status: "Ready", }
//           ])
//         }
//         else if (value === 'Put') {
//           props.setTradesRowObject([
//             { id: uuid.v4(), tradeType: 'PUT', action: "BUY_TO_OPEN", positionStatus: "OPEN", quantity: "1", status: "Ready", }
//           ])
//         }
//         else if (value === 'Covered Call') {
//           props.setTradesRowObject([
//             { id: uuid.v4(), tradeType: 'STOCK', action: "BUY", positionStatus: "OPEN", quantity: "100", status: "Ready", },
//             { id: uuid.v4(), tradeType: 'CALL', action: "SELL_TO_OPEN", positionStatus: "OPEN", quantity: "1", contracts: "1", status: "Ready", expiryDate: undefined, strikePrice: undefined, contracts: undefined, quantity: undefined, account: undefined, stockTicker: undefined, expiryDate: undefined, premium: undefined, strikePrice: undefined, initialDelta: undefined, initialGamma: undefined, initialIntrinsicValue: undefined, initialLastPrice: undefined, initialRho: undefined, initialTheoretical: undefined, initialTheta: undefined, initialTimeValue: undefined, initialVega: undefined, initialIV: undefined, totalRisk: undefined, optionsData: undefined, expiryOptions: undefined, loading: false }
//           ])

//         }
//       }}
//       value={value}
//     >
//       <option value="">Templates</option>
//       <option value="Stock">Stock</option>
//       <option value="Call">Call</option>
//       <option value="Put">Put</option>
//       <option value="Covered call">Covered call</option>
//     </Select>

//   )
// }

class AddTradeMultiLeg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalIsOpen: false,
      errors: {},
      stockData: {},
      open: false,
    };
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  addTemplate = () => {};

  addTrade = async (payload, error) => {
    let originalRow = payload;
    return new Promise(async (resolve, reject) => {
      this.setState({ loading: true });
      return API.addTrade(this.props.settings.APIEndpoint, payload)
        .then((res) => {
          if (res.success === true) {
            this.setState({ loading: false, modalIsOpen: false });
            resolve({ id: payload.id, status: "Trade created", originalRow });
          } else {
            this.setState({ loading: false });
            resolve({
              id: payload.id,
              status: "Failed to add trade",
              error: res.err,
              originalRow,
            });
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          resolve({ id: payload.id, status: "CATCH", error: e, originalRow });
        });
    });
  };

  componentDidMount() {
    this.props.setWidth("full");
  }

  render() {
    return (
      <>
        <AddTradeForm
          refresh={this.props.refresh}
          trades={this.props.trades}
          loading={this.state.loading}
          allTags={this.props.allTags}
          addTrade={this.addTrade}
          closeModal={() => {
            this.props.closeModal();
          }}
          APIEndpoint={this.props.settings.APIEndpoint}
          profile={this.props.settings.profile}
          predefinedStock={this.props.predefinedStock}
        />
      </>
    );
  }
}

function AddTradeForm(props) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: {},
  } = useForm();

  const [data, setData] = useState(null);
  const [stockName, setStockName] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [optionsData, setOptionData] = useState(null);
  const [tradeType, setTradeType] = useState("STOCK");
  const [code, setCode] = useState("");
  const [action, setAction] = useState("BUY");
  const [expiryDate, setExpiryDate] = useState(moment().format("MM/DD/YYYY"));
  const [loading, setLoading] = useState(false);
  const [expiryOptions, setExpiryOptions] = useState([]);
  const [strikePrices, setStrikePrices] = useState([]);
  const [strikePrice, setStrikePrice] = useState([]);
  const [specificOptionData, setSpecificOptionData] = useState([]);
  const [specificContractData, setSpecificContractData] = useState(null);
  const [stockSearchErrorMessage, setStockSearchErrorMessage] = useState(null);
  const [initialInTheMoney, setInitialInTheMoney] = useState(null);
  const [overrideAllValues, setOverrideAllValues] = useState(false);
  const [overrideStrikeInput, setOverrideStrikeInput] = useState(false);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(0);
  const [greeksData, setGreeksData] = useState([]);
  const [strategies, setStrategies] = useState([
    { label: "No strategy", value: "No strategy" },
  ]);
  const [strategy, setStrategy] = useState({
    label: "No strategy",
    value: "No strategy",
  });
  const [tags, setTags] = useState([]);
  const [sources, setSources] = useState([
    { label: "No source", value: "No source" },
  ]);
  const [source, setSource] = useState({
    label: "No source",
    value: "No source",
  });
  const [dateOfSpotPrice, setDateOfSpotPrice] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);
  const [initialDeltaValidationMessage, setInitialDeltaValidationMessage] =
    useState(0);
  const [initialGammaValidationMessage, setInitialGammaValidationMessage] =
    useState(0);
  const [initialRhoValidationMessage, setinItialRhoValidationMessage] =
    useState(0);
  const [initialThetaValidationMessage, setInitialThetaValidationMessage] =
    useState(0);
  const [initialVegaValidationMessage, setInitialVegaValidationMessage] =
    useState(0);
  const [initialIVValidationMessage, setInitialIVValidationMessage] =
    useState(0);
  const [dateOpened, setDateOpened] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [prefetchedOptionData, setPrefetchedOptionData] = useState({});
  const [tradesRowObject, setTradesRowObject] = useState([
    {
      id: uuid.v4(),
      stockTicker: props.predefinedStock,
      tradeType: "STOCK",
      action: "BUY",
      positionStatus: "OPEN",
      quantity: "100",
      status: "Ready",
    },
  ]);

  const [addingTrades, setAddingTrades] = useState(false);
  const [errorsList, setErrorsList] = useState([]);

  const [addLegButtonLoadingObject, setAddLegButtonLoading] = useState(false);
  const [submittingTradesObject, setSubmittingTradesObject] = useState(false);
  const [rowChange, setRowChange] = useState({});

  const addRow = () => {
    let newArray = tradesRowObject;

    newArray.push({
      id: uuid.v4(),
      tradeType: "STOCK",
      status: "Ready",
      action: "BUY",
      expiryDate: undefined,
      strikePrice: undefined,
      contracts: undefined,
      quantity: undefined,
      positionStatus: "OPEN",
      account: undefined,
      stockTicker: undefined,
      tradeType: undefined,
      action: undefined,
      positionStatus: undefined,
      expiryDate: undefined,
      premium: undefined,
      strikePrice: undefined,
      initialDelta: undefined,
      initialGamma: undefined,
      initialIntrinsicValue: undefined,
      initialLastPrice: undefined,
      initialRho: undefined,
      initialTheoretical: undefined,
      initialTheta: undefined,
      initialTimeValue: undefined,
      initialVega: undefined,
      initialIV: undefined,
      totalRisk: undefined,
      optionsData: undefined,
      expiryOptions: undefined,
      loading: false,
      // dateOpened: undefined,
      // spotPriceAtTradeTime: undefined,
      // strategy: undefined,
      // tradeSource: undefined,
      // notes: undefined,
      stockTicker: stockData.stockTicker,
    });

    setTradesRowObject(newArray);
    setRowChange({ msg: "change" });
  };

  const editRow = ({
    id,
    status,
    loading,
    tradeType,
    specificOptionData,
    specificOption,
    strikePrices,
    specificContractData,
    action,
    expiryOptions,
    contracts,
    quantity,
    account,
    optionsData,
    stockTicker,
    positionStatus,
    expiryDate,
    premium,
    strikePrice,
    initialDelta,
    initialGamma,
    initialIntrinsicValue,
    initialLastPrice,
    initialRho,
    initialTheoretical,
    initialTheta,
    initialTimeValue,
    initialVega,
    initialIV,
    totalRisk,
  }) => {
    let newRows = tradesRowObject.filter((value) => {
      //STOCK

      if (value.id === id) {
        tradeType = value.tradeType;
        status = value.status;
        specificOptionData = value.specificOptionData;
        strikePrices = value.strikePrices;
        action = value.action;
        optionsData = value.optionsData;
        expiryOptions = value.expiryOptions;
        expiryDate = value.expiryDate;
        strikePrice = value.strikePrice;
        contracts = value.quantity;
        quantity = value.quantity;
        positionStatus = value.positionStatus;
        account = value.account;
        stockTicker = value.stockTicker;
        tradeType = value.tradeType;
        premium = value.premium;
        action = value.action;
        positionStatus = value.positionStatus;
        expiryDate = value.expiryDate;
        strikePrice = value.strikePrice;
        initialDelta = value.initialDelta;
        initialGamma = value.initialGamma;
        initialIntrinsicValue = value.initialIntrinsicValue;
        initialLastPrice = value.initialLastPrice;
        initialRho = value.initialRho;
        initialTheoretical = value.initialTheoretical;
        initialTheta = value.initialTheta;
        initialTimeValue = value.initialTimeValue;
        initialVega = value.initialVega;
        initialIV = value.initialIV;
        totalRisk = value.totalRisk;
        loading = value.loading;
        specificContractData = value.specificContractData;
        specificOption = value.specificOption;
        let myRowErrorObject = { ...errors[id] };

        // isInvalid={errors[row.id].quantity}
        try {
          if (quantity === undefined || quantity.length === 0) {
            myRowErrorObject.quantity = true;
          } else {
            myRowErrorObject.quantity = false;
          }
          if (strikePrice === undefined || strikePrice.length === 0) {
            myRowErrorObject.strikePrice = true;
          } else {
            myRowErrorObject.strikePrice = false;
          }
          if (expiryDate === undefined || expiryDate.length === 0) {
            myRowErrorObject.expiryDate = true;
          } else {
            myRowErrorObject.expiryDate = false;
          }
          if (action === undefined || action.length === 0) {
            myRowErrorObject.action = true;
          } else {
            myRowErrorObject.action = false;
          }
          if (tradeType === undefined || tradeType.length === 0) {
            myRowErrorObject.tradeType = true;
          } else {
            myRowErrorObject.tradeType = false;
          }
          if (initialLastPrice === undefined || initialLastPrice.length === 0) {
            myRowErrorObject.premium = true;
          } else {
            myRowErrorObject.premium = false;
          }
        } catch (e) {
          console.log("error processing multi-leg", e);
        }

        setErrors({ ...errors, [id]: myRowErrorObject });
        return value;
      } else {
        return value;
      }
    });

    setTradesRowObject(newRows);
    setRowChange({ msg: "change" });
  };

  const removeRow = (id) => {
    let newRows = tradesRowObject.filter((value) => {
      if (value.id !== id) {
        return value;
      }
    });
    setTradesRowObject(newRows);
    setRowChange({ msg: "change" });
  };

  const onSubmit = () => {
    setSubmittingTradesObject(true);

    let rows = tradesRowObject;

    let completedRows = [];

    let errorArray = [];

    rows.filter((value, index) => {
      let errorMessage = "";

      if (!value.tradeType) {
        errorMessage += "Trade Type is required. ";
      } else {
        if (value.tradeType === "STOCK") {
          if (!value.action) {
            errorMessage += "Action is required. ";
          }
          if (!value.quantity) {
            errorMessage += "# of shares are required. ";
          }
        } else {
          if (!value.action) {
            errorMessage += "Action is required. ";
          }
          if (!value.quantity) {
            errorMessage += "# of contracts are required. ";
          }
          if (!value.strikePrice) {
            errorMessage += "Strike price is required. ";
          }
          if (!value.initialLastPrice) {
            errorMessage += "Premium is required. ";
          }
          if (!value.expiryDate || value.expiryDate.length < 2) {
            errorMessage += "Expiry date is required. ";
          }
        }
      }

      if (errorMessage.length > 0) {
        errorMessage = `You have errors on row ${index + 1}. ` + errorMessage;
        errorArray.push(errorMessage);
      }
    });

    if (errorArray.length > 0) {
      setErrorsList(errorArray);
      setSubmittingTradesObject(false);
      setLoading(false);
      return;
    } else {
      setErrorsList([]);
      rows.filter(async (data, index) => {
        if (data.status === "Ready" || data.status === "Failed") {
          let difference = undefined;
          let protection = undefined;
          let initialPremiumPerDay = undefined;
          let totalRisk = undefined;
          let potentialReturnPercentage = undefined;
          let AnnualisedReturn = undefined;
          if (data.tradeType !== "STOCK") {
            var b = moment(dateOpened, "MM/DD/YYYY");
            var a = moment(data.expiryDate, "MM/DD/YYYY");
            difference = a.diff(b, "days");

            protection = (stockData.close - data.strikePrice) / stockData.close;

            initialPremiumPerDay = data.initialLastPrice / difference;

            totalRisk = stockData.close * parseFloat(data.quantity) * 100;

            potentialReturnPercentage =
              (data.initialLastPrice / totalRisk) * 100;

            AnnualisedReturn = (potentialReturnPercentage / difference) * 365;
          } else {
            totalRisk = stockData.close * data.quantity;
          }

          let accountId = props.profile.accounts.filter((value, index) => {
            if (value.selected === true) {
              return value;
            }
          });
          accountId = accountId[0].id;

          let stockPrice = getValues("spotPriceAtTradeTime");
          try {
            stockPrice = stockData.close
              ? stockData.close
              : stockData.data.close;
          } catch (e) {
            console.log("FAILED TO SET STOCK PRICE");
            stockPrice = getValues("spotPriceAtTradeTime");
          }

          let payload = {
            id: data.id,
            stockTicker: props.predefinedStock,
            tradeType: data.tradeType,
            action: data.action,
            strategy: strategy.value,
            positionStatus: "OPEN",
            description: undefined,
            dateOpened: dateOpened,
            quantity: data.quantity,
            dateClosed: data.dateClosed,
            expiryDate: data.expiryDate,
            initialDaysToExpiry: difference,
            contracts: data.quantity,
            premium: data.initialLastPrice,
            spotPriceAtTradeTime: stockPrice,
            strikePrice: data.strikePrice,
            protection: protection,
            initialPremiumPerDay: initialPremiumPerDay,
            initialDelta: data.initialDelta,
            initialGamma: data.initialGamma,
            initialInTheMoney: data.initialInTheMoney,
            initialIntrinsicValue: data.initialIntrinsicValue,
            initialLastPrice: data.initialLastPrice,
            initialRho: data.initialRho,
            initialTheoretical: data.initialTheoretical,
            initialTheta: data.initialTheta,
            initialTimeValue: data.initialTimeValue,
            initialVega: data.initialVega,
            initialIV: data.initialIV,
            potentialProfit: data.potentialProfit,
            totalRisk: totalRisk,
            potentialReturnPercentage: potentialReturnPercentage,
            AnnualisedReturn: AnnualisedReturn,
            assignedBool: undefined,
            spotPriceAtAssignment: undefined,
            buyBackPricePerContract: undefined,
            tradeSource: source.value,
            notes: data.notes,
            realisedProfitAndLoss: undefined,
            account: accountId,
            commission: data.commission,
            tags: tags,
            status: "Ready",
          };

          let newRow = payload;

          payload = Object.fromEntries(
            Object.entries(payload).filter(([_, v]) => v != null)
          );

          await props
            .addTrade(payload)
            .then((res) => {
              if (res.status === "Trade created") {
                console.log(res);

                newRow = { ...newRow, ...data };
                newRow.status = "Trade created";

                completedRows.push(newRow);
              } else {
                newRow = { ...newRow, ...data };
                newRow.status = "Failed";

                completedRows.push(newRow);
              }
            })
            .then(() => {
              setRowChange({ msg: "change" });

              if (index === rows.length - 1) {
                setTimeout(() => {
                  props.refresh();
                }, 2500);
              }
            });
        } else {
          completedRows.push(data);
        }
      });

      setTradesRowObject(completedRows);
      setRowChange({ msg: "change" });

      let allTradesComplete = 0;
      completedRows.filter((value, index) => {
        if (value.status === "Trade created") {
          allTradesComplete++;
        }

        if (index === completedRows.length - 1) {
          if (allTradesComplete === completedRows.length) {
            console.log("All trades completed");

            props.closeModal();
          } else {
            console.log("Not all trades completed");
          }
        }
      });

      setTimeout(() => {
        //XXXX
        props.refresh();

        setTimeout(() => {
          setSubmittingTradesObject(false);
          setLoading(false);
          props.closeModal();
        }, 2500);
      }, 5500);
    }
  };

  const handleCreateItem = (item) => {
    setStrategies((curr) => [...curr, item]);
    setStrategy((curr) => item);
  };
  const handleCreateItemSource = (item) => {
    setSources((curr) => [...curr, item]);
    setSource((curr) => item);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setStrategy({ label: "No strategy", value: "No strategy" });
      } else {
        if (selectedItems.length > 1) {
          setStrategy(selectedItems[1]);
        } else {
          setStrategy({ label: "No strategy", value: "No strategy" });
        }
      }
    }
  };
  const handleSelectedItemsChangeSource = (selectedItems) => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setSource({ label: "No source", value: "No source" });
      } else {
        if (selectedItems.length > 1) {
          setSource(selectedItems[1]);
        } else {
          setSource({ label: "No source", value: "No source" });
        }
      }
    }
  };

  const customRender = (selected) => {
    return (
      <Flex flexDir="row" alignItems="center">
        <Text>{selected.label}</Text>
      </Flex>
    );
  };

  const setDate = (e, field) => {
    setDateOpened(
      moment(fixTimezoneOffset(e)).tz("Europe/London").format("MM/DD/YYYY")
    );
    setValue(
      "dateOpened",
      moment(fixTimezoneOffset(e)).tz("Europe/London").format("MM/DD/YYYY")
    );

    setValue(
      "dateOpened",
      moment(fixTimezoneOffset(e)).tz("Europe/London").format("MM/DD/YYYY")
    );
    field.field.onChange(e);

    let stockTicker = getValues("stockTicker");
    setPriceLoading(true);

    let today = moment();
    let date = moment(fixTimezoneOffset(e))
      .tz("Europe/London")
      .format("YYYY-MM-DD");

    if (!today.isSame(date, "d")) {
      API.getStockPriceByDate(props.APIEndpoint, stockTicker, date).then(
        (data) => {
          if (data.success === false) {
            setValue("spotPriceAtTradeTime", 0);
            setDateOfSpotPrice(
              moment(fixTimezoneOffset(e))
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            );
            setPriceLoading(false);
          } else if (data.data) {
            setValue("spotPriceAtTradeTime", data.data.close.toFixed(2));
            setDateOfSpotPrice(
              moment(fixTimezoneOffset(e))
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            );
            setPriceLoading(false);
          } else {
            // alert("Failed to get live spot price for that date.")
            setPriceLoading(false);
          }
        }
      );
    } else {
      API.getStockPrice(props.APIEndpoint, getValues("stockTicker")).then(
        (data) => {
          if (data.success === false) {
            setValue("spotPriceAtTradeTime", 0);
            setDateOfSpotPrice(null);
            setPriceLoading(false);
          } else {
            try {
              setValue("spotPriceAtTradeTime", data.data.close.toFixed(2));
            } catch (e) {
              setValue("spotPriceAtTradeTime", 0);
            }
            setDateOfSpotPrice(null);
            setPriceLoading(false);
          }
        }
      );
    }

    editRow({ id: 0 });
  };

  const customCreateItemRender = (value) => {
    return (
      <Text>
        <Box as="span">Create</Box>{" "}
        <Box as="span" bg="red.300" fontWeight="bold">
          "{value}"
        </Box>
      </Text>
    );
  };

  let premium = 0;
  let premiumTotal = 0;

  tradesRowObject.filter((value, index) => {
    if (value.tradeType !== "STOCK") {
      if (value.action === "BUY_TO_OPEN") {
        premium = premium - parseFloat(value.initialLastPrice);
      } else {
        premium = premium + parseFloat(value.initialLastPrice);
      }
    }

    if (value.tradeType === "STOCK" && value.action === "BUY") {
      premiumTotal -= value.quantity * getValues("spotPriceAtTradeTime");
    } else if (value.tradeType === "STOCK" && value.action === "SHORT") {
      premiumTotal += value.quantity * getValues("spotPriceAtTradeTime");
    } else if (value.tradeType !== "STOCK" && value.action === "BUY_TO_OPEN") {
      premiumTotal -= value.quantity * parseFloat(value.initialLastPrice) * 100;
    } else if (value.tradeType !== "STOCK" && value.action === "SELL_TO_OPEN") {
      premiumTotal += value.quantity * parseFloat(value.initialLastPrice) * 100;
    }

    premiumTotal -= parseFloat(value.commission);
  });

  if (premiumTotal < 0) {
    premiumTotal =
      currencyFormatter.format(premiumTotal, { currency: "USD" }) + " DB";
  } else {
    premiumTotal =
      currencyFormatter.format(premiumTotal, { currency: "USD" }) + " CR";
  }

  if (premium < 0) {
    premium = currencyFormatter.format(premium, { currency: "USD" }) + " DB";
  } else {
    premium = currencyFormatter.format(premium, { currency: "USD" }) + " CR";
  }

  const search = () => {
    setAddLegButtonLoading(true);
    setLoading(true);
    setStockName(null);
    setStockData(null);
    setOptionData(null);
    setTradeType("STOCK");
    setExpiryDate(null);
    setExpiryOptions([]);
    setStrikePrices([]);
    setStrikePrice([]);
    setSpecificOptionData([]);
    setSpecificContractData(null);
    setStockSearchErrorMessage(null);
    setInitialInTheMoney(null);
    setPrefetchedOptionData({});

    setTradesRowObject([
      {
        id: uuid.v4(),
        tradeType: "STOCK",
        action: "BUY",
        positionStatus: "OPEN",
        quantity: "100",
        status: "Ready",
      },
    ]);

    setValue("tradeType", null);

    API.searchForStock(props.APIEndpoint, getValues("stockTicker"))
      .then((data) => {
        setStockSearchErrorMessage(null);

        if (data.success === true && !data.data.Code.includes("/")) {
          let stockTicker = data.data.Code;
          setValue("stockTicker", data.data.Code);
          setCode(data.data.Code);

          let name = data.data.Name;

          API.getStockPrice(props.APIEndpoint, getValues("stockTicker"))
            .then((data) => {
              let d = undefined;

              if (data.data.data && data.data.data.success === true) {
                setStockData(data.data.data);
              } else {
                setStockData(data.data);
              }

              //Set stock price
              setValue("spotPriceAtTradeTime", data.data.close);
              setStockName(name);

              setLoading(false);

              let strategies = [...customStrategies];
              props.trades.filter((value, index) => {
                // if(value.stockTicker === data.data.stockTicker) {
                if (value.strategy !== undefined) {
                  strategies.push({
                    label: value.strategy,
                    value: value.strategy,
                  });
                }
                // }
              });

              const unique = [...new Set(strategies.map((item) => item.value))];
              let stratOb = [];
              unique.filter((value, index) => {
                stratOb.push({ label: value, value: value });
              });
              setStrategies(stratOb);

              let sources = [];
              props.trades.filter((value, index) => {
                // if(value.stockTicker === data.data.stockTicker) {
                if (value.tradeSource !== undefined) {
                  sources.push({
                    label: value.tradeSource,
                    value: value.tradeSource,
                  });
                }
                // }
              });

              const uniqueSource = [
                ...new Set(sources.map((item) => item.value)),
              ];
              let sourceOb = [];
              uniqueSource.filter((value, index) => {
                sourceOb.push({ label: value, value: value });
              });
              setSources(sourceOb);

              API.getOptionData(props.APIEndpoint, stockTicker).then((res) => {
                if (res.success === true) {
                  setPrefetchedOptionData(res.data);

                  setLoading(false);
                  setAddLegButtonLoading(false);
                } else {
                  setLoading(false);
                  setAddLegButtonLoading(false);
                  alert(
                    "Something went wrong retreiving the data",
                    res.message
                  );
                }
              });
            })
            .catch((e) => {
              console.log("Something went wrong getting the stock price", e);
              setStockSearchErrorMessage(
                "There was an issue retrieving the stock data."
              );
              setLoading(false);
            });
        } else {
          setOverrideAllValues(true);

          let stockTicker = getValues("stockTicker");
          setCode(stockTicker);

          let name = stockTicker;

          let data = {
            _id: "",
            lastUpdated: "",
            stockTicker: "",
            code: "",
            gmtoffset: 0,
            change_p: 0,
            __v: 0,
            change: 0,
            close: 0,
            high: 0,
            low: 0,
            open: 0,
            previousClose: 0,
            timestamp: 0,
            volume: 0,
          };

          let d = undefined;

          setStockData(data);

          //Set stock price
          setValue("spotPriceAtTradeTime", 0);
          setStockName(name);

          setLoading(false);

          let strategies = [...customStrategies];
          props.trades.filter((value, index) => {
            // if(value.stockTicker === data.data.stockTicker) {
            if (value.strategy !== undefined) {
              strategies.push({
                label: value.strategy,
                value: value.strategy,
              });
            }
            // }
          });

          const unique = [...new Set(strategies.map((item) => item.value))];
          let stratOb = [];
          unique.filter((value, index) => {
            stratOb.push({ label: value, value: value });
          });
          setStrategies(stratOb);

          let sources = [];
          props.trades.filter((value, index) => {
            // if(value.stockTicker === data.data.stockTicker) {
            if (value.tradeSource !== undefined) {
              sources.push({
                label: value.tradeSource,
                value: value.tradeSource,
              });
            }
            // }
          });

          const uniqueSource = [...new Set(sources.map((item) => item.value))];
          let sourceOb = [];
          uniqueSource.filter((value, index) => {
            sourceOb.push({ label: value, value: value });
          });
          setSources(sourceOb);

          /////
          setAddLegButtonLoading(false);
          setValue("spotPriceAtTradeTime", 0);
          // alert('Something has gone wrong. Try using single leg entry.');
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Something went wrong with the stock request.", e);
        setStockSearchErrorMessage("We could not find that stock.");
        setLoading(false);
      });
    return "";
  };

  useEffect(() => {
    if (props.predefinedStock) {
      setValue(
        "stockTicker",
        props.predefinedStock.toUpperCase().replace("/", "%2F")
      );
      search();
    }
  }, [props.predefinedStock]);

  if (submittingTradesObject === true) {
    return (
      <Box mt="2">
        <VStack>
          <Spinner />
          <Text>We're processing your trades...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Stack spacing="4">
          {loading === true && (
            <Box mt="2">
              <Spinner />
            </Box>
          )}

          {stockName && (
            <VStack width="full" spacing="6">
              <FormControl className="dateOpened">
                <FormLabel>Date opened</FormLabel>

                <InputGroup>
                  <Controller
                    control={control}
                    name="ReactDatepickerDateOpened"
                    render={(field) => (
                      <CustomDatePicker
                        placeholderText="Select date trade was opened"
                        onChange={(e) => {
                          setDate(e, field);
                        }}
                        selected={field.field.value}
                      />
                    )}
                  />
                  {/* <InputRightElement width="5rem">
                    <Controller
                      control={control}
                      name="ReactDatepickerDateOpened"
                      render={(field) => (
                        <Button h="1.75rem" size="sm" onClick={() => {
                          setDate(new Date(), field);
                        }}
                        >
                          Today
                        </Button>
                      )} />
                  </InputRightElement> */}
                </InputGroup>
              </FormControl>
            </VStack>
          )}

          {dateOpened !== undefined && stockName && (
            <VStack width="full" spacing="6">
              <FormControl id="spotPrice">
                <FormLabel>
                  Spot price
                  {dateOfSpotPrice !== null ? (
                    <span style={{ color: "var(--accent)" }}>
                      {" "}
                      (This is the price on{" "}
                      {moment(dateOfSpotPrice).format("MM/DD/YYYY")})
                    </span>
                  ) : (
                    <span style={{ color: "var(--accent)" }}>
                      {" "}
                      (Currently set to the live price)
                    </span>
                  )}{" "}
                </FormLabel>
                <InputGroup>
                  <Input
                    style={{ backgroundColor: "var(--dark-gray)", border: 0 }}
                    type="number"
                    placeholder="Enter spot price at time of trade"
                    step=".000001"
                    {...register("spotPriceAtTradeTime")}
                  />
                  {/* <InputRightElement width="7.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => {
                        setPriceLoading(true);

                        API.getStockPrice(
                          props.APIEndpoint,
                          getValues("stockTicker")
                        ).then((data) => {
                          try {
                            setValue(
                              "spotPriceAtTradeTime",
                              data.data.close.toFixed(2)
                            );
                          }
                          catch (e) {
                            setValue(
                              "spotPriceAtTradeTime",
                              0
                            );
                          }
                          setDateOfSpotPrice(null);
                          setPriceLoading(false);
                        });
                        return "";
                      }}
                      isLoading={priceLoading ? true : false}
                    >
                      Get live price
                    </Button>
                  </InputRightElement> */}
                </InputGroup>
              </FormControl>
            </VStack>
          )}

          {dateOpened !== undefined && stockName && (
            <VStack width="full" spacing="6">
              {addLegButtonLoadingObject === true ? (
                <Spinner />
              ) : (
                <>
                  {stockName && (
                    <TableFrame size="sm" id="multi-leg-entry-table">
                      <TableHeader>
                        <CustomTr>
                          <CustomTh>Type</CustomTh>
                          <CustomTh>Action</CustomTh>
                          <CustomTh>Expiry</CustomTh>
                          <CustomTh>Strike</CustomTh>
                          <Tooltip label="Contracts or number of shares">
                            <CustomTh>Quantity</CustomTh>
                          </Tooltip>
                          <CustomTh>Premium</CustomTh>
                          <CustomTh>Commission</CustomTh>
                          <CustomTh>Total</CustomTh>
                          <CustomTh>Status</CustomTh>
                        </CustomTr>
                      </TableHeader>
                      <CustomTBody>
                        {tradesRowObject.map((value, index) => {
                          let row = tradesRowObject.filter((trade) => {
                            if (trade.id === value.id) {
                              return trade;
                            }
                          })[0];
                          return (
                            <CustomTr index={"row-1-" + index}>
                              <CustomTd>
                                <FormControl
                                  size="sm"
                                  isRequired
                                  id="tradeType"
                                >
                                  <InputGroup>
                                    <Select
                                      isInvalid={
                                        errors[row.id] &&
                                        errors[row.id].tradeType
                                      }
                                      size="lg"
                                      onChange={(e) => {
                                        if (e.target.value.length < 2) {
                                          row.tradeType = "";
                                          row.action = "";
                                          editRow(row);
                                        } else if (e.target.value !== "STOCK") {
                                          row.id = value.id;
                                          row.optionsData = undefined;
                                          row.setExpiryOptions = undefined;
                                          row.tradeType = e.target.value;
                                          row.action = "SELL_TO_OPEN";
                                          row.loading = true;
                                          setAddLegButtonLoading(true);

                                          editRow(row);

                                          if (overrideAllValues === false) {
                                            let result =
                                              prefetchedOptionData.data.map(
                                                (a) => a.expirationDate
                                              );
                                            row.optionsData =
                                              prefetchedOptionData;
                                            row.expiryOptions = result;
                                            row.loading = false;
                                            setAddLegButtonLoading(false);
                                            editRow(row);
                                          } else {
                                            setPrefetchedOptionData({});
                                            setAddLegButtonLoading(false);
                                            row.loading = false;
                                            row.expiryOptions = [];
                                            row.optionsData = [];
                                            editRow(row);
                                          }
                                        } else {
                                          row.loading = false;
                                          setAddLegButtonLoading(false);

                                          row.tradeType = "STOCK";
                                          row.optionsData = undefined;
                                          row.setExpiryOptions = undefined;
                                          row.action = "BUY";
                                          editRow(row);
                                        }
                                      }}
                                      value={row.tradeType}
                                    >
                                      <option value=""></option>
                                      <option value="STOCK">Stock</option>
                                      <option value="PUT">Put</option>
                                      <option value="CALL">Call</option>
                                    </Select>
                                  </InputGroup>
                                </FormControl>
                              </CustomTd>
                              <CustomTd>
                                {row.tradeType !== undefined &&
                                  row.tradeType !== "STOCK" && (
                                    <FormControl isRequired id="actionTab">
                                      <InputGroup>
                                        <Select
                                          size="lg"
                                          isInvalid={
                                            errors[row.id] &&
                                            errors[row.id].action
                                          }
                                          onChange={(e) => {
                                            row.action = e.target.value;
                                            editRow(row);
                                          }}
                                          value={row.action}
                                        >
                                          <option value=""></option>
                                          <option value="BUY_TO_OPEN">
                                            Buy to open
                                          </option>
                                          <option value="SELL_TO_OPEN">
                                            Sell to open
                                          </option>
                                        </Select>
                                      </InputGroup>
                                    </FormControl>
                                  )}

                                {row.tradeType !== undefined &&
                                  row.tradeType === "STOCK" && (
                                    <FormControl isRequired id="actionTab">
                                      <InputGroup>
                                        <Select
                                          isInvalid={
                                            errors[row.id] &&
                                            errors[row.id].action
                                          }
                                          size="lg"
                                          onChange={(e) => {
                                            row.action = e.target.value;
                                            editRow(row);
                                          }}
                                          value={row.action}
                                        >
                                          <option value=""></option>

                                          <option value="BUY">Buy</option>
                                          <option value="SHORT">Short</option>
                                        </Select>
                                      </InputGroup>
                                    </FormControl>
                                  )}
                              </CustomTd>
                              <CustomTd>
                                {row.expiryOptions !== undefined &&
                                  row.tradeType !== undefined &&
                                  row.tradeType !== "STOCK" &&
                                  row.tradeType.length > 2 && (
                                    <FormControl isRequired id="expiryDate">
                                      {overrideAllValues === false ? (
                                        <InputGroup>
                                          <Select
                                            size="lg"
                                            isInvalid={
                                              errors[row.id] &&
                                              errors[row.id].expiryDate
                                            }
                                            onChange={(e) => {
                                              // row.expiryDate = moment(e.target.value).format("MM/DD/YYYY");
                                              row.expiryDate = e.target.value;
                                              row.loading = true;

                                              let exDate = moment(
                                                row.expiryDate
                                              ).format("YYYY-MM-DD");
                                              row.specificOption =
                                                row.optionsData.data.find(
                                                  (x) =>
                                                    x.expirationDate === exDate
                                                );
                                              row.specificOptionData =
                                                row.specificOption.options[
                                                  row.tradeType
                                                ];

                                              let distilledStrikePrices =
                                                row.specificOption.options[
                                                  row.tradeType
                                                ].map((a) => "" + a.strike);

                                              row.strikePrices =
                                                distilledStrikePrices;
                                              row.loading = false;
                                              editRow(row);
                                            }}
                                            value={row.expiryDate}
                                          >
                                            <option value={""}>
                                              Select a expiry
                                            </option>
                                            {row.expiryOptions.map(
                                              (value, index) => {
                                                return (
                                                  <option
                                                    value={value}
                                                    key={index}
                                                  >
                                                    {moment(value).format(
                                                      "Do MMM YYYY"
                                                    )}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </InputGroup>
                                      ) : (
                                        <Controller
                                          control={control}
                                          name={
                                            "ReactDatepickerOverrideExpiryDate" +
                                            row.id
                                          }
                                          render={(field) => {
                                            return (
                                              <ReactDatePicker
                                                className={
                                                  "react-datepicker-multi-leg"
                                                }
                                                placeholderText="Expiry"
                                                onChange={(e) => {
                                                  console.log(
                                                    field.field.value
                                                  );
                                                  field.field.value = e;
                                                  row.expiryDate = e;
                                                  row.strikePrices = 0;
                                                  editRow(row);
                                                  field.field.onChange(e);
                                                }}
                                                selected={field.field.value}
                                              />
                                            );
                                          }}
                                        />
                                      )}
                                    </FormControl>
                                  )}
                              </CustomTd>
                              <CustomTd>
                                {row.specificOption !== undefined &&
                                  row.specificOptionData !== undefined &&
                                  row.strikePrices !== undefined &&
                                  row.tradeType !== "STOCK" && (
                                    <FormControl isRequired id="strike">
                                      {overrideAllValues === false && (
                                        <Select
                                          size="lg"
                                          isInvalid={
                                            errors[row.id] &&
                                            errors[row.id].strikePrice
                                          }
                                          onChange={(e) => {
                                            row.strikePrice = e.target.value;

                                            let specificContract =
                                              row.specificOptionData.find(
                                                (x) =>
                                                  "" + x.strike ===
                                                  "" + e.target.value
                                              );

                                            row.specificContractData =
                                              specificContract;

                                            row.initialDelta =
                                              row.specificContractData.delta;
                                            row.initialGamma =
                                              row.specificContractData.gamma;
                                            row.initialIntrinsicValue =
                                              row.specificContractData.intrinsicValue;
                                            row.initialLastPrice =
                                              row.specificContractData.lastPrice;
                                            row.initialTheoretical =
                                              row.specificContractData.theoretical;
                                            row.initialTheta =
                                              row.specificContractData.theta;
                                            row.initialTimeValue =
                                              row.specificContractData.timeValue;
                                            row.initialIV =
                                              row.specificContractData.impliedVolatility;
                                            row.initialVega =
                                              row.specificContractData.vega;
                                            row.initialRho =
                                              row.specificContractData.rho;

                                            editRow(row);
                                          }}
                                          value={row.strikePrice}
                                        >
                                          <option value={""}>
                                            Select a strike
                                          </option>
                                          {row.strikePrices.map(
                                            (value, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={value}
                                                >
                                                  {value}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      )}
                                    </FormControl>
                                  )}

                                {row.tradeType !== "STOCK" &&
                                  overrideAllValues === true && (
                                    <Input
                                      onChange={(e) => {
                                        row.strikePrice = e.target.value;
                                        editRow(row);
                                      }}
                                      isInvalid={
                                        errors[row.id] &&
                                        errors[row.id].strikePrice
                                      }
                                      value={row.strikePrice}
                                      size="lg"
                                      type="number"
                                      placeholder="Enter strike"
                                    />
                                  )}
                              </CustomTd>

                              <CustomTd>
                                {tradeType !== "STOCK" && (
                                  <FormControl isRequired id="contracts">
                                    <InputGroup>
                                      <Input
                                        onChange={(e) => {
                                          row.contracts = e.target.value;
                                          editRow(row);
                                        }}
                                        isInvalid={
                                          errors[row.id] &&
                                          errors[row.id].quantity
                                        }
                                        value={row.contracts}
                                        size="lg"
                                        type="number"
                                        placeholder="Enter contracts"
                                      />
                                    </InputGroup>
                                  </FormControl>
                                )}
                                {tradeType === "STOCK" && (
                                  <FormControl isRequired>
                                    <InputGroup>
                                      <Input
                                        size="lg"
                                        type="number"
                                        isInvalid={
                                          errors[row.id] &&
                                          errors[row.id].quantity
                                        }
                                        placeholder="Enter quantity"
                                        onChange={(e) => {
                                          row.quantity = e.target.value;
                                          editRow(row);
                                        }}
                                        value={row.quantity}
                                      />
                                    </InputGroup>
                                  </FormControl>
                                )}
                              </CustomTd>
                              <CustomTd>
                                {row.tradeType !== "STOCK" && (
                                  <FormControl isRequired id="premium">
                                    <Input
                                      size="lg"
                                      type="number"
                                      isInvalid={
                                        errors[row.id] && errors[row.id].premium
                                      }
                                      placeholder="Enter premium"
                                      step=".000001"
                                      onChange={(e) => {
                                        row.initialLastPrice = e.target.value;
                                        editRow(row);
                                      }}
                                      value={row.initialLastPrice}
                                    />
                                  </FormControl>
                                )}
                              </CustomTd>
                              <CustomTd>
                                <FormControl isRequired id="commission">
                                  <Input
                                    size="lg"
                                    type="number"
                                    isInvalid={
                                      errors[row.id] &&
                                      errors[row.id].commission
                                    }
                                    placeholder="Enter commission ($)"
                                    step=".000001"
                                    onChange={(e) => {
                                      row.commission = e.target.value;
                                      editRow(row);
                                    }}
                                    value={row.commission}
                                  />
                                </FormControl>
                              </CustomTd>
                              <CustomTd>
                                {row.tradeType === "STOCK" &&
                                  row.action === "BUY" && (
                                    <Text>
                                      $
                                      {currencyFormatter.format(
                                        row.quantity *
                                          parseFloat(
                                            getValues("spotPriceAtTradeTime")
                                          ),
                                        { currency: "USD" }
                                      )}{" "}
                                      DB
                                    </Text>
                                  )}
                                {row.tradeType === "STOCK" &&
                                  row.action === "SHORT" && (
                                    <Text>
                                      $
                                      {currencyFormatter.format(
                                        row.quantity *
                                          parseFloat(
                                            getValues("spotPriceAtTradeTime")
                                          ),
                                        { currency: "USD" }
                                      )}{" "}
                                      CR
                                    </Text>
                                  )}
                                {row.tradeType !== "STOCK" &&
                                  row.action === "BUY_TO_OPEN" && (
                                    <Text>
                                      $
                                      {currencyFormatter.format(
                                        row.quantity *
                                          (parseFloat(row.initialLastPrice) *
                                            100),
                                        { currency: "USD" }
                                      )}{" "}
                                      DB
                                    </Text>
                                  )}
                                {row.tradeType !== "STOCK" &&
                                  row.action === "SELL_TO_OPEN" && (
                                    <Text>
                                      $
                                      {currencyFormatter.format(
                                        row.quantity *
                                          (parseFloat(row.initialLastPrice) *
                                            100),
                                        { currency: "USD" }
                                      )}{" "}
                                      CR
                                    </Text>
                                  )}
                              </CustomTd>
                              <CustomTd>
                                {row.loading === true ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <>{row.status}</>
                                )}
                              </CustomTd>

                              <CustomTd>
                                <CustomIconButton
                                  className="customDeleteButton"
                                  onClick={() => {
                                    removeRow(value.id);
                                  }}
                                  icon={<DeleteIcon />}
                                />
                              </CustomTd>
                            </CustomTr>
                          );
                        })}

                        <CustomTr>
                          <CustomTd></CustomTd>
                          <CustomTd></CustomTd>
                          <CustomTd></CustomTd>
                          <CustomTd></CustomTd>
                          <CustomTd></CustomTd>
                          <CustomTd fontSize="lg">Σ: {premium}</CustomTd>
                          <CustomTd></CustomTd>
                          <CustomTd fontSize="lg">Σ: {premiumTotal}</CustomTd>
                          <CustomTd></CustomTd>
                        </CustomTr>
                      </CustomTBody>
                    </TableFrame>
                  )}

                  {errorsList && errorsList.length > 0 && (
                    <Box>
                      {errorsList.map((value, index) => {
                        return (
                          <Text color="var(--red)" fontSize="14px" key={index}>
                            {value}
                          </Text>
                        );
                      })}
                    </Box>
                  )}

                  <HStack justifyContent={"flex-end"} width="100%">
                    <CustomToolTip label="Allows you to override the date and strike if they're not available in the drop down.">
                      <SecondaryButton
                        mr="5"
                        onClick={() => {
                          if (overrideAllValues === false) {
                            setOverrideAllValues(true);
                          } else {
                            setOverrideAllValues(false);
                          }
                        }}
                      >
                        {overrideAllValues === false
                          ? "Override strike & expiry fields"
                          : "Cancel"}
                      </SecondaryButton>
                    </CustomToolTip>
                    {stockName && (
                      <PrimaryButton
                        onClick={() => {
                          addRow();
                        }}
                      >
                        Add leg
                      </PrimaryButton>
                    )}
                  </HStack>
                </>
              )}
            </VStack>
          )}

          {dateOpened !== undefined && stockName && (
            <VStack width="full" spacing="6">
              {stockName && (
                <>
                  <FormControl className="strategyOp">
                    <DropDownSelectorsCustom
                      tagStyleProps={{
                        rounded: "full",
                      }}
                      label="Enter or select your strategy name here, this will be visible in the grouped view"
                      placeholder="Type a strategy and press return to add it"
                      onCreateItem={handleCreateItem}
                      items={strategies}
                      itemRenderer={customRender}
                      createItemRenderer={(value) =>
                        `Click here to save ${value}`
                      }
                      // createItemRenderer={customCreateItemRender}
                      selectedItems={[strategy]}
                      onSelectedItemsChange={(changes) => {
                        handleSelectedItemsChange(changes.selectedItems);
                      }}
                    />
                  </FormControl>

                  <FormControl id="source" mb="2">
                    <FormLabel fontWeight={600}>Tags</FormLabel>
                    <TagView
                      allTags={props.allTags}
                      recordTagValue={(tag) => {
                        setTags(tag);
                      }}
                    />
                  </FormControl>

                  <FormControl id="source">
                    <DropDownSelectorsCustom
                      tagStyleProps={{
                        rounded: "full",
                      }}
                      label="Enter where you came across the trade"
                      placeholder="Type a source and press return to add it"
                      onCreateItem={handleCreateItemSource}
                      items={sources}
                      createItemRenderer={(value) =>
                        `Click here to save ${value}`
                      }
                      itemRenderer={customRender}
                      // createItemRenderer={customCreateItemRender}
                      selectedItems={[source]}
                      onSelectedItemsChange={(changes) => {
                        handleSelectedItemsChangeSource(changes.selectedItems);
                      }}
                    />
                  </FormControl>
                </>
              )}

              {stockName && (
                <>
                  <FormControl id="notes">
                    <FormLabel>Notes</FormLabel>
                    <InputGroup>
                      <Textarea
                        style={{
                          backgroundColor: "var(--dark-gray)",
                          border: 0,
                        }}
                        placeholder="Add a note to make it easier to navigate the deal"
                        {...register("notes")}
                      />
                    </InputGroup>
                  </FormControl>
                </>
              )}
            </VStack>
          )}
        </Stack>

        <HStack mt="20px" justifyContent={"flex-end"}>
          <NegativeButton mr="5" onClick={() => props.closeModal()}>
            Close
          </NegativeButton>

          {stockName && stockName.length > 0 && (
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              isLoading={props.loading}
              disabled={addLegButtonLoadingObject}
            >
              Add trades
            </PrimaryButton>
          )}
        </HStack>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(AddTradeMultiLeg);
