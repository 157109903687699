import { CheckIcon, CloseIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Box, Button, chakra, Heading, HStack, Icon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Tab, Table, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import moment from 'moment';
import React, { Component } from 'react';
import { IoAddCircleOutline, IoRemoveCircle } from "react-icons/io5";
import { connect } from 'react-redux';
import { useExpanded, useGroupBy, useSortBy, useTable } from "react-table";
import { Spinner } from '../components';
import API from '../helpers/apiCalls';
import greeksComparison from '../helpers/greeksComparison';
import OptionChainAnalysis from './OptionChainAnalysis';
import './style.css';
import chroma from 'chroma-js';
import { FaCloudscale } from "react-icons/fa";
import { TableFrame } from "../helpers/TableFrame";
import { TableHeader } from "../helpers/TableHeader";
import { TabsAlt } from "../helpers/Tabs";
import { ChartFrame } from "../helpers/ChartFrame";
import { CustomModal, CustomModalBasic, CustomTab, CustomTabList, CustomTabsContainer, InsetTab } from "../../design_library";
import HistoricalOptionData from "../trades/subcomponents/HistoricalOptionData";

var currencyFormatter = require('currency-formatter');

class ScanOptionChain extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      tradeType: 'PUT',
      action: 'SELL_TO_OPEN',
      originalOptionChain: [],
      optionChain: [],
      optionsGreeksAnalysis: [],
      lastStockPrice: 0,
      trades: [],
      expiries: [],
      selectedExpiry: '',
      mergedOptionData: [],
    }
  }


  componentDidMount() {
    this.getTrades();
  }


  getTrades = () => {

    this.setState({ loading: true });
    API.getTrades(this.props.settings.APIEndpoint, {
      tradeType: undefined,
      positionStatus: 'CLOSED',
      stockTicker: '',
      tags: [],
    }).then((res) => {
      this.setState({ trades: res.trades });
      greeksComparison.calculateGreeksComparison(res.trades, this.state.tradeType, this.state.action, false).then((array) => {
        this.setState({ optionsGreeksAnalysis: array });
        API.getOptionData(this.props.settings.APIEndpoint, this.props.stockTicker).then((res) => {

          if (res.data === undefined) {
            this.setState({
              error: true
            });
            return;
          }
          else {

            let optionsData = [];
            let mergedOptionData = [];
            let lastStockPrice = res.data.lastTradePrice;
            let expiries = [];


            if (res.data && res.data.data) {
              res.data.data.filter((value, index) => {
                try {

                  expiries.push(value.expirationDate);


                  value.options[this.state.tradeType].filter((options, index) => {
                    if (options.expirationDate === expiries[0]) {
                      optionsData.push(options);
                    }
                  });

                  value.options['PUT'].filter((options, index) => {
                    if (options.expirationDate === expiries[0]) {
                      mergedOptionData.push(options);
                    }
                  });
                  value.options['CALL'].filter((options, index) => {
                    if (options.expirationDate === expiries[0]) {
                      mergedOptionData.push(options);
                    }
                  });
                }
                catch (e) {
                  console.log("ERROR:", e)
                }
              })
            }


            this.setState({ mergedOptionData: mergedOptionData, resDataOptions: res.data.data, loading: false, optionChain: optionsData, lastStockPrice, originalOptionChain: res.data.data, expiries: expiries, selectedExpiry: expiries[0] });

          }
        });
      });
    })
  };

  filterTrades = () => {
    this.setState({ loading: true });
    greeksComparison.calculateGreeksComparison(this.state.trades, this.state.tradeType, this.state.action, false).then((array) => {
      this.setState({ optionsGreeksAnalysis: array });
      let newOptionData = [];
      let mergedOptionData = [];

      this.state.resDataOptions.filter((value, index) => {


        value.options[this.state.tradeType].filter((options, index) => {
          if (options.expirationDate === this.state.selectedExpiry) {
            newOptionData.push(options);
          }
        });


        value.options['PUT'].filter((options, index) => {
          if (options.expirationDate === this.state.selectedExpiry) {
            mergedOptionData.push(options);
          }
        });
        value.options['CALL'].filter((options, index) => {
          if (options.expirationDate === this.state.selectedExpiry) {
            mergedOptionData.push(options);
          }
        });
      })
      this.setState({ mergedOptionData, optionChain: newOptionData, loading: false });
    })
  }




  render() {

    if (this.state.error === true) {
      return <Text>Something has gone wrong. 😭</Text>
    }

    return (

      <Box maxW="8xl" shadow="base" overflow="hidden" padding="5" >
        <Heading as="h4" size="md" pb="15px">Option chain {this.state.selectedExpiry && '(' + moment(this.state.selectedExpiry).format('MM/DD/YYYY') + ')'}</Heading>
        {/* {!this.props.settings.profile.annualProfitTarget && <Text mt={5} mb={5} color={'red'}>You haven't set your profit targets, go to the settings page to set them. You will not see indications of options meeting your targets until you do this.</Text>} */}
        {/* {Object.keys(this.state.optionsGreeksAnalysis).length > 0 && this.state.optionsGreeksAnalysis[0].winAverage === 0 && <Text mt={5} mb={5} color={'red'}>You haven't closed enough trades yet to start seeing indications of favourable greeks. Complete a few more trades so we can learn about your unique style.</Text>} */}
        <HStack pb={"15px"} maxW={"800px"}>
          <Select placeholder="Select trade type" value={this.state.tradeType} onChange={(e) => {
            this.setState({ tradeType: e.target.value });

          }}>
            <option value="CALL">Call</option>
            <option value="PUT">Put</option>
          </Select>
          {/* <Select placeholder="Select action" value={this.state.action} onChange={(e) => {
            this.setState({ action: e.target.value });

          }}>
            <option value="SELL_TO_OPEN">Sell to open</option>
            <option value="BUY_TO_OPEN">Buy to open</option>
          </Select> */}

          <Select placeholder="Select expiry" value={this.state.selectedExpiry} onChange={(e) => {
            this.setState({ selectedExpiry: e.target.value });
          }}>
            {this.state.expiries.map((value, index) => {
              return (
                <option value={value} key={index}>{moment(value).format('MM/DD/YYYY')}</option>
              )
            })}
          </Select>

          <Button
            size={'md'}
            pr="35px"
            pl="35px"
            isLoading={this.state.loading}
            disabled={this.state.action.length < 1 || this.state.tradeType.length < 1 ? true : false}
            onClick={() => {
              this.filterTrades();
            }}><Text>Filter</Text></Button>



        </HStack>

        <CustomTabsContainer >
          <CustomTabList>
            <CustomTab>Chain</CustomTab>
            <CustomTab>Charts</CustomTab>
          </CustomTabList>
          <TabPanels>
            <TabPanel>
              {this.state.loading === false ?
                <OptionsTable
                  openModal={(contractName) => {

                    this.setState({ contractName: contractName, isOpen: true });
                  }}
                  annualProfitTarget={this.props.settings.profile.annualProfitTarget} optionsGreekAnalysis={this.state.optionsGreeksAnalysis} data={this.state.optionChain} action={this.state.action} tradeType={this.state.tradeType} lastStockPrice={this.state.lastStockPrice} />
                :
                <Spinner />
              }
            </TabPanel>
            <TabPanel>
              {this.state.loading === false ?
                <ChartFrame>
                  <OptionChainAnalysis data={this.state.mergedOptionData} lastStockPrice={this.state.lastStockPrice} />

                </ChartFrame>
                :
                <Spinner />
              }
            </TabPanel>
          </TabPanels>

        </CustomTabsContainer>


        <CustomModal header={this.state.contractName && this.state.contractName.replace("O:", "")} size="full" isOpen={this.state.isOpen} onClose={() => {
          this.setState({ isOpen: false });
        }}
          body={
            <>
              <ModalCloseButton />
              <HistoricalOptionData contractName={this.state.contractName} />
            </>
          } />



      </Box>
    )



  }
}


function OptionsTable(props) {

  let optionsGreekAnalysis = props.optionsGreekAnalysis;
  let lastStockPrice = props.lastStockPrice;
  let annualProfitTarget = props.annualProfitTarget;
  let tradeAction = props.action;



  const data = React.useMemo(() => props.data, []);

  const calculateOutputDirection = (optionGreekValue, value) => {
    if (value === null) {
      return <></>;
    }

    if (optionGreekValue.name === 'IV') {
      value = value / 100;
    }

    let option = optionGreekValue;
    if (option.direction === 'less') {
      if (value < option.lossAverage) {
        return <Text bg="green" color={'white'}> <CheckIcon w={4} h={4} color="white.500" /> {(value).toFixed(3)}</Text>
      }
      else {
        return <Text bg="red.600" color={'white'}> <CloseIcon w={4} h={4} color="white.500" />   {(value).toFixed(3)}</Text>
      }
    }
    else {
      if (value > option.lossAverage) {
        return <Text bg="green" color={'white'}> <CheckIcon w={4} h={4} color="white.500" />  {(value).toFixed(3)}</Text>
      }
      else {
        return <Text bg="red.600" color={'white'}> <CloseIcon w={4} h={4} color="white.500" />   {(value).toFixed(3)}</Text>
      }
    }
  }

  function getColor(percent, start, end) {
    if (end > 100) {
      end = 100;
    }
    var a = percent / 100,
      b = (end - start) * a,
      c = b + start;
    return 'hsl(' + c + ', 100%, 50%)';
  }


  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Expiry Date",
      //   accessor: "expirationDate",
      //   tooltip:'The date the option expires with the days until expiration',
      //   Cell: (props) => {
      //     if(props.row.canExpand === true) {
      //       return (<Text> {moment(props.value).format('Do MMM YYYY ')} ({moment(props.value).diff(moment(), 'days')} DTE)</Text>)
      //     }
      //     else {
      //       return (<Text></Text>)
      //     }
      //   },
      // },
      {
        Header: "Strike",
        accessor: "strike",
        tooltip: 'The strike price of the option, at the moment is indicated through the yellow font',
        Cell: (props) => {
          if (props.row.canExpand === false) {
            if (Math.round(lastStockPrice / 5) * 5 === props.value) {
              return (<Text color="orange" fontWeight="600">ATM {currencyFormatter.format(props.value, { code: 'USD' })}</Text>)
            }
            else if (Math.round(lastStockPrice / 1) * 1 === props.value) {
              return (<Text color="orange" fontWeight="600">ATM {currencyFormatter.format(props.value, { code: 'USD' })}</Text>)
            }
            else {
              return (<Text>{currencyFormatter.format(props.value, { code: 'USD' })}</Text>)
            }
          }
          else {
            return ''
          }

        }
      },
      {
        Header: "Last Price",
        accessor: "lastPrice",
        tooltip: 'The last price that the trade was executed at, 0 indicates no trades made',
        Cell: (props) => {
          if (props.row.canExpand === false) {
            return currencyFormatter.format(props.value, { code: 'USD' })
          }
          else {
            return ''
          }
        },
      },
      // {
      //   Header: "Spread %",
      //   accessor: "spread",
      //   tooltip: 'The spread, green indicates it is within 10% of the last price',
      //   Cell: (props) => {

      //     if (props.row.canExpand === false) {
      //       let spread = ((props.row.original.ask - props.row.original.bid) / ((props.row.original.ask + props.row.original.bid) / 2)) * 100;
      //       return <Text bg={chroma.scale(['#0f0', '#f00']).mode('lrgb')(spread / 100).hex()} color="black">{(spread).toFixed(2)}%</Text>
      //     }
      //     else {
      //       return ''
      //     }

      //   },
      // },
      {
        Header: "Premium % (if sold)",
        accessor: "premiumPerc",
        tooltip: 'The premium is the last trade price, divided by the strike, multiplied by 100',
        Cell: (props) => {
          if (props.row.canExpand === false) {

            let rows = props.rows;
            let topValue = 0;
            rows.filter((value, index) => {
              let premium = (value.original.lastPrice / value.original.strike) * 100;
              if (premium > topValue) {
                topValue = premium;
              }
            })



            let premium = (props.row.original.lastPrice / props.row.original.strike) * 100;
            return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(premium / 100).hex()}>{premium && (premium).toFixed(2) + '%'}</Text>;

          }
          else {
            return ''
          }
        },
      },
      {
        Header: "Ann. Premium % (if sold)",
        accessor: "annPremPerc",
        tooltip: 'This is the annualized premium taking into account the days until expiry. Green indicates that is is within your target range',
        Cell: (props) => {
          if (props.row.canExpand === false) {

            let dte = moment(props.row.original.expirationDate).diff(moment(), 'days');

            let premium = (props.row.original.lastPrice / props.row.original.strike) * 100;
            let ann = (premium / dte) * 365;



            if (annualProfitTarget === 0) {
              return <Text>{ann.toFixed(2) + '%'}</Text>;
            }
            return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(ann / 100).hex()}>{ann && (ann).toFixed(2) + '%'}</Text>;
          }
          else {
            return ''
          }
        },
      },
      // {
      //   Header: "Delta",
      //   accessor: "delta",
      //   // tooltip: 'T
      //   Cell: (props) => {
      //     try {
      //       return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(Math.abs(props.value)).hex()}>{props.value && (props.value).toFixed(2) + ''}</Text>;
      //     }
      //     catch (e) {
      //       return '';

      //     }

      //   },
      // },
      // {
      //   Header: "IV",
      //   accessor: "impliedVolatility",
      //   Cell: (props) => {
      //     try {

      //       return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(Math.abs(props.value)).hex()}>{props.value && (props.value).toFixed(2) + ''}</Text>;
      //     }
      //     catch (e) {
      //       return '';
      //     }

      //   },
      // },
      // {
      //   Header: "Rho",
      //   accessor: "rho",
      //   Cell: (props) => {
      //     try {
      //       return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(Math.abs(props.value)).hex()}>{props.value && (props.value).toFixed(2) + ''}</Text>;
      //     }
      //     catch (e) {
      //       return '';
      //     }
      //   },
      // },
      // {
      //   Header: "Theta",
      //   accessor: "theta",
      //   Cell: (props) => {
      //     try {
      //       return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(Math.abs(props.value)).hex()}>{props.value && (props.value).toFixed(2) + ''}</Text>;
      //     }
      //     catch (e) {
      //       return '';
      //     }
      //   },
      // },
      // {
      //   Header: "Vega",
      //   accessor: "vega",
      //   Cell: (props) => {
      //     try {
      //       return <Text color="black" bg={chroma.scale(['#f00', '#0f0']).mode('lrgb')(Math.abs(props.value)).hex()}>{props.value && (props.value).toFixed(2) + ''}</Text>;
      //     }
      //     catch (e) {
      //       return '';
      //     }
      //   },
      // },

      // {
      //   Header: "bid",
      //   accessor: "bid",
      //   Cell: (props) => {
      //     return props.value
      //   },
      // },
      // {
      //   Header: "ask",
      //   accessor: "ask",
      //   Cell: (props) => {
      //     return props.value
      //   },
      // },


    ],
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    initialState: {
      sortBy: [
        {
          id: 'expirationDate',
          desc: false
        },
        {
          id: 'strike',
          desc: true
        },
      ],
      // groupBy: ['expirationDate'],

    },
    columns,
    data,
  },
    useGroupBy,
    useSortBy,
    useExpanded,
  )

  return (
    <TableFrame {...getTableProps()}>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
              >
                <Tooltip label={column.tooltip} aria-label={column.tooltip}>
                  {column.render("Header")}
                </Tooltip>

                <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </TableHeader>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <Tr {...row.getRowProps()} className="row"
              onClick={() => {
                props.openModal(row.original.contractName)
              }}
            >
              {row.cells.map((cell) => (
                <Td className="cell" {...cell.getCellProps()} isNumeric={cell.column.isNumeric} padding={0}>
                  {cell.isGrouped ? (
                    <Box minWidth={'75px'} display="flex" flexDir="row" alignItems="center" >
                      <Box marginRight="10px" {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <Icon as={IoRemoveCircle} w={6} h={6} color="green.400" /> : <Icon as={IoAddCircleOutline} w={6} h={6} color="grey" />}
                      </Box>{' '}
                      {cell.render('Cell')}
                    </Box>
                  ) :
                    (cell.render('Cell'))}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </TableFrame>
  )
}

const mapStateToProps = state => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(ScanOptionChain);
