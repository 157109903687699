import { chakra, HTMLChakraProps, useColorMode, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const Logo = (props: HTMLChakraProps<'svg'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <chakra.svg aria-hidden width="200px" {...rest}>
      {useColorMode().colorMode === 'dark' ?
        <image height="25" id="img1" href="../images/svg/logo-long-white.svg" />
        :
        <image height="25" id="img1" href="../images/svg/logo-long-black.svg" />

      }
    </chakra.svg>
  )
}
